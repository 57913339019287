import {useClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import {ControlChartSelectionApi} from "src/api/generated/spc/controlChart/api/controlChartSelectionApi.ts";
import {ControlledAsyncDataGrid} from "src/components/common/dataGrid/ControlledAsyncDataGrid.tsx";
import {integerColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {AavoTextField} from "src/components/common/inputFields/AavoTextField.tsx";
import {DocumentsOfObjectButton} from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import {nullableAavoObjectRef} from "src/utils/aavoObjectRefUtils.ts";
import {ControlChartView} from "src/api/generated/postgres/db/types/public_/tables/controlChartView.ts";

export interface ControlChartsDataGridProps {
	chartGroupId: number;
	onSelectionChanged: (rows: ControlChartView[]) => void;
}

export const ControlChartsDataGrid = ({ chartGroupId, onSelectionChanged }: ControlChartsDataGridProps) => {
	const { dataGridProps, refreshData, onlySelectedRow } = useClientSideDataGridModel({
		fetchData: ({ searchQuery }) =>
			ControlChartSelectionApi.getControlChartsInChartGroup({
				chartGroupId,
				searchQuery,
			}),
		initialParams: {
			searchQuery: "",
		},
		getRowId: (row) => row.chartId,
		onSelectionChanged: onSelectionChanged,
		gridId: "DE6B5D6A1F0EC2F",
	});

	return (
		<ControlledAsyncDataGrid<ControlChartView>
			columns={[
				textColumn({
					field: "chartName",
					headerName: i18n.t("name"),
					width: 200,
				}),
				textColumn({
					field: "chartDescription",
					headerName: i18n.t("description"),
					width: 200,
				}),
				integerColumn({
					field: "chartId",
					headerName: i18n.t("id"),
				}),
			]}
			actionBarComponents={
				<>
					<DocumentsOfObjectButton
						objectRef={nullableAavoObjectRef("CONTROL_CHART", onlySelectedRow?.chartId)}
					/>
					<AavoTextField label={i18n.t("search")} onSubmit={(searchQuery) => refreshData({ searchQuery })} />
				</>
			}
			{...dataGridProps}
		/>
	);
};
