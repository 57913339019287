// This file is automatically generated. Do not edit manually.

import { IsoDateString } from "src/types/dateTime";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace LuukkuPurchaseOrdersApi {
	export async function getLuukkuPurchaseOrdersForImport(): Promise<
		Array<LuukkuPurchaseOrdersApi_LuukkuPurchaseOrder>
	> {
		const response = await makeAxiosRequest({
			url: "/api/ext/mellano/luukku/purchaseOrders/getLuukkuPurchaseOrdersForImport",
			method: "POST",
			data: {},
		});
		return response as Array<LuukkuPurchaseOrdersApi_LuukkuPurchaseOrder>;
	}

	export async function importPurchaseOrders(args: {
		luukkuPurchaseOrders: Array<LuukkuPurchaseOrdersApi_LuukkuPurchaseOrder>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/mellano/luukku/purchaseOrders/importPurchaseOrders",
			method: "POST",
			data: {
				luukkuPurchaseOrders: args.luukkuPurchaseOrders,
			},
		});
		return response as void;
	}
}

export interface LuukkuPurchaseOrdersApi_LuukkuPurchaseOrder {
	header: LuukkuPurchaseOrdersApi_LuukkuPurchaseOrderHeader;
	lines: Array<LuukkuPurchaseOrdersApi_LuukkuPurchaseOrderLine>;
}

export interface LuukkuPurchaseOrdersApi_LuukkuPurchaseOrderHeader {
	customer: string;
	deliveryDate: IsoDateString;
	orderNumber: number;
	reference: string;
	reservationDate: IsoDateString;
}

export interface LuukkuPurchaseOrdersApi_LuukkuPurchaseOrderLine {
	customer: string;
	deliveryDate: IsoDateString;
	lineNumber: number;
	orderNumber: number;
	partDescription: string;
	partNumber: string;
	quantity: number;
	reference: string;
	reservationDate: IsoDateString;
}
