import {CostEventsDataGridBase} from "src/components/views/erp/common/costEvents/CostEventsDataGridBase.tsx";
import {
    ProjectCostEventsDataGridsApi
} from "src/api/generated/erp/project/project/api/projectCostEventsDataGridsApi.ts";
import {textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {ProjectActivityCostEventView} from "src/api/generated/erp/db/types/tables/projectActivityCostEventView.ts";
import {CostEventsOfObjectService_GridData} from "src/api/generated/erp/common/costEvents/costEventsOfObjectService.ts";

export interface ProjectCostEventsDataGridProps {
	projectId: number;
	costEventPriceListId: number;
}

export const ProjectCostEventsDataGrid = ({ projectId, costEventPriceListId }: ProjectCostEventsDataGridProps) => {
	return (
		<CostEventsDataGridBase<
			ProjectActivityCostEventView,
			object,
			CostEventsOfObjectService_GridData<ProjectActivityCostEventView>
		>
			fetchData={({ dataModelRequest }) =>
				ProjectCostEventsDataGridsApi.getGridDataForProject({
					projectId,
					dataModelRequest,
				})
			}
			initialParams={{}}
			insert={{ type: "disabled" }}
			gridId={"A13FAF0C5AE1EA16"}
			getRowCostEventPriceListId={() => costEventPriceListId}
			extraColumns={[
				textColumn({
					field: "subProjectDescription",
					headerName: i18n.t("sub_project"),
					width: 150,
					position: 0,
				}),
				textColumn({
					field: "activityName",
					headerName: i18n.t("activity"),
					width: 150,
					position: 1,
				}),
			]}
		/>
	);
};
