// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { CostEventsOfObjectService_GridData } from "src/api/generated/erp/common/costEvents/costEventsOfObjectService";
import { ShopOrderCostEventsView } from "src/api/generated/erp/db/types/tables/shopOrderCostEventsView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ShopOrderCostEventsDataGridApi {
	export async function getGridDataForShopOrder(args: {
		shopOrderId: number;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<CostEventsOfObjectService_GridData<ShopOrderCostEventsView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderCostEventsDataGrid/getGridDataForShopOrder",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as CostEventsOfObjectService_GridData<ShopOrderCostEventsView>;
	}
}
