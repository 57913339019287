import { FormCommonProps } from "../../../../common/forms/types";
import { SitesApi, SitesApi_SiteFormData } from "src/api/generated/erp/common/sites/sitesApi";
import { Site } from "src/api/generated/erp/db/types/tables/site";
import { FormTextField } from "src/components/common/forms/fields/FormTextField";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox";
import { spanGridColumns, startNewGridRow } from "src/components/common/forms/styles";
import { getSiteCertificateTypeLabels } from "src/api/generated/erp/db/types/enums/siteCertificateType";
import { DeepPartial, useFieldArray } from "react-hook-form";
import { requiredNonNegativeIntegerRule, requireRule } from "src/components/common/forms/validation";
import { getPurchaseOrderLineAppendModeLabels } from "src/api/generated/erp/db/types/enums/purchaseOrderLineAppendMode";
import { FormEnumSelectField } from "../../../../common/forms/fields/FormEnumSelectField.tsx";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { getDeliveryPackageLabelSizeLabels } from "src/api/generated/erp/db/types/enums/deliveryPackageLabelSize.ts";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import {
	getObjectAccountingCodeIdsFromFormValues,
	getObjectAccountingFormDefaultValues,
} from "src/components/views/erp/sales/accounting/ObjectAccountingForm.utils.ts";
import {
	ObjectAccountingForm,
	ObjectAccountingFormValues,
} from "src/components/views/erp/sales/accounting/ObjectAccountingForm.tsx";
import { getReclamationAutomaticClosingLabels } from "src/api/generated/erp/db/types/enums/reclamationAutomaticClosing.ts";
import i18n from "i18next";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { FormRichTextEditor, FormRichTextEditorProps } from "src/components/common/forms/fields/FormRichTextEditor.tsx";
import { SiteTexts } from "src/api/generated/erp/db/types/tables/siteTexts.ts";
import { AavoFormLayout } from "src/components/common/forms/AavoFormLayout.tsx";
import {
	getObjectAttributesEmbeddedFormDefaultValues,
	getObjectAttributesFromEmbeddedFormValues,
} from "src/components/views/erp/objectAttributes/ObjectAttributesEmbeddedForm.utils.tsx";
import {
	ObjectAttributesEmbeddedForm,
	ObjectAttributesEmbeddedFormValues,
} from "../../objectAttributes/ObjectAttributesEmbeddedForm.tsx";
import { FormSection } from "src/components/common/forms/FormSection.tsx";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { faExternalLink, faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { DocumentPreviewView } from "src/components/views/documents/preview/DocumentPreviewView.tsx";

export interface SiteFormProps extends FormCommonProps<number> {
	siteId?: number;
}

interface FormValues extends Site, ObjectAccountingFormValues, ObjectAttributesEmbeddedFormValues {
	siteTexts: SiteTexts;
}

export const SiteForm = ({ siteId, onFormEdited, onCompleted }: SiteFormProps) => {
	return (
		<AsyncForm<SitesApi_SiteFormData, FormValues, number>
			fetch={() => SitesApi.getSiteFormData({ siteId })}
			getDefaultValues={({ site, siteTexts, attributeValues, accountingDimensions, attributeFields }) => {
				const siteDefaultValues =
					site !== null ?
						{
							...site,
							siteTexts: siteTexts ?? FORM_DEFAULT_VALUES.siteTexts,
							attributes: attributeValues,
						}
					:	FORM_DEFAULT_VALUES;

				return {
					...siteDefaultValues,
					...getObjectAttributesEmbeddedFormDefaultValues(attributeValues, attributeFields),
					...getObjectAccountingFormDefaultValues(accountingDimensions),
				};
			}}
			onFormEdited={onFormEdited}
			onCompleted={onCompleted}
			submit={submitForm}
			columns={1}
			render={(params) => <FormContent {...params} />}
			noLayout
		/>
	);
};

interface FormContentProps extends AsyncFormContentParams<SitesApi_SiteFormData, FormValues> {}

const FormContent = (params: FormContentProps) => {
	return (
		<AavoMosaic
			layout={{
				type: "tabs",
				items: [
					{
						type: "panel",
						title: i18n.t("site"),
						content: <SiteContent {...params} />,
					},
					{
						type: "panel",
						title: i18n.t("site_texts"),
						content: <SiteTextsContent {...params} />,
					},
				],
			}}
		/>
	);
};

const SiteContent = (props: FormContentProps) => {
	const {
		control,
		data: { companyOptions },
	} = props;
	return (
		<AavoFormLayout columns={2}>
			<FormTextField control={control} name={"siteName"} label={i18n.t("site")} rules={requireRule()} autoFocus />
			<FormTextField control={control} name={"description"} label={i18n.t("description")} />
			<FormSelectField
				control={control}
				name={"companyId"}
				options={companyOptions}
				getOptionKey={(o) => o.companyId}
				getOptionLabel={(o) => o.companyName}
				label={i18n.t("company")}
				rules={requireRule()}
			/>
			<FormNumberField control={control} name={"interestRatePercent"} label={`${i18n.t("interest_rate")} %`} />
			<FormTextField control={control} name={"address_1"} label={i18n.t("address_1")} />
			<FormTextField control={control} name={"address_2"} label={i18n.t("address_2")} />
			<FormTextField control={control} name={"postalCode"} label={i18n.t("postal_code")} />
			<FormTextField control={control} name={"city"} label={i18n.t("city")} />
			<FormTextField
				control={control}
				name={"pickingDurationDays"}
				label={i18n.t("picking_duration_days")}
				type={"number"}
				rules={requiredNonNegativeIntegerRule()}
				startNewGridRow
			/>
			<FormTextField
				control={control}
				name={"shopOrderLineObjectAdvanceDays"}
				label={i18n.t("shop_order_bom_acquisition_advance_days")}
				rules={requiredNonNegativeIntegerRule()}
			/>
			<FormEnumSelectField
				control={control}
				name={"certificateType"}
				label={i18n.t("certificate_type")}
				options={getSiteCertificateTypeLabels()}
				sx={startNewGridRow}
			/>
			<FormTextField control={control} name={"certificateNo"} label={i18n.t("certificate_number")} />
			<FormCheckbox
				control={control}
				name={"showFullOperationProductionBom"}
				label={i18n.t("show_full_operation_production_bom")}
				sx={spanGridColumns}
			/>
			<FormCheckbox
				control={control}
				name={"projectAutomaticClose"}
				label={i18n.t("close_projects_automatically")}
				sx={spanGridColumns}
			/>
			<FormCheckbox
				control={control}
				name={"subProjectAutomaticClose"}
				label={i18n.t("close_sub_projects_automatically")}
				sx={spanGridColumns}
			/>
			<FormCheckbox
				control={control}
				name={"shopOrderAutomaticReservation"}
				label={i18n.t("shop_order_automatic_reservation_on_release")}
				sx={spanGridColumns}
			/>
			<FormCheckbox
				control={control}
				name={"shopOrderAutomaticPicking"}
				label={i18n.t("shop_order_automatic_picking_on_release")}
				sx={spanGridColumns}
			/>
			<FormCheckbox
				control={control}
				name={"updateCustomerOrderLineCostWhenPicking"}
				label={i18n.t("update_customer_order_line_cost_when_picking")}
				sx={spanGridColumns}
			/>
			<FormCheckbox
				control={control}
				name={"inventoryLevelEnabledAsDefault"}
				label={i18n.t("inventory_level_enabled_as_default")}
				sx={spanGridColumns}
			/>
			<FormCheckbox
				control={control}
				name={"enableNegativeInventoryLevel"}
				label={i18n.t("enable_negative_inventory_level")}
				sx={spanGridColumns}
			/>
			<FormEnumSelectField
				control={control}
				name={"customerOrderPurchaseOrderLineAppendMode"}
				label={i18n.t("purchase_order_from_customer_order_line_append_mode")}
				options={getPurchaseOrderLineAppendModeLabels()}
				sx={spanGridColumns}
			/>
			<FormEnumSelectField
				control={control}
				name={"shopOrderPurchaseOrderLineAppendMode"}
				label={i18n.t("purchase_order_from_shop_order_line_append_mode")}
				options={getPurchaseOrderLineAppendModeLabels()}
				sx={spanGridColumns}
			/>
			<FormEnumSelectField
				control={control}
				name={"projectPurchaseOrderLineAppendMode"}
				label={i18n.t("purchase_order_from_project_append_mode")}
				options={getPurchaseOrderLineAppendModeLabels()}
				sx={spanGridColumns}
			/>
			<FormEnumSelectField
				control={control}
				name={"customerOrderPackageLabelDefaultSize"}
				label={i18n.t("package_label_size")}
				options={getDeliveryPackageLabelSizeLabels()}
				sx={spanGridColumns}
			/>
			<FormEnumSelectField
				control={control}
				name={"reclamationAutomaticClosing"}
				label={i18n.t("reclamation_automatic_closing")}
				options={getReclamationAutomaticClosingLabels()}
				sx={spanGridColumns}
			/>
			<FormNumberField
				control={control}
				name={"partReorderPointCalculationDefaultParams.replenishmentTimeDaysAvg"}
				label={i18n.t("part_reorder_point_default_replenishment_time_days_avg")}
				sx={spanGridColumns}
			/>
			<FormNumberField
				control={control}
				name={"partReorderPointCalculationDefaultParams.replenishmentTimeDaysStDev"}
				label={i18n.t("part_reorder_point_default_replenishment_time_days_st_dev")}
				sx={spanGridColumns}
			/>
			<FormNumberField
				control={control}
				name={"partReorderPointCalculationDefaultParams.serviceLevelPercent"}
				label={i18n.t("part_reorder_point_default_service_level_percent")}
				sx={spanGridColumns}
			/>

			<ObjectAccountingForm control={control} />
			<ObjectAttributesEmbeddedForm control={control} />

			<CustomerOfferDefaultDocuments {...props} />
		</AavoFormLayout>
	);
};

const CustomerOfferDefaultDocuments = ({ control, watch }: FormContentProps) => {
	const fieldArray = useFieldArray({
		control: control,
		name: "customerOfferDefaultDocuments",
	});
	const { openDialog } = useGenericDialog();

	return (
		<FormSection label={i18n.t("offer_default_documents")}>
			<VerticalBox
				flex={1}
				sx={{
					gridColumn: "1 / -1",
					gap: 2,
				}}
			>
				{fieldArray.fields.map((field, idx) => {
					const documentId = watch(`customerOfferDefaultDocuments.${idx}.documentId`);
					return (
						<HorizontalBox key={field.id} alignItems={"center"} gap={2}>
							<FormNumberField
								control={control}
								name={`customerOfferDefaultDocuments.${idx}.documentId`}
								label={i18n.t("document_no")}
								type={"integer"}
							/>
							<FormCheckbox
								control={control}
								name={`customerOfferDefaultDocuments.${idx}.pin`}
								label={i18n.t("pin")}
							/>
							<AavoButton
								icon={faExternalLink}
								tooltip={i18n.t("open")}
								onClick={() => {
									openDialog({
										title: i18n.t("document"),
										content: <DocumentPreviewView documentId={documentId} />,
									});
								}}
							/>
							<AavoButton
								icon={faTrash}
								tooltip={i18n.t("remove")}
								onClick={() => {
									fieldArray.remove(idx);
								}}
							/>
						</HorizontalBox>
					);
				})}
				<AavoButton
					icon={faPlus}
					label={i18n.t("add")}
					onClick={() => {
						fieldArray.append({
							// react-hook-form doesn't support partial default values for field arrays
							// See https://github.com/orgs/react-hook-form/discussions/10211
							documentId: undefined as any,
							pin: true,
						});
					}}
				/>
			</VerticalBox>
		</FormSection>
	);
};

const SiteTextsContent = ({ control }: FormContentProps) => {
	return (
		<AavoMosaic
			layout={{
				type: "tabs",
				items: [
					{
						type: "panel",
						title: i18n.t("consignment_note"),
						content: <SiteFormText control={control} name={"siteTexts.consignmentNotePrintoutText"} />,
					},
					{
						type: "panel",
						title: i18n.t("purchase_order"),
						content: <SiteFormText control={control} name={"siteTexts.purchaseOrderPrintoutText"} />,
					},
					{
						type: "panel",
						title: i18n.t("delivery_note"),
						content: <SiteFormText control={control} name={"siteTexts.deliveryNotePrintoutText"} />,
					},
					{
						type: "panel",
						title: i18n.t("invoice"),
						content: <SiteFormText control={control} name={"siteTexts.invoicePrintoutText"} />,
					},
					{
						type: "panel",
						title: i18n.t("customer_offer_start_text"),
						content: <SiteFormText control={control} name={"siteTexts.customerOfferPrintoutStartText"} />,
					},
					{
						type: "panel",
						title: i18n.t("customer_offer_end_text"),
						content: <SiteFormText control={control} name={"siteTexts.customerOfferPrintoutEndText"} />,
					},
					{
						type: "panel",
						title: i18n.t("order_confirmation"),
						content: (
							<SiteFormText control={control} name={"siteTexts.customerOrderConfirmationPrintoutText"} />
						),
					},
				],
			}}
		/>
	);
};

const SiteFormText = ({ control, name }: FormRichTextEditorProps<FormValues>) => {
	return (
		<FormRichTextEditor
			control={control}
			name={name}
			jasperReportsCompatibility
			sx={{
				flex: "1",
			}}
		/>
	);
};

const FORM_DEFAULT_VALUES: DeepPartial<FormValues> = {
	timeZone: "Europe/Helsinki",
	pickingDurationDays: 0,
	shopOrderLineObjectAdvanceDays: 0,
	showFullOperationProductionBom: false,
	projectAutomaticClose: false,
	subProjectAutomaticClose: false,
	shopOrderAutomaticPicking: true,
	shopOrderAutomaticReservation: true,
	siteTexts: {
		consignmentNotePrintoutText: "",
		purchaseOrderPrintoutText: "",
		deliveryNotePrintoutText: "",
		invoicePrintoutText: "",
		customerOfferPrintoutStartText: "",
		customerOfferPrintoutEndText: "",
		customerOrderConfirmationPrintoutText: "",
	},
};

const submitForm = async (values: FormValues): Promise<number> => {
	const { accountingDimensionValues, siteTexts, ...site } = values;
	const result = await SitesApi.save({
		site: site,
		attributes: getObjectAttributesFromEmbeddedFormValues(values),
		accountingCodeIds: getObjectAccountingCodeIdsFromFormValues(accountingDimensionValues),
		siteTexts: siteTexts,
	});
	return result.siteId;
};
