// This file is automatically generated. Do not edit manually.

import { SupplierPart } from "src/api/generated/erp/db/types/tables/supplierPart";
import { ErpUnit } from "src/api/generated/erp/db/types/tables/erpUnit";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SupplierPartEditApi {
	export async function getFormInitData(args: { supplierPartId: number }): Promise<SupplierPartEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/suppliers/supplierParts/getFormInitData",
			method: "POST",
			data: {
				supplierPartId: args.supplierPartId,
			},
		});
		return response as SupplierPartEditApi_FormInitData;
	}

	export async function update(args: { supplierPart: SupplierPart }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/suppliers/supplierParts/update",
			method: "POST",
			data: {
				supplierPart: args.supplierPart,
			},
		});
		return response as void;
	}

	export async function delete_(args: { supplierPartId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/suppliers/supplierParts/delete",
			method: "POST",
			data: {
				supplierPartId: args.supplierPartId,
			},
		});
		return response as void;
	}
}

export interface SupplierPartEditApi_FormInitData {
	erpUnitOptions: Array<ErpUnit>;
	supplierPart: SupplierPart;
}
