import i18n from "i18next";
import {AavoMosaic} from "src/components/common/mosaic/AavoMosaic.tsx";
import {
    ControlChartGraphView,
    ControlChartGraphViewApi,
} from "src/components/views/spc/controlChart/controlChartGraph/ControlChartGraphView.tsx";
import {
    UnresolvedSpecialCausesDataGrid
} from "src/components/views/spc/controlChart/controlChartInspectingView/UnresolvedSpecialCausesDataGrid.tsx";
import {useRef} from "react";
import {
    ControlChartEventsDataGrid
} from "src/components/views/spc/controlChart/controlChartInspectingView/ControlChartEventsDataGrid.tsx";
import {useRefreshRef} from "src/utils/useRefreshRef.ts";
import {
    ControlChartInspectingViewContext
} from "src/components/views/spc/controlChart/controlChartInspectingView/ControlChartInspectingViewContext.ts";
import {Dayjs} from "dayjs";

export interface ControlChartInspectingViewProps {
	controlChartId: number;
	recordsBeforeTimeLimit?: Dayjs | null;
}

export const ControlChartInspectingView = ({
	controlChartId,
	recordsBeforeTimeLimit,
}: ControlChartInspectingViewProps) => {
	return (
		<ControlChartInspectingViewContext.Provider
			value={{
				graphViewApiRef: useRef<ControlChartGraphViewApi | null>(null),
				eventsDataGridRefreshRef: useRefreshRef(),
				unresolvedSpecialCausesDataGridRefreshRef: useRefreshRef(),
			}}
		>
			<AavoMosaic
				viewId={"8100F9A57DD37374"}
				layout={{
					type: "row",
					first: {
						type: "panel",
						title: i18n.t("graph"),
						content: (
							<ControlChartGraphView
								controlChartIds={[controlChartId]}
								showOpenInspectingButton={false}
								recordsBeforeTimeLimit={recordsBeforeTimeLimit}
							/>
						),
					},
					second: {
						type: "column",
						first: {
							type: "panel",
							title: i18n.t("unresolved_special_causes"),
							content: <UnresolvedSpecialCausesDataGrid controlChartId={controlChartId} />,
						},
						second: {
							type: "panel",
							title: i18n.t("actions"),
							content: <ControlChartEventsDataGrid controlChartId={controlChartId} />,
						},
					},
				}}
			/>
		</ControlChartInspectingViewContext.Provider>
	);
};
