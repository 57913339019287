import { useMemo, useState } from "react";
import { ConfiguratorTreeItemState } from "src/components/views/erp/configurator/configuratorForm/configuratorTree/treeItemState/ConfiguratorTreeItemState.ts";
import {
	PartConfiguratorFormApi,
	PartConfiguratorFormApi_ConfiguratorTreeInitData,
} from "src/api/generated/erp/configurator/api/partConfiguratorFormApi.ts";
import { mapConfiguratorTreeState } from "src/components/views/erp/configurator/configuratorForm/configuratorTree/treeItemState/mapConfiguratorTreeState.ts";
import { OnConfiguratorInputChangedParams } from "src/components/views/erp/configurator/configuratorForm/PartConfiguratorForm.tsx";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/ErrorDialogContext.tsx";
import { logError } from "src/errorHandling/errorLogging";
import { SubConfiguratorPartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/impl/subConfiguratorPartConfiguratorType.ts";
import { useDebounce } from "src/utils/useDebounce.ts";
import {
	propagateConfiguratorInputChangeToAncestors, propagateParentPropertyValuesToDescendants,
	updateConfiguratorTreeItemState
} from "src/components/views/erp/configurator/configuratorForm/configuratorTree/treeItemState/configuratorTreeItemStateUpdaters.ts";
import {
	configuratorTreeHasErrors,
	findTreeItem,
	findTreeItemParent,
} from "src/components/views/erp/configurator/configuratorForm/configuratorTree/treeItemState/configuratorTreeItemQueries.ts";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { AddSubConfiguratorToTreeView } from "./AddSubConfiguratorToTreeView";
import { ConfiguratorTree_SubConfiguratorComponentDto } from "src/api/generated/erp/configurator/configuratorTree/configuratorTree.ts";
import i18n from "i18next";
import { ConfiguratorInitialInputSource } from "src/api/generated/erp/configurator/misc/configuratorInitialInputSource.ts";
import { FormResult } from "src/components/common/forms/types";
import { ConfiguratorInput } from "src/api/generated/erp/configurator/model/configuratorInput.ts";
import { ConfigurationPropertyValues } from "src/api/generated/erp/configurator/model/configurationPropertyValues.ts";
import {
	PartConfiguratorTreeStatelessView,
	PartConfiguratorTreeStatelessViewProps,
} from "src/components/views/erp/configurator/configuratorForm/configuratorTree/PartConfiguratorTreeStatelessView.tsx";
import { ConfiguratorTreeCombinedErrorsView } from "src/components/views/erp/configurator/configuratorForm/configuratorTree/ConfiguratorTreeCombinedErrorsView.tsx";

export interface PartConfiguratorTreeStatefulViewProps
	extends Pick<
		PartConfiguratorTreeStatelessViewProps,
		"hideFooter" | "footerExtraComponents" | "transformComponentsErrorCustomContent" | "readonly"
	> {
	initData: PartConfiguratorFormApi_ConfiguratorTreeInitData;
	onCompleted: (result: FormResult<PartConfiguratorTreeResult>) => Promise<unknown>;
}

export interface PartConfiguratorTreeResult {
	configurationSessionId: string;
	configuratorInput: ConfiguratorInput;
	propertyValues: ConfigurationPropertyValues;
}

export const PartConfiguratorTreeStatefulView = ({
	initData,
	hideFooter,
	footerExtraComponents,
	onCompleted,
	transformComponentsErrorCustomContent,
	readonly,
}: PartConfiguratorTreeStatefulViewProps) => {
	const rootConfigurator = initData.configuratorTree.rootConfigurator;
	const rootConfiguratorType = rootConfigurator.configuratorType;
	const rootConfigurationSessionId = rootConfigurator.configurationSessionId;

	const { withErrorHandling, logErrorAndShowOnDialog } = useErrorDialog();

	const [rootItemState, setRootItemState] = useState<ConfiguratorTreeItemState>(() =>
		mapConfiguratorTreeState({ configuratorTreeModel: initData.configuratorTree }),
	);
	const rootConfiguratorInput = rootItemState.configuratorInput;

	const [configuratorInputVersion, setConfiguratorInputVersion] = useState<number>(-1);
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(
		initData.configuratorTree.configuratorHasUnsavedChanges,
	);

	const [selectedItemKey, setSelectedItemKey] = useState<string>(rootItemState.key);
	const selectedItemState = useMemo(
		() => findTreeItem(rootItemState, selectedItemKey),
		[rootItemState, selectedItemKey],
	);

	const { openDialog } = useGenericDialog();
	const debounceSaveIncompleteConfiguration = useDebounce();

	return (
		<PartConfiguratorTreeStatelessView
			rootItem={rootItemState}
			selectedItem={selectedItemState}
			selectTreeItem={selectTreeItem}
			toggleTreeItemExpansion={toggleTreeItemExpansion}
			onAddSubConfiguratorClicked={onAddSubConfiguratorClicked}
			deleteSubConfiguratorListItem={deleteSubConfiguratorListItem}
			refreshTreeItemComputedValues={refreshTreeItemComputedValues}
			onConfiguratorInputChanged={onConfiguratorInputChanged}
			rootConfiguratorType={rootConfiguratorType}
			rootConfigurationSessionId={rootConfigurationSessionId}
			configuratorInputVersion={configuratorInputVersion}
			hasUnsavedChanges={hasUnsavedChanges}
			readonly={readonly}
			transformComponentsErrorCustomContent={transformComponentsErrorCustomContent}
			onSubmit={onSubmitForm}
			onCancel={onCancelForm}
			resetToExistingConfiguration={resetToExistingConfiguration}
			resetToDefaultValues={resetToDefaultValues}
			hideFooter={hideFooter}
			footerExtraComponents={footerExtraComponents}
		/>
	);

	async function onConfiguratorInputChanged(
		key: string,
		{ configuratorInput, errors, resolvedValues, reason }: OnConfiguratorInputChangedParams,
	) {
		let upToDateRootState = updateTreeItemStateChained(
			key,
			(currentState) => ({
				...currentState,
				configuratorInput: configuratorInput,
				errors: errors,
				label: resolvedValues?.configuratorLabel ?? currentState.label,
				propertyValues: resolvedValues?.propertyValues ?? currentState.propertyValues,
			}),
			rootItemState,
		);

		// Configurator input must be updated on every ancestor, too.
		upToDateRootState = updateTreeItemStateChained(
			rootItemState.key,
			(currentState) => propagateConfiguratorInputChangeToAncestors(currentState),
			upToDateRootState,
		);

		// Propagate parent property values to PartConfiguratorTypes of descendants.
		upToDateRootState = updateTreeItemStateChained(
			rootItemState.key,
			(currentState) => propagateParentPropertyValuesToDescendants(currentState),
			upToDateRootState,
		)

		if (reason === "inputChanged") {
			markInputToDirty();
			debounceSaveIncompleteConfiguration(100, async () => {
				try {
					await PartConfiguratorFormApi.saveIncompleteConfiguratorInput({
						configuratorType: rootConfiguratorType,
						configuratorInput: upToDateRootState.configuratorInput,
					});
				} catch (e) {
					logErrorAndShowOnDialog(e);
				}
			});
		}
	}

	async function selectTreeItem(newSelectedItemKey: string) {
		withErrorHandling(async () => {
			const oldSelectedItemKey = selectedItemKey;
			if (oldSelectedItemKey === newSelectedItemKey) return;
			await refreshTreeItemComputedValues(oldSelectedItemKey);
			setSelectedItemKey(newSelectedItemKey);
		});
	}

	function toggleTreeItemExpansion(key: string) {
		updateTreeItemState(key, (currentState) => ({
			...currentState,
			expanded: !currentState.expanded,
		}));
	}

	async function onAddSubConfiguratorClicked(parentKey: string) {
		const parentState = getTreeItemState(parentKey);
		if (parentState == null) return;

		openDialog(({ closeDialog }) => ({
			title: i18n.t("add_sub_configuration"),
			size: "sm",
			content: (
				<AddSubConfiguratorToTreeView
					subConfiguratorComponents={parentState.subConfiguratorComponents}
					existingSubConfigurators={parentState.children}
					onSubmit={async (selectedComponent) => {
						await addSubConfigurator(parentState, selectedComponent);
						await closeDialog();
					}}
				/>
			),
		}));
	}

	async function addSubConfigurator(
		parentState: ConfiguratorTreeItemState,
		subConfiguratorComponent: ConfiguratorTree_SubConfiguratorComponentDto,
	) {
		const newSubConfiguratorType: SubConfiguratorPartConfiguratorType = {
			type: "subConfigurator",
			configuratorVersion: null,
			isSubConfigurator: true,
			isTestConfigurator: parentState.configuratorType.isTestConfigurator,
			parentConfiguratorType: parentState.configuratorType,
			parentProperties: parentState.propertyValues,
			subConfiguratorCatalogPartId: subConfiguratorComponent.subConfiguratorCatalogPartId,
		};
		const newSubConfiguratorTreeInitData = await PartConfiguratorFormApi.getConfiguratorTreeInitData({
			rootConfiguratorType: newSubConfiguratorType,
		});
		const newItemsRoot = mapConfiguratorTreeState({
			configuratorTreeModel: newSubConfiguratorTreeInitData.configuratorTree,
		});
		newItemsRoot.selfConfigurationComponentName = subConfiguratorComponent.componentName;

		updateTreeItemState(parentState.key, (currentState) => ({
			...currentState,
			children: [...currentState.children, newItemsRoot],
		}));

		markInputToDirty();

		await selectTreeItem(newItemsRoot.key);
	}

	function deleteSubConfiguratorListItem(key: string) {
		const parentItem = findTreeItemParent(rootItemState, key);
		if (parentItem == null) {
			logError(`Parent item not found for sub configurator ${key}`);
			return;
		}
		const newChildren = parentItem.children.filter((child) => child.key !== key);
		updateTreeItemState(parentItem.key, (currentState) => ({
			...currentState,
			children: newChildren,
		}));

		markInputToDirty();
	}

	/**
	 * Refreshes propertyValues and label of the tree item with the given key.
	 * Returns updated root tree item.
	 */
	async function refreshTreeItemComputedValues(key: string) {
		const treeItem = getTreeItemState(key);
		if (treeItem == null) return;

		updateTreeItemState(key, (currentState) => ({
			...currentState,
			isLoading: true,
		}));

		try {
			const resolveStateResult = await PartConfiguratorFormApi.resolveConfiguratorState({
				configurationSessionId: treeItem.configurationSessionId,
				configuratorType: treeItem.configuratorType,
				configuratorInput: treeItem.configuratorInput,
			});
			updateTreeItemState(key, (currentState) => ({
				...currentState,
				propertyValues: resolveStateResult.propertyValues,
				label: resolveStateResult.label,
				isLoading: false,
			}));
		} catch (e) {
			updateTreeItemState(key, (currentState) => ({
				...currentState,
				isLoading: false,
			}));
			throw e;
		}
	}

	function updateTreeItemState(
		key: string,
		updateFn: (state: ConfiguratorTreeItemState) => ConfiguratorTreeItemState,
	) {
		setRootItemState((currentRootState) => updateConfiguratorTreeItemState(currentRootState, key, updateFn));
	}

	function updateTreeItemStateChained(
		key: string,
		updateFn: (state: ConfiguratorTreeItemState) => ConfiguratorTreeItemState,
		upToDateRootState: ConfiguratorTreeItemState,
	): ConfiguratorTreeItemState {
		const updatedRootState = updateConfiguratorTreeItemState(upToDateRootState, key, updateFn);
		setRootItemState(updatedRootState);
		return updatedRootState;
	}

	function getTreeItemState(key: string): ConfiguratorTreeItemState | undefined {
		return findTreeItem(rootItemState, key);
	}

	function markInputToDirty() {
		setHasUnsavedChanges(true);
		setConfiguratorInputVersion((prevVersion) => prevVersion + 1);
	}

	async function onSubmitForm() {
		const hasErrors = configuratorTreeHasErrors(rootItemState);
		if (hasErrors) {
			openDialog({
				title: i18n.t("configurator_form_errors_title"),
				size: "sm",
				content: (
					<ConfiguratorTreeCombinedErrorsView
						treeItem={rootItemState}
						sx={{
							marginX: 2,
							marginY: 1,
						}}
					/>
				),
			});
			return;
		}

		const finalState = await PartConfiguratorFormApi.resolveConfiguratorState({
			configurationSessionId: rootConfigurationSessionId,
			configuratorType: rootConfiguratorType,
			configuratorInput: rootConfiguratorInput,
		});
		// It is important to save final incomplete configurator input,
		// so that we know if configurator has unsaved changes on next time.
		await PartConfiguratorFormApi.clearIncompleteConfiguratorInput({
			configuratorType: rootConfiguratorType,
		});
		await onCompleted({
			type: "success",
			value: {
				configurationSessionId: rootConfigurationSessionId,
				configuratorInput: rootConfiguratorInput,
				propertyValues: finalState.propertyValues,
			},
		});
	}

	async function onCancelForm() {
		await onCompleted({
			type: "cancel",
			isEdited: true,
		});
	}

	async function resetToDefaultValues() {
		await resetForm("DEFAULT_VALUES");
	}

	async function resetToExistingConfiguration() {
		await resetForm("EXISTING_CONFIGURATION");
	}

	async function resetForm(forceSource: ConfiguratorInitialInputSource) {
		await PartConfiguratorFormApi.clearIncompleteConfiguratorInput({
			configuratorType: rootConfiguratorType,
		});
		const newInitData = await PartConfiguratorFormApi.getConfiguratorTreeInitData({
			rootConfiguratorType: rootConfiguratorType,
			forceInitialInputSource: forceSource,
		});
		const newRootItemState = mapConfiguratorTreeState({
			configuratorTreeModel: newInitData.configuratorTree,
		});

		setRootItemState(newRootItemState);
		setSelectedItemKey(newRootItemState.key);
		setHasUnsavedChanges(newInitData.configuratorTree.configuratorHasUnsavedChanges);
		setConfiguratorInputVersion((prevVersion) => prevVersion + 1);
	}
};
