import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { PartSelectionPartCategoryTreeView } from "src/components/views/erp/parts/PartSelectionPartCategoryTreeView.tsx";
import { PartCategory } from "src/api/generated/erp/db/types/tables/partCategory.ts";
import { useState } from "react";
import { InventoryLevelProfileDataGrid } from "src/components/views/erp/warehouse/inventory/InventoryLevelProfileDataGrid.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import i18n from "i18next";
import { FutureInventoryEventsDataGrid } from "src/components/views/erp/warehouse/inventory/FutureInventoryEventsDataGrid.tsx";
import { InventoryLevelsByPartWarehouseLocationsDataGrid } from "src/components/views/erp/warehouse/inventory/InventoryLevelsByPartWarehouseLocationsDataGrid.tsx";
import { PartFutureInventoryLevelChart } from "src/components/views/erp/warehouse/inventory/PartFutureInventoryLevelChart.tsx";

export interface InventoryLevelProfileViewProps {
	onlyPartToShowId?: number;
	isActive?: boolean
}

export const InventoryLevelProfileView = ({ onlyPartToShowId }: InventoryLevelProfileViewProps) => {
	const [selectedPartCategory, setSelectedPartCategory] = useState<PartCategory | null>(null);
	const [selectedPartId, setSelectedPartId] = useState<number | null>(onlyPartToShowId ?? null);
	return (
		<AavoMosaic
			viewId={"21B677540FC0609F"}
			layout={{
				type: "row",
				splitPercentage: 20,
				first: {
					hidden: onlyPartToShowId != null,
					type: "panel",
					title: i18n.t("part_categories"),
					content: (
						<PartSelectionPartCategoryTreeView
							selectedPartCategory={selectedPartCategory}
							setSelectedPartCategory={setSelectedPartCategory}
						/>
					),
				},
				second: {
					type: "column",
					first: {
						type: "panel",
						title: i18n.t("part_inventory_levels"),
						content: (
							<InventoryLevelProfileDataGrid
								key={selectedPartCategory?.partCategoryId ?? -1}
								partCategoryId={selectedPartCategory?.partCategoryId ?? null}
								onlyPartToShowId={onlyPartToShowId}
								setSelectedPartId={setSelectedPartId}
							/>
						),
					},
					second: {
						type: "row",
						first: {
							type: "tabs",
							items: [
								{
									type: "panel",
									key: "inventory_events",
									title: i18n.t("future_inventory_events"),
									content:
										selectedPartId == null ?
											<CenteredTypography>{i18n.t("select_part")}</CenteredTypography>
										:	<FutureInventoryEventsDataGrid key={selectedPartId} partId={selectedPartId} />,
								},
								{
									type: "panel",
									key: "inventoryLevelsByWarehouseLocations",
									title: i18n.t("inventory_levels_by_warehouse_locations"),
									content:
										selectedPartId == null ?
											<CenteredTypography>{i18n.t("select_part")}</CenteredTypography>
										:	<InventoryLevelsByPartWarehouseLocationsDataGrid
												key={selectedPartId}
												partId={selectedPartId}
											/>,
								},
							],
						},
						second: {
							type: "panel",
							title: i18n.t("forecast"),
							content:
								selectedPartId == null ?
									<CenteredTypography>{i18n.t("select_part")}</CenteredTypography>
								:	<PartFutureInventoryLevelChart key={selectedPartId} partId={selectedPartId} />,
						},
					},
				},
			}}
		/>
	);
};
