// This file is automatically generated. Do not edit manually.

import { ProcessImprovementConnectedSpecialCausesView } from "src/api/generated/postgres/db/types/public_/tables/processImprovementConnectedSpecialCausesView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProcessImprovementConnectedSpecialCausesApi {
	export async function getConnectedSpecialCauses(args: {
		processImprovementId: number;
	}): Promise<Array<ProcessImprovementConnectedSpecialCausesView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/processImprovementConnectedSpecialCauses/getConnectedSpecialCauses",
			method: "POST",
			data: {
				processImprovementId: args.processImprovementId,
			},
		});
		return response as Array<ProcessImprovementConnectedSpecialCausesView>;
	}

	export async function disconnectSpecialCauseFromProcessImprovement(args: { rawRecordId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/processImprovementConnectedSpecialCauses/disconnectSpecialCauseFromProcessImprovement",
			method: "POST",
			data: {
				rawRecordId: args.rawRecordId,
			},
		});
		return response as void;
	}
}
