// This file is automatically generated. Do not edit manually.

import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType";
import { ConfiguratorInitialInputSource } from "src/api/generated/erp/configurator/misc/configuratorInitialInputSource";
import { ConfiguratorInput } from "src/api/generated/erp/configurator/model/configuratorInput";
import { ConfiguratorEvent } from "src/api/generated/erp/configurator/model/configuratorEvent";
import { TransformComponentsResult } from "src/api/generated/erp/configurator/componentTransformation/transformationPipe/transformComponentsResult";
import { ConfiguratorTree } from "src/api/generated/erp/configurator/configuratorTree/configuratorTree";
import { ConfiguratorVersion } from "src/api/generated/erp/configurator/model/configuratorVersion";
import { ConfigurationPropertyValues } from "src/api/generated/erp/configurator/model/configurationPropertyValues";
import { IsoDateString } from "src/types/dateTime";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PartConfiguratorFormApi {
	export async function getConfigurationHasBeenEditedManually(args: {
		configuratorType: PartConfiguratorType;
	}): Promise<boolean> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuratorForm/getConfigurationHasBeenEditedManually",
			method: "POST",
			data: {
				configuratorType: args.configuratorType,
			},
		});
		return response as boolean;
	}

	export async function getConfiguratorTreeInitData(args: {
		rootConfiguratorType: PartConfiguratorType;
		forceInitialInputSource?: ConfiguratorInitialInputSource | null | undefined;
	}): Promise<PartConfiguratorFormApi_ConfiguratorTreeInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuratorForm/getConfiguratorTreeInitData",
			method: "POST",
			data: {
				rootConfiguratorType: args.rootConfiguratorType,
				forceInitialInputSource: args.forceInitialInputSource,
			},
		});
		return response as PartConfiguratorFormApi_ConfiguratorTreeInitData;
	}

	export async function saveIncompleteConfiguratorInput(args: {
		configuratorType: PartConfiguratorType;
		configuratorInput: ConfiguratorInput;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuratorForm/saveIncompleteConfiguratorInput",
			method: "POST",
			data: {
				configuratorType: args.configuratorType,
				configuratorInput: args.configuratorInput,
			},
		});
		return response as void;
	}

	export async function clearIncompleteConfiguratorInput(args: {
		configuratorType: PartConfiguratorType;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuratorForm/clearIncompleteConfiguratorInput",
			method: "POST",
			data: {
				configuratorType: args.configuratorType,
			},
		});
		return response as void;
	}

	export async function resolveConfiguratorState(args: {
		configurationSessionId: string;
		configuratorType: PartConfiguratorType;
		configuratorInput: ConfiguratorInput;
	}): Promise<PartConfiguratorFormApi_ResolveConfiguratorStateResult> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuratorForm/resolveConfiguratorState",
			method: "POST",
			data: {
				configurationSessionId: args.configurationSessionId,
				configuratorType: args.configuratorType,
				configuratorInput: args.configuratorInput,
			},
		});
		return response as PartConfiguratorFormApi_ResolveConfiguratorStateResult;
	}

	export async function transformComponents(args: {
		configurationSessionId: string;
		configuratorType: PartConfiguratorType;
		configuratorInput: ConfiguratorInput;
		event?: ConfiguratorEvent | null | undefined;
	}): Promise<TransformComponentsResult> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuratorForm/transformComponents",
			method: "POST",
			data: {
				configurationSessionId: args.configurationSessionId,
				configuratorType: args.configuratorType,
				configuratorInput: args.configuratorInput,
				event: args.event,
			},
		});
		return response as TransformComponentsResult;
	}

	export async function getComponentDocumentId(args: { configuratorComponentId: number }): Promise<number | null> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuratorForm/getComponentDocumentId",
			method: "POST",
			data: {
				configuratorComponentId: args.configuratorComponentId,
			},
		});
		return response === "" ? null : (response as number | null);
	}

	export async function getCurrentConfigurationSalesPrice(args: {
		configurationSessionId: string;
		configuratorType: PartConfiguratorType;
		configuratorInput: ConfiguratorInput;
	}): Promise<PartConfiguratorFormApi_GetConfigurationSalesPriceResult | null> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuratorForm/getCurrentConfigurationSalesPrice",
			method: "POST",
			data: {
				configurationSessionId: args.configurationSessionId,
				configuratorType: args.configuratorType,
				configuratorInput: args.configuratorInput,
			},
		});
		return response === "" ? null : (response as PartConfiguratorFormApi_GetConfigurationSalesPriceResult | null);
	}

	export async function getConfiguratorVersionInfo(args: {
		configuratorType: PartConfiguratorType;
	}): Promise<PartConfiguratorFormApi_ConfiguratorVersionInfo> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/configuratorForm/getConfiguratorVersionInfo",
			method: "POST",
			data: {
				configuratorType: args.configuratorType,
			},
		});
		return response as PartConfiguratorFormApi_ConfiguratorVersionInfo;
	}
}

export interface PartConfiguratorFormApi_ConfiguratorTreeInitData {
	configuratorTree: ConfiguratorTree;
}

export interface PartConfiguratorFormApi_ResolveConfiguratorStateResult {
	configuratorVersion: ConfiguratorVersion | null | undefined;
	label: string;
	propertyValues: ConfigurationPropertyValues;
}

export interface PartConfiguratorFormApi_GetConfigurationSalesPriceResult {
	salesPrice: number;
	salesPriceWithVat: number;
	vatPercent: number;
}

export interface PartConfiguratorFormApi_ConfiguratorVersionInfo {
	partDescription1: string;
	partNo: string;
	partRevisionNote: string;
	partRevisionNumber: number;
	partRevisionValidFrom: IsoDateString | null | undefined;
	productFamilyVersionNumber: number;
}
