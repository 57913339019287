import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {
    ProcessImprovementConnectedSpecialCausesApi
} from "src/api/generated/spc/processImprovement/processImprovementConnectedSpecialCausesApi.ts";
import {AavoDataGrid} from "src/components/common/dataGrid/AavoDataGrid.tsx";
import {dateTimeColumn, floatColumn, integerColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {faUnlink} from "@fortawesome/pro-regular-svg-icons";
import {
    OpenControlChartGraphButton
} from "src/components/views/spc/basedata/controlCharts/OpenControlChartGraphButton.tsx";
import {DocumentsOfObjectButton} from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import {nullableAavoObjectRef} from "src/utils/aavoObjectRefUtils.ts";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {
    ProcessImprovementConnectedSpecialCausesView
} from "src/api/generated/postgres/db/types/public_/tables/processImprovementConnectedSpecialCausesView.ts";
import dayjs from "dayjs";
import {getSpecialCauseDescriptionForCode} from "src/components/views/spc/specialCauseUtils.ts";

export interface ConnectedSpecialCausesDataGridProps {
	processImprovementId: number;
}

export const ConnectedSpecialCausesDataGrid = ({ processImprovementId }: ConnectedSpecialCausesDataGridProps) => {
	return (
		<ClientSideDataGridModel
			gridId={"9570AE9A9EC849AC"}
			fetchData={() =>
				ProcessImprovementConnectedSpecialCausesApi.getConnectedSpecialCauses({
					processImprovementId: processImprovementId,
				})
			}
			initialParams={{}}
			getRows={(data) => data}
			getRowId={(row) => row.rawRecordId}
			render={({ dataGridProps, onlySelectedRow, refreshData }) => {
				return (
					<AavoDataGrid<ProcessImprovementConnectedSpecialCausesView>
						columns={[
							textColumn({
								field: "chartGroupName",
								headerName: i18n.t("chart_group"),
							}),
							textColumn({
								field: "chartName",
								headerName: i18n.t("chart"),
							}),
							dateTimeColumn({
								field: "observationTime",
								headerName: i18n.t("observation_time"),
							}),
							floatColumn({
								field: "primaryValue",
								headerName: i18n.t("value"),
							}),
							textColumn({
								field: "specialCauseCode",
								headerName: i18n.t("special_cause"),
								valueGetter: (_, row) =>
									row.specialCauseCode == null ?
										""
									:	getSpecialCauseDescriptionForCode(row.specialCauseCode),
								sortable: false,
								width: 600,
							}),
							integerColumn({
								field: "rawRecordId",
								headerName: i18n.t("id"),
							}),
						]}
						rowContextMenuComponents={({ row }) => [
							<OpenControlChartGraphButton
								controlChartId={row.controlChartId}
								recordsBeforeTimeLimit={dayjs(row.observationTime)}
							/>,
						]}
						actionBarComponents={
							<>
								<DocumentsOfObjectButton
									objectRef={nullableAavoObjectRef("SPC_RECORD", onlySelectedRow?.rawRecordId)}
								/>
								<AsyncButton
									label={i18n.t("cancel_connection")}
									icon={faUnlink}
									variant={"outlined"}
									onClick={async () => {
										if (onlySelectedRow == null) return;
										await ProcessImprovementConnectedSpecialCausesApi.disconnectSpecialCauseFromProcessImprovement(
											{ rawRecordId: onlySelectedRow.rawRecordId },
										);
										await refreshData();
									}}
								/>
							</>
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
