import { JobQueueView } from "src/components/views/erp/production/jobQueue/base/JobQueueView.tsx";
import { ProductionLineJobQueueInitialsApi } from "src/api/generated/erp/production/jobQueues/api/productionLine/productionLineJobQueueInitialsApi.ts";
import { ProductionLineJobQueueInProgressApi } from "src/api/generated/erp/production/jobQueues/api/productionLine/productionLineJobQueueInProgressApi.ts";
import { ProductionLineJobQueueCompletedApi } from "src/api/generated/erp/production/jobQueues/api/productionLine/productionLineJobQueueCompletedApi.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faCheck, faExchangeAlt } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { SelectedOperationActionsViewExtraComponentProps } from "src/components/views/erp/production/jobQueue/base/inProgressView/SelectedOperationActionsView.tsx";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { ProductionLineApi } from "src/api/generated/erp/production/api/productionLineApi.ts";
import { ProductionPlanningActionsApi } from "src/api/generated/erp/production/productionPlanning/api/productionPlanningActionsApi.ts";
import { JobQueueInitialsViewExtraActionBarComponentsProps } from "src/components/views/erp/production/jobQueue/base/JobQueueInitialsView.tsx";
import { useUserPermissions } from "src/components/views/erp/common/userPermissions.ts";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { openProductionLineWorkCenterOperationActionViewDialog } from "src/components/views/erp/production/jobQueue/common/ProductionLineWorkCenterOperationActionView.utils.tsx";

export interface ProductionLineJobQueueViewProps {
	isActive: boolean;
}

export const ProductionLineJobQueueView = ({ isActive }: ProductionLineJobQueueViewProps) => {
	return (
		<JobQueueView
			viewId={"productionLineJobQueueView"}
			isActive={isActive}
			moveShopOrdersOnProductionLine
			initialsProps={{
				fetchData: ProductionLineJobQueueInitialsApi.getInitialOperations,
				extraActionBarComponents: (params) => <InitialsViewExtraActionBarComponents {...params} />,
			}}
			inProgressProps={{
				fetchData: ProductionLineJobQueueInProgressApi.getInProgressOperations,
				extraActionBarComponents: (params) => <InProgressViewExtraActionBarComponents {...params} />,
				confirmIfWorkCenterHasIncompleteOperations: true,
			}}
			completedProps={{
				fetchData: ProductionLineJobQueueCompletedApi.getCompletedOperations,
			}}
		/>
	);
};

const InitialsViewExtraActionBarComponents = ({
	selectedOperations,
	refreshData,
}: JobQueueInitialsViewExtraActionBarComponentsProps) => {
	const showInputDialog = useInputDialog();
	const userPermissions = useUserPermissions();
	return (
		<>
			{userPermissions.production.setShopOrderProductionLine && (
				<AsyncButton
					key={"changeProductionLine"}
					icon={faExchangeAlt}
					variant={"outlined"}
					label={i18n.t("change_production_line")}
					disabled={selectedOperations.length === 0}
					onClick={async () => {
						const productionLines = await ProductionLineApi.getProductionLines({
							siteId: selectedOperations[0]!.siteId,
							includeDeactivated: false,
						});
						const newProductionLineId = await showInputDialog({
							type: "singleNumberSelect",
							title: i18n.t("change_production_line"),
							fieldLabel: i18n.t("new_production_line"),
							required: true,
							defaultValue: null,
							fieldProps: {
								selection: {
									options: productionLines.map((line) => ({
										value: line.productionLineId,
										label: line.productionLineName,
									})),
								},
							},
						});
						if (newProductionLineId === undefined) return;

						await ProductionPlanningActionsApi.setProductionLineForShopOrders({
							productionLineId: newProductionLineId,
							shopOrderIds: selectedOperations.map((row) => row.shopOrderId),
						});
						await refreshData();
					}}
				/>
			)}
		</>
	);
};

const InProgressViewExtraActionBarComponents = ({
	onlySelectedOperation,
}: SelectedOperationActionsViewExtraComponentProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<>
			<AsyncButton
				icon={faCheck}
				label={i18n.t("operation_completion")}
				variant={"outlined"}
				disabled={!onlySelectedOperation}
				onClick={() => {
					openProductionLineWorkCenterOperationActionViewDialog({
						openDialog: openDialog,
						shopOrderOperation: onlySelectedOperation!,
					});
				}}
			/>
		</>
	);
};
