import {CostEventsDataGridBase} from "src/components/views/erp/common/costEvents/CostEventsDataGridBase.tsx";
import {textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {CostEventsOfObjectService_GridData} from "src/api/generated/erp/common/costEvents/costEventsOfObjectService.ts";
import {ShopOrderCostEventsView} from "src/api/generated/erp/db/types/tables/shopOrderCostEventsView.ts";
import {
    ShopOrderCostEventsDataGridApi
} from "src/api/generated/erp/production/api/shopOrder/shopOrderCostEventsDataGridApi.ts";

export interface ShopOrderCostEventsDataGridProps {
	shopOrderId: number;
}

export const ShopOrderCostEventsDataGrid = ({ shopOrderId }: ShopOrderCostEventsDataGridProps) => {
	return (
		<CostEventsDataGridBase<
			ShopOrderCostEventsView,
			object,
			CostEventsOfObjectService_GridData<ShopOrderCostEventsView>
		>
			fetchData={({ dataModelRequest }) =>
				ShopOrderCostEventsDataGridApi.getGridDataForShopOrder({
					shopOrderId,
					dataModelRequest,
				})
			}
			initialParams={{}}
			getRowCostEventPriceListId={(row) => row.costEventPriceListId}
			insert={{ type: "disabled" }}
			gridId={"1BE0E12B17A13D2"}
			extraColumns={[
				textColumn({
					field: "operationLongName",
					headerName: i18n.t("operation"),
					width: 150,
					position: 0,
				}),
			]}
		/>
	);
};
