import { ObjectAttributeDecodedValue } from "src/components/views/erp/objectAttributes/ObjectAttributeDecodedValue.ts";
import { ObjectAttributeFieldType } from "src/api/generated/erp/common/objectAttributes/model/objectAttributeFieldType.ts";
import { toFloatOrNull, toIntOrNull } from "src/utils/strings.tsx";
import dayjs from "dayjs";
import i18n from "i18next";
import { formatDayJs } from "src/utils/dayjsUtils.ts";
import { logError } from "src/errorHandling/errorLogging.ts";

export function decodeObjectAttributeValue(
	dbValue: string | null,
	type: ObjectAttributeFieldType,
): ObjectAttributeDecodedValue {
	try {
		switch (type.type) {
			case "text":
			case "textarea":
			case "richText":
				return {
					type: "string",
					value: dbValue ?? "",
				};
			case "integer": {
				return {
					type: "number",
					value: toIntOrNull(dbValue),
				};
			}
			case "decimal": {
				return {
					type: "number",
					value: toFloatOrNull(dbValue),
				};
			}
			case "checkbox": {
				return {
					type: "bool",
					value: dbValue === "1",
				};
			}
			case "date":
				return {
					type: "date",
					value: dbValue == null ? null : dayjs(dbValue, dateFormat),
				};
			case "dateTime":
				return {
					type: "dateTime",
					value: dbValue == null ? null : dayjs(dbValue, dateTimeFormat),
				};

			case "selection":
				return {
					type: "selection",
					value: dbValue != null ? JSON.parse(dbValue) : [],
				};
		}
	} catch (e) {
		logError(e, `Attribute value decoding failed. Value: ${dbValue}, type: ${type}`);
		return {
			type: "string",
			value: "",
		};
	}
}

export function encodeObjectAttributeValue(value: ObjectAttributeDecodedValue): string | null {
	switch (value.type) {
		case "string":
			return value.value;
		case "number":
			return value.value?.toString() ?? null;
		case "bool":
			return value.value ? "1" : "0";
		case "date":
			return value.value?.format(dateFormat) ?? null;
		case "dateTime":
			return value.value?.format(dateTimeFormat) ?? null;
		case "selection":
			return JSON.stringify(value.value);
	}
}

export function attributeValueToUserFriendlyString(
	value: ObjectAttributeDecodedValue,
	fieldType: ObjectAttributeFieldType,
): string {
	switch (value.type) {
		case "string":
			return value.value;
		case "number":
			return value.value?.toString() ?? "";
		case "bool":
			return value.value ? i18n.t("true") : i18n.t("false");
		case "date":
			return formatDayJs(value.value, "L");
		case "dateTime":
			return formatDayJs(value.value, "L LT");
		case "selection": {
			const options = fieldType.type === "selection" ? fieldType.options : {};
			return value.value.map((key) => options[key] ?? key).join(", ");
		}
	}
}

const dateFormat = "YYYY-MM-DD";
const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";
