import {
	DefaultFrontendViewProps,
	FrontendViewConfig,
	ResolvedFrontendViewConfig,
} from "src/components/views/frontendViews/frontendViewConfig.ts";
import React, { useMemo } from "react";
import { FRONTEND_VIEWS_BY_KEYS } from "src/components/views/frontendViews/frontendViews.ts";
import { ensureSuffix } from "src/utils/strings";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { logError } from "src/errorHandling/errorLogging.ts";
import { useStartUpData } from "src/contexts/StartUpDataContext.ts";

import { FrontendViewsContext } from "./FrontendViewsContext";

export interface FrontendViewsContextProviderProps {
	children: React.ReactNode;
}

export const FrontendViewsContextProvider = ({ children }: FrontendViewsContextProviderProps) => {
	const startUpData = useStartUpData();
	const userFrontendViewKeys = startUpData.frontendViews.map((view) => view.key);
	return (
		<AsyncFetchRender
			fetch={() => resolveMainMenuFrontendViews(userFrontendViewKeys)}
			content={(resolvedViews) => <Content allMainMenuFrontendViews={resolvedViews}>{children}</Content>}
		/>
	);
};

interface ContentProps extends FrontendViewsContextProviderProps {
	allMainMenuFrontendViews: ResolvedFrontendViewConfig[];
}

const Content = ({ children, allMainMenuFrontendViews }: ContentProps) => {
	const value = useMemo(() => {
		const allMainMenuFrontendViewsByKeys: Record<string, ResolvedFrontendViewConfig> =
			allMainMenuFrontendViews.reduce((acc, view) => ({ ...acc, [view.key]: view }), {});

		return {
			allMainMenuFrontendViews,
			getFrontendViewByUrl: (url: string) => allMainMenuFrontendViews.find((view) => view.url === url),
			getFrontendViewByKey: (key: string) => allMainMenuFrontendViewsByKeys[key],
		};
	}, [allMainMenuFrontendViews]);

	return <FrontendViewsContext.Provider value={value}>{children}</FrontendViewsContext.Provider>;
};

const resolveMainMenuFrontendViews = async <T extends DefaultFrontendViewProps>(
	userFrontendViewKeys: string[],
): Promise<ResolvedFrontendViewConfig<T>[]> => {
	const results = await Promise.all(
		Object.entries(FRONTEND_VIEWS_BY_KEYS)
			.filter(([key]) => userFrontendViewKeys.includes(key))
			.map(([key, config]) => resolveMainMenuFrontendView(key, config)),
	);
	return results.flat();
};

const resolveMainMenuFrontendView = async <T extends DefaultFrontendViewProps>(
	key: string,
	config: FrontendViewConfig<T>,
): Promise<ResolvedFrontendViewConfig<T>[]> => {
	try {
		if (config.type === "modal") return [];
		if (config.type === "static")
			return [
				{
					...config,
					component: config.component as React.FC<T>,
					props: {} as T,
					key: key,
					staticKey: key,
				},
			];

		const generateResult = await config.generate();
		return generateResult.map((generatedView) => ({
			component: config.component,
			props: generatedView.props,
			title: generatedView.title,
			icon: generatedView.icon ?? config.icon,
			url: ensureSuffix(config.baseUrl, "/") + generatedView.generatedKey,
			key: key + "_" + generatedView.generatedKey,
			staticKey: key,
		}));
	} catch (e) {
		logError(e, `Failed to resolve frontend view ${key}`);
		// Do not fail the whole app if one view fails to load
		return [];
	}
};
