import { ServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";
import { useGlobalInitData } from "src/contexts/GlobalInitDataContext.ts";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid";
import {
	SupplierPartsDataGridApi,
	SupplierPartsDataGridApi_Row,
} from "src/api/generated/erp/purchase/suppliers/api/supplierPartsDataGridApi";
import { booleanColumn, floatColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { SelectSiteField } from "src/components/views/erp/common/sites/SelectSiteField.tsx";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import { SupplierPartForm } from "./SupplierPartForm";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { SupplierPartEditApi } from "src/api/generated/erp/purchase/suppliers/api/supplierPartEditApi.ts";

export interface SupplierPartsDataGridProps {
	supplierId: number;
}

export const SupplierPartsDataGrid = ({ supplierId }: SupplierPartsDataGridProps) => {
	const { defaultSiteId } = useGlobalInitData();

	return (
		<ServerSideDataGridModel
			fetchData={(params) => SupplierPartsDataGridApi.getDataGridData({ supplierId, ...params })}
			initialParams={{
				searchQuery: "",
				showInactiveParts: false,
				siteId: genericNullableValue(defaultSiteId),
			}}
			getDataModelResult={(response) => response}
			getRowId={(row) => row.supplierPartId}
			gridId={"439F10E2B5D869B90"}
			render={({ dataGridProps, refreshData, currentParams, onlySelectedRow }) => (
				<CrudDataGrid<SupplierPartsDataGridApi_Row>
					columns={[
						textColumn({
							field: "siteName",
							headerName: i18n.t("site"),
						}),
						textColumn({
							field: "partLongName",
							headerName: i18n.t("part"),
							width: 250,
						}),
						textColumn({
							field: "purchaseUnit",
							headerName: i18n.t("purchase_unit"),
							width: 120,
						}),
						floatColumn({
							field: "purchaseFactor",
							headerName: i18n.t("purchase_factor"),
							width: 120,
						}),
						floatColumn({
							field: "purchasePriceUnit",
							headerName: i18n.t("purchase_unit"),
							width: 120,
						}),
						floatColumn({
							field: "purchasePriceFactor",
							headerName: i18n.t("price_factor"),
							width: 120,
						}),
						floatColumn({
							field: "purchasePrice",
							headerName: i18n.t("purchase_price"),
							width: 120,
						}),
						floatColumn({
							field: "contractPrice",
							headerName: i18n.t("price_from_contract"),
							width: 120,
						}),
						floatColumn({
							field: "packSize",
							headerName: i18n.t("pack_size"),
							width: 120,
						}),
						textColumn({
							field: "supplierPartNo",
							headerName: i18n.t("supplier_part_no"),
							width: 120,
						}),
						textColumn({
							field: "supplierPartDescription",
							headerName: i18n.t("supplier_part_description"),
							width: 120,
						}),
						integerColumn({
							field: "deliveryDuration",
							headerName: i18n.t("delivery_duration_days"),
							width: 120,
						}),
						booleanColumn({
							field: "isDefault",
							headerName: i18n.t("default"),
						}),
						booleanColumn({
							field: "partIsActive",
							headerName: i18n.t("active"),
						}),
					]}
					actionBarComponents={
						<>
							<DocumentsOfObjectButton
								objectRef={nullableAavoObjectRef("SUPPLIER_PART", onlySelectedRow?.supplierPartId)}
							/>
							<AavoTextField
								label={i18n.t("search")}
								onSubmit={(searchQuery) => refreshData({ searchQuery })}
							/>
							<SelectSiteField
								value={currentParams.siteId}
								onChange={(siteId) => refreshData({ siteId })}
							/>
						</>
					}
					actionBarMenuComponents={[
						<MenuCheckbox
							key={"showInactiveParts"}
							label={i18n.t("show_deactivated")}
							checked={currentParams.showInactiveParts}
							onChange={(showInactiveParts) => refreshData({ showInactiveParts })}
						/>,
					]}
					form={{
						addRowEnabled: false,
						editEnabled: true,
						dialogSize: "xl",
						dialogTitle: (row) => row?.partLongName ?? i18n.t("supplier_part"),
						component: ({ row, onCompleted, onFormEdited }) => (
							<SupplierPartForm
								supplierPartId={row!.supplierPartId}
								onCompleted={onCompleted}
								onFormEdited={onFormEdited}
							/>
						),
					}}
					remove={({ row }) => SupplierPartEditApi.delete_({ supplierPartId: row.supplierPartId })}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
