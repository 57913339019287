import i18n from "i18next";
import { formatMoney, formatNumber } from "src/utils/numberUtils.ts";
import { LabelValueView } from "src/components/common/misc/LabelValueView.tsx";
import { calculateSalesMarginPercent } from "src/components/views/erp/sales/salesUtils.ts";
import { RawHtmlContent } from "src/components/common/RawHtmlContent.tsx";
import { PartConfigurationView } from "src/api/generated/erp/db/types/tables/partConfigurationView.ts";
import { TypographyWithInfoCircle } from "src/components/common/typography/TypographyWithInfoCircle.tsx";
import { linebreaksToHtmlBreaks } from "src/utils/strings.tsx";

export interface PartConfigurationTopLevelDataViewProps {
	partConfiguration: PartConfigurationView;
}

export const PartConfigurationTopLevelDataView = ({ partConfiguration }: PartConfigurationTopLevelDataViewProps) => {
	return (
		<LabelValueView
			sx={{
				margin: 1,
				flex: 1,
			}}
			items={[
				{
					label: i18n.t("description"),
					value: partConfiguration.description,
				},
				{
					label: i18n.t("material_cost"),
					value: formatMoney(partConfiguration.materialCost),
				},
				{
					label: i18n.t("work_cost"),
					value: formatMoney(partConfiguration.workCost),
				},
				{
					label: i18n.t("total_cost"),
					value: formatMoney(partConfiguration.totalCost),
				},
				...(partConfiguration.salesPrice !== null ?
					[
						{
							label: i18n.t("calculated_price"),
							value: formatMoney(partConfiguration.basePrice),
						},
						{
							label: i18n.t("discount"),
							value: formatNumber(partConfiguration.discountPercentage) + " %",
						},
						{
							label: i18n.t("sales_price"),
							value: formatMoney(partConfiguration.salesPrice),
						},
						{
							label: i18n.t("profit_margin"),
							value:
								formatNumber(
									calculateSalesMarginPercent({
										cost: partConfiguration.totalCost ?? 0,
										salesPrice: partConfiguration.salesPrice,
									}),
									{ maxDigits: 0 },
								) + " %",
						},
					]
				:	[]),
				{
					label: i18n.t("weight"),
					value: partConfiguration.weight != null && formatNumber(partConfiguration.weight),
				},
				{
					label: i18n.t("info_text"),
					value: <RawHtmlContent html={partConfiguration.infoText} />,
				},
				{
					label: i18n.t("configuration_id"),
					value: partConfiguration.partConfigurationId,
				},
				{
					label: i18n.t("part_revision"),
					value: (
						<TypographyWithInfoCircle
							infoContent={linebreaksToHtmlBreaks(partConfiguration.partRevisionNote ?? "")}
						>
							{partConfiguration.revisionNumber}
						</TypographyWithInfoCircle>
					),
				},
				{
					label: i18n.t("product_family_version"),
					value: partConfiguration.productFamilyVersionNumber,
				},
				{
					label: i18n.t("log_key"),
					value: partConfiguration.configurationLogPathBase64,
				},
			]}
		/>
	);
};
