import { ConfiguratorTreeItemState } from "src/components/views/erp/configurator/configuratorForm/configuratorTree/treeItemState/ConfiguratorTreeItemState.ts";

export function findTreeItem(rootItem: ConfiguratorTreeItemState, key: string): ConfiguratorTreeItemState | undefined {
	if (rootItem.key === key) {
		return rootItem;
	}

	for (const child of rootItem.children) {
		const result = findTreeItem(child, key);
		if (result) {
			return result;
		}
	}

	return undefined;
}

export function findTreeItemParent(
	rootItem: ConfiguratorTreeItemState,
	childKey: string,
): ConfiguratorTreeItemState | undefined {
	for (const child of rootItem.children) {
		if (child.key === childKey) {
			return rootItem;
		}

		const result = findTreeItemParent(child, childKey);
		if (result) {
			return result;
		}
	}

	return undefined;
}

export function configuratorTreeHasErrors(treeItem: ConfiguratorTreeItemState): boolean {
	if (treeItem.errors.length > 0) {
		return true;
	}

	return treeItem.children.some((child) => configuratorTreeHasErrors(child));
}
