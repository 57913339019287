import { useMemo } from "react";
import { BoxProps, Button, Typography } from "@mui/material";
import { mergeSx } from "src/utils/styles";
import i18n from "i18next";
import { linebreaksToHtmlBreaks } from "src/utils/strings";
import { OpenErrorReportDialogButton } from "src/sentry/OpenErrorReportDialogButton";
import { VerticalBox } from "../box/VerticalBox";
import { LoggedError } from "src/errorHandling/errorLogging.ts";
import { faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import Box from "@mui/material/Box";
import { OutdatedFrontendVersionOnErrorView } from "src/components/common/errors/OutdatedFrontendVersionOnErrorView.tsx";
import { KnownApiErrorView } from "src/components/common/errors/KnownApiErrorView.tsx";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

export interface ErrorViewProps extends BoxProps {
	error: LoggedError;
	showReportButton?: boolean;
	reload?: () => void;
	disableCustomizedErrorViews?: boolean;
	sx?: SxProps<Theme>;
}

export const ErrorView = (props: ErrorViewProps) => {
	const { error, reload, disableCustomizedErrorViews, sx } = props;
	if (error.knownError != null && !disableCustomizedErrorViews) {
		return (
			<VerticalBox
				flex={1}
				alignItems={"center"}
				justifyContent={"center"}
				sx={mergeSx(
					{
						flex: 1,
					},
					sx,
				)}
			>
				<KnownApiErrorView
					error={error.knownError}
					renderOutdatedFrontendVersionMessage={() => <OutdatedFrontendVersionOnErrorView error={error} />}
					reload={reload}
				/>
			</VerticalBox>
		);
	} else {
		return <FallbackErrorView {...props} />;
	}
};

const FallbackErrorView = ({ error, reload, showReportButton = true, sx, ...other }: ErrorViewProps) => {
	const errorContent = useMemo(() => {
		const ret = linebreaksToHtmlBreaks(error.message, {
			textAlign: "center",
			marginBottom: 1,
		});
		if (error.sentryEventId)
			ret.push(
				<Typography
					key={"aavo-error-text-log-id"}
					sx={{
						textAlign: "center",
					}}
				>
					{`${i18n.t("log_id")}: ${error.sentryEventId}`}
					{error.backendLogId && `-${error.backendLogId}`}
				</Typography>,
			);
		return ret;
	}, [error]);

	return (
		<VerticalBox
			sx={mergeSx(
				{
					flex: 1,
					gap: 1,
					justifyContent: "center",
				},
				sx,
			)}
			{...other}
		>
			<Box
				sx={{
					gap: 1,
					overflow: "auto",
				}}
			>
				{errorContent}
			</Box>
			<HorizontalBox
				sx={{
					justifyContent: "center",
					alignItems: "center",
					minHeight: "auto",
					flexWrap: "wrap",
					gap: 1,
					marginY: 1,
				}}
			>
				{reload && (
					<Button
						variant={"outlined"}
						startIcon={<FontAwesomeIcon icon={faRefresh} />}
						onClick={() => {
							reload();
						}}
					>
						<Typography variant={"button"}>{i18n.t("reload")}</Typography>
					</Button>
				)}
				{showReportButton && error.treatAsError && error.sentryEventId && (
					<OpenErrorReportDialogButton eventId={error.sentryEventId} />
				)}
				<OutdatedFrontendVersionOnErrorView error={error} />
			</HorizontalBox>
		</VerticalBox>
	);
};
