// This file is automatically generated. Do not edit manually.

import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SupplierActionApi {
	export async function deactivateSupplier(args: { supplierId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/suppliers/deactivateSupplier",
			method: "POST",
			data: {
				supplierId: args.supplierId,
			},
		});
		return response as void;
	}

	export async function activateSupplier(args: { supplierId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/suppliers/activateSupplier",
			method: "POST",
			data: {
				supplierId: args.supplierId,
			},
		});
		return response as void;
	}

	export async function getAllSupplierPartsCsv(): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/suppliers/getAllSupplierPartsCsv",
			method: "POST",
			data: {},
		});
		return response as FrontendDownloadFileHandle;
	}
}
