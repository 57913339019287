import { AsyncButton, AsyncButtonProps } from "src/components/common/buttons/AsyncButton.tsx";
import i18n from "i18next";
import { faUndo } from "@fortawesome/pro-regular-svg-icons";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import Typography from "@mui/material/Typography";
import { StackedIcon } from "src/components/common/icons/StackedIcon.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/pro-solid-svg-icons";
import { ConfiguratorTreeFooterProps } from "src/components/views/erp/configurator/configuratorForm/components/ConfiguratorTreeFooter.tsx";

export interface ResetConfiguratorValuesButtonProps extends Omit<AsyncButtonProps, "onClick"> {
	resetToExistingConfiguration?: () => Promise<unknown>;
	resetToDefaultValues?: () => Promise<unknown>;
}

export const ResetConfiguratorValuesButton = ({
	resetToExistingConfiguration,
	resetToDefaultValues,
	...other
}: ResetConfiguratorValuesButtonProps) => {
	const { openDialog } = useGenericDialog();

	if (resetToExistingConfiguration == null && resetToDefaultValues == null) return;

	return (
		<AsyncButton
			label={i18n.t("reset")}
			icon={faUndo}
			onClick={async () => {
				openDialog(({ closeDialog }) => ({
					title: i18n.t("reset_configurator_form"),
					size: "sm",
					content: (
						<ResetFormDialogContent
							resetToDefaultValues={resetToDefaultValues}
							resetToExistingConfiguration={resetToExistingConfiguration}
							closeDialog={closeDialog}
						/>
					),
				}));
			}}
			{...other}
		/>
	);
};

interface ResetFormDialogContentProps
	extends Pick<ConfiguratorTreeFooterProps, "resetToDefaultValues" | "resetToExistingConfiguration"> {
	closeDialog: () => void;
}

const ResetFormDialogContent = ({
	resetToDefaultValues,
	resetToExistingConfiguration,
	closeDialog,
}: ResetFormDialogContentProps) => {
	return (
		<VerticalBox gap={1.5} margin={1.5}>
			<Typography>{i18n.t("what_would_you_like_to_do")}</Typography>
			<AsyncButton
				disabled={resetToDefaultValues == null}
				label={i18n.t("reset_to_default_values")}
				variant={"outlined"}
				icon={faUndo}
				onClick={async () => {
					await resetToDefaultValues?.();
					closeDialog();
				}}
			/>
			<AsyncButton
				disabled={resetToExistingConfiguration == null}
				label={i18n.t("reset_to_existing_configuration")}
				variant={"outlined"}
				icon={faUndo}
				onClick={async () => {
					await resetToExistingConfiguration?.();
					closeDialog();
				}}
				iconEl={
					<StackedIcon>
						<FontAwesomeIcon icon={faUndo} />
						<FontAwesomeIcon
							icon={faFloppyDisk}
							transform={{
								size: 8,
								x: -11,
								y: 4,
							}}
						/>
					</StackedIcon>
				}
			/>
		</VerticalBox>
	);
};
