import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {
    WorkCenterWarehouseAreaApi
} from "src/api/generated/erp/production/basedata/workcenters/workCenterWarehouseAreaApi.ts";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {dateTimeColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {
    WorkCenterWarehouseAreaForm
} from "src/components/views/erp/production/baseData/workcenter/WorkCenterWarehouseAreaForm.tsx";
import {WorkcenterWarehouseArea} from "src/api/generated/erp/db/types/tables/workcenterWarehouseArea.ts";

export interface WorkCenterWarehouseAreaDataGridProps {
	selectedWorkCenterId: number;
}

export const WorkCenterWarehouseAreaDataGrid = ({ selectedWorkCenterId }: WorkCenterWarehouseAreaDataGridProps) => {
	return (
		<ClientSideDataGridModel
			gridId={"C875701748B78F53"}
			fetchData={() =>
				WorkCenterWarehouseAreaApi.getWorkCenterWarehouseAreas({ workCenterId: selectedWorkCenterId })
			}
			initialParams={{}}
			getRows={(data) => data}
			getRowId={(row) => row.workcenterWarehouseAreaId}
			render={({ dataGridProps }) => {
				return (
					<CrudDataGrid<WorkcenterWarehouseArea>
						columns={[
							textColumn({
								field: "warehouse" as any,
								headerName: i18n.t("warehouse"),
							}),
							textColumn({
								field: "aisle" as any,
								headerName: i18n.t("aisle"),
							}),
							textColumn({
								field: "shelfSpace" as any,
								headerName: i18n.t("shelf_space"),
							}),
							textColumn({
								field: "tier" as any,
								headerName: i18n.t("tier"),
							}),
							textColumn({
								field: "locker" as any,
								headerName: i18n.t("locker"),
							}),
							dateTimeColumn({
								field: "createdAt",
								headerName: i18n.t("created_at"),
								width: 150,
							}),
						]}
						form={{
							addRowEnabled: true,
							editEnabled: true,
							dialogTitle: i18n.t("warehouse_area"),
							dialogSize: "sm",
							component: ({ row, onFormEdited, onCompleted }) => (
								<WorkCenterWarehouseAreaForm
									workCenterId={selectedWorkCenterId}
									workCenterWarehouseArea={row}
									onCompleted={onCompleted}
									onFormEdited={onFormEdited}
								/>
							),
						}}
						remove={({ row }) =>
							WorkCenterWarehouseAreaApi.delete_({
								workCenterWarehouseAreaId: row.workcenterWarehouseAreaId,
							})
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
