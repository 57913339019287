import { Box, Typography } from "@mui/material";
import { ConfiguratorTree_SubConfiguratorComponentDto } from "src/api/generated/erp/configurator/configuratorTree/configuratorTree.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton";
import { ConfiguratorTreeItemState } from "src/components/views/erp/configurator/configuratorForm/configuratorTree/treeItemState/ConfiguratorTreeItemState.ts";

export interface AddSubConfiguratorToTreeViewProps {
	subConfiguratorComponents: ConfiguratorTree_SubConfiguratorComponentDto[];
	existingSubConfigurators: ConfiguratorTreeItemState[];
	onSubmit: (selectedComponent: ConfiguratorTree_SubConfiguratorComponentDto) => Promise<void>;
}

export const AddSubConfiguratorToTreeView = ({
	subConfiguratorComponents,
	existingSubConfigurators,
	onSubmit,
}: AddSubConfiguratorToTreeViewProps) => {
	const usableSubConfiguratorComponents = filterSubConfigurators(subConfiguratorComponents, existingSubConfigurators);
	return (
		<Box
			sx={{
				flex: 1,
				alignSelf: "stretch",
				display: "grid",
				gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
				alignItems: "stretch",
				gap: 1.5,
				padding: 1,
			}}
		>
			{usableSubConfiguratorComponents.map((subConfiguratorComponent) => (
				<AsyncButton
					key={subConfiguratorComponent.configurationComponentId}
					variant={"outlined"}
					onClick={async () => {
						await onSubmit(subConfiguratorComponent);
					}}
				>
					<Typography
						variant={"button"}
						sx={{
							textAlign: "center",
							padding: 0.5
						}}
					>
						{subConfiguratorComponent.label}
					</Typography>
				</AsyncButton>
			))}
		</Box>
	);
};

const filterSubConfigurators = (
	subConfiguratorComponents: ConfiguratorTree_SubConfiguratorComponentDto[],
	existingSubConfigurators: ConfiguratorTreeItemState[],
): ConfiguratorTree_SubConfiguratorComponentDto[] => {
	const existingSubConfiguratorComponentNames = existingSubConfigurators.map(
		(subConfigurator) => subConfigurator.selfConfigurationComponentName,
	);
	return subConfiguratorComponents.filter((subConfiguratorComponent) => {
		switch (subConfiguratorComponent.componentType) {
			case "SUB_CONFIGURATOR_LIST":
				return true;
			case "SINGLE_CONFIGURATOR":
				return !existingSubConfiguratorComponentNames.includes(subConfiguratorComponent.componentName);
		}
	});
};
