import { PartConfiguratorTopLevelView } from "src/components/views/erp/configurator/configuratorForm/PartConfiguratorTopLevelView.tsx";
import { PartConfiguratorTreeResult } from "src/components/views/erp/configurator/configuratorForm/configuratorTree/PartConfiguratorTreeStatefulView.tsx";
import { ConfigurationCreationApi } from "src/api/generated/erp/configurator/api/configurationCreationApi";
import i18n from "i18next";
import { PartConfigurationInspectingView } from "src/components/views/erp/configurator/inspecting/PartConfigurationInspectingView.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { askObjectConfigurationHistoryEntryInput } from "src/components/views/erp/configurator/objectConfigurationHistory/ObjectConfigurationHistoryEntryInputForm.utils.tsx";
import { PurchaseOrderLinePartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/impl/purchaseOrderLinePartConfiguratorType.ts";

export interface PurchaseOrderLineConfiguratorViewProps {
	configuratorType: PurchaseOrderLinePartConfiguratorType;
	closeDialog: () => Promise<unknown>;
	refreshSourceView?: () => void;
	disabled?: boolean;
}

export const PurchaseOrderLineConfiguratorView = ({
	configuratorType,
	closeDialog,
	refreshSourceView,
	disabled,
}: PurchaseOrderLineConfiguratorViewProps) => {
	const { openDialog } = useGenericDialog();
	const purchaseOrderLineId = configuratorType.purchaseOrderLineId;

	return (
		<PartConfiguratorTopLevelView
			configuratorType={configuratorType}
			readonly={disabled}
			onCompleted={async (result) => {
				if (result.type === "success") {
					await onSubmit(result.value);
				} else {
					closeDialog?.();
				}
			}}
		/>
	);

	async function onSubmit({ propertyValues, configurationSessionId }: PartConfiguratorTreeResult) {
		const historyEntryInput = await askObjectConfigurationHistoryEntryInput({
			openDialog,
			objectType: "PURCHASE_ORDER_LINE",
			objectId: purchaseOrderLineId,
		});
		if (historyEntryInput === undefined) return;

		const partConfigurationId = await ConfigurationCreationApi.createPartConfigurationForPurchaseOrderLine({
			purchaseOrderLineId: purchaseOrderLineId,
			propertyValues: propertyValues,
			configurationSessionId: configurationSessionId,
			historyEntryInput: historyEntryInput,
		});

		await closeDialog();
		refreshSourceView?.();

		openDialog(() => ({
			title: i18n.t("configuration"),
			content: <PartConfigurationInspectingView partConfigurationId={partConfigurationId} showBom={false} />,
		}));
	}
};
