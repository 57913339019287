import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import i18n from "i18next";
import { AavoFormFooter } from "src/components/common/forms/AavoFormFooter.tsx";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import Typography from "@mui/material/Typography";
import React from "react";
import { ConfiguratorFormFooterPriceView } from "src/components/views/erp/configurator/configuratorForm/components/ConfiguratorFormFooterPriceView.tsx";
import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType.ts";
import { VersionedConfiguratorInput } from "src/components/views/erp/configurator/configuratorForm/VersionedConfiguratorInput.ts";
import { Box } from "@mui/material";
import { ResetConfiguratorValuesButton } from "src/components/views/erp/configurator/configuratorForm/components/ResetConfiguratorValuesButton.tsx";

export interface ConfiguratorTreeFooterProps {
	rootConfigurationSessionId: string;
	rootConfiguratorType: PartConfiguratorType;
	rootConfiguratorInput: VersionedConfiguratorInput;
	rootConfiguratorInputErrors: string[];
	onCancel?: () => void;
	resetToExistingConfiguration?: () => Promise<unknown>;
	resetToDefaultValues?: () => Promise<unknown>;
	submitForm?: () => Promise<unknown>;
	hasUnsavedChanges: boolean;
	extraComponents?: React.ReactNode;
}

export const ConfiguratorTreeFooter = (props: ConfiguratorTreeFooterProps) => {
	const {
		rootConfigurationSessionId,
		rootConfiguratorType,
		rootConfiguratorInput,
		rootConfiguratorInputErrors,
		onCancel,
		submitForm,
		resetToExistingConfiguration,
		resetToDefaultValues,
		hasUnsavedChanges,
		extraComponents,
	} = props;

	return (
		<AavoFormFooter
			sx={{
				paddingLeft: 1,
			}}
		>
			<ConfiguratorFormFooterPriceView
				configurationSessionId={rootConfigurationSessionId}
				configuratorType={rootConfiguratorType}
				configuratorInput={rootConfiguratorInput}
				configuratorInputErrors={rootConfiguratorInputErrors}
			/>
			{hasUnsavedChanges ?
				<Typography
					sx={{
						color: "warning.main",
						alignSelf: "center",
						flex: 1,
						textAlign: "center",
					}}
				>
					{i18n.t("configurator_has_unsaved_changes_message")}
				</Typography>
			:	<Box flex={1} />}
			{extraComponents}
			<ResetConfiguratorValuesButton
				resetToExistingConfiguration={resetToExistingConfiguration}
				resetToDefaultValues={resetToDefaultValues}
				color={"secondary"}
			/>
			{onCancel && (
				<AsyncButton
					color={"secondary"}
					label={i18n.t("close")}
					icon={faTimes}
					onClick={async () => {
						onCancel();
					}}
				/>
			)}
			{submitForm && (
				<AsyncButton
					label={i18n.t("ok")}
					onClick={async () => {
						await submitForm();
					}}
				/>
			)}
		</AavoFormFooter>
	);
};
