import { Typography } from "@mui/material";
import { VerticalBox } from "src/components/common/box/VerticalBox";
import { ConfiguratorTreeItemState } from "src/components/views/erp/configurator/configuratorForm/configuratorTree/treeItemState/ConfiguratorTreeItemState.ts";
import { configuratorTreeHasErrors } from "src/components/views/erp/configurator/configuratorForm/configuratorTree/treeItemState/configuratorTreeItemQueries.ts";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { mergeSx } from "src/utils/styles.ts";

export interface ConfiguratorTreeCombinedErrorsViewProps {
	treeItem: ConfiguratorTreeItemState;
	sx?: SxProps<Theme>;
}

export const ConfiguratorTreeCombinedErrorsView = ({ treeItem, sx }: ConfiguratorTreeCombinedErrorsViewProps) => {
	return (
		<VerticalBox sx={mergeSx({ gap: 1 }, sx)}>
			{treeItem.errors.map((error, index) => (
				<Typography key={index} color="error">
					{error}
				</Typography>
			))}
			<VerticalBox gap={0.5}>
				{treeItem.children.map((child) => {
					const childHasErrors = configuratorTreeHasErrors(child);
					if (!childHasErrors) return null;

					return (
						<VerticalBox key={child.key} sx={{ marginLeft: 1 }}>
							<Typography>{child.label}</Typography>
							<ConfiguratorTreeCombinedErrorsView treeItem={child} />
						</VerticalBox>
					);
				})}
			</VerticalBox>
		</VerticalBox>
	);
};
