import { OpenShopOrderButton } from "src/components/views/erp/utilComponents/OpenShopOrderButton.tsx";
import { OpenPurchaseOrderButton } from "src/components/views/erp/utilComponents/OpenPurchaseOrderButton.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import i18n from "i18next";
import {
	faBan,
	faCalendarAlt,
	faClone,
	faFileDownload,
	faShare,
	faSignInAlt,
	faSyncAlt,
	faTools,
	faUndo,
} from "@fortawesome/pro-regular-svg-icons";
import { CustomerOrderLineConfiguratorView } from "src/components/views/erp/configurator/configuratorForm/impl/CustomerOrderLineConfiguratorView.tsx";
import { downloadFile } from "src/utils/fileDownloading.ts";
import { AavoDataGridRowContextMenuParams } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { CustomerOrderLineView } from "src/api/generated/erp/db/types/tables/customerOrderLineView.ts";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { CustomerOrderLinesDataGrid } from "src/components/views/erp/sales/customerOrderLine/CustomerOrderLinesDataGrid.tsx";
import { dayJsToDateIsoString } from "src/utils/dayjsUtils.ts";
import dayjs from "dayjs";
import { CustomerOrderLineActionApi } from "src/api/generated/erp/sales/customerOrder/api/customerOrderLineActionApi.ts";
import { OpenObjectChangeLogButton } from "src/components/views/changeLogging/OpenObjectChangeLogButton.tsx";
import { CustomerOrderServiceLineDeliveryForm } from "src/components/views/erp/sales/customerOrder/CustomerOrderServiceLineDeliveryForm.tsx";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { OpenPartConfigurationButton } from "../../configurator/inspecting/OpenPartConfigurationButton";
import { OpenConfigurationHistoryButton } from "src/components/views/erp/configurator/objectConfigurationHistory/OpenConfigurationHistoryButton.tsx";
import { ShopOrderPrintApi } from "src/api/generated/erp/production/api/shopOrder/shopOrderPrintApi.ts";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { CustomerOrderLineForm } from "src/components/views/erp/sales/customerOrderLine/CustomerOrderLineForm.tsx";
import { OpenInventoryProfileButton } from "../../utilComponents/OpenInventoryProfileButton.tsx";
import { CustomerOrder } from "src/api/generated/erp/db/types/tables/customerOrder.ts";
import { createCustomerOrderLineConfiguratorType } from "src/components/views/erp/configurator/configuratorForm/utils/partConfiguratorTypeCreators.ts";
import { getConfiguratorFormDialogTitle } from "src/components/views/erp/configurator/configuratorForm/utils/configuratorFormUtils.tsx";

export interface CustomerOrderLinesDataGridContextMenuComponentsProps
	extends AavoDataGridRowContextMenuParams<CustomerOrderLineView> {
	refreshData: () => Promise<unknown>;
	parentLineId: number | null;
	customerOrder: CustomerOrder;
}

export const CustomerOrderLinesDataGridContextMenuComponents = ({
	refreshData,
	parentLineId,
	customerOrder,
	...props
}: CustomerOrderLinesDataGridContextMenuComponentsProps) => {
	const { row, onlySingleRowSelected, allSelectedRows } = props;
	const { openDialog } = useGenericDialog();
	const showInputDialog = useInputDialog();
	const showConfirmDialog = useConfirmDialog();

	return [
		row.customerOrderLineState === "INITIAL" && (
			<AsyncMenuButton
				icon={faShare}
				key={"release"}
				label={i18n.t("release")}
				onClick={async () => {
					const confirmed = await showConfirmDialog({
						title: i18n.t("are_you_sure"),
						message: i18n.t("release_customer_order_lines_confirmation"),
					});
					if (!confirmed) return;

					await CustomerOrderLineActionApi.releaseCustomerOrderLines({
						customerOrderLineIds: allSelectedRows.map((row) => row.customerOrderLineId),
					});
					await refreshData();
				}}
				disabled={
					customerOrder.capacityReservation ? i18n.t("customer_order_has_capacity_reservation") : undefined
				}
			/>
		),
		onlySingleRowSelected && row.salesPartType != "PACKAGE_" && (
			<AsyncMenuButton
				key={"cancelButton"}
				label={i18n.t("cancel")}
				icon={faBan}
				onClick={async () => {
					if (!row) return;
					const confirmed = await showConfirmDialog({
						cancelButtonText: i18n.t("no"),
					});
					if (!confirmed) return;

					await CustomerOrderLineActionApi.cancelCustomerOrderLine({
						customerOrderLineId: row.customerOrderLineId,
					});
					await refreshData();
				}}
			/>
		),
		onlySingleRowSelected && (
			<AsyncMenuButton
				key={"clone"}
				label={i18n.t("copy")}
				icon={faClone}
				onClick={async () => {
					openFormOnDialog({
						openDialog,
						component: CustomerOrderLineForm,
						size: "xl",
						title: i18n.t("copy_customer_order_line"),
						props: {
							customerOrderId: row.customerOrderId,
							customerOrderLineId: undefined,
							parentLineId: parentLineId ?? undefined,
							copySourceCustomerOrderLine: row,
						},
						onSubmit: refreshData,
					});
				}}
			/>
		),
		onlySingleRowSelected && row.shopOrderId != null && (
			<OpenShopOrderButton key={"openShopOrderButton"} shopOrderId={row.shopOrderId} />
		),
		onlySingleRowSelected && row.purchaseOrderId != null && (
			<OpenPurchaseOrderButton key={"openPurchaseOrderButton"} purchaseOrderId={row.purchaseOrderId} />
		),
		onlySingleRowSelected && row.partIsConfigurable && (
			<AsyncMenuButton
				key={"configureButton"}
				label={i18n.t("configure")}
				icon={faTools}
				disabled={row.customerOrderLineState !== "INITIAL"}
				onClick={async () => {
					const configuratorType = createCustomerOrderLineConfiguratorType(row.customerOrderLineId);
					const dialogTitle = await getConfiguratorFormDialogTitle({ configuratorType: configuratorType });
					openDialog(({ closeDialog }) => ({
						title: dialogTitle,
						content: (
							<CustomerOrderLineConfiguratorView
								configuratorType={configuratorType}
								closeDialog={closeDialog}
								refreshSourceView={refreshData}
							/>
						),
					}));
				}}
			/>
		),
		onlySingleRowSelected && (
			<OpenPartConfigurationButton
				key={"openConfigurationsButton"}
				partConfigurationId={row.partConfigurationId}
				vatPercent={row.vatPercent}
			/>
		),
		onlySingleRowSelected && row.partConfigurationId != null && (
			<OpenConfigurationHistoryButton
				key={"openConfigurationHistoryButton"}
				objectType={"CUSTOMER_ORDER_LINE"}
				objectId={row.customerOrderLineId}
				refreshSourceView={refreshData}
			/>
		),
		onlySingleRowSelected && (
			<AsyncMenuButton
				key={"printShopOrderSurveysButton"}
				label={i18n.t("print_shop_order_surveys")}
				icon={faFileDownload}
				disabled={row.shopOrderId == null}
				onClick={async () => {
					const fileHandle = await ShopOrderPrintApi.printSurveysOfShopOrderOperations({
						shopOrderId: row.shopOrderId!,
					});
					downloadFile(fileHandle);
				}}
			/>
		),
		onlySingleRowSelected && parentLineId == null && row.salesPartType === "PACKAGE_" && (
			<AsyncMenuButton
				key={"openPackageLinesButton"}
				label={i18n.t("package_lines")}
				icon={faShare}
				onClick={() => {
					openDialog(() => ({
						title: i18n.t("package_lines"),
						size: "xl",
						content: (
							<CustomerOrderLinesDataGrid
								customerOrderId={row.customerOrderId}
								parentLineId={row.customerOrderLineId}
							/>
						),
					}));
				}}
			/>
		),
		parentLineId == null && allSelectedRows.length > 0 && (
			<AsyncMenuButton
				key={"changePlannedDeliveryDateButton"}
				label={i18n.t("change_planned_delivery_date")}
				icon={faCalendarAlt}
				onClick={async () => {
					await changeCustomerOrderLinePlannedDeliveryDate();
					await refreshData();
				}}
			/>
		),
		row.salesPartType === "SERVICE" && row.deliveredQuantity !== row.salesQuantityAsWarehouseUnits && (
			<AsyncMenuButton
				key={"serviceLineButton"}
				label={i18n.t("deliver")}
				icon={faSignInAlt}
				onClick={async () => {
					openDialog(({ closeDialog }) => ({
						title: i18n.t("quantity_to_deliver"),
						size: "sm",
						content: (
							<CustomerOrderServiceLineDeliveryForm
								customerOrderLines={allSelectedRows}
								onCompleted={async () => {
									await refreshData();
									await closeDialog();
								}}
								isRevert={false}
							/>
						),
					}));
				}}
			/>
		),

		row.salesPartType === "SERVICE" && row.deliveredQuantity != 0 && (
			<AsyncMenuButton
				key={"revertServiceLineDeliverButton"}
				label={i18n.t("revert_delivery")}
				icon={faUndo}
				onClick={async () => {
					openDialog(({ closeDialog }) => ({
						title: i18n.t("quantity_to_revert"),
						size: "sm",
						content: (
							<CustomerOrderServiceLineDeliveryForm
								customerOrderLines={allSelectedRows}
								onCompleted={async () => {
									await refreshData();
									await closeDialog();
								}}
								isRevert={true}
							/>
						),
					}));
				}}
			/>
		),

		row.enableQuantityUpdate && onlySingleRowSelected && (
			<AsyncMenuButton
				key={"updateQuantityButton"}
				label={i18n.t("change_line_quantity")}
				icon={faSyncAlt}
				onClick={async () => {
					await changeCustomerOrderLineQuantity();
					await refreshData();
				}}
			/>
		),
		onlySingleRowSelected && <OpenInventoryProfileButton key={"inventoryLevelProfile"} partId={row.partId} />,
		onlySingleRowSelected && (
			<OpenObjectChangeLogButton
				key={"changeLog"}
				objectRef={{
					objectType: "CUSTOMER_ORDER_LINE",
					objectId: row.customerOrderLineId,
				}}
			/>
		),
	];

	async function changeCustomerOrderLinePlannedDeliveryDate() {
		const newPlannedDeliveryDate = await askDeliveryDate();
		const updateAcquisitionObjectsDates =
			row.customerOrderLineState === "INITIAL" ? true : await askAcquisitionObjectUpdate();
		if (newPlannedDeliveryDate == null || updateAcquisitionObjectsDates == null) return;

		await CustomerOrderLineActionApi.rescheduleSelectedCustomerOrderLines({
			customerOrderLineIds: allSelectedRows.map((x) => x.customerOrderLineId),
			newPlannedDeliveryDate: dayJsToDateIsoString(newPlannedDeliveryDate),
			updateAcquisitionObjects: updateAcquisitionObjectsDates,
		});

		function askDeliveryDate() {
			return showInputDialog({
				defaultValue: dayjs(row.plannedDeliveryDate),
				type: "date",
				title: i18n.t("change_planned_delivery_date"),
				fieldLabel: i18n.t("new_delivery_date"),
				required: true,
			});
		}

		function askAcquisitionObjectUpdate() {
			return showInputDialog({
				defaultValue: true,
				type: "boolean",
				title: i18n.t("change_planned_delivery_date"),
				fieldLabel: i18n.t("update_also_initial_purchase_and_shop_orders_dates"),
			});
		}
	}

	async function changeCustomerOrderLineQuantity() {
		const newQuantity = await askQuantity();
		if (newQuantity == null) return;
		await CustomerOrderLineActionApi.updateCustomerOrderLineQuantity({
			customerOrderLineId: row.customerOrderLineId,
			newQuantity: newQuantity,
		});

		function askQuantity() {
			return showInputDialog({
				defaultValue: row.salesQuantityAsSalesUnits,
				type: "decimal",
				title: i18n.t("change_line_quantity"),
				fieldLabel: i18n.t("new_quantity"),
				required: true,
			});
		}
	}
};
