// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { SupplierView } from "src/api/generated/erp/db/types/tables/supplierView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SuppliersDataGridApi {
	export async function getDataGridData(args: {
		searchQuery?: string;
		showDeactivated?: boolean;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<SupplierView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/suppliers/dataGrid/getDataGridData",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				showDeactivated: args.showDeactivated,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<SupplierView>;
	}
}
