import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {
    DefectPerformanceChartLineApi
} from "src/api/generated/spc/defectPerformanceChart/defectPerformanceChartLineApi.ts";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {concatWithPipe} from "src/utils/strings.tsx";
import {
    DefectPerformanceChartLineView
} from "src/api/generated/postgres/db/types/public_/tables/defectPerformanceChartLineView.ts";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {faLink} from "@fortawesome/pro-regular-svg-icons";
import {useGenericDialog} from "src/components/common/dialogs/GenericDialogContext.ts";
import {
    DefectPerformanceChartConnectableDefectGroupsDataGrid
} from "src/components/views/spc/basedata/performanceDefectCharts/DefectPerformanceChartConnectableDefectGroupsDataGrid.tsx";

export interface DefectPerformanceChartLinesDataGridProps {
	defectPerformanceChartId: number;
}

export const DefectPerformanceChartLinesDataGrid = ({
	defectPerformanceChartId,
}: DefectPerformanceChartLinesDataGridProps) => {
	const { openDialog } = useGenericDialog();
	return (
		<ClientSideDataGridModel
			gridId={"D0E7D720B4A9EC4A"}
			fetchData={() =>
				DefectPerformanceChartLineApi.getDefectPerformanceChartLines({
					defectPerformanceChartId: defectPerformanceChartId,
				})
			}
			getRows={(data) => data}
			getRowId={(row) => row.defectPerformanceChartLineId}
			initialParams={{}}
			render={({ dataGridProps, refreshData }) => {
				return (
					<CrudDataGrid<DefectPerformanceChartLineView>
						columns={[
							textColumn({
								field: "defectGroupId",
								headerName: i18n.t("defect_group"),
								valueGetter: (_, row) =>
									concatWithPipe(row.defectGroupSuperGroupName, row.defectGroupName),
								width: 300,
							}),
						]}
						remove={({ row }) =>
							DefectPerformanceChartLineApi.delete_({
								defectPerformanceChartLineId: row.defectPerformanceChartLineId,
							})
						}
						actionBarComponents={
							<>
								<AsyncButton
									icon={faLink}
									label={i18n.t("add_connection")}
									variant={"outlined"}
									onClick={async () => {
										openDialog(({ closeDialog }) => ({
											title: i18n.t("connect"),
											size: "md",
											content: (
												<DefectPerformanceChartConnectableDefectGroupsDataGrid
													defectPerformanceChartId={defectPerformanceChartId}
													closeDialog={async () => {
														await closeDialog();
														await refreshData();
													}}
												/>
											),
										}));
									}}
								/>
							</>
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
