import { PartConfiguratorTopLevelView } from "src/components/views/erp/configurator/configuratorForm/PartConfiguratorTopLevelView.tsx";
import { ConfigurationCreationApi } from "src/api/generated/erp/configurator/api/configurationCreationApi";
import { UnsavedCustomerOfferLinePartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/impl/unsavedCustomerOfferLinePartConfiguratorType.ts";
import { FormResult } from "src/components/common/forms/types.ts";

export interface CustomerOfferLineBeforeCreateConfiguratorViewProps {
	configuratorType: UnsavedCustomerOfferLinePartConfiguratorType;
	onCompleted: (result: FormResult<number>) => void;
}

export const CustomerOfferLineBeforeCreateConfiguratorView = ({
	configuratorType,
	onCompleted,
}: CustomerOfferLineBeforeCreateConfiguratorViewProps) => {
	return (
		<PartConfiguratorTopLevelView
			configuratorType={configuratorType}
			onCompleted={async (result) => {
				if (result.type === "success") {
					const { propertyValues, configurationSessionId } = result.value;
					const partConfigurationId =
						await ConfigurationCreationApi.createPartConfigurationForUnsavedCustomerOfferLine({
							configuratorType: configuratorType,
							propertyValues: propertyValues,
							configurationSessionId: configurationSessionId,
						});
					onCompleted({ type: "success", value: partConfigurationId });
				} else {
					onCompleted(result);
				}
			}}
		/>
	);
};
