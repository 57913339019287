import {
	ProductionLineEditApi,
	ProductionLineEditApi_FormInitData,
} from "src/api/generated/erp/production/basedata/productionLines/api/productionLineEditApi.ts";
import { DeepPartial } from "react-hook-form";
import { ProductionLine } from "src/api/generated/erp/db/types/tables/productionLine.ts";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import { concatWithPipe } from "src/utils/strings.tsx";
import { useMemo } from "react";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { FormDurationField } from "src/components/common/forms/fields/FormDurationField.tsx";
import { FormSection } from "src/components/common/forms/FormSection.tsx";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";

export interface ProductionLineFormProps extends FormCommonProps<number> {
	productionLineGroupId: number;
	productionLineId: number | undefined;
}

interface FormValues extends ProductionLine {}

export const ProductionLineForm = (props: ProductionLineFormProps) => {
	const { productionLineGroupId, productionLineId, onCompleted, onFormEdited } = props;
	return (
		<AsyncForm
			fetch={() =>
				ProductionLineEditApi.getFormInitData({
					productionLineGroupId,
					productionLineId,
				})
			}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			columns={2}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues({ productionLine }: ProductionLineEditApi_FormInitData): DeepPartial<FormValues> {
		return (
			productionLine ?? {
				productionLineGroupId: productionLineGroupId,
			}
		);
	}

	async function submit(values: FormValues) {
		if (productionLineId == null) {
			return await ProductionLineEditApi.insert({ productionLine: values });
		} else {
			await ProductionLineEditApi.update({ productionLine: values });
			return productionLineId;
		}
	}
};

interface FormContentProps
	extends ProductionLineFormProps,
		AsyncFormContentParams<ProductionLineEditApi_FormInitData, FormValues> {}

const FormContent = ({
	control,
	watch,
	data: {
		productionLineGroupOptions,
		operationOptions: allSiteOperationOptions,
		unitOptions,
		taktTimeCalendarOptions,
		taktTimeControlChartOptions,
	},
}: FormContentProps) => {
	const productionLineGroupId = watch("productionLineGroupId");

	const operationOptions = useMemo(() => {
		const productionLineGroup = productionLineGroupOptions.find(
			(group) => group.productionLineGroupId === productionLineGroupId,
		);
		return allSiteOperationOptions.filter((operation) => operation.siteId === productionLineGroup?.siteId);
	}, [productionLineGroupId, productionLineGroupOptions, allSiteOperationOptions]);

	return (
		<>
			<FormTextField control={control} name={"productionLineName"} label={i18n.t("name")} rules={requireRule()} />
			<FormSelectField
				control={control}
				name={"productionLineGroupId"}
				label={i18n.t("group")}
				options={productionLineGroupOptions}
				getOptionKey={(option) => option.productionLineGroupId}
				getOptionLabel={(option) => option.name}
				disableClearable
				rules={requireRule()}
			/>
			<FormSelectField
				control={control}
				name={"capacityUnitId"}
				label={i18n.t("capacity_unit")}
				options={unitOptions}
				getOptionKey={(option) => option.unitId}
				getOptionLabel={(option) => option.unitName}
				disableClearable
				rules={requireRule()}
			/>
			<FormSelectField
				control={control}
				name={"operationId"}
				label={i18n.t("production_line_operation")}
				options={operationOptions}
				getOptionKey={(option) => option.operationId}
				getOptionLabel={(option) => concatWithPipe(option.operationNo, option.operationDescription)}
			/>
			<FormCheckbox
				control={control}
				name={"createStatusView"}
				label={i18n.t("create_status_view")}
				spanGridColumns
			/>
			<FormSection label={i18n.t("takt_time")}>
				<FormDurationField
					control={control}
					name={"taktTimeSeconds"}
					label={i18n.t("takt_time_duration")}
					durationUnits={"seconds"}
				/>
				<FormSelectField
					control={control}
					name={"taktTimeCalendarId"}
					label={i18n.t("calendar")}
					options={taktTimeCalendarOptions}
					getOptionKey={(o) => o.id}
					getOptionLabel={(o) => o.name}
				/>
				<FormDurationField
					control={control}
					name={"taktTimeYellowLimitSeconds"}
					label={i18n.t("yellow_limit")}
					durationUnits={"seconds"}
				/>
				<FormDurationField
					control={control}
					name={"taktTimeRedLimitSeconds"}
					label={i18n.t("red_limit")}
					durationUnits={"seconds"}
				/>
				<FormSelectField
					control={control}
					name={"taktTimeControlChartId"}
					label={i18n.t("takt_time_control_chart")}
					options={taktTimeControlChartOptions}
					getOptionKey={(option) => option.chartId}
					getOptionLabel={(option) =>
						concatWithPipe(option.chartSuperGroupName, option.chartGroupName, option.chartName)
					}
				/>
			</FormSection>
		</>
	);
};
