import { JobQueueView } from "src/components/views/erp/production/jobQueue/base/JobQueueView.tsx";
import { WorkCenterJobQueueInitialsApi } from "src/api/generated/erp/production/jobQueues/api/workCenter/workCenterJobQueueInitialsApi.ts";
import { WorkCenterJobQueueInProgressApi } from "src/api/generated/erp/production/jobQueues/api/workCenter/workCenterJobQueueInProgressApi.ts";
import { WorkCenterJobQueueCompletedApi } from "src/api/generated/erp/production/jobQueues/api/workCenter/workCenterJobQueueCompletedApi.ts";

export interface WorkcenterJobQueueViewProps {
	isActive: boolean;
}

export const WorkcenterJobQueueView = ({ isActive }: WorkcenterJobQueueViewProps) => {
	return (
		<JobQueueView
			viewId={"workcenterJobQueueView"}
			isActive={isActive}
			initialsProps={{
				fetchData: WorkCenterJobQueueInitialsApi.getInitialOperations,
			}}
			inProgressProps={{
				fetchData: WorkCenterJobQueueInProgressApi.getInProgressOperations,
				alwaysAllowOperationCompletion: true,
			}}
			completedProps={{
				fetchData: WorkCenterJobQueueCompletedApi.getCompletedOperations,
			}}
		/>
	);
};
