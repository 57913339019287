import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import { PartConfigurationBomApi } from "src/api/generated/erp/configurator/api/partConfigurationBomApi.ts";
import i18n from "i18next";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { faTable } from "@fortawesome/pro-regular-svg-icons";
import { PartConfigurationInspectingView } from "src/components/views/erp/configurator/inspecting/PartConfigurationInspectingView.tsx";
import { PartConfigurationBomLineForm } from "src/components/views/erp/configurator/inspecting/PartConfigurationBomLineForm.tsx";
import { PartConfigurationView } from "src/api/generated/erp/db/types/tables/partConfigurationView.ts";
import { getPartConfigurationBomDataGridColumns } from "src/components/views/erp/configurator/inspecting/configurationBomUtils.ts";
import { PartConfigurationBomLineView } from "src/api/generated/erp/db/types/tables/partConfigurationBomLineView.ts";

export interface PartConfigurationBomDataGridProps {
	partConfiguration: PartConfigurationView;
	editable: boolean;
	vatPercent?: number;
}

export const PartConfigurationBomDataGrid = ({
	partConfiguration,
	editable,
	vatPercent,
}: PartConfigurationBomDataGridProps) => {
	const { openDialog } = useGenericDialog();

	const { dataGridProps } = useClientSideDataGridModel({
		fetchData: () =>
			PartConfigurationBomApi.getPartConfigurationBomLines({
				partConfigurationId: partConfiguration.partConfigurationId,
			}),
		getRowId: (row) => row.partConfigurationBomLineId,
		gridId: "CBAF44A180B53A68",
		initialParams: {},
	});

	return (
		<ControlledAsyncCrudDataGrid<PartConfigurationBomLineView>
			columns={getPartConfigurationBomDataGridColumns({ partConfiguration, vatPercent })}
			form={{
				component: ({ row, onCompleted, onFormEdited }) => (
					<PartConfigurationBomLineForm
						partConfiguration={partConfiguration}
						existingBomLine={row}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
					/>
				),
				editEnabled: editable,
				addRowEnabled: editable,
				dialogSize: "md",
				dialogTitle: i18n.t("bom_line"),
			}}
			remove={
				editable ?
					{
						type: "enabled",
						action: ({ items }) =>
							PartConfigurationBomApi.deleteBomLines({
								partConfigurationBomLineIds: items.map((item) => item.partConfigurationBomLineId),
							}),
					}
				:	{ type: "hidden" }
			}
			rowContextMenuComponents={({ row, onlySingleRowSelected }) => [
				onlySingleRowSelected && row.bomLinePartConfigurationId != null && (
					<AsyncMenuButton
						key={"openSubConfiguration"}
						label={i18n.t("configuration")}
						icon={faTable}
						onClick={async () => {
							const bomLinePartConfigurationId = row.bomLinePartConfigurationId;
							if (bomLinePartConfigurationId == null) return;

							openDialog(() => ({
								title: i18n.t("configuration"),
								content: (
									<PartConfigurationInspectingView
										partConfigurationId={bomLinePartConfigurationId}
										vatPercent={vatPercent}
									/>
								),
							}));
						}}
					/>
				),
			]}
			{...dataGridProps}
		/>
	);
};
