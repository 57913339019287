// This file is automatically generated. Do not edit manually.

import { DefectPerformanceChartView } from "src/api/generated/postgres/db/types/public_/tables/defectPerformanceChartView";
import { DefectPerformanceChartService_ChartData } from "src/api/generated/spc/defectPerformanceChart/defectPerformanceChartService";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace DefectPerformanceChartApi {
	export async function getPerformanceCharts(): Promise<Array<DefectPerformanceChartView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/defectPerformanceChart/getPerformanceCharts",
			method: "POST",
			data: {},
		});
		return response as Array<DefectPerformanceChartView>;
	}

	export async function getPerformanceChartData(args: {
		defectPerformanceChartId: number;
	}): Promise<DefectPerformanceChartService_ChartData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/defectPerformanceChart/getPerformanceChartData",
			method: "POST",
			data: {
				defectPerformanceChartId: args.defectPerformanceChartId,
			},
		});
		return response as DefectPerformanceChartService_ChartData;
	}
}
