// This file is automatically generated. Do not edit manually.

import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType";
import { ConfigurationProperty } from "src/api/generated/erp/db/types/tables/configurationProperty";
import { ConfigurationPropertyValues } from "src/api/generated/erp/configurator/model/configurationPropertyValues";
import { PartConfigurationToplevelScriptResult } from "src/api/generated/erp/configurator/configurationResolving/topLevel/partConfigurationToplevelScriptResult";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace TestConfigurationCommonResultsApi {
	export async function getConfigurationProperties(args: {
		configuratorType: PartConfiguratorType;
	}): Promise<Array<ConfigurationProperty>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/testing/getConfiguratorProperties",
			method: "POST",
			data: {
				configuratorType: args.configuratorType,
			},
		});
		return response as Array<ConfigurationProperty>;
	}

	export async function getTopLevelData(args: {
		configurationSessionId: string;
		configuratorType: PartConfiguratorType;
		propertyValues: ConfigurationPropertyValues;
	}): Promise<TestConfigurationCommonResultsApi_TopLevelDataDto> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/testing/getTopLevelData",
			method: "POST",
			data: {
				configurationSessionId: args.configurationSessionId,
				configuratorType: args.configuratorType,
				propertyValues: args.propertyValues,
			},
		});
		return response as TestConfigurationCommonResultsApi_TopLevelDataDto;
	}
}

export interface TestConfigurationCommonResultsApi_TopLevelDataDto {
	infoText: string;
	price: string | null | undefined;
	topLevelData: PartConfigurationToplevelScriptResult;
}
