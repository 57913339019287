import {Box, Tooltip, Typography, useTheme} from "@mui/material";
import {
	ProductionLineStatusViewApi
} from "src/api/generated/erp/production/productionLineStatus/api/productionLineStatusViewApi.ts";
import {
	ProductionLineStatus,
	ProductionLineStatus_ShopOrderDto,
	ProductionLineStatus_WorkCenterDto,
} from "src/api/generated/erp/production/productionLineStatus/model/productionLineStatus";
import {AsyncFetchRefreshFunc, AsyncFetchRender} from "src/components/common/async/AsyncFetchRender";
import {HorizontalBox} from "src/components/common/box/HorizontalBox";
import {VerticalBox} from "src/components/common/box/VerticalBox";
import {AsyncButton} from "src/components/common/buttons/AsyncButton";
import {concatWithPipe} from "src/utils/strings.tsx";
import {mergeSx} from "src/utils/styles";
import {faBackward, faForward, faSync} from "@fortawesome/pro-regular-svg-icons";
import {AavoActionBar} from "src/components/common/actionBar/AavoActionBar";
import i18n from "i18next";
import {useGenericDialog} from "src/components/common/dialogs/GenericDialogContext.ts";
import {
	ShopOrderWorkCenterStatusView
} from "src/components/views/erp/production/productionLineStatus/ShopOrderWorkCenterStatusView.tsx";
import {useInterval} from "src/utils/useInterval.ts";
import {OpenShopOrderButton} from "src/components/views/erp/utilComponents/OpenShopOrderButton.tsx";
import {useContextMenu} from "src/components/common/contextMenu/useContextMenu.ts";
import {AavoContextMenu} from "src/components/common/contextMenu/AavoContextMenu.tsx";
import {
	ProductionLineStatusTaktTimeView
} from "src/components/views/erp/production/productionLineStatus/ProductionLineStatusTaktTimeView.tsx";
import {useConfirmDialog} from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {formatIsoString} from "src/utils/dayjsUtils.ts";
import {LabelValueView} from "src/components/common/misc/LabelValueView.tsx";
import {useUserPermissions} from "src/components/views/erp/common/userPermissions.ts";

export interface AsyncProductionLineStatusViewProps {
	productionLineId: number;
	isActive: boolean;
}

export const AsyncProductionLineStatusView = ({ productionLineId, isActive }: AsyncProductionLineStatusViewProps) => {
	return (
		<AsyncFetchRender
			fetch={() => ProductionLineStatusViewApi.getProductionLineStatus({ productionLineId })}
			content={(status, refresh) => (
				<ProductionLineStatusView
					status={status}
					refresh={refresh}
					productionLineId={productionLineId}
					intervalRefresh={isActive}
				/>
			)}
		/>
	);
};

interface ProductionLineStatusViewProps {
	productionLineId: number;
	status: ProductionLineStatus;
	refresh: AsyncFetchRefreshFunc<unknown>;
	intervalRefresh?: boolean;
	hideRefreshButton?: boolean;
	sx?: SxProps<Theme>;
}

export const ProductionLineStatusView = ({
	productionLineId,
	status,
	refresh,
	sx,
	intervalRefresh = false,
	hideRefreshButton = false,
}: ProductionLineStatusViewProps) => {
	useInterval({
		callback: () => refresh({ silent: true }),
		intervalMs: 30 * 1000,
		isActive: intervalRefresh,
	});

	return (
		<VerticalBox
			sx={mergeSx(
				{
					flex: 1,
					margin: 1,
					marginTop: 0,
				},
				sx,
			)}
		>
			<ActionBar
				refresh={refresh}
				productionLineId={productionLineId}
				status={status}
				hideRefreshButton={hideRefreshButton}
			/>
			<HorizontalBox
				sx={{
					flex: 2,
					gap: 1,
				}}
			>
				{status.workCenters.map((workCenter, idx) => (
					<WorkCenterView
						key={workCenter.workCenterId}
						productionLineId={productionLineId}
						workCenter={workCenter}
						refresh={refresh}
						isFirstPosition={idx === 0}
						isLastPosition={idx === status.workCenters.length - 1}
					/>
				))}
			</HorizontalBox>
			<ProductionLineStatusTaktTimeView
				sx={{
					flex: 1,
				}}
				taktTime={status.taktTime}
			/>
		</VerticalBox>
	);
};

interface ActionBarProps {
	productionLineId: number;
	refresh: () => Promise<unknown>;
	status: ProductionLineStatus;
	hideRefreshButton: boolean;
}

const ActionBar = ({ productionLineId, refresh, status, hideRefreshButton }: ActionBarProps) => {
	const showConfirmDialog = useConfirmDialog();

	const showMoveLineButton = status.workCenters.length > 1;

	const userPermissions = useUserPermissions();

	if (!showMoveLineButton && hideRefreshButton) return;

	return (
		<AavoActionBar
			sx={{
				paddingX: 0,
			}}
		>
			{!hideRefreshButton && <AsyncButton icon={faSync} onClick={() => refresh()} />}
			{showMoveLineButton && userPermissions.production.productionLineMovingEnabled && (
				<AsyncButton
					icon={faForward}
					label={i18n.t("move_line")}
					variant={"outlined"}
					onClick={async () => {
						const confirmed = await showConfirmDialog({
							message: i18n.t("move_production_line_confirm"),
						});
						if (!confirmed) return;
						await ProductionLineStatusViewApi.moveLineForward({ productionLineId });
						await refresh?.();
					}}
				/>
			)}
		</AavoActionBar>
	);
};

interface WorkCenterViewProps {
	refresh: () => Promise<unknown>;
	productionLineId: number;
	workCenter: ProductionLineStatus_WorkCenterDto;
	isFirstPosition: boolean;
	isLastPosition: boolean;
}

const WorkCenterView = ({
	workCenter,
	refresh,
	productionLineId,
	isLastPosition,
	isFirstPosition,
}: WorkCenterViewProps) => {
	return (
		<VerticalBox
			sx={{
				flex: 1,
				gap: 1,
				border: "1px solid",
				borderColor: "grey.400",
				borderRadius: 2,
				padding: 1,
			}}
		>
			<Typography variant="h6" textAlign={"center"}>
				{workCenter.workCenterDescription}
			</Typography>
			{workCenter.shopOrders.map((shopOrder) => (
				<WorkCenterShopOrderView
					key={shopOrder.shopOrderId}
					productionLineId={productionLineId}
					workCenter={workCenter}
					shopOrder={shopOrder}
					refresh={refresh}
					isFirstPosition={isFirstPosition}
					isLastPosition={isLastPosition}
				/>
			))}
		</VerticalBox>
	);
};

interface WorkCenterShopOrderViewProps {
	refresh: () => Promise<unknown>;
	productionLineId: number;
	workCenter: ProductionLineStatus_WorkCenterDto;
	shopOrder: ProductionLineStatus_ShopOrderDto;
	isFirstPosition: boolean;
	isLastPosition: boolean;
}

const WorkCenterShopOrderView = ({
	productionLineId,
	shopOrder,
	workCenter,
	refresh,
	isFirstPosition,
	isLastPosition,
}: WorkCenterShopOrderViewProps) => {
	const { palette } = useTheme();
	const { openDialog } = useGenericDialog();
	const [openContextMenu, contextMenuState] = useContextMenu();

	const totalOperationsCount = shopOrder.workCenterOperations.length;
	const completedOperationsCount = shopOrder.workCenterOperations.filter(
		(operation) => operation.shopOrderOperationState === "READY",
	).length;
	const isCompleted = completedOperationsCount === totalOperationsCount;
	const isStarted = shopOrder.workCenterOperations.some(
		(operation) => operation.shopOrderOperationState === "STARTED" || operation.shopOrderOperationState === "READY",
	);

	const backgroundColor =
		isCompleted ? palette.state.completed
		: isStarted ? palette.state.inProgress
		: palette.state.initial;
	const textColor = palette.augmentColor({ color: { main: backgroundColor } }).contrastText;
	const userPermissions = useUserPermissions();

	return (
		<Box
			sx={mergeSx(
				{
					flex: 1,
					display: "flex",
					flexDirection: "column",
					backgroundColor: backgroundColor,
					color: textColor,
					padding: 1,
					borderRadius: 1,
					textAlign: "center",
				},
				workCenter.andonActive && {
					border: "10px solid",
					borderColor: palette.error.main,
				},
			)}
		>
			<AavoContextMenu state={contextMenuState} />
			<Tooltip
				title={<ShopOrderTooltipContent shopOrder={shopOrder} />}
				placement={"right"}
				slotProps={{
					tooltip: {
						sx: {
							maxWidth: "none",
						},
					},
				}}
			>
				<VerticalBox
					sx={{
						flex: 1,
						alignItems: "center",
						justifyContent: "center",
						fontSize: "1.5rem",
						cursor: "pointer",
						overflow: "hidden",
					}}
					onClick={() => {
						openShopOrderOnWorkCenterView();
					}}
					onContextMenu={(e) => {
						openContextMenu({
							mouseEvent: e,
							content: <ShopOrderViewContextMenu shopOrder={shopOrder} />,
						});
					}}
				>
					<Typography fontSize={"inherit"}>
						{i18n.t("shop_order")} {shopOrder.shopOrderId}
					</Typography>
					<Typography fontSize={"inherit"}>{shopOrder.sourceRef}</Typography>
					<Typography fontSize={"inherit"}>{shopOrder.configurationOrPartDescription}</Typography>
					<Typography fontSize={"inherit"}>{shopOrder.productionLineName}</Typography>
					<Typography fontSize={"inherit"}>{formatIsoString(shopOrder.plannedBeginDate, "L")}</Typography>
					<Typography
						fontSize={"inherit"}
					>{`${completedOperationsCount} / ${totalOperationsCount}`}</Typography>
				</VerticalBox>
			</Tooltip>
			<HorizontalBox
				sx={{
					justifySelf: "flex-end",
				}}
			>
				{!isFirstPosition && userPermissions.production.productionLineMovingEnabled && (
					<AsyncButton
						icon={faBackward}
						color={"black"}
						onClick={async () => {
							await ProductionLineStatusViewApi.moveShopOrderToPreviousWorkCenter({
								shopOrderId: shopOrder.shopOrderId,
								productionLineId: productionLineId,
							});
							await refresh();
						}}
					/>
				)}
				<Box flex={1} />
				{!isLastPosition && userPermissions.production.productionLineMovingEnabled && (
					<AsyncButton
						icon={faForward}
						color={"black"}
						onClick={async () => {
							await ProductionLineStatusViewApi.moveShopOrderToNextWorkCenter({
								shopOrderId: shopOrder.shopOrderId,
								productionLineId: productionLineId,
							});
							await refresh();
						}}
					/>
				)}
			</HorizontalBox>
		</Box>
	);

	function openShopOrderOnWorkCenterView() {
		openDialog({
			title: concatWithPipe(shopOrder.shopOrderId, shopOrder.sourceRef),
			content: (
				<ShopOrderWorkCenterStatusView
					shopOrder={shopOrder}
					workCenterId={workCenter.workCenterId}
					productionLineId={productionLineId}
				/>
			),
		});
	}
};

const ShopOrderTooltipContent = ({ shopOrder }: { shopOrder: ProductionLineStatus_ShopOrderDto }) => {
	return (
		<LabelValueView
			items={[
				{
					label: i18n.t("shop_order"),
					value: shopOrder.shopOrderId,
				},
				{
					label: i18n.t("source"),
					value: shopOrder.sourceRef,
				},
				{
					label: i18n.t("part"),
					value: shopOrder.configurationOrPartDescription,
				},
				{
					label: i18n.t("production_line"),
					value: shopOrder.productionLineName,
				},
				{
					label: i18n.t("planned_begin"),
					value: formatIsoString(shopOrder.plannedBeginDate, "L"),
				},
			]}
		/>
	);
};

const ShopOrderViewContextMenu = ({ shopOrder }: { shopOrder: ProductionLineStatus_ShopOrderDto }) => {
	const userPermissions = useUserPermissions();
	return [
		userPermissions.production.productionLineMovingEnabled && (
			<OpenShopOrderButton key={shopOrder.shopOrderId} shopOrderId={shopOrder.shopOrderId} />
		),
	];
};
