import { Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { HorizontalBox } from "src/components/common/box/HorizontalBox";
import { AsyncButton } from "src/components/common/buttons/AsyncButton";
import { formatMoney, formatNumber } from "src/utils/numberUtils.ts";
import { logError, LoggedError } from "src/errorHandling/errorLogging.ts";
import i18n from "i18next";
import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType.ts";
import {
	PartConfiguratorFormApi,
	PartConfiguratorFormApi_GetConfigurationSalesPriceResult,
} from "src/api/generated/erp/configurator/api/partConfiguratorFormApi.ts";
import {
	faCircleInfo,
	faRefresh,
	faArrowRightToBracket,
	faArrowLeftToBracket,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mergeSx } from "src/utils/styles.ts";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/ErrorDialogContext.tsx";
import { linebreaksToHtmlBreaks } from "src/utils/strings.tsx";
import { useAavoToast } from "src/components/common/toast/AavoToastContext.tsx";
import { VerticalBox } from "src/components/common/box/VerticalBox";
import { VersionedConfiguratorInput } from "src/components/views/erp/configurator/configuratorForm/VersionedConfiguratorInput.ts";

export interface ConfiguratorFormFooterPriceViewProps {
	configurationSessionId: string;
	configuratorType: PartConfiguratorType;
	configuratorInput: VersionedConfiguratorInput;
	configuratorInputErrors: string[];
}

type PriceState =
	| {
			type: "ready";
			priceResult: PartConfiguratorFormApi_GetConfigurationSalesPriceResult | undefined;
	  }
	| {
			type: "error";
			error: LoggedError;
	  };

export const ConfiguratorFormFooterPriceView = ({
	configurationSessionId,
	configuratorType,
	configuratorInput,
	configuratorInputErrors,
}: ConfiguratorFormFooterPriceViewProps) => {
	const { showErrorDialog } = useErrorDialog();
	const { showToast } = useAavoToast();

	const [showPrice, setShowPrice] = useState<boolean>(false);
	const [priceState, setPriceState] = useState<PriceState>({
		type: "ready",
		priceResult: undefined,
	});

	const [inputVersionForCurrentPrice, setInputVersionForCurrentPrice] = useState<number>(-1);

	if (!configuratorCanBePriced(configuratorType)) {
		return null;
	}

	const priceIsOutdated = inputVersionForCurrentPrice !== configuratorInput.version;

	const configuratorInputHasErrors = configuratorInputErrors.length > 0;
	const refreshDisabledDueToErrorsTooltip =
		configuratorInputHasErrors ?
			linebreaksToHtmlBreaks(
				i18n.t("configurator_form_errors_title") + ":\n\n" + configuratorInputErrors.join("\n"),
			)
		:	undefined;

	if (priceState.type === "ready") {
		return (
			<HorizontalBox alignItems={"center"} gap={1}>
				{priceState.priceResult != null && (
					<AsyncButton
						icon={showPrice ? faArrowLeftToBracket : faArrowRightToBracket}
						size={"small"}
						tooltip={showPrice ? i18n.t("hide_total_price") : i18n.t("show_total_price")}
						onClick={() => setShowPrice(!showPrice)}
					/>
				)}
				{priceState.priceResult != null && showPrice && (
					<PriceText priceResult={priceState.priceResult} priceIsOutdated={priceIsOutdated} />
				)}
				<AsyncButton
					icon={faRefresh}
					size={"small"}
					disabled={configuratorInputHasErrors}
					tooltip={refreshDisabledDueToErrorsTooltip ?? i18n.t("refresh_price")}
					onClick={() => loadPrice()}
				/>
			</HorizontalBox>
		);
	}

	if (priceState.type === "error") {
		return (
			<HorizontalBox alignItems={"center"} gap={1}>
				<Tooltip title={priceState.error.message}>
					<HorizontalBox alignItems={"center"} gap={0.5} onClick={() => showErrorDialog(priceState.error)}>
						<Typography>{i18n.t("error")}</Typography>
						<FontAwesomeIcon
							transform={{
								y: -5,
							}}
							icon={faCircleInfo}
						/>
					</HorizontalBox>
				</Tooltip>
				<AsyncButton
					icon={faRefresh}
					disabled={configuratorInputHasErrors}
					tooltip={refreshDisabledDueToErrorsTooltip ?? i18n.t("try_again")}
					onClick={() => loadPrice()}
				/>
			</HorizontalBox>
		);
	}

	return null;

	async function loadPrice() {
		try {
			const result = await PartConfiguratorFormApi.getCurrentConfigurationSalesPrice({
				configurationSessionId,
				configuratorType,
				configuratorInput,
			});
			showPriceDifference(result);
			setPriceState({
				type: "ready",
				priceResult: result ?? undefined,
			});
			setInputVersionForCurrentPrice(configuratorInput.version);
		} catch (e) {
			const loggedError = logError(e);
			setPriceState({
				type: "error",
				error: loggedError,
			});
		}
	}

	function showPriceDifference(
		newPriceResult: PartConfiguratorFormApi_GetConfigurationSalesPriceResult | undefined | null,
	) {
		if (newPriceResult == null) return;

		const oldPrice = priceState.type === "ready" ? priceState.priceResult?.salesPriceWithVat : undefined;
		if (oldPrice === undefined) return;

		const priceDifference = newPriceResult.salesPriceWithVat - oldPrice;

		showToast({
			hideCloseButton: true,
			message: `${i18n.t("price_change_compared_to_previous_price")}: 
			 ${priceDifference > 0 ? "+" : ""}${formatMoney(priceDifference)}`,
		});
	}
};

interface PriceTextProps {
	priceResult: PartConfiguratorFormApi_GetConfigurationSalesPriceResult;
	priceIsOutdated: boolean;
}

const PriceText = ({ priceResult, priceIsOutdated }: PriceTextProps) => {
	if (priceResult == null) return;
	return (
		<Tooltip title={<PriceTextTooltip priceResult={priceResult} priceIsOutdated={priceIsOutdated} />}>
			<Typography
				sx={mergeSx(
					priceIsOutdated && {
						filter: "blur(2px)",
					},
				)}
			>
				{`${i18n.t("sales_price")}: ${formatMoney(priceResult.salesPriceWithVat)}`}
			</Typography>
		</Tooltip>
	);
};

const PriceTextTooltip = ({ priceResult, priceIsOutdated }: PriceTextProps) => {
	if (priceIsOutdated) return i18n.t("price_may_been_outdated");

	return (
		<VerticalBox>
			<Typography>{`${i18n.t("sales_price_without_vat")}: ${formatMoney(priceResult.salesPrice)}`}</Typography>
			<Typography>{`${i18n.t("vat_percent")}: ${formatNumber(priceResult.vatPercent)} %`}</Typography>
			<Typography>{`${i18n.t("sales_price_with_vat")}: ${formatMoney(priceResult.salesPriceWithVat)}`}</Typography>
		</VerticalBox>
	);
};

function configuratorCanBePriced(configuratorType: PartConfiguratorType): boolean {
	switch (configuratorType.type) {
		case "customerOrderLine":
		case "customerOrderOfferLine":
		case "unsavedCustomerOrderLine":
		case "unsavedCustomerOfferLine":
			return true;
		case "sitePartTest":
		case "catalogPartTest":
		case "subConfigurator":
		case "shopOrderLine":
		case "purchaseOrderLine":
		case "projectActivityMaterial":
			return false;
	}
}
