import {AavoMosaic} from "src/components/common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import {
    DefectPerformanceChartsDataGrid
} from "src/components/views/spc/basedata/performanceDefectCharts/DefectPerformanceChartsDataGrid.tsx";
import {
    DefectPerformanceChartLinesDataGrid
} from "src/components/views/spc/basedata/performanceDefectCharts/DefectPerformanceChartLinesDataGrid.tsx";
import {useState} from "react";
import {CenteredTypography} from "src/components/common/CenteredTypography.tsx";

export const DefectPerformanceChartBaseDataView = () => {
	const [selectedDefectPerformanceChartId, setSelectedDefectPerformanceChartId] = useState<number | undefined>(
		undefined,
	);
	return (
		<AavoMosaic
			layout={{
				type: "row",
				splitPercentage: 65,
				first: {
					type: "panel",
					title: i18n.t("yield_charts"),
					content: (
						<DefectPerformanceChartsDataGrid
							setSelectedDefectPerformanceChartId={setSelectedDefectPerformanceChartId}
						/>
					),
				},
				second: {
					type: "panel",
					title: i18n.t("connected_defect_charts"),
					content:
						selectedDefectPerformanceChartId == null ?
							<CenteredTypography>{i18n.t("select_row")}</CenteredTypography>
						:	<DefectPerformanceChartLinesDataGrid
								key={selectedDefectPerformanceChartId}
								defectPerformanceChartId={selectedDefectPerformanceChartId}
							/>,
				},
			}}
		/>
	);
};
