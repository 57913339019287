import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { ShopOrderBatchesDataGrid } from "src/components/views/erp/production/shopOrderBatch/ShopOrderBatchesDataGrid.tsx";
import { ShopOrderBatchShopOrdersDataGrid } from "src/components/views/erp/production/shopOrderBatch/ShopOrderBatchShopOrdersDataGrid.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import i18n from "i18next";
import { useState } from "react";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";
import { ShopOrderBatchDataGridApi_ShopOrderBatchDto } from "src/api/generated/erp/production/shopOrderBatch/api/shopOrderBatchDataGridApi.ts";

export interface ShopOrderBatchesViewProps {
	onlyShopOrderBatchId?: number;
	isActive?: boolean
}

export const ShopOrderBatchesView = ({ onlyShopOrderBatchId }: ShopOrderBatchesViewProps) => {
	const [selectedShopOrderBatch, setSelectedShopOrderBatch] =
		useState<ShopOrderBatchDataGridApi_ShopOrderBatchDto | null>(null);
	const shopOrderBatchRefreshRef = useRefreshRef();

	return (
		<AavoMosaic
			layout={{
				type: "row",
				first: {
					type: "panel",
					title: i18n.t("shop_order_batches"),
					content: (
						<ShopOrderBatchesDataGrid
							onSelectionChanged={setSelectedShopOrderBatch}
							refreshRef={shopOrderBatchRefreshRef}
							onlyShopOrderBatchId={onlyShopOrderBatchId}
						/>
					),
				},
				second: {
					type: "panel",
					title: i18n.t("connected_shop_orders"),
					content:
						selectedShopOrderBatch == null ?
							<CenteredTypography>{i18n.t("select_shop_order_batch")}</CenteredTypography>
						:	<ShopOrderBatchShopOrdersDataGrid
								key={selectedShopOrderBatch.shopOrderBatchId}
								shopOrderBatch={selectedShopOrderBatch}
								shopOrderBatchesRefreshRef={shopOrderBatchRefreshRef}
							/>,
				},
			}}
		/>
	);
};
