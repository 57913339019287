import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType.ts";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import {
	PartConfiguratorFormApi,
	PartConfiguratorFormApi_ConfiguratorTreeInitData,
} from "src/api/generated/erp/configurator/api/partConfiguratorFormApi.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { useRef } from "react";
import i18n from "i18next";
import {
	PartConfiguratorTreeStatefulView,
	PartConfiguratorTreeStatefulViewProps,
} from "src/components/views/erp/configurator/configuratorForm/configuratorTree/PartConfiguratorTreeStatefulView.tsx";

export interface PartConfiguratorTopLevelViewProps extends Omit<PartConfiguratorTreeStatefulViewProps, "initData"> {
	configuratorType: PartConfiguratorType;
}

export const PartConfiguratorTopLevelView = (props: PartConfiguratorTopLevelViewProps) => {
	const { configuratorType, onCompleted, ...other } = props;

	const showConfirmDialog = useConfirmDialog();

	const configurationAfterManualEditConfirmed = useRef(false);

	return (
		<AsyncFetchRender
			fetch={getInitData}
			content={(initData) => <PartConfiguratorTreeStatefulView {...other} initData={initData} onCompleted={onCompleted} />}
		/>
	);

	async function getInitData(): Promise<PartConfiguratorFormApi_ConfiguratorTreeInitData> {
		if (!configurationAfterManualEditConfirmed.current) {
			const hasBeenEditedManually = await PartConfiguratorFormApi.getConfigurationHasBeenEditedManually({
				configuratorType: configuratorType,
			});

			if (hasBeenEditedManually) {
				const confirmed = await showConfirmDialog({
					message: i18n.t("confirm_configure_manually_edited_configuration"),
				});
				if (!confirmed) {
					await onCompleted?.({
						type: "cancel",
						isEdited: false,
					});
				}
			}
			configurationAfterManualEditConfirmed.current = true;
		}

		return await PartConfiguratorFormApi.getConfiguratorTreeInitData({
			rootConfiguratorType: configuratorType,
		});
	}
};
