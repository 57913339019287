// This file is automatically generated. Do not edit manually.

import { Document } from "src/api/generated/postgres/db/types/documents/tables/document";
import { DocumentCategory } from "src/api/generated/postgres/db/types/documents/tables/documentCategory";
import { AavoObjectType } from "src/api/generated/common/sourceType/aavoObjectType";
import { DocumentConnectionView } from "src/api/generated/postgres/db/types/documents/tables/documentConnectionView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace DocumentQueryApi {
	export async function getDocument(args: { documentId: number }): Promise<Document> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/getDocument",
			method: "POST",
			data: {
				documentId: args.documentId,
			},
		});
		return response as Document;
	}

	export async function getDocumentPrimaryFileUuid(args: { documentId: number }): Promise<string> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/getDocumentPrimaryFileUuid",
			method: "POST",
			data: {
				documentId: args.documentId,
			},
		});
		return response as string;
	}

	export async function getDocumentRevisionPrimaryFileUuid(args: { documentRevisionId: number }): Promise<string> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/getDocumentRevisionPrimaryFileUuid",
			method: "POST",
			data: {
				documentRevisionId: args.documentRevisionId,
			},
		});
		return response as string;
	}

	export async function getDocumentCategoryOptions(): Promise<Array<DocumentCategory>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/getDocumentCategoryOptions",
			method: "POST",
			data: {},
		});
		return response as Array<DocumentCategory>;
	}

	export async function getDocumentsOfObject(args: {
		objectType: AavoObjectType;
		objectId: number;
	}): Promise<Array<DocumentConnectionView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/documents/getDocumentsOfObject",
			method: "POST",
			data: {
				objectType: args.objectType,
				objectId: args.objectId,
			},
		});
		return response as Array<DocumentConnectionView>;
	}
}
