import {
	ReplenishPartWarehouseLocationResult,
	ReplenishPartWarehouseLocationResult_PurchaseRequestCreated,
	ReplenishPartWarehouseLocationResult_ShopOrderCreated,
	ReplenishPartWarehouseLocationResult_WarehouseTransferCreated,
} from "src/api/generated/erp/warehouse/warehouseLocation/service/replenishPartWarehouseLocationResult.ts";
import Box from "@mui/material/Box";
import { LabelValueView } from "src/components/common/misc/LabelValueView.tsx";
import i18n from "i18next";
import { formatIsoString } from "src/utils/dayjsUtils.ts";

export interface ReplenishmentRequestLogViewProps {
	log: Array<ReplenishPartWarehouseLocationResult>;
}

export const ReplenishmentRequestLogView = ({ log }: ReplenishmentRequestLogViewProps) => {
	return (
		<Box
			sx={{
				flex: 1,
				overflow: "auto",
				margin: "1rem",
			}}
		>
			{log.map((item) => {
				switch (item.type) {
					case "purchaseRequestCreated":
						return <PurchaseRequestLogItem item={item} />;
					case "warehouseTransferCreated":
						return <WarehouseTransferLogItem item={item} />;
					case "shopOrderCreated":
						return <ShopOrderLogItem item={item} />;
				}
			})}
		</Box>
	);
};

const PurchaseRequestLogItem = ({ item }: { item: ReplenishPartWarehouseLocationResult_PurchaseRequestCreated }) => {
	return (
		<LabelValueView
			fontSize={"small"}
			sx={{
				rowGap: 0.25,
				padding: 0.25,
			}}
			items={[
				{
					type: "section",
					label: i18n.t("purchase_request"),
					fields: [
						{
							label: i18n.t("purchase_request_number"),
							value: item.purchaseRequest.purchaseRequestId,
						},
						{
							label: i18n.t("created_at"),
							value: formatIsoString(item.purchaseRequest.purchaseRequestDate, "L LT"),
						},
						{
							label: i18n.t("part"),
							value: item.purchaseRequest.partNo,
						},
						{
							label: i18n.t("description"),
							value: item.purchaseRequest.partDescription_1,
						},
						{
							label: i18n.t("quantity"),
							value: item.purchaseRequest.quantity,
						},
						{
							label: i18n.t("unit"),
							value: item.purchaseRequest.purchaseUnit,
						},
					],
				},
			]}
		/>
	);
};
const ShopOrderLogItem = ({ item }: { item: ReplenishPartWarehouseLocationResult_ShopOrderCreated }) => {
	return (
		<LabelValueView
			fontSize={"small"}
			sx={{
				rowGap: 0.25,
				padding: 0.25,
			}}
			items={[
				{
					type: "section",
					label: i18n.t("shop_order"),
					fields: [
						{
							label: i18n.t("shop_order_number"),
							value: item.shopOrder.shopOrderId,
						},
						{
							label: i18n.t("created_at"),
							value: formatIsoString(item.shopOrder.createdDate, "L LT"),
						},
						{
							label: i18n.t("part"),
							value: item.shopOrder.partNo,
						},
						{
							label: i18n.t("description"),
							value: item.shopOrder.partDescription_1,
						},
						{
							label: i18n.t("quantity"),
							value: item.shopOrder.quantity,
						},
						{
							label: i18n.t("unit"),
							value: item.shopOrder.partUnit,
						},
					],
				},
			]}
		/>
	);
};

const WarehouseTransferLogItem = ({
	item,
}: {
	item: ReplenishPartWarehouseLocationResult_WarehouseTransferCreated;
}) => {
	return (
		<LabelValueView
			fontSize={"small"}
			sx={{
				rowGap: 0.25,
				padding: 0.25,
			}}
			items={[
				{
					type: "section",
					label: i18n.t("warehouse_transfer"),
					fields: [
						{
							label: i18n.t("warehouse_transfer_number"),
							value: item.warehouseTransfer.warehouseTransferId,
						},
						{
							label: i18n.t("created_at"),
							value: formatIsoString(item.warehouseTransfer.transferRequestDate, "L LT"),
						},
						{
							label: i18n.t("part"),
							value: item.requestedPart.partNo,
						},
						{
							label: i18n.t("description"),
							value: item.requestedPart.partDescription_1,
						},
						{
							label: i18n.t("warehouse_location"),
							value: item.warehouseTransfer.requestingWarehouseLocationCode,
						},
						{
							label: i18n.t("quantity"),
							value: item.warehouseTransfer.quantity,
						},
						{
							label: i18n.t("base_unit"),
							value: item.requestedPart.partUnit,
						},
						{
							label: i18n.t("warehouse_transfer_factor"),
							value: item.warehouseTransfer.warehouseTransferFactor,
						},
						{
							label: i18n.t("warehouse_transfer_unit"),
							value: item.warehouseTransfer.warehouseTransferUnit,
						},
						{
							label: i18n.t("warehouse_transfer_additional_info"),
							value: item.warehouseTransfer.info,
						},
					],
				},
			]}
		/>
	);
};
