import {ServerSideDataGridModel} from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";
import {
    ProcessImprovementsApi
} from "src/api/generated/spc/processImprovement/processImprovementsApi.ts";
import {genericNullableValue} from "src/utils/genericNullableValue.ts";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {dateTimeColumn, enumColumn, integerColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {ProcessImprovementView} from "src/api/generated/postgres/db/types/public_/tables/processImprovementView.ts";
import {ProcessImprovementForm} from "src/components/views/spc/processImprovement/ProcessImprovementForm.tsx";
import {
    getProcessImprovementStateLabel,
    getProcessImprovementStateLabels,
    ProcessImprovementState,
    ProcessImprovementStateValues,
} from "src/api/generated/postgres/db/types/public_/enums/processImprovementState.ts";
import {SelectField} from "src/components/common/inputFields/SelectField.tsx";
import {AsyncUserSelectField} from "src/components/views/users/AsyncUserSelectField.tsx";
import {MenuCheckbox} from "src/components/common/contextMenu/MenuCheckbox.tsx";
import {AsyncMenuButton} from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {faBan, faShare} from "@fortawesome/pro-regular-svg-icons";
import {ProcessImprovementEditApi} from "src/api/generated/spc/processImprovement/processImprovementEditApi.ts";
import {DocumentsOfObjectButton} from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import {nullableAavoObjectRef} from "src/utils/aavoObjectRefUtils.ts";
import React from "react";
import {AavoTextField} from "src/components/common/inputFields/AavoTextField.tsx";

export interface ProcessImprovementsDataGridProps {
	setSelectedProcessImprovementId?: (id: number | undefined) => void;
	actionBarExtraComponents?: (onlySelectedRow: ProcessImprovementView | undefined) => React.ReactNode;
	onlyProcessImprovementId?: number | null;
	selectFirstRowOnLoad?: boolean;
}

export const ProcessImprovementsDataGrid = ({
	setSelectedProcessImprovementId,
	actionBarExtraComponents,
	onlyProcessImprovementId = null,
	selectFirstRowOnLoad = false,
}: ProcessImprovementsDataGridProps) => {
	return (
		<ServerSideDataGridModel
			selectFirstRowOnLoad={selectFirstRowOnLoad}
			gridId={"C8E50B72E0C0A6C2"}
			fetchData={(params) => ProcessImprovementsApi.getProcessImprovementsGridData(params)}
			getDataModelResult={(data) => data.processImprovements}
			getRowId={(row) => row.id}
			initialParams={{
				processImprovementId: genericNullableValue<number>(onlyProcessImprovementId),
				processImprovementCategoryId: genericNullableValue<number>(null),
				responsiblePersonId: genericNullableValue<number>(null),
				processImprovementState: genericNullableValue<ProcessImprovementState>(null),
				showCancelled: false,
				searchQuery: "",
			}}
			onSelectionChanged={(rows) =>
				setSelectedProcessImprovementId ? setSelectedProcessImprovementId(rows[0]?.id) : undefined
			}
			render={({ dataGridProps, data: { categoryOptions }, refreshData, currentParams, onlySelectedRow }) => (
				<CrudDataGrid<ProcessImprovementView>
					columns={[
						textColumn({
							field: "title",
							headerName: i18n.t("name"),
							width: 200,
						}),
						textColumn({
							field: "categoryName",
							headerName: i18n.t("category"),
						}),
						textColumn({
							field: "responsiblePersonUserName",
							headerName: i18n.t("responsible_person"),
							width: 130,
						}),
						textColumn({
							field: "team",
							headerName: i18n.t("team"),
							width: 150,
						}),
						textColumn({
							field: "description",
							headerName: i18n.t("description"),
							width: 250,
						}),
						enumColumn({
							field: "processImprovementState",
							headerName: i18n.t("state"),
							enumLabels: getProcessImprovementStateLabels(),
						}),
						textColumn({
							field: "createdByUserName",
							headerName: i18n.t("created_by"),
						}),
						dateTimeColumn({
							field: "createdDate",
							headerName: i18n.t("created_at"),
							width: 130,
						}),
						integerColumn({
							field: "id",
							headerName: i18n.t("id"),
						}),
					]}
					form={{
						addRowEnabled: true,
						editEnabled: true,
						dialogSize: "md",
						dialogTitle: i18n.t("explanation"),
						component: ({ onCompleted, onFormEdited, row }) => (
							<ProcessImprovementForm
								onCompleted={onCompleted}
								onFormEdited={onFormEdited}
								processImprovementId={row?.id}
							/>
						),
					}}
					actionBarComponents={
						<>
							<DocumentsOfObjectButton
								objectRef={nullableAavoObjectRef("PROCESS_IMPROVEMENT", onlySelectedRow?.id)}
							/>
							<AavoTextField
								label={i18n.t("search")}
								onSubmit={async (value) => {
									await refreshData({ searchQuery: value });
								}}
							/>
							<SelectField
								label={i18n.t("category")}
								options={categoryOptions}
								getOptionKey={(o) => o.id}
								getOptionLabel={(o) => o.categoryName}
								onChange={(value) => refreshData({ processImprovementCategoryId: value })}
							/>
							<SelectField
								label={i18n.t("state")}
								options={ProcessImprovementStateValues.filter((state) => state != "CANCELLED")}
								getOptionKey={(o) => o}
								getOptionLabel={(o) => getProcessImprovementStateLabel(o)}
								onChange={(value) => refreshData({ processImprovementState: value })}
							/>
							<AsyncUserSelectField
								label={i18n.t("responsible_person")}
								onChange={(value) => refreshData({ responsiblePersonId: value?.id })}
							/>
							{actionBarExtraComponents ? actionBarExtraComponents(onlySelectedRow) : undefined}
						</>
					}
					actionBarMenuComponents={[
						<MenuCheckbox
							label={i18n.t("show_cancelled")}
							checked={currentParams.showCancelled}
							onChange={(value) => refreshData({ showCancelled: value })}
						/>,
					]}
					rowContextMenuComponents={({ row }) => [
						row.processImprovementState == "INITIAL" && (
							<AsyncMenuButton
								key={"release"}
								label={i18n.t("release")}
								icon={faShare}
								onClick={async () => {
									await ProcessImprovementEditApi.release({ processImprovementId: row.id });
									await refreshData();
								}}
							/>
						),
						row.processImprovementState !== "CANCELLED" && (
							<AsyncMenuButton
								key={"cancel"}
								label={i18n.t("cancel")}
								icon={faBan}
								onClick={async () => {
									await ProcessImprovementEditApi.cancel({ processImprovementId: row.id });
									await refreshData();
								}}
							/>
						),
					]}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
