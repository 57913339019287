// This file is automatically generated. Do not edit manually.

import { IsoDateTimeString } from "src/types/dateTime";
import { SingleShiftPerformanceChartService_ChartDataPoint } from "src/api/generated/spc/defectPerformanceChart/singleShiftPerformanceChartService";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SingleShiftPerformanceChartApi {
	export async function getSingleShiftPerformanceChartData(args: {
		defectPerformanceChartId: number;
		startTime: IsoDateTimeString;
		endTime: IsoDateTimeString;
	}): Promise<Array<SingleShiftPerformanceChartService_ChartDataPoint>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/singleShiftPerformanceChart/getSingleShiftPerformanceChartData",
			method: "POST",
			data: {
				defectPerformanceChartId: args.defectPerformanceChartId,
				startTime: args.startTime,
				endTime: args.endTime,
			},
		});
		return response as Array<SingleShiftPerformanceChartService_ChartDataPoint>;
	}
}
