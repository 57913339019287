import {AsyncRender} from "src/components/common/async/AsyncRender.tsx";
import {ControlChartGraphDto} from "src/api/generated/spc/graphs/controlChartGraphDto.ts";
import i18n from "i18next";
import {VerticalBox} from "src/components/common/box/VerticalBox.tsx";
import {nullIfBlank} from "src/utils/strings.tsx";
import {useContextMenu} from "src/components/common/contextMenu/useContextMenu.ts";
import {AavoContextMenu} from "src/components/common/contextMenu/AavoContextMenu.tsx";
import {useContext, useEffect} from "react";
import {
    ControlChartGraphViewActionBar
} from "src/components/views/spc/controlChart/controlChartGraph/ControlChartGraphViewActionBar.tsx";
import {useAsyncState} from "src/utils/async/asyncState.ts";
import {logError} from "src/errorHandling/errorLogging.ts";
import dayjs, {Dayjs} from "dayjs";
import {ChartPaginationDirection} from "src/api/generated/common/chartPaginationDirection.ts";
import {
    ControlChartInspectingViewContext
} from "src/components/views/spc/controlChart/controlChartInspectingView/ControlChartInspectingViewContext.ts";
import {
    ControlChartGraphApi,
    ControlChartGraphApi_GraphDataDto,
} from "src/api/generated/spc/controlChart/api/controlChartGraphApi.ts";
import {
    ControlChartSingleGraph
} from "src/components/views/spc/controlChart/controlChartGraph/ControlChartSingleGraph.tsx";
import {getOnlyMember} from "src/utils/arrayUtils.ts";
import {useStoredState} from "src/utils/useStoredState.ts";

export interface ControlChartGraphViewProps {
	controlChartIds: number[];
	showOpenInspectingButton?: boolean;
	recordsBeforeTimeLimit?: Dayjs | null;
}

export interface ControlChartGraphViewRefreshParams {
	recordsBeforeLimit?: Dayjs | null;
	paginationDirection?: ChartPaginationDirection;
	recordCount?: number;
}

export interface ControlChartGraphViewApi {
	refresh: () => Promise<unknown>;
	searchWithTimeLimit: (params: { recordsBeforeLimit: Dayjs }) => Promise<unknown>;
}

export const ControlChartGraphView = (props: ControlChartGraphViewProps) => {
	const { controlChartIds, recordsBeforeTimeLimit } = props;

	const { graphViewApiRef } = useContext(ControlChartInspectingViewContext) ?? {};

	const [dataAsync, setDataAsync] = useAsyncState<ControlChartGraphApi_GraphDataDto>({});

	const [recordCount, setRecordCount] = useStoredState<number | null>({
		defaultValue: null,
		key: "controlChartGraphViewRecordCount." + [...controlChartIds].sort().join(","),
	});

	const refreshData = async (params?: ControlChartGraphViewRefreshParams) => {
		const onlyControlChart = dataAsync.data == null ? undefined : getOnlyMember(dataAsync.data.controlCharts);
		const currentRecords = onlyControlChart?.records ?? [];

		if (params?.recordCount !== undefined) {
			setRecordCount(params.recordCount);
		}

		await setDataAsync(() =>
			ControlChartGraphApi.getGraphData({
				controlChartIds: controlChartIds,
				recordsBeforeLimit: recordsBeforeTimeLimit?.toISOString() ?? params?.recordsBeforeLimit?.toISOString(),
				currentFirstRecordTime: currentRecords[0]?.observationTime,
				currentLastRecordTime: currentRecords[currentRecords.length - 1]?.observationTime,
				recordCount: params?.recordCount ?? recordCount,
				paginationDirection: getActualPaginationDirection(params?.paginationDirection, onlyControlChart),
			}),
		);
	};

	useEffect(() => {
		refreshData().catch(logError);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (graphViewApiRef != null) {
			graphViewApiRef.current = {
				refresh: () => refreshData(),
				searchWithTimeLimit: async (params) => {
					await refreshData({ recordsBeforeLimit: params.recordsBeforeLimit });
				},
			};
		}
	});

	return (
		<AsyncRender
			asyncData={dataAsync}
			reloadOnError={() => refreshData()}
			content={(data) => <AsyncContent data={data} refresh={refreshData} recordCount={recordCount} {...props} />}
		/>
	);
};

interface AsyncContentProps extends ControlChartGraphViewProps {
	data: ControlChartGraphApi_GraphDataDto;
	refresh: (params?: ControlChartGraphViewRefreshParams) => Promise<unknown>;
	recordCount: number | null;
}

const AsyncContent = ({ data, refresh, showOpenInspectingButton, recordCount }: AsyncContentProps) => {
	const [openContextMenu, contextMenuState] = useContextMenu();

	const { controlCharts } = data;
	const onlyControlChart = getOnlyMember(controlCharts);

	return (
		<VerticalBox
			sx={{
				flex: 1,
				alignItems: "stretch",
				paddingX: 1,
				paddingY: 0.5,
			}}
		>
			<ControlChartGraphViewActionBar
				controlCharts={controlCharts}
				refresh={refresh}
				canNavigateBackwards={getHasCurrentlyOldestData(onlyControlChart)}
				canNavigateForwards={getHasCurrentlyLatestData(onlyControlChart)}
				showOpenInspectingButton={showOpenInspectingButton}
				recordCount={recordCount}
			/>
			<VerticalBox flex={1} gap={1}>
				<ControlChartSingleGraph
					controlCharts={controlCharts}
					valueDescription={nullIfBlank(onlyControlChart?.valueDescription) ?? i18n.t("value")}
					xDescription={onlyControlChart?.xDescription ?? ""}
					yDescription={getIChartYAxisDescription()}
					getRecordValue={(r) => r.primaryValue}
					getAverage={(r) => r.xAvg}
					getLcl={(r) => r.xLcl}
					getUcl={(r) => r.xUcl}
					filterSpecialCauses={(sc) => sc > 100 && sc < 200}
					openContextMenu={openContextMenu}
					refreshData={refresh}
					drawSpecificationLimits={true}
				/>
				{hasRChart() && (
					<ControlChartSingleGraph
						controlCharts={controlCharts}
						valueDescription={"R̄"}
						xDescription={onlyControlChart?.xDescription ?? ""}
						yDescription={getRChartYAxisDescription()}
						getRecordValue={(r) => r.rValue ?? 0}
						getAverage={(r) => r.rAvg}
						getLcl={(r) => r.rLcl}
						getUcl={(r) => r.rUcl}
						filterSpecialCauses={(sc) => sc >= 200 && sc < 300}
						openContextMenu={openContextMenu}
						refreshData={refresh}
						drawSpecificationLimits={false}
					/>
				)}
			</VerticalBox>
			<AavoContextMenu state={contextMenuState} />
		</VerticalBox>
	);

	function hasRChart() {
		return controlCharts.every((cc) => ["I_MR", "XBAR_R", "XBAR_S"].includes(cc.chartType));
	}

	function getIChartYAxisDescription() {
		if (onlyControlChart == null) return "";

		if (nullIfBlank(onlyControlChart.yDescription) != null) {
			return onlyControlChart.yDescription!;
		}
		switch (onlyControlChart.chartType) {
			case "I":
			case "I_MR":
				return i18n.t("value");
			case "C":
				return i18n.t("count");
			case "XBAR_R":
			case "XBAR_S":
				return i18n.t("avg");
		}
	}

	function getRChartYAxisDescription() {
		if (onlyControlChart == null) return "";

		switch (onlyControlChart.chartType) {
			case "I":
			case "C":
				return "";
			case "I_MR":
				return i18n.t("moving_range.spc");
			case "XBAR_R":
				return i18n.t("range");
			case "XBAR_S":
				return i18n.t("standard_deviation");
		}
	}
};

function getActualPaginationDirection(
	paginationDirection: ChartPaginationDirection | undefined,
	onlyControlChart: ControlChartGraphDto | undefined,
): ChartPaginationDirection {
	switch (paginationDirection) {
		case "BACKWARD":
		case "FORWARD":
		case "LAST_PAGE":
			return paginationDirection;
		case "CURRENT_PAGE":
		case undefined:
			if (onlyControlChart == null) return "LAST_PAGE";
			// User most probably wants to see the latest data, if they currently have the latest data.
			else if (getHasCurrentlyLatestData(onlyControlChart)) return "LAST_PAGE";
			else return "CURRENT_PAGE";
	}
}

function getHasCurrentlyLatestData(onlyControlChart: ControlChartGraphDto | undefined) {
	if (onlyControlChart == null) return false;
	const { maxObservationTime, records } = onlyControlChart;

	const lastRecord = records[records.length - 1];
	return (
		lastRecord == null ||
		maxObservationTime == null ||
		dayjs(lastRecord.observationTime) >= dayjs(maxObservationTime)
	);
}

function getHasCurrentlyOldestData(onlyControlChart: ControlChartGraphDto | undefined) {
	if (onlyControlChart == null) return false;

	const { minObservationTime, records } = onlyControlChart;
	const firstRecord = records[0];
	return (
		firstRecord == null ||
		minObservationTime == null ||
		dayjs(firstRecord.observationTime) <= dayjs(minObservationTime)
	);
}
