import { AavoLoading } from "src/components/common/AavoLoading.tsx";
import { VerticalBox } from "src/components/common/box/VerticalBox";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { ConfiguratorTreeItemState } from "src/components/views/erp/configurator/configuratorForm/configuratorTree/treeItemState/ConfiguratorTreeItemState.ts";
import { Typography, useTheme } from "@mui/material";
import { mergeSx } from "src/utils/styles.ts";
import { alpha } from "@mui/material/styles";
import { overlaySx } from "src/styles/sx.ts";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { faChevronDown, faChevronRight, faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import i18n from "i18next";
import { AavoContextMenu } from "src/components/common/contextMenu/AavoContextMenu.tsx";
import { useContextMenu } from "src/components/common/contextMenu/useContextMenu.ts";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";

export interface RecursiveConfiguratorTreeItemProps<
	TState extends ConfiguratorTreeItemState = ConfiguratorTreeItemState,
> {
	state: TState;
	selectedItemKey: string | undefined;
	depth: number;
	selectTreeItem: (key: string) => void;
	toggleTreeItemExpansion: (key: string) => void;
	onAddSubConfiguratorClicked: (parentKey: string) => Promise<unknown>;
	deleteSubConfiguratorListItem: (key: string) => void;
	refreshTreeItemComputedValues: (key: string) => void;
	readonly? : boolean
}

export const RecursiveConfiguratorTreeItem = (props: RecursiveConfiguratorTreeItemProps) => {
	const {
		state,
		selectedItemKey,
		depth,
		selectTreeItem,
		toggleTreeItemExpansion,
		onAddSubConfiguratorClicked,
		readonly
	} = props;

	const { palette } = useTheme();
	const [openContextMenu, contextMenuState] = useContextMenu();

	const isSelected = state.key === selectedItemKey;

	return (
		<>
			<HorizontalBox
				component={"li"}
				onClick={() => {
					selectTreeItem(state.key);
				}}
				onContextMenu={(e) => {
					selectTreeItem(state.key);
					openContextMenu({ mouseEvent: e, content: <TreeItemContextMenu {...props} /> });
				}}
				sx={mergeSx(
					{
						paddingY: 1.25,
						paddingLeft: 1 + depth * 2,
						":hover": {
							backgroundColor: palette.grey[100],
						},
					},
					isSelected && {
						backgroundColor: alpha(palette.primary.light, 0.1),
					},
				)}
			>
				<AavoContextMenu state={contextMenuState} />
				<HorizontalBox
					sx={{
						position: "relative",
						alignItems: "center",
					}}
				>
					<VerticalBox>
						<Typography fontWeight={"bold"} marginRight={1}>
							{state.label}
						</Typography>
					</VerticalBox>
					{state.isLoading && (
						<HorizontalBox
							sx={mergeSx(overlaySx, {
								alignItems: "center",
								justifyContent: "center",
							})}
						>
							<AavoLoading />
						</HorizontalBox>
					)}
					{state.subConfiguratorComponents.length > 0 && !readonly && (
						<AsyncButton
							icon={faPlus}
							tooltip={i18n.t("add_sub_configuration")}
							size={"small"}
							sx={{
								paddingX: 0.5,
							}}
							onClick={async () => {
								await onAddSubConfiguratorClicked(state.key);
							}}
						/>
					)}
					{state.children.length > 0 && (
						<AavoButton
							icon={state.expanded ? faChevronDown : faChevronRight}
							color={"black"}
							sx={{
								fontSize: "0.75rem",
								padding: 1,
							}}
							onClick={() => {
								toggleTreeItemExpansion(state.key);
							}}
						/>
					)}
				</HorizontalBox>
			</HorizontalBox>
			{state.expanded &&
				state.children.map((childState) => (
					<RecursiveConfiguratorTreeItem
						{...props}
						key={childState.key}
						state={childState}
						depth={depth + 1}
					/>
				))}
		</>
	);
};

const TreeItemContextMenu = ({
	state,
	deleteSubConfiguratorListItem,
	readonly
}: RecursiveConfiguratorTreeItemProps) => {
	return [
		!readonly && <AsyncMenuButton
			key={"remove"}
			label={i18n.t("remove")}
			icon={faTrash}
			onClick={() => {
				deleteSubConfiguratorListItem(state.key);
			}}
		/>,
	];
};
