// This file is automatically generated. Do not edit manually.

import { Supplier } from "src/api/generated/erp/db/types/tables/supplier";
import { DeliveryMethod } from "src/api/generated/erp/db/types/tables/deliveryMethod";
import { DeliveryTerm } from "src/api/generated/erp/db/types/tables/deliveryTerm";
import { Site } from "src/api/generated/erp/db/types/tables/site";
import { PaymentTerm } from "src/api/generated/erp/db/types/tables/paymentTerm";
import { SupplierGroup } from "src/api/generated/erp/db/types/tables/supplierGroup";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SupplierEditApi {
	export async function getFormInitData(args: {
		supplierId: number | null | undefined;
	}): Promise<SupplierEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/suppliers/getFormInitData",
			method: "POST",
			data: {
				supplierId: args.supplierId,
			},
		});
		return response as SupplierEditApi_FormInitData;
	}

	export async function insert(args: { supplier: Supplier }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/suppliers/insert",
			method: "POST",
			data: {
				supplier: args.supplier,
			},
		});
		return response as number;
	}

	export async function update(args: { supplier: Supplier }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/suppliers/update",
			method: "POST",
			data: {
				supplier: args.supplier,
			},
		});
		return response as void;
	}

	export async function delete_(args: { supplierId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/suppliers/delete",
			method: "POST",
			data: {
				supplierId: args.supplierId,
			},
		});
		return response as void;
	}
}

export interface SupplierEditApi_FormInitData {
	deliveryMethodOptions: Array<DeliveryMethod>;
	deliveryTermOptions: Array<DeliveryTerm>;
	internalSupplierSiteOptions: Array<Site>;
	paymentTermOptions: Array<PaymentTerm>;
	supplier: Supplier | null | undefined;
	supplierGroupOptions: Array<SupplierGroup>;
}
