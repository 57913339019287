import {useOpenLegacyView} from "src/components/views/legacy/useOpenLegacyView.ts";
import {useGenericDialog} from "src/components/common/dialogs/GenericDialogContext.ts";
import {HorizontalBox} from "src/components/common/box/HorizontalBox.tsx";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {
	faBox,
	faChalkboardTeacher,
	faCheckDouble,
	faDolly,
	faPeopleCarry,
	faRefresh,
	faUndo,
} from "@fortawesome/pro-regular-svg-icons";
import {AddDocumentsToObjectButton} from "src/components/views/documents/AddDocumentsToObjectButton.tsx";
import i18n from "i18next";
import {
	ProductionJobQueueOperationActionApi
} from "src/api/generated/erp/production/jobQueues/api/productionJobQueueOperationActionApi.ts";
import {OpenShopOrderOperationSurveyButton} from "src/components/views/surveys/OpenShopOrderOperationSurveyButton.tsx";
import {
	openLegacyWorkCenterWarehouseSupplyRequestContainerView
} from "src/components/views/legacy/legacyViewAdapters.ts";
import {
	JobQueueShopOrderOperation
} from "src/components/views/erp/production/jobQueue/base/JobQueueShopOrderOperation.ts";
import {useJobQueueViewContext} from "src/components/views/erp/production/jobQueue/base/JobQueueViewContext.ts";
import {
	OperationAndWorkCenterDocumentsView
} from "src/components/views/erp/production/jobQueue/base/inProgressView/OperationAndWorkCenterDocumentsView.tsx";
import {ReactNode} from "react";
import {
	ShopOrderBomReserveAndPickView
} from "src/components/views/erp/production/shopOrderPicking/ShopOrderBomReserveAndPickView.tsx";
import {useConfirmDialog} from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import {openFormOnDialog} from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import {ShopOrderCompletionForm} from "src/components/views/erp/production/jobQueue/common/ShopOrderCompletionForm.tsx";
import {JobQueueAndonButton} from "src/components/views/erp/production/jobQueue/common/JobQueueAndonButton.tsx";
import {Workcenter} from "src/api/generated/erp/db/types/tables/workcenter.ts";
import {getOnlyMember} from "src/utils/arrayUtils.ts";
import {nullableAavoObjectRef} from "src/utils/aavoObjectRefUtils.ts";
import {useTenantCustomizations} from "src/tenantCustomizations/TenantCustomizationsContext.ts";
import {HourglassWithPlusIcon} from "src/components/common/icons/HourglassWithPlusIcon.tsx";
import {CostEventForm} from "src/components/views/erp/common/costEvents/CostEventForm.tsx";
import {CostEventsOfObjectDataGridApi} from "src/api/generated/erp/common/costEvents/costEventsOfObjectDataGridApi.ts";
import {CostEventObjectRef} from "src/api/generated/erp/common/costEvents/costEventObjectRef.ts";
import {showAsyncDialog} from "src/components/common/dialogs/asyncDialog.ts";

export interface SelectedOperationActionsViewProps {
	selectedOperations: JobQueueShopOrderOperation[];
	workCenter: Workcenter;
	extraActionBarComponents?: (props: SelectedOperationActionsViewExtraComponentProps) => ReactNode;
	alwaysAllowOperationCompletion?: boolean;
	confirmIfWorkCenterHasIncompleteOperations?: boolean;
	moveShopOrdersOnProductionLine: boolean;
	revertStartOperationEnabled: boolean;
}

export interface SelectedOperationActionsViewExtraComponentProps {
	selectedOperations: JobQueueShopOrderOperation[];
	onlySelectedOperation: JobQueueShopOrderOperation | undefined;
}

export const SelectedOperationActionsView = ({
	selectedOperations,
	workCenter,
	extraActionBarComponents,
	alwaysAllowOperationCompletion,
	confirmIfWorkCenterHasIncompleteOperations,
	moveShopOrdersOnProductionLine,
	revertStartOperationEnabled,
}: SelectedOperationActionsViewProps) => {
	const {
		initialsViewRefreshRef,
		inProgressViewRefreshRef,
		completedViewRefreshRef,
		inProgressViewDocumentsRefreshRef,
	} = useJobQueueViewContext();

	const openLegacyView = useOpenLegacyView();
	const { openDialog } = useGenericDialog();
	const { tenantConfig } = useTenantCustomizations();
	const showConfirmDialog = useConfirmDialog();

	const onlySelectedOperation = getOnlyMember(selectedOperations);

	return (
		<HorizontalBox
			sx={{
				padding: 1,
				gap: 1,
				flexWrap: "wrap",
			}}
		>
			<AsyncButton
				icon={faRefresh}
				onClick={async () => {
					await inProgressViewRefreshRef.refresh();
				}}
			/>
			{tenantConfig.documentsEnabled && (
				<>
					<AddDocumentsToObjectButton
						objectRef={nullableAavoObjectRef(
							"SHOP_ORDER_OPERATION",
							onlySelectedOperation?.shopOrderOperationId,
						)}
						afterUpload={inProgressViewDocumentsRefreshRef.refresh}
					/>
					<AsyncButton
						icon={faChalkboardTeacher}
						disabled={!onlySelectedOperation}
						onClick={() => {
							openDialog({
								title: i18n.t("documents"),
								content: (
									<OperationAndWorkCenterDocumentsView
										operationId={onlySelectedOperation!.operationId}
										workCenterId={workCenter.workcenterId}
									/>
								),
							});
						}}
					/>
				</>
			)}
			{onlySelectedOperation?.costEventPriceListId != null && (
				<AsyncButton
					iconEl={<HourglassWithPlusIcon />}
					tooltip={i18n.t("add_cost_event")}
					onClick={() => {
						openDialog(({ closeDialog }) => ({
							title: i18n.t("add_cost_event"),
							size: "sm",
							content:
								onlySelectedOperation.costEventPriceListId == null ?
									<></>
								:	<CostEventForm
										costEventId={undefined}
										costEventPriceListId={onlySelectedOperation.costEventPriceListId}
										newCostEventObjectRef={{
											objectId: onlySelectedOperation.shopOrderOperationId,
											objectType: "SHOP_ORDER_OPERATION",
										}}
										enableShowEventsButton={true}
										onCompleted={async () => {
											await closeDialog();
										}}
									/>,
						}));
					}}
				/>
			)}
			{extraActionBarComponents?.({
				selectedOperations,
				onlySelectedOperation,
			})}
			{(alwaysAllowOperationCompletion || workCenter.completionEnabled) && (
				<AsyncButton
					label={i18n.t("complete")}
					icon={faCheckDouble}
					variant={"outlined"}
					disabled={selectedOperations.length === 0}
					onClick={async () => {
						for (const operation of selectedOperations) {
							if (confirmIfWorkCenterHasIncompleteOperations) {
								const workCenterHasIncompleteOperations =
									await ProductionJobQueueOperationActionApi.hasWorkcenterIncompleteOperations({
										shopOrderId: operation.shopOrderId,
									});
								if (workCenterHasIncompleteOperations) {
									const confirmed = await showConfirmDialog({
										title: i18n.t("are_you_sure"),
										message: i18n.t("shop_order_has_incomplete_operations_on_work_center", {
											shopOrderId: operation.shopOrderId,
										}),
									});
									if (!confirmed) return;
								}
							}
							const objectRef = {
								objectType: "SHOP_ORDER_OPERATION",
								objectId: operation.shopOrderOperationId,
							} as CostEventObjectRef;
							const hasCostEvents = await CostEventsOfObjectDataGridApi.objectHasCostEvents({
								objectRef: objectRef,
							});
							const costEventPriceListId = operation.costEventPriceListId;
							if (operation.requireCostEvent && !hasCostEvents && costEventPriceListId != null) {
								const costEventId = await showAsyncDialog<number>(openDialog, ({ onCompleted }) => ({
									title: `${i18n.t("add_cost_event")} | ${operation.shopOrderId} | ${operation.operationDescription}`,
									size: "sm",
									content: (
										<CostEventForm
											costEventId={undefined}
											costEventPriceListId={costEventPriceListId}
											newCostEventObjectRef={objectRef}
											onCompleted={onCompleted}
										/>
									),
								}));
								if (costEventId == undefined) return;
							}
						}

						await ProductionJobQueueOperationActionApi.completeOperations({
							shopOrderOperationIds: selectedOperations.map(
								(operation) => operation.shopOrderOperationId,
							),
							moveShopOrderOnProductionLine: moveShopOrdersOnProductionLine,
						});
						await Promise.all([inProgressViewRefreshRef.refresh(), completedViewRefreshRef.refresh()]);
					}}
				/>
			)}
			{revertStartOperationEnabled && (
				<AsyncButton
					label={i18n.t("cancel")}
					icon={faUndo}
					variant={"outlined"}
					disabled={!onlySelectedOperation}
					onClick={async () => {
						await ProductionJobQueueOperationActionApi.revertStartOperations({
							shopOrderOperationIds: [onlySelectedOperation!.shopOrderOperationId],
							moveShopOrderOnProductionLine: moveShopOrdersOnProductionLine,
						});
						await Promise.all([inProgressViewRefreshRef.refresh(), initialsViewRefreshRef.refresh()]);
					}}
				/>
			)}
			<OpenShopOrderOperationSurveyButton shopOrderOperation={onlySelectedOperation} />
			<AsyncButton
				icon={faDolly}
				label={i18n.t("reserve_and_pick")}
				variant={"outlined"}
				disabled={!onlySelectedOperation}
				onClick={() => {
					openDialog(() => ({
						title: i18n.t("reserve_and_pick_materials"),
						size: "lg",
						content: (
							<ShopOrderBomReserveAndPickView
								shopOrderId={onlySelectedOperation!.shopOrderId}
								shopOrderOperationId={onlySelectedOperation!.shopOrderOperationId}
							/>
						),
					}));
				}}
			/>
			<AsyncButton
				label={i18n.t("warehouse_transfer")}
				icon={faPeopleCarry}
				variant={"outlined"}
				onClick={() => {
					openLegacyWorkCenterWarehouseSupplyRequestContainerView({
						openLegacyView,
					});
				}}
			/>
			{workCenter.partialCompletionEnabled && (
				<AsyncButton
					label={i18n.t("report")}
					icon={faBox}
					variant={"outlined"}
					disabled={!onlySelectedOperation}
					onClick={async () => {
						openFormOnDialog({
							openDialog,
							component: ShopOrderCompletionForm,
							title: i18n.t("report"),
							size: "md",
							props: {
								shopOrderId: onlySelectedOperation!.shopOrderId,
								shopOrderOperationIdToComplete: onlySelectedOperation!.shopOrderOperationId,
								exitShopOrderFromProductionLine: moveShopOrdersOnProductionLine,
							},
							confirmCloseIfEdited: false,
							onSubmit: async () => {
								await Promise.all([
									inProgressViewRefreshRef.refresh(),
									completedViewRefreshRef.refresh(),
								]);
							},
						});
					}}
				/>
			)}
			<JobQueueAndonButton workCenter={workCenter} refresh={inProgressViewRefreshRef.refresh} />
		</HorizontalBox>
	);
};
