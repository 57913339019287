import i18n from "i18next";
import { WarehouseReplenishmentRequestApi } from "src/api/generated/erp/warehouse/warehouseTransfer/api/warehouseReplenishmentRequestApi.ts";
import { useGlobalInitData } from "src/contexts/GlobalInitDataContext.ts";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { parseReplenishmentRequestCode } from "src/components/views/erp/warehouse/replenishment/utils.tsx";
import { useMessageDialog } from "src/components/common/dialogs/messageDialog/MessageDialogContext.tsx";
import { PartWarehouseLocationQueryApi } from "src/api/generated/erp/parts/partWarehouseLocations/api/partWarehouseLocationQueryApi.ts";
import { useReplenishmentRequestLog } from "src/components/views/erp/warehouse/replenishment/useReplenishmentRequestLog.tsx";
import { ReplenishmentRequestBarcodeView } from "src/components/views/erp/warehouse/replenishment/ReplenishmentRequestBarcodeView.tsx";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { ReplenishmentRequestCameraView } from "src/components/views/erp/warehouse/replenishment/ReplenishmentRequestCameraView.tsx";
import { useAavoToast } from "src/components/common/toast/AavoToastContext.tsx";
import { logError } from "src/errorHandling/errorLogging.ts";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/ErrorDialogContext.tsx";

export const WarehouseReplenishmentRequestView = () => {
	const { defaultSiteId } = useGlobalInitData();
	const showInputDialog = useInputDialog();
	const showMessageDialog = useMessageDialog();
	const { logErrorAndShowOnDialog } = useErrorDialog();
	const { log, addLogEntry, dropLogEntryById } = useReplenishmentRequestLog();
	const { showToast } = useAavoToast();

	return (
		<AavoMosaic
			viewId={"8832DB1A04A2ED79"}
			layout={{
				type: "tabs",
				items: [
					{
						type: "panel",
						title: i18n.t("barcode"),
						content: (
							<ReplenishmentRequestBarcodeView
								createReplenishmentRequest={createReplenishmentRequest}
								cancelReplenishmentRequest={cancelReplenishmentRequest}
								log={log}
							/>
						),
					},
					{
						type: "panel",
						title: i18n.t("camera"),
						content: (
							<ReplenishmentRequestCameraView
								createReplenishmentRequest={createReplenishmentRequest}
								cancelReplenishmentRequest={cancelReplenishmentRequest}
								log={log}
							/>
						),
					},
				],
			}}
		/>
	);

	async function createReplenishmentRequest(code: string) {
		try {
			const parseRequestCodeReturn = parseReplenishmentRequestCode({
				code: code,
			});

			if (parseRequestCodeReturn.type == "error") {
				showMessageDialog({
					title: i18n.t("error"),
					content: parseRequestCodeReturn.errorMessage,
				});
				return;
			}

			let warehouseLocation;
			try {
				warehouseLocation = await PartWarehouseLocationQueryApi.getPartWarehouseLocation({
					partNo: parseRequestCodeReturn.partNo,
					locationCode: parseRequestCodeReturn.locationCode,
					siteId: defaultSiteId,
				});
			} catch (e) {
				logError(e);
				showMessageDialog({
					title: i18n.t("error"),
					content: i18n.t("warehouse_location_for_part_not_found", {
						partNo: parseRequestCodeReturn.partNo,
						warehouseLocationCode: parseRequestCodeReturn.locationCode,
					}),
				});
				return;
			}

			const quantity =
				warehouseLocation.askQuantity ?
					await showInputDialog({
						type: "decimal",
						title: i18n.t("quantity"),
						defaultValue: null,
						required: true,
					})
				:	warehouseLocation.defaultReplenishmentQuantity;

			const info =
				warehouseLocation.askTransferInfo ?
					await showInputDialog({
						type: "string",
						title: i18n.t("warehouse_transfer_additional_info"),
						defaultValue: "",
					})
				:	"";

			if (quantity == undefined) return;
			if (info === undefined) return;

			const replenishmentResult = await WarehouseReplenishmentRequestApi.createReplenishmentRequest({
				partWarehouseLocationId: warehouseLocation.partWarehouseLocationId,
				replenishmentRequestInput: {
					info: info,
					quantity: quantity,
				},
			});

			addLogEntry(replenishmentResult);

			showToast({
				title: i18n.t("ready"),
				message: i18n.t("request_created"),
			});
		} catch (e) {
			logErrorAndShowOnDialog(e);
		}
	}

	async function cancelReplenishmentRequest(code: string) {
		try {
			const parseRequestCodeReturn = parseReplenishmentRequestCode({
				code: code,
			});

			if (parseRequestCodeReturn.type == "error") {
				showMessageDialog({ content: parseRequestCodeReturn.errorMessage });
				return;
			}

			const cancelledReplenishmentRequestId =
				await WarehouseReplenishmentRequestApi.cancelWarehouseReplenishmentRequest({
					partNo: parseRequestCodeReturn.partNo,
					locationCode: parseRequestCodeReturn.locationCode,
					siteId: defaultSiteId,
				});

			dropLogEntryById(cancelledReplenishmentRequestId);

			showToast({
				title: i18n.t("ready"),
				message: i18n.t("request_cancelled"),
			});
		} catch (e) {
			logErrorAndShowOnDialog(e);
		}
	}
};
