// This file is automatically generated. Do not edit manually.

import { DefectPerformanceChart } from "src/api/generated/postgres/db/types/public_/tables/defectPerformanceChart";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace DefectPerformanceChartEditApi {
	export async function getFormInitData(args: {
		defectPerformanceChartId: number | null | undefined;
	}): Promise<DefectPerformanceChartEditApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/defectPerformanceChart/edit/getFormInitData",
			method: "POST",
			data: {
				defectPerformanceChartId: args.defectPerformanceChartId,
			},
		});
		return response as DefectPerformanceChartEditApi_InitData;
	}

	export async function insert(args: { defectPerformanceChart: DefectPerformanceChart }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/defectPerformanceChart/edit/insert",
			method: "POST",
			data: {
				defectPerformanceChart: args.defectPerformanceChart,
			},
		});
		return response as number;
	}

	export async function update(args: { defectPerformanceChart: DefectPerformanceChart }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/defectPerformanceChart/edit/update",
			method: "POST",
			data: {
				defectPerformanceChart: args.defectPerformanceChart,
			},
		});
		return response as void;
	}

	export async function delete_(args: { defectPerformanceChartId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/defectPerformanceChart/edit/delete",
			method: "POST",
			data: {
				defectPerformanceChartId: args.defectPerformanceChartId,
			},
		});
		return response as void;
	}
}

export interface DefectPerformanceChartEditApi_InitData {
	defectPerformanceChart: DefectPerformanceChart | null | undefined;
	totalOutputChartFullName: string;
}
