import {SystemDiagramNode} from "src/api/generated/postgres/db/types/public_/tables/systemDiagramNode";
import {
    SystemDiagramNodeCrudApi_FormInitialData
} from "src/api/generated/systemDiagrams/management/systemDiagramNodeCrudApi";
import {SystemDiagramFunction} from "src/api/generated/systemDiagrams/functions/systemDiagramFunction";
import {Typography} from "@mui/material";
import {logError} from "src/errorHandling/errorLogging";
import {Controller, UseControllerProps} from "react-hook-form";
import {
    OperationSelectionField
} from "src/components/views/erp/common/systemDiagrams/functionParamForms/OperationSelectionField";
import i18n from "i18next";
import {ControlChartSelectionFormFields} from "src/components/views/spc/common/ControlChartSelectionFormFields.tsx";
import {FormSerializedMultiSelectField} from "src/components/common/forms/fields/FormSerializedMultiSelectField.tsx";
import {FormMultiSelectField} from "src/components/common/forms/fields/FormMultiSelectField.tsx";
import {getShopOrderStateLabel, ShopOrderStateValues} from "src/api/generated/erp/db/types/enums/shopOrderState.ts";
import {
    ProductionLinesSelectionField
} from "src/components/views/erp/common/systemDiagrams/functionParamForms/ProductionLinesSelectionField.tsx";

export interface SystemDiagramFunctionParamsFormProps {
	control: UseControllerProps<SystemDiagramNode>["control"];
	nodeFunction: SystemDiagramFunction | undefined;
	formInitialData: SystemDiagramNodeCrudApi_FormInitialData;
}

export const SystemDiagramFunctionParamsForm = (props: SystemDiagramFunctionParamsFormProps) => {
	const { nodeFunction } = props;
	if (nodeFunction === undefined) return <></>;

	const functionType = functionTypeMap[nodeFunction.id];
	if (functionType == null) {
		logError(`System diagram node function "${nodeFunction.label}" not implemented`);
		return (
			<Typography>
				{i18n.t("function_is_not_defined", {
					function: nodeFunction.label,
				})}
			</Typography>
		);
	}

	switch (functionType) {
		case "operations_completed_today":
			return (
				<>
					<OperationSelectionField fieldName={"systemDiagramNodeFunctionParams.param1"} {...props} />
					<ProductionLinesSelectionField fieldName={"systemDiagramNodeFunctionParams.param9"} {...props} />
				</>
			);

		case "first_operation_completed_any_other_not_started":
			return (
				<>
					<ProductionLinesSelectionField fieldName={"systemDiagramNodeFunctionParams.param9"} {...props} />
					<OperationSelectionField
						fieldName={"systemDiagramNodeFunctionParams.param1"}
						label={i18n.t("completed_operation")}
						{...props}
					/>
					<OperationSelectionField
						fieldName={"systemDiagramNodeFunctionParams.param2"}
						operationIndex={2}
						{...props}
					/>
					<OperationSelectionField
						fieldName={"systemDiagramNodeFunctionParams.param3"}
						operationIndex={3}
						{...props}
					/>
					<OperationSelectionField
						fieldName={"systemDiagramNodeFunctionParams.param4"}
						operationIndex={4}
						{...props}
					/>
					<OperationSelectionField
						fieldName={"systemDiagramNodeFunctionParams.param5"}
						operationIndex={5}
						{...props}
					/>
					<OperationSelectionField
						fieldName={"systemDiagramNodeFunctionParams.param6"}
						operationIndex={6}
						{...props}
					/>
					<OperationSelectionField
						fieldName={"systemDiagramNodeFunctionParams.param7"}
						operationIndex={7}
						{...props}
					/>
					<OperationSelectionField
						fieldName={"systemDiagramNodeFunctionParams.param8"}
						operationIndex={8}
						{...props}
					/>
				</>
			);

		case "first_completed_second_started":
			return (
				<>
					<ProductionLinesSelectionField fieldName={"systemDiagramNodeFunctionParams.param9"} {...props} />
					<OperationSelectionField
						fieldName={"systemDiagramNodeFunctionParams.param1"}
						operationIndex={1}
						{...props}
					/>
					<OperationSelectionField
						fieldName={"systemDiagramNodeFunctionParams.param2"}
						operationIndex={2}
						{...props}
					/>
				</>
			);

		case "first_or_second_operation_completed_third_not_started":
			return (
				<>
					<ProductionLinesSelectionField fieldName={"systemDiagramNodeFunctionParams.param9"} {...props} />
					<OperationSelectionField
						fieldName={"systemDiagramNodeFunctionParams.param1"}
						operationIndex={1}
						{...props}
					/>
					<OperationSelectionField
						fieldName={"systemDiagramNodeFunctionParams.param2"}
						operationIndex={2}
						{...props}
					/>
					<OperationSelectionField
						fieldName={"systemDiagramNodeFunctionParams.param3"}
						operationIndex={3}
						{...props}
					/>
				</>
			);

		case "first_and_second_operation_completed_third_not_started":
			return (
				<>
					<ProductionLinesSelectionField fieldName={"systemDiagramNodeFunctionParams.param9"} {...props} />
					<OperationSelectionField
						fieldName={"systemDiagramNodeFunctionParams.param1"}
						operationIndex={1}
						{...props}
					/>
					<OperationSelectionField
						fieldName={"systemDiagramNodeFunctionParams.param2"}
						operationIndex={2}
						{...props}
					/>
					<OperationSelectionField
						fieldName={"systemDiagramNodeFunctionParams.param3"}
						operationIndex={3}
						{...props}
					/>
				</>
			);

		case "operation_incomplete":
			return (
				<>
					<ProductionLinesSelectionField fieldName={"systemDiagramNodeFunctionParams.param9"} {...props} />
					<OperationSelectionField fieldName={"systemDiagramNodeFunctionParams.param1"} {...props} />
				</>
			);

		case "operations_started_today":
			return (
				<>
					<ProductionLinesSelectionField fieldName={"systemDiagramNodeFunctionParams.param9"} {...props} />
					<OperationSelectionField fieldName={"systemDiagramNodeFunctionParams.param1"} {...props} />
				</>
			);

		case "picked_customer_orders":
			return (
				<>
					<ProductionLinesSelectionField fieldName={"systemDiagramNodeFunctionParams.param9"} {...props} />
				</>
			);

		case "operation_not_started_materials_available":
			return (
				<>
					<ProductionLinesSelectionField fieldName={"systemDiagramNodeFunctionParams.param9"} {...props} />
				</>
			);

		case "customer_order_in_tenant_state":
			return (
				<>
					<FormSerializedMultiSelectField
						label={i18n.t("state")}
						name={"systemDiagramNodeFunctionParams.param1"}
						control={props.control}
						options={props.formInitialData.customerOrderTenantStateOptions}
					/>
					<FormSerializedMultiSelectField
						label={i18n.t("customer_order_type")}
						name={"systemDiagramNodeFunctionParams.param2"}
						control={props.control}
						options={props.formInitialData.customerOrderTypeOptions.map((orderType) => ({
							key: orderType.customerOrderTypeId,
							label: orderType.name,
						}))}
					/>
				</>
			);

		case "control_chart_status":
			return (
				<Controller
					name={"systemDiagramNodeFunctionParams.param1"}
					control={props.control}
					render={({ field }) => (
						<ControlChartSelectionFormFields
							currentControlChartId={field.value == null ? undefined : parseInt(field.value)}
							onChange={field.onChange}
						/>
					)}
				/>
			);

		case "shop_order_state":
			return (
				<>
					<FormMultiSelectField
						control={props.control}
						name={"systemDiagramNodeFunctionParams.param1"}
						label={i18n.t("states")}
						options={ShopOrderStateValues.map((state) => ({
							key: state,
							label: getShopOrderStateLabel(state),
						}))}
						rules={{ required: i18n.t("required") }}
						encodeValue={(v) => JSON.stringify(v)}
						decodeValue={(v) => (v != null ? JSON.parse(v) : [])}
					/>
					<ProductionLinesSelectionField fieldName={"systemDiagramNodeFunctionParams.param9"} {...props} />
				</>
			);
	}
};

type FunctionType =
	| "operations_completed_today"
	| "first_operation_completed_any_other_not_started"
	| "first_completed_second_started"
	| "first_or_second_operation_completed_third_not_started"
	| "first_and_second_operation_completed_third_not_started"
	| "operation_incomplete"
	| "operations_started_today"
	| "picked_customer_orders"
	| "operation_not_started_materials_available"
	| "customer_order_in_tenant_state"
	| "shop_order_state"
	| "control_chart_status";

const functionTypeMap: Record<number, FunctionType> = {
	1: "operations_completed_today",
	2: "first_operation_completed_any_other_not_started",
	3: "first_completed_second_started",
	4: "first_or_second_operation_completed_third_not_started",
	5: "first_and_second_operation_completed_third_not_started",
	6: "operation_incomplete",
	7: "operations_started_today",
	8: "picked_customer_orders",
	9: "operation_not_started_materials_available",
	10: "customer_order_in_tenant_state",
	11: "shop_order_state",
	100: "control_chart_status",
};
