import { useRefreshRef } from "src/utils/useRefreshRef.ts";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { WarehouseTransferJobQueueNotStartedDataGrid } from "src/components/views/erp/warehouse/transfer/WarehouseTransferJobQueueNotStartedDataGrid.tsx";
import i18n from "i18next";
import { WarehouseTransferJobQueueReservedDataGrid } from "src/components/views/erp/warehouse/transfer/WarehouseTransferJobQueueReservedDataGrid.tsx";
import { WarehouseTransferJobQueueTransferredDataGrid } from "src/components/views/erp/warehouse/transfer/WarehouseTransferJobQueueTransferredDataGrid.tsx";
import { WarehouseTransferJobQueuePickedDataGrid } from "src/components/views/erp/warehouse/transfer/WarehouseTransferJobQueuePickedDataGrid.tsx";
import { useInterval } from "src/utils/useInterval.ts";

export interface WarehouseTransferJobQueueViewProps {
	isActive: boolean;
}

export const WarehouseTransferJobQueueView = ({ isActive }: WarehouseTransferJobQueueViewProps) => {
	const notStartedRefreshRef = useRefreshRef();
	const reservedRefreshRef = useRefreshRef();
	const pickedRefreshRef = useRefreshRef();
	const deliveredRefreshRef = useRefreshRef();

	useInterval({
		callback: () => notStartedRefreshRef.refresh(),
		intervalMs: 30 * 1000,
		isActive: isActive,
	});

	return (
		<AavoMosaic
			viewId={"7EB53A3C4887ACDC"}
			layout={{
				type: "tabs",
				items: [
					{
						type: "panel",
						title: i18n.t("not_started"),
						content: (
							<WarehouseTransferJobQueueNotStartedDataGrid
								refreshRef={notStartedRefreshRef}
								reservedRefreshRef={reservedRefreshRef}
							/>
						),
					},
					{
						type: "panel",
						title: i18n.t("reserved"),
						content: (
							<WarehouseTransferJobQueueReservedDataGrid
								refreshRef={reservedRefreshRef}
								notStartedRefreshRef={notStartedRefreshRef}
								pickedRefreshRef={pickedRefreshRef}
								deliveredRefreshRef={deliveredRefreshRef}
							/>
						),
					},
					{
						type: "panel",
						title: i18n.t("picked"),
						content: (
							<WarehouseTransferJobQueuePickedDataGrid
								refreshRef={pickedRefreshRef}
								reservedRefreshRef={reservedRefreshRef}
								deliveredRefreshRef={deliveredRefreshRef}
							/>
						),
					},
					{
						type: "panel",
						title: i18n.t("delivered"),
						content: (
							<WarehouseTransferJobQueueTransferredDataGrid
								refreshRef={deliveredRefreshRef}
								pickedRefreshRef={pickedRefreshRef}
							/>
						),
					},
				],
			}}
		/>
	);
};
