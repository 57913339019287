// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ProcessImprovementStateValues = [
	"INITIAL",
	"RELEASED",
	"ANALYSIS_STARTED",
	"CAUSE_KNOWN",
	"ACT_STARTED",
	"COMPLETED",
	"CANCELLED",
] as const;

export type ProcessImprovementState = (typeof ProcessImprovementStateValues)[number];

export const getProcessImprovementStateLabels = () => ({
	INITIAL: t("initial"),
	RELEASED: t("released"),
	ANALYSIS_STARTED: t("analysis_started"),
	CAUSE_KNOWN: t("cause_known"),
	ACT_STARTED: t("act_started"),
	COMPLETED: t("completed"),
	CANCELLED: t("cancelled"),
});

export const getProcessImprovementStateLabel = (value: ProcessImprovementState): string => {
	return getProcessImprovementStateLabels()[value];
};
