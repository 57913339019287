// This file is automatically generated. Do not edit manually.

import { ConfigurationProductFamilyVersionView } from "src/api/generated/erp/db/types/tables/configurationProductFamilyVersionView";
import { CatalogPart } from "src/api/generated/erp/db/types/tables/catalogPart";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ConfigurationProductFamilyVersionApi {
	export async function getProductFamilyVersions(args: {
		productFamilyId: number;
	}): Promise<Array<ConfigurationProductFamilyVersionView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/productFamily/version/getProductFamilyVersions",
			method: "POST",
			data: {
				productFamilyId: args.productFamilyId,
			},
		});
		return response as Array<ConfigurationProductFamilyVersionView>;
	}

	export async function getProductFamilyVersion(args: {
		productFamilyVersionId: number;
	}): Promise<ConfigurationProductFamilyVersionView> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/productFamily/version/getProductFamilyVersion",
			method: "POST",
			data: {
				productFamilyVersionId: args.productFamilyVersionId,
			},
		});
		return response as ConfigurationProductFamilyVersionView;
	}

	export async function getProductFamilyVersionCatalogParts(args: {
		productFamilyVersionId: number;
	}): Promise<Array<CatalogPart>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/productFamily/version/getProductFamilyVersionCatalogParts",
			method: "POST",
			data: {
				productFamilyVersionId: args.productFamilyVersionId,
			},
		});
		return response as Array<CatalogPart>;
	}

	export async function updateProductFamilyVersion(args: {
		productFamilyVersionId: number;
		description: string;
		configurationTopLevelScript: string;
		infoTextTemplate?: string;
		unrestrictedEditing: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/productFamily/version/updateProductFamilyVersion",
			method: "POST",
			data: {
				productFamilyVersionId: args.productFamilyVersionId,
				description: args.description,
				configurationTopLevelScript: args.configurationTopLevelScript,
				infoTextTemplate: args.infoTextTemplate,
				unrestrictedEditing: args.unrestrictedEditing,
			},
		});
		return response as void;
	}

	export async function cloneProductFamilyVersion(args: {
		sourceVersionId: number;
		newVersionDescription: string;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/productFamily/version/cloneProductFamilyVersion",
			method: "POST",
			data: {
				sourceVersionId: args.sourceVersionId,
				newVersionDescription: args.newVersionDescription,
			},
		});
		return response as void;
	}

	export async function publishProductFamilyVersion(args: { versionId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/productFamily/version/publishProductFamilyVersion",
			method: "POST",
			data: {
				versionId: args.versionId,
			},
		});
		return response as void;
	}

	export async function revertPublishProductFamilyVersion(args: { versionId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/productFamily/version/revertPublishProductFamilyVersion",
			method: "POST",
			data: {
				versionId: args.versionId,
			},
		});
		return response as void;
	}

	export async function deleteProductFamilyVersion(args: { versionId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/productFamily/version/deleteProductFamilyVersion",
			method: "POST",
			data: {
				versionId: args.versionId,
			},
		});
		return response as void;
	}
}
