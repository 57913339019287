import {AsyncFetchRender} from "src/components/common/async/AsyncFetchRender.tsx";
import {
    SingleShiftPerformanceChartApi
} from "src/api/generated/spc/defectPerformanceChart/singleShiftPerformanceChartApi.ts";
import {IsoDateTimeString} from "src/types/dateTime.ts";
import {AavoECharts} from "src/components/common/echarts/AavoECharts.tsx";
import i18n from "i18next";
import {useTheme} from "@mui/material";

export interface SingleShiftPerformanceChartViewProps {
	defectPerformanceChartId: number;
	startTime: IsoDateTimeString;
	endTime: IsoDateTimeString;
}

export const SingleShiftPerformanceChartView = ({
	defectPerformanceChartId,
	startTime,
	endTime,
}: SingleShiftPerformanceChartViewProps) => {
	const { palette } = useTheme();
	return (
		<AsyncFetchRender
			fetch={async () =>
				await SingleShiftPerformanceChartApi.getSingleShiftPerformanceChartData({
					defectPerformanceChartId: defectPerformanceChartId,
					startTime: startTime,
					endTime: endTime,
				})
			}
			content={(data) => {
				return (
					<AavoECharts
						sx={{
							flex: 1,
						}}
						option={{
							grid: {
								left: 30,
								bottom: 0,
								containLabel: true,
							},
							animationDuration: 500,
							xAxis: {
								type: "category",
								data: data.map((p) => p.chartName),
								axisTick: {
									alignWithLabel: true,
									interval: 0,
								},
								axisLabel: {
									interval: 0,
									rotate: 45,
								},
							},
							yAxis: {
								name: i18n.t("amount"),
								nameLocation: "center",
								nameGap: 30,
								show: true,
								type: "value",
							},
							series: [
								{
									name: i18n.t("amount"),
									data: data.map((dataPoint) => dataPoint.defectCount),
									type: "bar",
									barWidth: "80%",
								},
							],
							tooltip: {},
							color: [palette.primary.main],
						}}
					/>
				);
			}}
		/>
	);
};
