import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender";
import { PartConfigurationApi } from "src/api/generated/erp/configurator/api/partConfigurationApi.ts";
import { RawHtmlContent } from "src/components/common/RawHtmlContent";

export interface SelectedOperationWorkInstructionViewProps {
	partConfigurationId: number;
}

export const SelectedOperationWorkInstructionView = ({
	partConfigurationId,
}: SelectedOperationWorkInstructionViewProps) => {
	return (
		<AsyncFetchRender
			fetch={() =>
				PartConfigurationApi.getPartConfigurationInfoText({
					partConfigurationId,
				})
			}
			content={(infoText) => <RawHtmlContent html={infoText} sx={{ margin: 2 }} />}
		/>
	);
};
