import { ConfiguratorTreeItemState } from "src/components/views/erp/configurator/configuratorForm/configuratorTree/treeItemState/ConfiguratorTreeItemState.ts";
import {
	ConfiguratorTree,
	ConfiguratorTree_TreeItem,
} from "src/api/generated/erp/configurator/configuratorTree/configuratorTree";

interface MapConfiguratorTreeStateParams {
	configuratorTreeModel: ConfiguratorTree;
}

export function mapConfiguratorTreeState({
	configuratorTreeModel,
}: MapConfiguratorTreeStateParams): ConfiguratorTreeItemState {
	const rootKey = crypto.randomUUID();
	const rootBackendItem = configuratorTreeModel.rootConfigurator;

	const children = rootBackendItem.children.map((subTreeItem) => mapBackendSubConfiguratorTreeItem(subTreeItem));

	return {
		key: rootKey,
		label: rootBackendItem.label,
		propertyValues: rootBackendItem.propertyValues,
		configuratorType: rootBackendItem.configuratorType,
		configurationSessionId: rootBackendItem.configurationSessionId,
		configuratorInput: rootBackendItem.configuratorInput,
		subConfiguratorComponents: rootBackendItem.subConfiguratorComponents,
		selfConfigurationComponentName: undefined,
		children: children,
		isLoading: false,
		errors: [],
		expanded: true,
	};
}

function mapBackendSubConfiguratorTreeItem(backendTreeItem: ConfiguratorTree_TreeItem): ConfiguratorTreeItemState {
	const key = crypto.randomUUID();

	const children = backendTreeItem.children.flatMap((subTreeItem) => mapBackendSubConfiguratorTreeItem(subTreeItem));

	return {
		key: key,
		label: backendTreeItem.label,
		propertyValues: backendTreeItem.propertyValues,
		configurationSessionId: backendTreeItem.configurationSessionId,
		configuratorInput: backendTreeItem.configuratorInput,
		configuratorType: backendTreeItem.configuratorType,
		subConfiguratorComponents: backendTreeItem.subConfiguratorComponents,
		selfConfigurationComponentName: backendTreeItem.selfConfigurationComponentName ?? undefined,
		children: children,
		isLoading: false,
		errors: [],
		expanded: true,
	};
}
