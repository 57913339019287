// This file is automatically generated. Do not edit manually.

import { WorkCenterPrerequisiteOperationView } from "src/api/generated/erp/db/types/tables/workCenterPrerequisiteOperationView";
import { UpOrDown } from "src/api/generated/common/upOrDown";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WorkCenterPrerequisiteOperationsApi {
	export async function getWorkCenterPrerequisiteOperations(args: {
		workCenterId: number;
	}): Promise<Array<WorkCenterPrerequisiteOperationView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterPrerequisiteOperations/getWorkCenterPrerequisiteOperations",
			method: "POST",
			data: {
				workCenterId: args.workCenterId,
			},
		});
		return response as Array<WorkCenterPrerequisiteOperationView>;
	}

	export async function delete_(args: { workCenterPrerequisiteOperationId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterPrerequisiteOperations/delete",
			method: "POST",
			data: {
				workCenterPrerequisiteOperationId: args.workCenterPrerequisiteOperationId,
			},
		});
		return response as void;
	}

	export async function addPrerequisiteOperationsToWorkCenter(args: {
		workCenterId: number;
		operationIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterPrerequisiteOperations/addPrerequisiteOperationsToWorkCenter",
			method: "POST",
			data: {
				workCenterId: args.workCenterId,
				operationIds: args.operationIds,
			},
		});
		return response as void;
	}

	export async function movePosition(args: {
		workCenterPrerequisiteOperationId: number;
		direction: UpOrDown;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterPrerequisiteOperations/movePosition",
			method: "POST",
			data: {
				workCenterPrerequisiteOperationId: args.workCenterPrerequisiteOperationId,
				direction: args.direction,
			},
		});
		return response as void;
	}

	export async function getAvailablePrerequisiteOperationOptions(args: {
		siteId: number;
		workCenterId: number;
	}): Promise<Record<number, string>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterPrerequisiteOperations/getAvailablePrerequisiteOperationOptions",
			method: "POST",
			data: {
				siteId: args.siteId,
				workCenterId: args.workCenterId,
			},
		});
		return response as Record<number, string>;
	}
}
