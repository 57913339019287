import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm";
import { DocumentQueryApi } from "src/api/generated/documents/api/documentQueryApi.ts";
import i18n from "i18next";
import { DocumentConnectionView } from "src/api/generated/postgres/db/types/documents/tables/documentConnectionView.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { HorizontalBox } from "src/components/common/box/HorizontalBox";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import Typography from "@mui/material/Typography";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext";
import { AavoButton } from "src/components/common/buttons/AavoButton";
import { DocumentPreviewView } from "src/components/views/documents/preview/DocumentPreviewView";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { useFieldArray } from "react-hook-form";
import { AavoCheckbox } from "src/components/common/inputFields/AavoCheckbox.tsx";
import {
	CustomerOfferContractApi
} from "src/api/generated/erp/sales/customerOrderOffer/contracts/api/customerOfferContractApi.ts";

export interface SendCustomerOfferContractForSigningFormProps extends FormCommonProps<void> {
	customerOfferId: number;
}

interface FormValues {
	documents: DocumentFormValues[];
}

interface DocumentFormValues extends DocumentConnectionView {
	attach: boolean;
}

export const SendCustomerOfferContractForSigningForm = ({
	customerOfferId,
	onFormEdited,
	onCompleted,
}: SendCustomerOfferContractForSigningFormProps) => {
	return (
		<AsyncForm<DocumentConnectionView[], FormValues, void>
			fetch={async () => {
				const documents = await DocumentQueryApi.getDocumentsOfObject({
					objectType: "CUSTOMER_ORDER_OFFER",
					objectId: customerOfferId,
				});
				return documents.filter((doc) => doc.primaryFileExtension?.toLowerCase() === "pdf");
			}}
			getDefaultValues={(documentsOfOffer) => ({
				documents: documentsOfOffer
					.toSorted((a, b) => Number(b.isPinned) - Number(a.isPinned))
					.map((document) => ({
						...document,
						attach: document.isPinned,
					})),
			})}
			columns={1}
			noLayout
			render={(params) => <FormContent {...params} />}
			submitLabel={i18n.t("send")}
			submit={async ({ documents }) => {
				await CustomerOfferContractApi.beginContractSigningProcess({
					customerOfferId,
					documentIdsToAttach: documents.filter((doc) => doc.attach).map((doc) => doc.documentId),
				});
			}}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
		/>
	);
};

interface FormContentProps extends AsyncFormContentParams<DocumentConnectionView[], FormValues> {}

const FormContent = ({ control }: FormContentProps) => {
	const { openDialog } = useGenericDialog();

	const documentsFieldArray = useFieldArray({
		control,
		name: "documents",
	});

	return (
		<VerticalBox
			sx={{
				overflowY: "auto",
				margin: 1,
				marginX: 2,
			}}
		>
			<Typography
				variant={"h6"}
				sx={{
					marginBottom: 1,
				}}
			>
				{i18n.t("select_documents_to_attach")}
			</Typography>
			<AavoCheckbox disabled checked label={i18n.t("contract_printout")} />
			{documentsFieldArray.fields.map((doc, idx) => (
				<HorizontalBox key={doc.documentId} gap={1}>
					<FormCheckbox control={control} name={`documents.${idx}.attach`} label={doc.description1} />
					<AavoButton
						icon={faExternalLink}
						tooltip={i18n.t("open")}
						sx={{
							fontSize: "1rem",
						}}
						onClick={() => {
							openDialog(() => ({
								title: doc.description1,
								content: <DocumentPreviewView documentId={doc.documentId} />,
							}));
						}}
					/>
				</HorizontalBox>
			))}
		</VerticalBox>
	);
};
