// This file is automatically generated. Do not edit manually.

import { ProcessImprovementCategory } from "src/api/generated/postgres/db/types/public_/tables/processImprovementCategory";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProcessImprovementCategoryApi {
	export async function getProcessImprovementCategories(): Promise<Array<ProcessImprovementCategory>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/processImprovementCategory/getProcessImprovementCategories",
			method: "POST",
			data: {},
		});
		return response as Array<ProcessImprovementCategory>;
	}

	export async function save(args: {
		processImprovementCategories: Array<ProcessImprovementCategory>;
	}): Promise<Array<ProcessImprovementCategory>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/processImprovementCategory/save",
			method: "POST",
			data: {
				processImprovementCategories: args.processImprovementCategories,
			},
		});
		return response as Array<ProcessImprovementCategory>;
	}

	export async function delete_(args: { processImprovementCategoryIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/processImprovementCategory/delete",
			method: "POST",
			data: {
				processImprovementCategoryIds: args.processImprovementCategoryIds,
			},
		});
		return response as void;
	}
}
