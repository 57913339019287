import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender";
import i18n from "i18next";
import { TestConfigurationResultViewCommonProps } from "src/components/views/erp/configurator/configuratorTesting/TestConfigurationResultViewCommonProps.ts";
import { TestConfigurationCommonResultsApi } from "src/api/generated/erp/configurator/api/configuratorTesting/testConfigurationCommonResultsApi.ts";
import { LabelValueView } from "src/components/common/misc/LabelValueView.tsx";
import { formatNumber } from "src/utils/numberUtils.ts";
import { RawHtmlContent } from "src/components/common/RawHtmlContent.tsx";

export const TestConfigurationTopLevelResultsView = ({
	configurationSessionId,
	configuratorType,
	propertyValues,
}: TestConfigurationResultViewCommonProps) => {
	return (
		<AsyncFetchRender
			fetch={() =>
				TestConfigurationCommonResultsApi.getTopLevelData({
					configurationSessionId,
					configuratorType,
					propertyValues,
				})
			}
			content={({ topLevelData, price, infoText }) => {
				return (
					<LabelValueView
						items={[
							{ label: i18n.t("description"), value: topLevelData.description },
							{ label: i18n.t("price"), value: price },
							{
								label: i18n.t("capacity"),
								value: topLevelData.capacity == null ? "-" : formatNumber(topLevelData.capacity),
							},
							{
								label: i18n.t("weight"),
								value: topLevelData.weight == null ? "-" : formatNumber(topLevelData.weight),
							},
							{
								label: i18n.t("info_text"),
								value: <RawHtmlContent html={infoText} />,
							},
						]}
					/>
				);
			}}
		/>
	);
};
