import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm";
import {
	SupplierEditApi,
	SupplierEditApi_FormInitData,
} from "src/api/generated/erp/purchase/suppliers/api/supplierEditApi.ts";
import { Supplier } from "src/api/generated/erp/db/types/tables/supplier.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import i18n from "i18next";
import { DeepPartial } from "react-hook-form";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { concatWithPipe } from "src/utils/strings.tsx";
import { FormAsyncUserSelectField } from "src/components/views/users/FormAsyncUserSelectField.tsx";
import { FormSection } from "src/components/common/forms/FormSection.tsx";

export interface SupplierFormProps extends FormCommonProps<number> {
	supplierId: number | undefined;
}

interface FormValues extends Supplier {
	salesOrderIntegrationConfigType: "VISMA_NET" | undefined;
}

export const SupplierForm = (props: SupplierFormProps) => {
	const { onCompleted, onFormEdited, supplierId } = props;
	return (
		<AsyncForm
			fetch={() => SupplierEditApi.getFormInitData({ supplierId })}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			columns={2}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues({
		supplier,
		paymentTermOptions,
		deliveryTermOptions,
		deliveryMethodOptions,
	}: SupplierEditApi_FormInitData): DeepPartial<FormValues> {
		if (supplier != null)
			return {
				...supplier,
				salesOrderIntegrationConfigType: supplier.salesOrderIntegration?.type,
			};
		else
			return {
				paymentTermId: paymentTermOptions[0]?.paymentTermId,
				deliveryMethodId: deliveryMethodOptions[0]?.deliveryMethodId,
				deliveryTermsId: deliveryTermOptions[0]?.deliveryTermsId,
			};
	}

	async function submit(values: FormValues) {
		let salesOrderIntegration = values.salesOrderIntegration;
		if (salesOrderIntegration?.type == null) {
			salesOrderIntegration = null;
		}
		const mappedValues = {
			...values,
			salesOrderIntegration: salesOrderIntegration,
		};

		if (supplierId == null) return await SupplierEditApi.insert({ supplier: mappedValues });
		else {
			await SupplierEditApi.update({ supplier: mappedValues });
			return supplierId;
		}
	}
};

interface FormContentProps
	extends SupplierFormProps,
		AsyncFormContentParams<SupplierEditApi_FormInitData, FormValues> {}

const FormContent = (props: FormContentProps) => {
	const {
		control,
		watch,
		data: {
			supplierGroupOptions,
			paymentTermOptions,
			deliveryTermOptions,
			deliveryMethodOptions,
			internalSupplierSiteOptions,
		},
	} = props;

	return (
		<>
			<FormTextField control={control} name={"supplierName"} label={i18n.t("name")} rules={requireRule()} />
			<FormTextField control={control} name={"businessId"} label={i18n.t("business_id")} />
			<FormSelectField
				control={control}
				name={"supplierGroupId"}
				label={i18n.t("supplier_group")}
				options={supplierGroupOptions}
				getOptionKey={(option) => option.supplierGroupId}
				getOptionLabel={(option) => option.name}
			/>
			<FormTextField control={control} name={"externalId"} label={i18n.t("external_id")} />
			<FormTextField control={control} name={"contact"} label={i18n.t("contact")} />
			<FormTextField control={control} name={"address_1"} label={i18n.t("address_1")} />
			<FormTextField control={control} name={"address_2"} label={i18n.t("address_2")} />
			<FormTextField control={control} name={"postalCode"} label={i18n.t("postal_code")} />
			<FormTextField control={control} name={"city"} label={i18n.t("city")} />
			<FormTextField control={control} name={"phone"} label={i18n.t("phone")} />
			<FormTextField control={control} name={"email"} label={i18n.t("email")} />
			<FormSelectField
				control={control}
				name={"paymentTermId"}
				label={i18n.t("payment_term")}
				options={paymentTermOptions}
				getOptionKey={(option) => option.paymentTermId}
				getOptionLabel={(option) => concatWithPipe(option.paymentTerm, option.paymentTermDesc)}
				rules={requireRule()}
			/>
			<FormSelectField
				control={control}
				name={"deliveryMethodId"}
				label={i18n.t("delivery_method")}
				options={deliveryMethodOptions}
				getOptionKey={(option) => option.deliveryMethodId}
				getOptionLabel={(option) => concatWithPipe(option.deliveryMethodCode, option.deliveryMethodDescription)}
			/>
			<FormSelectField
				control={control}
				name={"deliveryTermsId"}
				label={i18n.t("delivery_terms")}
				options={deliveryTermOptions}
				getOptionKey={(option) => option.deliveryTermsId}
				getOptionLabel={(option) => concatWithPipe(option.deliveryTermsCode, option.deliveryTermsDescription)}
			/>
			<FormCheckbox
				control={control}
				name={"isInternalSupplier"}
				label={i18n.t("internal_supplier")}
				spanGridColumns
			/>
			{watch("isInternalSupplier") && (
				<>
					<FormSelectField
						control={control}
						name={"internalSupplierSiteId"}
						label={i18n.t("internal_supplier_site")}
						options={internalSupplierSiteOptions}
						getOptionKey={(option) => option.siteId}
						getOptionLabel={(option) => option.siteName}
					/>
					<FormAsyncUserSelectField
						control={control}
						name={"internalOrderResponsiblePersonId"}
						label={i18n.t("internal_customer_order_responsible_person")}
					/>
				</>
			)}
			<FormTextField control={control} name={"note"} label={i18n.t("note")} spanGridColumns multiline />
			<SupplierSalesOrderIntegrationConfigForm {...props} />
		</>
	);
};

const SupplierSalesOrderIntegrationConfigForm = (props: FormContentProps) => {
	const { control, watch } = props;
	const integrationType = watch("salesOrderIntegration.type");
	return (
		<FormSection label={i18n.t("automatic_sales_order_integration")}>
			<FormSelectField
				control={control}
				name={"salesOrderIntegration.type"}
				label={i18n.t("supplier_system")}
				options={[
					{
						key: "VISMA_NET",
						label: i18n.t("visma_net"),
					},
				]}
				getOptionKey={(option) => option.key}
				getOptionLabel={(option) => option.label}
				spanGridColumns
			/>
			{integrationType === "VISMA_NET" && <VismaNetIntegrationConfigForm {...props} />}
		</FormSection>
	);
};

const VismaNetIntegrationConfigForm = ({ control }: FormContentProps) => {
	return (
		<>
			<FormTextField
				control={control}
				name={"salesOrderIntegration.supplierVismaNetTenantId"}
				label={i18n.t("supplier_visma_net_tenant_id")}
				rules={requireRule()}
			/>
			<FormTextField
				control={control}
				name={"salesOrderIntegration.ourVismaNetCustomerId"}
				label={i18n.t("our_customer_id_in_visma_net")}
				rules={requireRule()}
			/>
			<FormTextField
				control={control}
				name={"salesOrderIntegration.vismaNetOrderType"}
				label={i18n.t("visma_net_order_type")}
				rules={requireRule()}
			/>
		</>
	);
};
