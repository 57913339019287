import { useEffect, useRef } from "react";
import QrScanner from "qr-scanner";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/ErrorDialogContext.tsx";
import i18n from "i18next";
import { Box } from "@mui/material";
import { parseError } from "src/errorHandling/errorParsing.ts";
import { useDebounce } from "src/utils/useDebounce";

export interface QrcodeReaderProps {
	onQrCodeRead: (qrCodeContent: string) => void;
}

export const QrcodeReader = ({ onQrCodeRead }: QrcodeReaderProps) => {
	const videoElRef = useRef<HTMLVideoElement>(null);
	const { logErrorAndShowOnDialog } = useErrorDialog();

	const codeHasBeenRead = useRef<boolean>(false);
	const debounceRead = useDebounce();

	useEffect(() => {
		if (!videoElRef.current) return;

		const qrScanner = new QrScanner(
			videoElRef.current,
			(result) => {
				// Duplicate reads may easily happen.
				// Checking the ref is not enough, because callback may be called twice before the new value is set.
				// That's why the debounce is used in addition.
				if (codeHasBeenRead.current) return;

				codeHasBeenRead.current = true;
				debounceRead(10, () => onQrCodeRead(result.data));
			},
			{
				preferredCamera: "environment",
				highlightCodeOutline: true,
				highlightScanRegion: true,
			},
		);

		qrScanner.start().catch((error) => {
			logErrorAndShowOnDialog(i18n.t("failed_to_open_qr_code_reader"), parseError(error).message);
		});

		return () => {
			qrScanner.stop();
			qrScanner.destroy();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box
			sx={{
				display: "flex",
				flex: 1,
				alignSelf: "stretch",
				margin: "30px",
			}}
		>
			<video
				style={{
					width: "100%",
					height: "100%",
				}}
				ref={videoElRef}
			/>
		</Box>
	);
};
