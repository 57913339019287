import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel.tsx";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import {
	dateColumn,
	dateTimeColumn,
	enumColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import { getDeliveryStateLabel, getDeliveryStateLabels } from "src/api/generated/erp/db/types/enums/deliveryState.ts";
import i18n from "i18next";
import { DeliveryView } from "src/api/generated/erp/db/types/tables/deliveryView.ts";
import { formatDayJs } from "src/utils/dayjsUtils.ts";
import { getCountryLabels } from "src/api/generated/erp/db/types/enums/country.ts";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/material";
import { faCheck, faHourglassHalf, faTruck, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { useContext } from "react";
import { CustomerOrdersPageContext } from "src/components/views/erp/sales/customerOrder/CustomerOrdersPageContext.tsx";
import { DeliveryForm } from "src/components/views/erp/delivery/delivery/DeliveryForm.tsx";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { OpenCustomerOrderButton } from "src/components/views/erp/utilComponents/OpenCustomerOrderButton.tsx";
import { DeliveryActionApi } from "src/api/generated/erp/delivery/api/deliveryActionApi.ts";
import { DeliveriesDataGridApi } from "src/api/generated/erp/delivery/api/deliveriesDataGridApi.ts";
import { DeliveryEditApi } from "src/api/generated/erp/delivery/api/deliveryEditApi.ts";
import { DeliveryFormWithPackages } from "src/components/views/erp/delivery/delivery/DeliveryFormWithPackages.tsx";
import {
	PrintDeliveryConsignmentNoteButton,
	PrintDeliveryNoteButton,
} from "src/components/views/erp/delivery/delivery/commonDeliveryComponents.tsx";
import { DeliverySource } from "src/api/generated/erp/delivery/model/deliverySource.ts";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { DeliverySourceType } from "src/api/generated/erp/db/types/enums/deliverySourceType.ts";
import { AavoCheckbox } from "src/components/common/inputFields/AavoCheckbox";

export interface DeliveriesDataGridProps {
	gridId: string;
	deliverySource?: DeliverySource;
	deliverySourceType?: DeliverySourceType;
	onSelectionChanged?: (selectedDelivery: DeliveryView | undefined) => void;
	showSearchField?: boolean;
	showOnlyUndeliveredByDefault?: boolean;
	showSourceDocumentsButton?: boolean;
	onlyDeliveryId?: number;
}

export const DeliveriesDataGrid = ({
	gridId,
	deliverySource,
	deliverySourceType,
	onSelectionChanged,
	showSearchField,
	showSourceDocumentsButton,
	showOnlyUndeliveredByDefault,
	onlyDeliveryId,
}: DeliveriesDataGridProps) => {
	const customerOrdersRefreshRef = useContext(CustomerOrdersPageContext)?.customerOrdersRefreshRef;

	const { dataGridProps, refreshData, currentParams } = useServerSideDataGridModel({
		gridId: gridId,
		fetchData: (params) =>
			DeliveriesDataGridApi.getDataGridData({
				deliverySource: deliverySource,
				deliverySourceType: deliverySourceType,
				onlyDeliveryId: onlyDeliveryId,
				...params,
			}),
		initialParams: {
			searchQuery: "",
			onlyUndelivered: showOnlyUndeliveredByDefault,
			onlyDefaultSite: deliverySource == null,
		},
		getRowId: (row) => row.deliveryId,
		onSelectionChanged: (selectedDeliveries) => onSelectionChanged?.(selectedDeliveries[0]),
		selectFirstRowOnLoad: true,
	});

	const { palette } = useTheme();

	return (
		<ControlledAsyncCrudDataGrid<DeliveryView>
			disableMultipleRowSelection={true}
			columns={[
				integerColumn({
					field: "deliveryId",
					headerName: i18n.t("number_shortened"),
					width: 80,
				}),
				deliverySource == null && [
					textColumn({
						field: "siteName",
						headerName: i18n.t("site"),
					}),
					integerColumn({
						field: "sourceId",
						headerName:
							deliverySourceType === "PROJECT" ? i18n.t("project_no")
							: deliverySourceType === "CUSTOMER_ORDER" ? i18n.t("order_no")
							: i18n.t("source_id"),
						width: 80,
					}),
					textColumn({
						field: "sourceDescription",
						headerName:
							deliverySourceType === "PROJECT" ? i18n.t("project")
							: deliverySourceType === "CUSTOMER_ORDER" ? i18n.t("order")
							: i18n.t("source"),
						width: 200,
					}),
				],
				enumColumn({
					field: "deliveryState",
					headerName: i18n.t("status"),
					enumLabels: getDeliveryStateLabels(),
					renderCell: ({ row }) => (
						<HorizontalBox alignItems="center" gap={1}>
							<FontAwesomeIcon
								icon={row.deliveryState === "DELIVERED" ? faCheck : faHourglassHalf}
								color={row.deliveryState === "DELIVERED" ? palette.success.main : palette.grey[600]}
							/>
							{getDeliveryStateLabel(row.deliveryState)}
						</HorizontalBox>
					),
				}),
				dateColumn({
					field: "actualDeliveryDate",
					headerName: i18n.t("delivered_at"),
				}),
				textColumn({
					field: "cargoDriverName",
					headerName: i18n.t("driver"),
				}),
				dateColumn({
					field: "plannedLoadingDate",
					headerName: i18n.t("planned_loading_date"),
					width: 160,
					valueFormatter: (value) => `${formatDayJs(value)} (${i18n.t("week_abbr")} ${value.week()})`,
				}),
				dateColumn({
					field: "plannedUnloadingDate",
					headerName: i18n.t("planned_unloading_date"),
					width: 160,
				}),
				textColumn({
					field: "trackingCode",
					headerName: i18n.t("tracking_code"),
					width: 130,
				}),
				textColumn({
					field: "note",
					headerName: i18n.t("note"),
					width: 130,
				}),
				textColumn({
					field: "deliveryName",
					headerName: i18n.t("address_name"),
					width: 130,
				}),
				textColumn({
					field: "deliveryAddress_1",
					headerName: i18n.t("delivery_address"),
					width: 150,
				}),
				textColumn({
					field: "deliveryPostalCode",
					headerName: i18n.t("postal_code"),
				}),
				textColumn({
					field: "deliveryCity",
					headerName: i18n.t("city"),
				}),
				enumColumn({
					field: "deliveryCountry",
					headerName: i18n.t("country"),
					enumLabels: getCountryLabels(),
				}),
				textColumn({
					field: "senderContact",
					headerName: i18n.t("sender_contact"),
					width: 170,
				}),
				textColumn({
					field: "recipientContact",
					headerName: i18n.t("recipient_contact"),
					width: 180,
				}),
				dateTimeColumn({
					field: "createdDate",
					headerName: i18n.t("created_at"),
					width: 140,
				}),
				textColumn({
					field: "createdByUserName",
					headerName: i18n.t("created_by"),
				}),
			]}
			actionBarComponents={
				<>
					{showSourceDocumentsButton && deliverySource?.type === "customerOrder" && (
						<DocumentsOfObjectButton
							label={i18n.t("order_documents")}
							objectRef={{
								objectType: "CUSTOMER_ORDER",
								objectId: deliverySource.customerOrderId,
							}}
							variant={"outlined"}
						/>
					)}
					{showSourceDocumentsButton && deliverySource?.type === "project" && (
						<DocumentsOfObjectButton
							label={i18n.t("project_documents")}
							objectRef={{
								objectType: "PROJECT",
								objectId: deliverySource.projectId,
							}}
							variant={"outlined"}
						/>
					)}
					{showSearchField && (
						<AavoTextField
							label={i18n.t("search")}
							onSubmit={(searchQuery) => refreshData({ searchQuery })}
						/>
					)}
					{showOnlyUndeliveredByDefault && (
						<AavoCheckbox
							label={i18n.t("show_delivered")}
							checked={!currentParams.onlyUndelivered}
							onChange={(value) => refreshData({ onlyUndelivered: !value })}
						/>
					)}
				</>
			}
			rowContextMenuComponents={({ row, onlySingleRowSelected }) => [
				<PrintDeliveryConsignmentNoteButton key={"printConsignmentNote"} variant={"menu"} delivery={row} />,
				<PrintDeliveryNoteButton key={"printDeliveryNote"} variant={"menu"} delivery={row} />,
				onlySingleRowSelected && row?.deliveryState === "INITIAL" && (
					<AsyncMenuButton
						key={"markToDelivered"}
						icon={faTruck}
						label={i18n.t("mark_delivered")}
						onClick={async () => {
							await DeliveryActionApi.setDeliveryToDelivered({
								deliveryId: row.deliveryId!,
							});
							await Promise.all([refreshData(), customerOrdersRefreshRef?.refresh()]);
						}}
					/>
				),
				onlySingleRowSelected && row?.deliveryState === "DELIVERED" && (
					<AsyncMenuButton
						key={"markToUndelivered"}
						icon={faUndo}
						label={i18n.t("return_to_initial_state")}
						onClick={async () => {
							await DeliveryActionApi.revertDeliveryToInitial({
								deliveryId: row.deliveryId!,
							});
							await Promise.all([refreshData(), customerOrdersRefreshRef?.refresh()]);
						}}
					/>
				),
				deliverySource?.type === "customerOrder" && (
					<OpenCustomerOrderButton
						key={"showCustomerOrder"}
						customerOrderId={deliverySource.customerOrderId}
					/>
				),
			]}
			form={{
				addRowEnabled: deliverySource != null,
				editEnabled: true,
				dialogTitle: i18n.t("delivery"),
				dialogSize: (row) => (row == null ? "xl" : "fullscreen"),
				component: ({ row, onCompleted, onFormEdited }) => {
					if (row == null) {
						if (deliverySource == null) return;
						return (
							<DeliveryForm
								deliverySource={deliverySource}
								onCompleted={onCompleted}
								onFormEdited={onFormEdited}
							/>
						);
					} else
						return (
							<DeliveryFormWithPackages
								delivery={row}
								onFormCompleted={onCompleted}
								onFormEdited={onFormEdited}
							/>
						);
				},
			}}
			remove={({ items }) =>
				DeliveryEditApi.delete_({
					deliveryIds: items.map((item) => item.deliveryId),
				})
			}
			{...dataGridProps}
		/>
	);
};
