// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { SupplierPartViewCurrency } from "src/api/generated/erp/db/types/enums/supplierPartViewCurrency";
import { IsoDateTimeString } from "src/types/dateTime";
import { SupplierPartViewPartType } from "src/api/generated/erp/db/types/enums/supplierPartViewPartType";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SupplierPartsDataGridApi {
	export async function getDataGridData(args: {
		supplierId: number;
		searchQuery?: string;
		showInactiveParts?: boolean;
		siteId?: number | null | undefined;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<SupplierPartsDataGridApi_Row>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/suppliers/supplierPartsDataGrid/getDataGridData",
			method: "POST",
			data: {
				supplierId: args.supplierId,
				searchQuery: args.searchQuery,
				showInactiveParts: args.showInactiveParts,
				siteId: args.siteId,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<SupplierPartsDataGridApi_Row>;
	}
}

export interface SupplierPartsDataGridApi_Row {
	contractPrice: number;
	currency: SupplierPartViewCurrency;
	deliveryDuration: number;
	isDefault: boolean;
	lastUpdated: IsoDateTimeString;
	packSize: number;
	partCategory: string | null | undefined;
	partCategoryDescription: string;
	partDescription_1: string;
	partDescription_2: string;
	partId: number;
	partIsActive: boolean;
	partIsConfigurable: number | null | undefined;
	partLongName: string | null | undefined;
	partNo: string;
	partResponsiblePersonId: number;
	partType: SupplierPartViewPartType;
	partUnit: string | null | undefined;
	purchaseFactor: number;
	purchasePrice: number;
	purchasePriceFactor: number;
	purchasePriceUnit: string;
	purchasePriceUnitId: number;
	purchaseUnit: string;
	purchaseUnitId: number;
	siteId: number;
	siteName: string;
	standardCost: number;
	supplierExternalId: string;
	supplierId: number;
	supplierIsActive: boolean;
	supplierName: string;
	supplierPartDescription: string;
	supplierPartId: number;
	supplierPartNo: string;
	weight: number | null | undefined;
}
