import { DeliveriesDataGrid } from "../../delivery/delivery/DeliveriesDataGrid.tsx";

export const AllProjectDeliveriesDataGrid = () => {
	return (
		<DeliveriesDataGrid
			deliverySourceType={"PROJECT"}
			gridId={"F76A4E4E5856D928"}
			showSearchField
			showOnlyUndeliveredByDefault
		/>
	);
};
