import {AavoECharts} from "src/components/common/echarts/AavoECharts.tsx";
import {DefectPerformanceChartApi} from "src/api/generated/spc/defectPerformanceChart/defectPerformanceChartApi.ts";
import i18n from "i18next";
import {formatIsoString} from "src/utils/dayjsUtils.ts";
import {useTheme} from "@mui/material";
import {AsyncFetchRender} from "src/components/common/async/AsyncFetchRender";
import {IsoDateTimeString} from "src/types/dateTime.ts";
import {
    DefectPerformanceChartService_ChartData,
    DefectPerformanceChartService_DefectPerformanceChartDataPoint,
} from "src/api/generated/spc/defectPerformanceChart/defectPerformanceChartService.ts";
import {
    DefectPerformanceMetric,
    getDefectPerformanceMetricLabel,
} from "src/api/generated/io/aavo/applications/db/postgres/enums/defectPerformanceMetric.ts";
import {useCallback} from "react";
import {ECElementEvent} from "echarts";
import {
    OpenContextMenuFunc,
    OpenContextMenuOptions,
    useContextMenu,
} from "src/components/common/contextMenu/useContextMenu.ts";
import {
    OpenGenericDialogFuncProps,
    OpenGenericDialogFuncPropsProvider,
    useGenericDialog,
} from "src/components/common/dialogs/GenericDialogContext.ts";
import {
    SingleShiftPerformanceChartView
} from "src/components/views/spc/basedata/performanceDefectCharts/SingleShiftPerformanceChartView.tsx";
import {VerticalBox} from "src/components/common/box/VerticalBox.tsx";
import {AavoContextMenu} from "src/components/common/contextMenu/AavoContextMenu.tsx";
import {AsyncMenuButton} from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {logError} from "src/errorHandling/errorLogging.ts";
import dataPointSymbol from "./dataPoint.svg";
import {faChartColumn} from "@fortawesome/pro-regular-svg-icons";

export interface DefectPerformanceChartGraphViewProps {
	defectPerformanceChartId: number;
}

export const DefectPerformanceChartGraphView = ({ defectPerformanceChartId }: DefectPerformanceChartGraphViewProps) => {
	const [openContextMenu, contextMenuState] = useContextMenu();

	return (
		<AsyncFetchRender
			fetch={async () =>
				await DefectPerformanceChartApi.getPerformanceChartData({
					defectPerformanceChartId: defectPerformanceChartId,
				})
			}
			content={(data) => {
				return (
					<VerticalBox
						sx={{
							flex: 1,
						}}
					>
						<DefectPerformanceChartContent
							data={data}
							openContextMenu={openContextMenu}
							defectPerformanceChartId={defectPerformanceChartId}
						/>
						<AavoContextMenu state={contextMenuState} />
					</VerticalBox>
				);
			}}
		/>
	);
};

interface DefectPerformanceChartContentProps {
	data: DefectPerformanceChartService_ChartData;
	openContextMenu: OpenContextMenuFunc;
	defectPerformanceChartId: number;
}

const DefectPerformanceChartContent = ({
	data,
	openContextMenu,
	defectPerformanceChartId,
}: DefectPerformanceChartContentProps) => {
	const { palette } = useTheme();
	const metric = data.performanceMetricName;
	const isYieldChart = metric == "YIELD";

	const { openDialog } = useGenericDialog();
	const onContextMenu = useCallback(
		(props: ECElementEvent) => {
			getContextMenu(props, openContextMenu, openDialog, defectPerformanceChartId);
		},
		[defectPerformanceChartId, openContextMenu, openDialog],
	);

	return (
		<AavoECharts
			sx={{
				flex: 1,
			}}
			onEvents={{
				contextmenu: onContextMenu,
			}}
			option={{
				grid: {
					top: 50,
					left: 50,
					right: 20,
				},
				animationDuration: 500,
				xAxis: {
					name: i18n.t("date"),
					nameLocation: "center",
					nameGap: 35,
					type: "time",
					axisLabel: {
						formatter: (value: IsoDateTimeString) => formatIsoString(value, "D.M."),
					},
				},
				yAxis: {
					name: getDefectPerformanceMetricLabel(metric),
					nameLocation: "center",
					nameGap: 27,
					show: true,
					min: isYieldChart ? 0 : null,
					max: isYieldChart ? 100 : null,
					splitNumber: 4,
					maxInterval: 25,
					axisLabel: {},
				},
				series: [getDataSeries()],
				tooltip: {},
				color: [palette.primary.main],
			}}
		/>
	);

	function getDataSeries() {
		return {
			name: getDefectPerformanceMetricLabel(metric),
			data: data.dataPoints.map((dataPoint) => {
				return {
					name: formatIsoString(dataPoint.endTime),
					value: [dataPoint.endTime, dataPoint.value],
					tooltip: {
						formatter: getDataPointTooltip(dataPoint, metric),
					},
					customData: dataPoint,
				};
			}),
			symbol: `image://${dataPointSymbol}`,
			symbolSize: 30,
			type: "line",
			markLine:
				isYieldChart ?
					{}
				:	{
						data: [
							{
								yAxis: 25,
							},
							{
								yAxis: 50,
							},
							{
								yAxis: 75,
							},
							{
								yAxis: 100,
							},
						],
						symbol: "none",
						lineStyle: {
							color: palette.secondary.main,
							width: 1,
							type: "solid",
						},
						label: {
							position: "start",
							formatter: "{c} %",
						},
					},
		};
	}
};

function getContextMenu(
	{ event, data }: ECElementEvent,
	openContextMenu: (options: OpenContextMenuOptions) => void,
	openDialog: (propsProvider: OpenGenericDialogFuncProps | OpenGenericDialogFuncPropsProvider) => void,
	defectPerformanceChartId: number,
) {
	if (event != null && "clientY" in event.event) {
		const record = (data as any).customData as DefectPerformanceChartService_DefectPerformanceChartDataPoint;
		if (record === undefined) {
			logError("Record data not found for data point", JSON.stringify(data));
			return;
		}

		openContextMenu({
			mouseEvent: event.event,
			content: (
				<AsyncMenuButton
					icon={faChartColumn}
					label={i18n.t("defect_analysis")}
					onClick={() => {
						openDialog(() => ({
							title: i18n.t("defect_analysis"),
							size: "lg",
							content: (
								<SingleShiftPerformanceChartView
									defectPerformanceChartId={defectPerformanceChartId}
									startTime={record.startTime}
									endTime={record.endTime}
								/>
							),
						}));
					}}
				/>
			),
		});
	}
}

function getDataPointTooltip(
	dataPoint: DefectPerformanceChartService_DefectPerformanceChartDataPoint,
	metric: DefectPerformanceMetric,
) {
	const lines = [
		{
			value: formatIsoString(dataPoint.endTime, "D.M.YYYY"),
		},
		{
			label: getDefectPerformanceMetricLabel(metric),
			value: dataPoint.value,
		},
		{
			label: i18n.t("completed_quantity"),
			value: dataPoint.totalOutput.toString(),
		},
		{
			label: i18n.t("defect_quantity"),
			value: dataPoint.totalDefects.toString(),
		},
		{
			label: i18n.t("start_time"),
			value: formatIsoString(dataPoint.startTime, "D.M.YYYY HH:mm"),
		},
		{
			label: i18n.t("end_time"),
			value: formatIsoString(dataPoint.endTime, "D.M.YYYY HH:mm"),
		},
	];
	return lines
		.reduce((acc, item) => {
			const { label, value } = item;
			const lineStr = label == null ? value : `<strong>${label}</strong>: ${value}`;
			return [...acc, lineStr];
		}, Array<string>())
		.join("<br/>");
}
