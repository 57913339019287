// This file is automatically generated. Do not edit manually.

import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { IsoDateString } from "src/types/dateTime";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderActionApi {
	export async function confirmCustomerOrder(args: { customerOrderId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/confirmCustomerOrder",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as void;
	}

	export async function revertConfirmCustomerOrder(args: { customerOrderId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/revertConfirmCustomerOrder",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as void;
	}

	export async function checkCustomerOrder(args: { customerOrderId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/checkCustomerOrder",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as void;
	}

	export async function revertCheckCustomerOrder(args: { customerOrderId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/revertCheckCustomerOrder",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as void;
	}

	export async function freezeCustomerOrder(args: { customerOrderId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/freezeCustomerOrder",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as void;
	}

	export async function unfreezeCustomerOrder(args: { customerOrderId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/unfreezeCustomerOrder",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as void;
	}

	export async function printCustomerOrderConfirmation(args: {
		customerOrderId: number;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/printCustomerOrderConfirmation",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as FrontendDownloadFileHandle;
	}

	export async function printCustomerOrderDegreeOfReadinessReport(args: {
		customerOrderId: number;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/printCustomerOrderDegreeOfReadinessReport",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as FrontendDownloadFileHandle;
	}

	export async function cancelCustomerOrder(args: { customerOrderId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/cancelCustomerOrder",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as void;
	}

	export async function deleteCustomerOrder(args: { customerOrderId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/deleteCustomerOrder",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as void;
	}

	export async function createProjectFromCustomerOrder(args: {
		customerOrderId: number;
		templateProjectId: number;
		plannedStartDate: IsoDateString;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/createProjectFromCustomerOrder",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
				templateProjectId: args.templateProjectId,
				plannedStartDate: args.plannedStartDate,
			},
		});
		return response as void;
	}

	export async function connectCustomerOrderToProject(args: {
		customerOrderId: number;
		projectId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/connectCustomerOrderToProject",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
				projectId: args.projectId,
			},
		});
		return response as void;
	}

	export async function copyCustomerOrderTasks(args: {
		sourceCustomerOrderId: number;
		targetCustomerOrderId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/copyCustomerOrderTasks",
			method: "POST",
			data: {
				sourceCustomerOrderId: args.sourceCustomerOrderId,
				targetCustomerOrderId: args.targetCustomerOrderId,
			},
		});
		return response as void;
	}
}
