import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import { ShopOrdersDataGrid } from "src/components/views/erp/production/shopOrder/ShopOrdersDataGrid.tsx";
import { ShopOrderOperationsDataGrid } from "src/components/views/erp/production/shopOrderOperation/ShopOrderOperationsDataGrid.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { useState } from "react";
import { ShopOrderBomDataGrid } from "src/components/views/erp/production/shopOrderBom/ShopOrderBomDataGrid.tsx";
import { ShopOrderView } from "src/api/generated/erp/db/types/tables/shopOrderView.ts";

export interface ShopOrdersViewProps {
	onlyShopOrderId?: number;
	isActive?: boolean
}

export const ShopOrdersView = ({ onlyShopOrderId }: ShopOrdersViewProps) => {
	const [selectedShopOrder, setSelectedShopOrder] = useState<ShopOrderView | undefined>(undefined);

	return (
		<AavoMosaic
			viewId={"84087C040325F3AC"}
			layout={{
				type: "column",
				first: {
					type: "panel",
					title: i18n.t("shop_orders"),
					content: (
						<ShopOrdersDataGrid
							onlyShopOrderId={onlyShopOrderId}
							onSelectionChanged={setSelectedShopOrder}
						/>
					),
				},
				second: {
					type: "tabs",
					items: [
						{
							type: "panel",
							title: i18n.t("operations"),
							content:
								selectedShopOrder == null ?
									<CenteredTypography>{i18n.t("select_shop_order")}</CenteredTypography>
								:	<ShopOrderOperationsDataGrid
										key={selectedShopOrder.shopOrderId}
										shopOrderId={selectedShopOrder.shopOrderId}
									/>,
						},
						{
							type: "panel",
							title: i18n.t("bom"),
							content:
								selectedShopOrder == null ?
									<CenteredTypography>{i18n.t("select_shop_order")}</CenteredTypography>
								:	<ShopOrderBomDataGrid
										key={selectedShopOrder.shopOrderId}
										shopOrderId={selectedShopOrder.shopOrderId}
									/>,
						},
					],
				},
			}}
		/>
	);
};
