import { ObjectAttributeField } from "src/api/generated/erp/common/objectAttributes/model/objectAttributeField.ts";
import { AavoTextField, AavoTextFieldProps } from "src/components/common/inputFields/AavoTextField.tsx";
import i18n from "i18next";
import { AavoCheckbox } from "src/components/common/inputFields/AavoCheckbox.tsx";
import { logError } from "src/errorHandling/errorLogging.ts";
import { SelectField } from "src/components/common/inputFields/SelectField.tsx";
import {
	decodeObjectAttributeValue,
	encodeObjectAttributeValue
} from "src/components/views/erp/objectAttributes/objectAttributeValueConverting.ts";
import { ObjectAttributeDecodedValue } from "src/components/views/erp/objectAttributes/ObjectAttributeDecodedValue.ts";
import { SimpleSelectFieldOption } from "src/components/common/inputFields/types.ts";
import { MultiSelectField } from "src/components/common/inputFields/MultiSelectField.tsx";
import { AavoDatePicker } from "src/components/common/inputFields/AavoDatePicker";
import { Dayjs } from "dayjs";
import { AavoDateTimePicker } from "src/components/common/inputFields/AavoDateTimePicker.tsx";
import { isEmptyArray } from "src/utils/arrayUtils.ts";
import React from "react";
import { SxProps, Theme } from "@mui/material";
import { mergeSx } from "src/utils/styles";
import { AavoRichTextEditor } from "src/components/common/inputFields/AavoRichTextEditor.tsx";

export interface ObjectAttributeFieldComponentProps {
	fieldDefinition: ObjectAttributeField;
	value: string | null;
	onChange: (newValue: string | null) => void;
	onBlur?: () => void;
	inputRef?: React.Ref<HTMLInputElement>;
	disabled?: boolean;
}

interface ObjectAttributeFieldComponentInnerProps extends Omit<ObjectAttributeFieldComponentProps, "value"> {
	value: ObjectAttributeDecodedValue;
}

export const ObjectAttributeFieldComponent = (props: ObjectAttributeFieldComponentProps) => {
	const { fieldDefinition, value } = props;

	const innerProps: ObjectAttributeFieldComponentInnerProps = {
		...props,
		value: decodeObjectAttributeValue(value, fieldDefinition.type)
	};

	switch (fieldDefinition.type.type) {
		case "text":
			return <RenderTextField {...innerProps} />;
		case "textarea":
			return <RenderTextField {...innerProps} multiline />;
		case "integer":
		case "decimal":
			return <RenderTextField {...innerProps} type="number" />;
		case "richText":
			return <RenderRichTextEditor {...innerProps} />;
		case "checkbox":
			return <RenderCheckbox {...innerProps} />;
		case "date":
		case "dateTime":
			return <RenderDatePicker {...innerProps} />;
		case "selection":
			return <RenderSelectField {...innerProps} />;
	}
};

interface RenderTextFieldProps
	extends ObjectAttributeFieldComponentInnerProps,
		Pick<AavoTextFieldProps, "multiline" | "type"> {
}

const RenderTextField = ({
							 fieldDefinition,
							 value,
							 onChange,
							 multiline,
							 type,
							 inputRef,
							 disabled
						 }: RenderTextFieldProps) => {
	if (value.type !== "string" && value.type !== "number") {
		logError("ObjectAttributeFieldComponent: Invalid value type for text field: " + value.type);
		return null;
	}

	return (
		<AavoTextField
			ref={inputRef}
			label={getLabelWithRequiredMark(fieldDefinition)}
			disabled={disabled || fieldDefinition.disabled}
			value={value.value?.toString() ?? ""}
			onChange={(newValue) => {
				onChange(
					encodeObjectAttributeValue({
						type: "string",
						value: newValue
					})
				);
			}}
			error={getErrorMessage(fieldDefinition, value.value)}
			sx={mergeSx(
				{
					flex: 1
				},
				layoutParamsToSx(fieldDefinition)
			)}
			multiline={multiline}
			type={type}
		/>
	);
};

const RenderRichTextEditor = ({
								  fieldDefinition,
								  value,
								  onChange,
								  disabled
							  }: ObjectAttributeFieldComponentInnerProps) => {
	if (value.type !== "string") {
		logError("ObjectAttributeFieldComponent: Invalid value type for rich text field: " + value.type);
		return null;
	}

	return (
		<AavoRichTextEditor
			label={getLabelWithRequiredMark(fieldDefinition)}
			value={value.value}
			onChange={(newValue) => {
				onChange(newValue);
			}}
			readOnly={disabled}
			sx={{
				marginTop: 1
			}}
		/>
	);
};

const RenderCheckbox = ({ fieldDefinition, value, onChange, disabled }: ObjectAttributeFieldComponentInnerProps) => {
	if (value.type !== "bool") {
		logError("ObjectAttributeFieldComponent: Invalid value type for checkbox field: " + value.type);
		return null;
	}

	return (
		<AavoCheckbox
			label={getLabelWithRequiredMark(fieldDefinition)}
			disabled={disabled || fieldDefinition.disabled}
			checked={value.value}
			onChange={(newValue) => {
				onChange(
					encodeObjectAttributeValue({
						type: "bool",
						value: newValue
					})
				);
			}}
		/>
	);
};

const RenderDatePicker = ({
							  fieldDefinition,
							  value,
							  onChange,
							  inputRef,
							  disabled
						  }: ObjectAttributeFieldComponentInnerProps) => {
	if (value.type !== "date" && value.type !== "dateTime") {
		logError("ObjectAttributeFieldComponent: Invalid value type for date or dateTime field: " + value.type);
		return null;
	}

	const commonProps = {
		value: value.value,
		onChange: (newValue: Dayjs | null) => {
			onChange(encodeObjectAttributeValue({ type: value.type, value: newValue }));
		},
		label: getLabelWithRequiredMark(fieldDefinition),
		errorMessage: getErrorMessage(fieldDefinition, value.value),
		disabled: disabled || fieldDefinition.disabled
	};

	return fieldDefinition.type.type === "dateTime" ?
		<AavoDateTimePicker {...commonProps} ref={inputRef} />
		: <AavoDatePicker {...commonProps} />;
};

const RenderSelectField = ({ fieldDefinition, value, onChange, disabled }: ObjectAttributeFieldComponentInnerProps) => {
	const fieldType = fieldDefinition.type;
	if (fieldType.type !== "selection") {
		logError("ObjectAttributeFieldComponent: Invalid field type for select field: " + fieldType.type);
		return null;
	}
	if (value.type !== "selection") {
		logError("ObjectAttributeFieldComponent: Invalid value type for select field: " + value.type);
		return null;
	}

	const commonProps = {
		label: getLabelWithRequiredMark(fieldDefinition),
		disabled: fieldDefinition.disabled,
		options: Object.entries(fieldType.options).map(([value, label]) => ({
			key: value,
			label: label
		})),
		getOptionKey: (option: SimpleSelectFieldOption<string>) => option.key,
		getOptionLabel: (option: SimpleSelectFieldOption<string>) => option.label,
		error: getErrorMessage(fieldDefinition, value.value[0]),
		sx: {
			flex: 1
		}
	};

	if (fieldType.multiSelect)
		return (
			<MultiSelectField
				{...commonProps}
				value={value.value}
				onChange={(newValue) => {
					onChange(
						encodeObjectAttributeValue({
							type: "selection",
							value: newValue
						})
					);
				}}
				disabled={disabled || fieldDefinition.disabled}
			/>
		);
	else
		return (
			<SelectField
				{...commonProps}
				disableClearable={fieldDefinition.required}
				disabled={disabled || fieldDefinition.disabled}
				value={value.value[0] ?? null}
				onChange={(newValue) => {
					onChange(
						encodeObjectAttributeValue({
							type: "selection",
							value: newValue == null ? [] : [newValue]
						})
					);
				}}
			/>
		);
};

function getLabelWithRequiredMark(field: ObjectAttributeField): string {
	return field.label + (field.required ? " *" : "");
}

function getErrorMessage(fieldDefinition: ObjectAttributeField, value: unknown | null | undefined): string | undefined {
	return fieldDefinition.required && (value == null || value === "" || isEmptyArray(value)) ?
		i18n.t("required")
		: undefined;
}

function layoutParamsToSx({ layoutParams }: ObjectAttributeField): SxProps<Theme> {
	return {
		gridColumn: layoutParams.includes("SPAN_COLUMNS") ? "1 / -1" : undefined
	};
}
