import { FormCommonProps } from "src/components/common/forms/types.ts";
import {
	ObjectAccountingForm,
	ObjectAccountingFormValues,
} from "src/components/views/erp/sales/accounting/ObjectAccountingForm.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { DeepPartial } from "react-hook-form";
import {
	getObjectAccountingCodeIdsFromFormValues,
	getObjectAccountingFormDefaultValues,
} from "src/components/views/erp/sales/accounting/ObjectAccountingForm.utils.ts";
import { BillingPlanModelLine } from "src/api/generated/erp/db/types/tables/billingPlanModelLine.ts";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import { getBillingPlanModelLineTypeLabels } from "src/api/generated/erp/db/types/enums/billingPlanModelLineType.ts";
import { FormEnumSelectField } from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import { removeKeysFromRecord } from "src/utils/objectUtils.ts";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import {
	BillingPlanModelLineEditApi,
	BillingPlanModelLineEditApi_FormInitData,
} from "src/api/generated/erp/sales/billingPlan/model/api/billingPlanModelLineEditApi.ts";
import {
	getObjectAttributesEmbeddedFormDefaultValues,
	getObjectAttributesFromEmbeddedFormValues,
} from "src/components/views/erp/objectAttributes/ObjectAttributesEmbeddedForm.utils.tsx";
import {
	ObjectAttributesEmbeddedForm,
	ObjectAttributesEmbeddedFormValues,
} from "src/components/views/erp/objectAttributes/ObjectAttributesEmbeddedForm.tsx";

export interface BillingPlanModelLineFormProps extends FormCommonProps<number> {
	billingPlanModelId: number;
	billingPlanModelLineId: number | undefined;
}

interface FormValues extends BillingPlanModelLine, ObjectAccountingFormValues, ObjectAttributesEmbeddedFormValues {}

export const BillingPlanModelLineForm = (props: BillingPlanModelLineFormProps) => {
	const { billingPlanModelId, billingPlanModelLineId, onCompleted, onFormEdited } = props;
	return (
		<AsyncForm
			fetch={() => BillingPlanModelLineEditApi.getFormInitData({ billingPlanModelLineId })}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control, watch, data: { billingPlanModelLine } }) => {
				const isNewRecord = billingPlanModelLine == null;
				const inputType = watch("type");

				return (
					<>
						<FormTextField control={control} name={"description"} label={i18n.t("description")} />
						<FormEnumSelectField
							control={control}
							name={"type"}
							label={i18n.t("input_method")}
							options={
								isNewRecord ?
									removeKeysFromRecord(getBillingPlanModelLineTypeLabels(), ["REMAINDER"])
								:	getBillingPlanModelLineTypeLabels()
							}
							disabled={!isNewRecord}
							disableClearable
						/>
						{inputType !== "REMAINDER" && (
							<FormNumberField
								control={control}
								name={"amount"}
								label={
									inputType === "PERCENT" ? i18n.t("percent")
									: inputType === "SUM" ?
										i18n.t("sum")
									:	""
								}
								rules={requireRule()}
							/>
						)}
						<ObjectAccountingForm control={control} />
						<FormCheckbox control={control} name={"funded"} label={i18n.t("funded")} startNewGridRow />
						<ObjectAttributesEmbeddedForm control={control} />
					</>
				);
			}}
		/>
	);

	function getDefaultValues({
		billingPlanModelLine,
		accountingDimensions,
		attributeValues,
		attributeFields,
	}: BillingPlanModelLineEditApi_FormInitData): DeepPartial<FormValues> {
		const accountingValues = getObjectAccountingFormDefaultValues(accountingDimensions);
		const attributeFormValues = getObjectAttributesEmbeddedFormDefaultValues(attributeValues, attributeFields);
		if (billingPlanModelLine != null) {
			return {
				...billingPlanModelLine,
				...accountingValues,
				...attributeFormValues,
			};
		} else {
			return {
				billingPlanModelId: billingPlanModelId,
				type: "SUM",
				...accountingValues,
				...attributeFormValues,
			};
		}
	}

	async function submit(values: FormValues) {
		const { accountingDimensionValues, ...billingPlanModelLine } = values;
		const accountingCodeIds = getObjectAccountingCodeIdsFromFormValues(accountingDimensionValues);
		const attributes = getObjectAttributesFromEmbeddedFormValues(values);
		if (billingPlanModelLineId != null) {
			await BillingPlanModelLineEditApi.update({
				billingPlanModelLine,
				accountingCodeIds,
				attributes,
			});
			return billingPlanModelLineId;
		} else {
			return await BillingPlanModelLineEditApi.insert({
				billingPlanModelLine,
				accountingCodeIds,
				attributes,
			});
		}
	}
};
