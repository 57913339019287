// This file is automatically generated. Do not edit manually.

import { WorkcenterWarehouseArea } from "src/api/generated/erp/db/types/tables/workcenterWarehouseArea";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WorkCenterWarehouseAreaApi {
	export async function getWorkCenterWarehouseAreas(args: {
		workCenterId: number;
	}): Promise<Array<WorkcenterWarehouseArea>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterWarehouseAreas/getWorkCenterWarehouseAreas",
			method: "POST",
			data: {
				workCenterId: args.workCenterId,
			},
		});
		return response as Array<WorkcenterWarehouseArea>;
	}

	export async function insert(args: { workCenterWarehouseArea: WorkcenterWarehouseArea }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterWarehouseAreas/insert",
			method: "POST",
			data: {
				workCenterWarehouseArea: args.workCenterWarehouseArea,
			},
		});
		return response as number;
	}

	export async function update(args: { workCenterWarehouseArea: WorkcenterWarehouseArea }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterWarehouseAreas/update",
			method: "POST",
			data: {
				workCenterWarehouseArea: args.workCenterWarehouseArea,
			},
		});
		return response as void;
	}

	export async function delete_(args: { workCenterWarehouseAreaId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterWarehouseAreas/delete",
			method: "POST",
			data: {
				workCenterWarehouseAreaId: args.workCenterWarehouseAreaId,
			},
		});
		return response as void;
	}
}
