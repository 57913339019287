import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import { ProjectsDataGrid } from "src/components/views/erp/project/project/ProjectsDataGrid.tsx";
import { useState } from "react";
import { ProjectView } from "src/api/generated/erp/db/types/tables/projectView.ts";
import { SubProjectsDataGrid } from "src/components/views/erp/project/subProject/SubProjectsDataGrid.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";

export interface ProjectsContainerViewProps {
	onlyProjectId?: number;
	isActive?: boolean
}

export const ProjectsContainerView = ({ onlyProjectId }: ProjectsContainerViewProps) => {
	const [selectedProject, setSelectedProject] = useState<ProjectView | undefined>(undefined);

	return (
		<AavoMosaic
			viewId={"90B849B13AEBB19A"}
			layout={{
				type: "column",
				first: {
					type: "panel",
					title: i18n.t("projects"),
					content: (
						<ProjectsDataGrid
							onlyProjectId={onlyProjectId}
							onSelectionChanged={setSelectedProject}
						/>
					),
				},
				second: {
					type: "panel",
					title: i18n.t("sub_projects"),
					content:
						selectedProject == null ?
							<CenteredTypography children={i18n.t("select_a_project")} />
						:	<SubProjectsDataGrid
								key={selectedProject.projectId}
								projectId={selectedProject.projectId}
							/>,
				},
			}}
		/>
	);
};
