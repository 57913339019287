import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {
	ProcessImprovementCategory
} from "src/api/generated/postgres/db/types/public_/tables/processImprovementCategory";
import {ProcessImprovementCategoryApi} from "src/api/generated/spc/processImprovement/processImprovementCategoryApi.ts";

export const ProcessImprovementCategoriesDataGrid = () => {
	return (
		<ClientSideDataGridModel
			gridId={"E15BE711D3AC0441"}
			fetchData={ProcessImprovementCategoryApi.getProcessImprovementCategories}
			initialParams={{}}
			getRows={(data) => data}
			getRowId={(row) => row.id}
			render={({ dataGridProps }) => (
				<CrudDataGrid<ProcessImprovementCategory>
					columns={[
						textColumn({
							field: "categoryName",
							headerName: i18n.t("name"),
							editable: true,
						}),
						textColumn({
							field: "categoryDescription",
							headerName: i18n.t("description"),
							width: 300,
							editable: true,
						}),
					]}
					add={{
						type: "enabled",
						action: () => ({}),
					}}
					save={{
						type: "enabled",
						action: async ({ items }) => {
							return await ProcessImprovementCategoryApi.save({
								processImprovementCategories: items,
							});
						},
					}}
					remove={{
						type: "enabled",
						action: async ({ items }) => {
							await ProcessImprovementCategoryApi.delete_({
								processImprovementCategoryIds: items.map((item) => item.id),
							});
						},
					}}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
