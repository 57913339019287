import i18n from "i18next";
import { faFileInvoice } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton, AsyncButtonProps } from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { ShopOrdersView } from "src/components/views/erp/production/shopOrder/ShopOrdersView.tsx";
import { resolveValueOrProvider, ValueOrProvider } from "src/utils/valueOrProvider.ts";
import { useUserPermissions } from "src/components/views/erp/common/userPermissions.ts";

export interface OpenShopOrderButtonProps extends Omit<AsyncButtonProps, "onClick"> {
	shopOrderId: ValueOrProvider<number | Promise<number>> | null | undefined;
}

export const OpenShopOrderButton = ({
	shopOrderId: shopOrderIdProp,
	variant = "menu",
	...props
}: OpenShopOrderButtonProps) => {
	const { openDialog } = useGenericDialog();
	const userPermissions = useUserPermissions();

	if (!userPermissions.production.readShopOrders) return null;
	if (shopOrderIdProp == null) return null;

	return (
		<AsyncButton
			label={i18n.t("open_shop_order")}
			icon={faFileInvoice}
			variant={variant}
			onClick={async () => {
				if (shopOrderIdProp == null) return;
				const shopOrderId = await resolveValueOrProvider(shopOrderIdProp);
				openDialog({
					title: i18n.t("shop_order"),
					content: <ShopOrdersView onlyShopOrderId={shopOrderId} />,
				});
			}}
			{...props}
		/>
	);
};
