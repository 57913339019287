import { AsyncRenderErrorContentProps } from "src/components/common/async/AsyncRender.tsx";
import React from "react";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { ErrorView } from "src/components/common/errors/ErrorView.tsx";
import { ResetConfiguratorValuesButton } from "src/components/views/erp/configurator/configuratorForm/components/ResetConfiguratorValuesButton.tsx";
import i18n from "i18next";

export interface TransformComponentsErrorContentProps extends AsyncRenderErrorContentProps {
	customContent: React.ReactNode | undefined;
	resetToExistingConfiguration?: () => Promise<unknown>;
	resetToDefaultValues?: () => Promise<unknown>;
}

export const TransformComponentsErrorContent = ({
	error,
	reload,
	customContent,
	resetToExistingConfiguration,
	resetToDefaultValues,
}: TransformComponentsErrorContentProps) => {
	return (
		<VerticalBox
			sx={{
				flex: 1,
				gap: 2,
				marginBottom: 1,
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<ErrorView
				error={error}
				reload={reload}
				sx={{
					flex: "auto",
					flexGrow: 0,
				}}
			/>
			<ResetConfiguratorValuesButton
				resetToExistingConfiguration={resetToExistingConfiguration}
				resetToDefaultValues={resetToDefaultValues}
				variant={"outlined"}
				label={i18n.t("reset_values")}
			/>
			{customContent}
		</VerticalBox>
	);
};
