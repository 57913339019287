import { PartialTenantCustomizations } from "src/tenantCustomizations/TenantCustomizations.ts";
import {
	getInventoryLevelEventQuantitySpcSamplingMethod,
	getInventoryLevelEventTotalWeightSpcSamplingMethod,
} from "src/components/views/erp/spcSamplingMethods/inventoryLevelEventSpcSamplingMethods.tsx";

export const lapellandCustomizations: PartialTenantCustomizations = {
	tenantConfig: {
		erp: {
			enabled: true,
			ledgerIntegrationEnabled: true,
			customerOfferContractPrintoutEnabled: true,
			customerOfferContractSigningEnabled: true,
			reclamationsEnabled: true,
			configuratorEnabled: false,
			defaultCustomerType: "CONSUMER",
			surveysEnabled: true,
		},
		tasks: {
			enabled: true,
		},
		maps: {
			enabled: false,
		},
		documentsEnabled: true,
	},
	spcSamplingMethods: () => [
		getInventoryLevelEventQuantitySpcSamplingMethod(),
		getInventoryLevelEventTotalWeightSpcSamplingMethod(),
	],
};
