import {OperationApi} from "src/api/generated/erp/production/basedata/operations/api/operationApi";
import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel";
import {genericNullableValue} from "src/utils/genericNullableValue.ts";
import {useGlobalInitData} from "src/contexts/GlobalInitDataContext.ts";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid";
import {
    booleanColumn,
    coloredBooleanColumn,
    integerColumn,
    textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {OperationView} from "src/api/generated/erp/db/types/tables/operationView.ts";
import {useTenantCustomizations} from "src/tenantCustomizations/TenantCustomizationsContext.ts";
import {SelectSiteField} from "src/components/views/erp/common/sites/SelectSiteField.tsx";
import {DocumentsOfObjectButton} from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import {nullableAavoObjectRef} from "src/utils/aavoObjectRefUtils.ts";
import {MenuCheckbox} from "src/components/common/contextMenu/MenuCheckbox.tsx";
import {useConfirmDialog} from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import {faBan, faUndo} from "@fortawesome/pro-regular-svg-icons";
import {AavoTextField} from "src/components/common/inputFields/AavoTextField.tsx";
import {AsyncMenuButton} from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {OperationForm} from "src/components/views/erp/production/baseData/operations/OperationForm.tsx";

export const OperationsDataGrid = () => {
	const { defaultSiteId } = useGlobalInitData();
	const { tenantConfig } = useTenantCustomizations();
	const showConfirmDialog = useConfirmDialog();

	return (
		<ClientSideDataGridModel
			fetchData={(params) => OperationApi.getOperations(params)}
			initialParams={{
				searchQuery: "",
				siteId: genericNullableValue<number>(defaultSiteId),
				includeInactive: false,
			}}
			getRows={(response) => response}
			getRowId={(row) => row.operationId}
			gridId={"F10C04491275C8C34"}
			render={({ dataGridProps, refreshData, currentParams, onlySelectedRow }) => (
				<CrudDataGrid<OperationView>
					disableMultipleRowSelection
					columns={[
						textColumn({
							field: "siteName",
							headerName: i18n.t("site"),
						}),
						integerColumn({
							field: "operationNo",
							headerName: i18n.t("operation_no"),
							width: 120,
						}),
						textColumn({
							field: "operationDescription",
							headerName: i18n.t("description"),
							width: 160,
						}),
						tenantConfig.erp.surveysEnabled && [
							textColumn({
								field: "surveyFormName",
								headerName: i18n.t("survey"),
								width: 180,
							}),
							booleanColumn({
								field: "surveyRequired",
								headerName: i18n.t("survey_required"),
							}),
						],
						booleanColumn({
							field: "isActive",
							headerName: i18n.t("active"),
						}),
						integerColumn({
							field: "operationId",
							headerName: i18n.t("id"),
						}),
						textColumn({
							field: "costEventPriceListName",
							headerName: i18n.t("price_list"),
						}),
						coloredBooleanColumn({
							field: "requireCostEvent",
							headerName: i18n.t("require_cost_event"),
							width: 150,
							falseColor: "grey",
						}),
					]}
					actionBarComponents={
						<>
							<DocumentsOfObjectButton
								objectRef={nullableAavoObjectRef("OPERATION", onlySelectedRow?.operationId)}
							/>
							<AavoTextField
								label={i18n.t("search")}
								onSubmit={(searchQuery) => refreshData({ searchQuery })}
							/>
							<SelectSiteField
								value={currentParams.siteId}
								onChange={(siteId) => refreshData({ siteId })}
							/>
						</>
					}
					actionBarMenuComponents={[
						<MenuCheckbox
							key={"showInactive"}
							label={i18n.t("show_inactive")}
							checked={currentParams.includeInactive}
							onChange={(checked) => refreshData({ includeInactive: checked })}
						/>,
					]}
					rowContextMenuComponents={({ row }) => [
						row.isActive && (
							<AsyncMenuButton
								key={"inactivate"}
								label={i18n.t("inactivate")}
								icon={faBan}
								onClick={async () => {
									const confirmed = await showConfirmDialog();
									if (!confirmed) return;
									await OperationApi.setIsActive({
										operationId: row.operationId,
										isActive: false,
									});
									await refreshData();
								}}
							/>
						),
						!row.isActive && (
							<AsyncMenuButton
								key={"activate"}
								label={i18n.t("activate")}
								icon={faUndo}
								onClick={async () => {
									const confirmed = await showConfirmDialog();
									if (!confirmed) return;
									await OperationApi.setIsActive({
										operationId: row.operationId,
										isActive: true,
									});
									await refreshData();
								}}
							/>
						),
					]}
					form={{
						addRowEnabled: true,
						editEnabled: true,
						dialogTitle: i18n.t("operation"),
						dialogSize: "md",
						component: ({ row, onCompleted, onFormEdited }) => (
							<OperationForm
								siteId={currentParams.siteId ?? defaultSiteId}
								operationId={row?.operationId}
								onCompleted={onCompleted}
								onFormEdited={onFormEdited}
							/>
						),
					}}
					remove={({ row }) => OperationApi.delete_({ operationId: row.operationId })}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
