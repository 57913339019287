import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel";
import {
    WorkCenterOperationsApi
} from "src/api/generated/erp/production/basedata/workcenters/workCenterOperationsApi.ts";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {WorkCenterOperationView} from "src/api/generated/erp/db/types/tables/workCenterOperationView.ts";
import {textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {faLink} from "@fortawesome/pro-regular-svg-icons";
import {useGenericDialog} from "src/components/common/dialogs/GenericDialogContext.ts";
import {
    WorkCenterOperationSelectionView
} from "src/components/views/erp/production/baseData/workcenter/WorkCenterOperationSelectionView.tsx";

export interface WorkCenterOperationsDataGridProps {
	workCenterId: number;
}

export const WorkCenterOperationsDataGrid = ({ workCenterId }: WorkCenterOperationsDataGridProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<ClientSideDataGridModel
			gridId={"F9C35F2C0EAB1B0C"}
			fetchData={() =>
				WorkCenterOperationsApi.getWorkCenterOperations({
					workCenterId: workCenterId,
				})
			}
			initialParams={{}}
			getRows={(data) => data}
			getRowId={(row) => row.workcenterOperationId}
			render={({ dataGridProps, refreshData }) => {
				return (
					<CrudDataGrid<WorkCenterOperationView>
						columns={[
							textColumn({
								field: "operationNo",
								headerName: i18n.t("operation"),
							}),
							textColumn({
								field: "operationDescription",
								headerName: i18n.t("description"),
								width: 300,
							}),
						]}
						actionBarComponents={
							<>
								<AsyncButton
									icon={faLink}
									label={i18n.t("add_operation")}
									variant={"outlined"}
									onClick={async () => {
										openOperationConnectionDialog(refreshData);
									}}
								/>
							</>
						}
						remove={({ row }) =>
							WorkCenterOperationsApi.delete_({
								workCenterOperationId: row.workcenterOperationId,
							})
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);

	function openOperationConnectionDialog(refreshData: () => void) {
		openDialog(({ closeDialog }) => ({
			title: i18n.t("add_operation"),
			size: "md",
			content: (
				<WorkCenterOperationSelectionView
					workCenterId={workCenterId}
					onSubmit={async (selectedOperationIds) => {
						await WorkCenterOperationsApi.addOperationToWorkCenter({
							workCenterId: workCenterId,
							operationIds: selectedOperationIds,
						});
						refreshData();
						await closeDialog();
					}}
				/>
			),
		}));
	}
};
