import { Tooltip, Typography, useTheme } from "@mui/material";
import { ShopOrderOperationView } from "src/api/generated/erp/db/types/tables/shopOrderOperationView";
import { ProductionLineStatusViewApi } from "src/api/generated/erp/production/productionLineStatus/api/productionLineStatusViewApi.ts";
import { AsyncFetchRefreshFunc, AsyncFetchRender } from "src/components/common/async/AsyncFetchRender";
import { HorizontalBox } from "src/components/common/box/HorizontalBox";
import { OpenShopOrderButton } from "src/components/views/erp/utilComponents/OpenShopOrderButton.tsx";
import { VerticalBox } from "src/components/common/box/VerticalBox";
import { faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { AavoActionBar } from "src/components/common/actionBar/AavoActionBar";
import { formatIsoString } from "src/utils/dayjsUtils.ts";
import i18n from "i18next";
import { ShopOrderProductionLineWorkCenterStatus } from "src/api/generated/erp/production/productionLineStatus/model/shopOrderProductionLineWorkCenterStatus.ts";
import { WorkCenterPrerequisitesStatus } from "src/api/generated/erp/production/productionLineStatus/model/workCenterPrerequisitesStatus.ts";
import { OpenCustomerOrderInfoButton } from "src/components/views/erp/utilComponents/OpenCustomerOrderInfoButton.tsx";
import { ProductionLineStatus_ShopOrderDto } from "src/api/generated/erp/production/productionLineStatus/model/productionLineStatus.ts";
import { useInterval } from "src/utils/useInterval.ts";

export interface ShopOrderWorkCenterStatusViewProps {
	shopOrder: ProductionLineStatus_ShopOrderDto;
	workCenterId: number;
	productionLineId: number;
}

export const ShopOrderWorkCenterStatusView = ({
	shopOrder,
	workCenterId,
	productionLineId,
}: ShopOrderWorkCenterStatusViewProps) => {
	return (
		<AsyncFetchRender
			fetch={() =>
				ProductionLineStatusViewApi.getShopOrderStatusOnWorkCenter({
					shopOrderId: shopOrder.shopOrderId,
					workCenterId,
					productionLineId,
				})
			}
			content={(status, refresh) => <AsyncContent status={status} refresh={refresh} shopOrder={shopOrder} />}
		/>
	);
};

interface AsyncContentProps {
	shopOrder: ProductionLineStatus_ShopOrderDto;
	status: ShopOrderProductionLineWorkCenterStatus;
	refresh: AsyncFetchRefreshFunc<unknown>;
}

const AsyncContent = ({ status, refresh, shopOrder }: AsyncContentProps) => {
	useInterval({
		callback: () => refresh({silent: true}),
		intervalMs: 30 * 1000,
		isActive: true,
	});

	return (
		<VerticalBox flex={1} margin={1} gap={1}>
			<AavoActionBar sx={{ padding: 0 }}>
				<AsyncButton icon={faRefresh} onClick={() => refresh()} />
				<OpenShopOrderButton variant={"outlined"} shopOrderId={shopOrder.shopOrderId} />
				<OpenCustomerOrderInfoButton variant={"outlined"} customerOrderId={shopOrder.customerOrderId} />
			</AavoActionBar>
			<HorizontalBox
				sx={{
					flex: 1,
					gap: 1,
				}}
			>
				{status.currentWorkCenterOperations.map((operation) => (
					<OperationView key={operation.shopOrderOperationId} operation={operation} />
				))}
			</HorizontalBox>
			{status.currentAndFutureWorkCenterPrerequisites.length > 0 && (
				<HorizontalBox
					sx={{
						flex: 3,
						gap: 2,
						marginTop: 1,
					}}
				>
					{status.currentAndFutureWorkCenterPrerequisites.map((workCenterPrerequisitesStatus) => (
						<WorkCenterPrerequisitesView
							key={workCenterPrerequisitesStatus.workCenterId}
							workCenterPrerequisitesStatus={workCenterPrerequisitesStatus}
						/>
					))}
				</HorizontalBox>
			)}
		</VerticalBox>
	);
};

interface WorkCenterPrerequisitesViewProps {
	workCenterPrerequisitesStatus: WorkCenterPrerequisitesStatus;
}

const WorkCenterPrerequisitesView = ({ workCenterPrerequisitesStatus }: WorkCenterPrerequisitesViewProps) => {
	return (
		<VerticalBox
			sx={{
				flex: 1,
				gap: 1,
				border: "1px solid",
				borderColor: "grey.400",
				borderRadius: 2,
				padding: 1,
			}}
		>
			<Typography variant="h6" textAlign={"center"}>
				{workCenterPrerequisitesStatus.workCenterDescription}
			</Typography>
			{workCenterPrerequisitesStatus.prerequisiteOperations.map((operation) => (
				<OperationView key={operation.shopOrderOperationId} operation={operation} />
			))}
		</VerticalBox>
	);
};

interface OperationViewProps {
	operation: ShopOrderOperationView;
}

const OperationView = ({ operation }: OperationViewProps) => {
	const { palette } = useTheme();

	const backgroundColor =
		operation.shopOrderOperationState === "READY" ? palette.state.completed
		: operation.shopOrderOperationState === "STARTED" ? palette.state.inProgress
		: palette.state.initial;

	const textColor = palette.augmentColor({ color: { main: backgroundColor } }).contrastText;

	return (
		<Tooltip
			enterTouchDelay={0}
			leaveTouchDelay={3000}
			title={
				<>
					<Typography>
						{operation.operationNo} | {operation.operationDescription}
					</Typography>
					{operation.actualBeginDate != null && (
						<Typography>
							{i18n.t("started")}: {formatIsoString(operation.actualBeginDate, "L LT")}
						</Typography>
					)}
					{operation.actualEndDate != null && (
						<Typography>
							{i18n.t("completed")}: {formatIsoString(operation.actualEndDate, "L LT")}
						</Typography>
					)}
					{operation.operator != null && (
						<Typography>
							{i18n.t("operator")}: {operation.operator}
						</Typography>
					)}
				</>
			}
		>
			<VerticalBox
				sx={{
					flex: 1,
					textAlign: "center",
					alignItems: "center",
					backgroundColor: backgroundColor,
					color: textColor,
					borderRadius: 2,
					fontSize: "1.5rem",
					justifyContent: "center",
					overflow: "hidden",
				}}
			>
				<Typography fontSize={"inherit"}>{operation.operationDescription}</Typography>
			</VerticalBox>
		</Tooltip>
	);
};
