import { ConfigurationProductFamily } from "src/api/generated/erp/db/types/tables/configurationProductFamily.ts";
import { DeepPartial } from "react-hook-form";
import { ConfigurationProductFamiliesApi } from "src/api/generated/erp/configurator/management/productFamilies/api/configurationProductFamiliesApi.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import { ConfigurationProductFamilyCategoryApi } from "src/api/generated/erp/configurator/management/productFamilies/api/configurationProductFamilyCategoryApi.ts";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { ConfigurationProductFamilyCategory } from "src/api/generated/erp/db/types/tables/configurationProductFamilyCategory.ts";

export interface ConfigurationProductFamilyFormProps extends FormCommonProps<number> {
	productFamily: ConfigurationProductFamily | undefined;
}

interface FormValues extends ConfigurationProductFamily {}

export const ConfigurationProductFamilyForm = (props: ConfigurationProductFamilyFormProps) => {
	const { onCompleted, onFormEdited, productFamily } = props;
	return (
		<AsyncForm
			fetch={ConfigurationProductFamilyCategoryApi.getCategories}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control, data: categoryOptions }) => (
				<>
					<FormTextField control={control} name={"name"} label={i18n.t("name")} rules={requireRule()} />
					<FormSelectField
						control={control}
						name={"categoryId"}
						label={i18n.t("category")}
						options={categoryOptions}
						getOptionKey={(o) => o.configurationProductFamilyCategoryId}
						getOptionLabel={(o) => o.name}
						rules={requireRule()}
					/>
				</>
			)}
		/>
	);

	function getDefaultValues(categoryOptions: Array<ConfigurationProductFamilyCategory>): DeepPartial<FormValues> {
		if (productFamily != null) return productFamily;
		else
			return {
				categoryId: categoryOptions[0]?.configurationProductFamilyCategoryId,
			};
	}

	async function submit(values: FormValues) {
		if (productFamily != null) {
			await ConfigurationProductFamiliesApi.update({
				productFamily: values,
			});
			return values.configurationProductFamilyId;
		} else {
			return await ConfigurationProductFamiliesApi.insert({
				productFamily: values,
			});
		}
	}
};
