import { TransformedConfigurationComponent } from "src/api/generated/erp/configurator/componentTransformation/model/transformedConfigurationComponent.ts";
import {
	ConfiguratorInput,
	ConfiguratorInput_InputValue,
} from "src/api/generated/erp/configurator/model/configuratorInput.ts";
import { logError } from "src/errorHandling/errorLogging.ts";
import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType.ts";
import { PartConfiguratorFormApi } from "src/api/generated/erp/configurator/api/partConfiguratorFormApi.ts";
import { formatIsoString } from "src/utils/dayjsUtils.ts";
import i18n from "i18next";
import Typography from "@mui/material/Typography";
import { TypographyWithInfoCircle } from "src/components/common/typography/TypographyWithInfoCircle.tsx";
import { linebreaksToHtmlBreaks } from "src/utils/strings.tsx";
import React from "react";

export function constructConfiguratorInputFromComponents(
	components: TransformedConfigurationComponent[],
): ConfiguratorInput {
	return {
		inputComponentValues: components.map((component) => ({
			componentName: component.name,
			value: component.currentValue,
		})),
	};
}

export function setConfiguratorInputSingleComponentValue({
	configuratorInput,
	componentName,
	newComponentValue,
}: {
	configuratorInput: ConfiguratorInput;
	componentName: string;
	newComponentValue: ConfiguratorInput_InputValue;
}): ConfiguratorInput {
	const newInputComponentValues = [...configuratorInput.inputComponentValues]
		.filter((item) => item.componentName !== componentName)
		.concat({
			componentName: componentName,
			value: newComponentValue,
		});

	return {
		...configuratorInput,
		inputComponentValues: newInputComponentValues,
	};
}

export function setComponentCurrentValue(
	component: TransformedConfigurationComponent,
	value: ConfiguratorInput_InputValue,
): TransformedConfigurationComponent {
	switch (component.type) {
		case "field":
			return {
				...component,
				currentValue: value.type === "SCALAR" ? value : component.currentValue,
			};
		case "subConfigurator":
			if (value.type !== "SUB_CONFIGURATION") {
				logError(`Sub configurator value is not of type sub_configuration but ${value.type}`);
				return component;
			}
			return {
				...component,
				currentValue: value,
			};
		case "subConfiguratorList":
			if (value.type !== "SUB_CONFIGURATION_LIST") {
				logError(`Sub configurator list value is not of type sub_configuration_list but ${value.type}`);
				return component;
			}
			return {
				...component,
				currentValue: value,
			};
		case "text":
		case "sectionBreak":
		case "tab":
			return component;
	}
}

export async function getConfiguratorFormDialogTitle({
	configuratorType,
	prefix,
}: {
	configuratorType: PartConfiguratorType;
	prefix?: string;
}): Promise<React.ReactNode> {
	const versionInfo = await PartConfiguratorFormApi.getConfiguratorVersionInfo({ configuratorType });

	const infoContent = (
		<>
			<Typography>
				<b>{i18n.t("product_family_version")}: </b>
				{versionInfo.productFamilyVersionNumber}
			</Typography>
			<Typography fontWeight={"bold"}> {i18n.t("revision_note")}</Typography>
			<Typography>{linebreaksToHtmlBreaks(versionInfo.partRevisionNote ?? "")}</Typography>
		</>
	);

	const prefixPart = prefix ? `${prefix} | ` : "";

	return (
		<TypographyWithInfoCircle infoContent={infoContent} variant={"h6"}>
			{prefixPart}
			{versionInfo.partNo} | {versionInfo.partDescription1} | {i18n.t("revision")}{" "}
			{versionInfo.partRevisionNumber} | {i18n.t("active_from")}:{" "}
			{formatIsoString(versionInfo.partRevisionValidFrom)}
		</TypographyWithInfoCircle>
	);
}
