import {DefectPerformanceChartApi} from "src/api/generated/spc/defectPerformanceChart/defectPerformanceChartApi.ts";
import {AsyncFetchRender} from "src/components/common/async/AsyncFetchRender.tsx";
import {VerticalBox} from "src/components/common/box/VerticalBox.tsx";
import {List, ListItem, ListItemButton, ListItemText} from "@mui/material";

export interface DefectPerformanceChartsListViewProps {
	selectedDefectPerformanceChartId: number | null;
	setSelectedDefectPerformanceChartId: (performanceChartId: number | null) => void;
}

export const DefectPerformanceChartsListView = ({
	selectedDefectPerformanceChartId,
	setSelectedDefectPerformanceChartId,
}: DefectPerformanceChartsListViewProps) => {
	return (
		<AsyncFetchRender
			fetch={() => DefectPerformanceChartApi.getPerformanceCharts()}
			content={(data) => {
				return (
					<VerticalBox
						sx={{
							flex: 1,
						}}
					>
						<List
							sx={{
								overflow: "auto",
								flex: 1,
							}}
						>
							{data.map((chart) => (
								<ListItem
									key={chart.defectPerformanceChartId}
									disablePadding
									sx={{
										borderBottom: "1px solid",
										borderColor: "divider",
									}}
								>
									<ListItemButton
										dense
										selected={selectedDefectPerformanceChartId == chart.defectPerformanceChartId}
										onClick={() =>
											setSelectedDefectPerformanceChartId(chart.defectPerformanceChartId)
										}
									>
										<ListItemText primary={chart.defectPerformanceChartName} />
									</ListItemButton>
								</ListItem>
							))}
						</List>
					</VerticalBox>
				);
			}}
		/>
	);
};
