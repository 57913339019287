import {StackedIcon, StackedIconProps} from "src/components/common/icons/StackedIcon.tsx";
import {faHourglassHalf} from "@fortawesome/pro-regular-svg-icons";
import {faPlus} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface HourglassWithPlusProps extends StackedIconProps {}

export const HourglassWithPlusIcon = ({ ...other }: HourglassWithPlusProps) => {
	return (
		<StackedIcon {...other}>
			<FontAwesomeIcon
				icon={faHourglassHalf}
				transform={{
					size: 14,
					x: -3,
					y: 1,
				}}
			/>
			<FontAwesomeIcon
				icon={faPlus}
				transform={{
					size: 9,
					x: 5,
					y: -8,
				}}
			/>
		</StackedIcon>
	);
};
