import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {faLink} from "@fortawesome/pro-regular-svg-icons";
import {useGlobalInitData} from "src/contexts/GlobalInitDataContext.ts";
import {WorkcenterProductionLineView} from "src/api/generated/erp/db/types/tables/workcenterProductionLineView.ts";
import {
    WorkCenterProductionLinesApi
} from "src/api/generated/erp/production/basedata/workcenters/workCenterProductionLinesApi.ts";
import {useInputDialog} from "src/components/common/dialogs/input/useInputDialog.tsx";

export interface WorkCenterProductionLinesDataGridProps {
	workCenterId: number;
}

export const WorkCenterProductionLinesDataGrid = ({ workCenterId }: WorkCenterProductionLinesDataGridProps) => {
	const { defaultSiteId } = useGlobalInitData();
	const showInputDialog = useInputDialog();

	return (
		<ClientSideDataGridModel
			gridId={"E8322340D2216CD3"}
			fetchData={() =>
				WorkCenterProductionLinesApi.getWorkCenterProductionLinesGridData({
					workCenterId: workCenterId,
					siteId: defaultSiteId,
				})
			}
			initialParams={{}}
			getRows={(data) => data.productionLines}
			getRowId={(row) => row.workcenterProductionLineId}
			render={({ dataGridProps, refreshData, data: { productionLineOptions } }) => {
				return (
					<CrudDataGrid<WorkcenterProductionLineView>
						columns={[
							textColumn({
								field: "productionLineName",
								headerName: i18n.t("production_line"),
								width: 300,
							}),
						]}
						actionBarComponents={
							<>
								<AsyncButton
									icon={faLink}
									label={i18n.t("add_production_line")}
									variant={"outlined"}
									onClick={async () => {
										const productionLineId = await showInputDialog({
											title: i18n.t("select_line"),
											type: "singleNumberSelect",
											defaultValue: null,
											fieldProps: {
												selection: {
													options: productionLineOptions.map((line) => ({
														value: line.productionLineId,
														label: line.productionLineName,
													})),
												},
											},
										});
										if (productionLineId != undefined)
											await WorkCenterProductionLinesApi.addProductionLineToWorkCenter({
												workCenterId: workCenterId,
												productionLineId: productionLineId,
											});
										await refreshData();
									}}
								/>
							</>
						}
						remove={({ row }) =>
							WorkCenterProductionLinesApi.delete_({
								workCenterProductionLineId: row.workcenterProductionLineId,
							})
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
