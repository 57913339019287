import i18n from "i18next";

export type ParseReplenishmentRequestReturn =
	| {
			type: "success";
			partNo: string;
			locationCode: string;
	  }
	| {
			type: "error";
			errorMessage: string;
	  };

export function parseReplenishmentRequestCode({ code }: { code: string }): ParseReplenishmentRequestReturn {
	const codeParts = code.split("+");

	const partNo = codeParts[1];
	const locationCode = codeParts[2];
	if (locationCode == null || partNo == null || codeParts.length != 3) {
		return { type: "error", errorMessage: i18n.t("barcode_incorrect", { code: code }) };
	}

	return { type: "success", partNo: partNo, locationCode: locationCode };
}
