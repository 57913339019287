import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {faAngleDown, faAngleUp, faLink} from "@fortawesome/pro-regular-svg-icons";
import {useGenericDialog} from "src/components/common/dialogs/GenericDialogContext.ts";
import {
    WorkCenterOperationSelectionView
} from "src/components/views/erp/production/baseData/workcenter/WorkCenterOperationSelectionView.tsx";
import {
    WorkCenterPrerequisiteOperationView
} from "src/api/generated/erp/db/types/tables/workCenterPrerequisiteOperationView.ts";
import {
    WorkCenterPrerequisiteOperationsApi
} from "src/api/generated/erp/production/basedata/workcenters/workCenterPrerequisiteOperationsApi.ts";

export interface WorkCenterPrerequisiteOperationsDataGridProps {
	workCenterId: number;
}

export const WorkCenterPrerequisiteOperationsDataGrid = ({
	workCenterId,
}: WorkCenterPrerequisiteOperationsDataGridProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<ClientSideDataGridModel
			gridId={"44CFC87C94A09FB7"}
			fetchData={() =>
				WorkCenterPrerequisiteOperationsApi.getWorkCenterPrerequisiteOperations({ workCenterId: workCenterId })
			}
			initialParams={{}}
			getRows={(data) => data}
			getRowId={(row) => row.workCenterPrerequisiteOperationId}
			render={({ dataGridProps, refreshData, onlySelectedRow }) => {
				return (
					<CrudDataGrid<WorkCenterPrerequisiteOperationView>
						columns={[
							textColumn({
								field: "operationNo",
								headerName: i18n.t("operation"),
							}),
							textColumn({
								field: "operationDescription",
								headerName: i18n.t("description"),
								width: 300,
							}),
						]}
						actionBarComponents={
							<>
								<AsyncButton
									icon={faAngleUp}
									disabled={!onlySelectedRow}
									onClick={async () => {
										await WorkCenterPrerequisiteOperationsApi.movePosition({
											workCenterPrerequisiteOperationId:
												onlySelectedRow!.workCenterPrerequisiteOperationId,
											direction: "UP",
										});
										await refreshData();
									}}
								/>
								<AsyncButton
									icon={faAngleDown}
									disabled={!onlySelectedRow}
									onClick={async () => {
										await WorkCenterPrerequisiteOperationsApi.movePosition({
											workCenterPrerequisiteOperationId:
												onlySelectedRow!.workCenterPrerequisiteOperationId,
											direction: "DOWN",
										});
										await refreshData();
									}}
								/>
								<AsyncButton
									icon={faLink}
									label={i18n.t("add_operation")}
									variant={"outlined"}
									onClick={async () => {
										openOperationConnectionDialog(refreshData);
									}}
								/>
							</>
						}
						remove={({ row }) =>
							WorkCenterPrerequisiteOperationsApi.delete_({
								workCenterPrerequisiteOperationId: row.workCenterPrerequisiteOperationId,
							})
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);

	function openOperationConnectionDialog(refreshData: () => void) {
		openDialog(({ closeDialog }) => ({
			title: i18n.t("add_operation"),
			size: "md",
			content: (
				<WorkCenterOperationSelectionView
					workCenterId={workCenterId}
					onSubmit={async (selectedOperationIds) => {
						await WorkCenterPrerequisiteOperationsApi.addPrerequisiteOperationsToWorkCenter({
							workCenterId: workCenterId,
							operationIds: selectedOperationIds,
						});
						refreshData();
						await closeDialog();
					}}
				/>
			),
		}));
	}
};
