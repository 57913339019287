import { ServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel";
import { SuppliersDataGridApi } from "src/api/generated/erp/purchase/suppliers/api/suppliersDataGridApi.ts";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid";
import { booleanColumn, integerColumn, textColumn } from "../../../../common/dataGrid/columns.tsx";
import i18n from "i18next";
import { SupplierView } from "src/api/generated/erp/db/types/tables/supplierView.ts";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { SupplierActionApi } from "src/api/generated/erp/purchase/suppliers/api/supplierActionApi.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { faBan, faFileCsv, faFileSignature, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { SupplierEditApi } from "src/api/generated/erp/purchase/suppliers/api/supplierEditApi.ts";
import { SupplierForm } from "src/components/views/erp/purchase/suppliers/SupplierForm.tsx";
import { downloadFile } from "src/utils/fileDownloading.ts";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";

export interface SuppliersDataGridProps {
	onSelectionChanged: (selectedRow: SupplierView | undefined) => void;
}

export const SuppliersDataGrid = ({ onSelectionChanged }: SuppliersDataGridProps) => {
	const showConfirmDialog = useConfirmDialog();
	const openLegacyView = useOpenLegacyView();

	return (
		<ServerSideDataGridModel
			fetchData={(params) => SuppliersDataGridApi.getDataGridData(params)}
			initialParams={{
				searchQuery: "",
				showDeactivated: false,
			}}
			getDataModelResult={(response) => response}
			getRowId={(row) => row.supplierId}
			gridId={"436F6E2B5D869B90"}
			onSelectionChanged={(rows) => onSelectionChanged(rows[0])}
			render={({ dataGridProps, refreshData, currentParams, onlySelectedRow }) => (
				<CrudDataGrid<SupplierView>
					columns={[
						integerColumn({
							field: "supplierId",
							headerName: i18n.t("supplier_no"),
						}),
						textColumn({
							field: "supplierGroupName",
							headerName: i18n.t("supplier_group"),
							width: 150,
						}),
						textColumn({
							field: "supplierName",
							headerName: i18n.t("name"),
							width: 250,
						}),
						textColumn({
							field: "externalId",
							headerName: i18n.t("external_id"),
						}),
						textColumn({
							field: "internalSupplierSiteName",
							headerName: i18n.t("internal_supplier"),
						}),
						textColumn({
							field: "businessId",
							headerName: i18n.t("business_id"),
						}),
						textColumn({
							field: "contact",
							headerName: i18n.t("contact"),
						}),
						textColumn({
							field: "address_1",
							headerName: i18n.t("address_1"),
						}),
						textColumn({
							field: "address_2",
							headerName: i18n.t("address_2"),
						}),
						textColumn({
							field: "postalCode",
							headerName: i18n.t("postal_code"),
						}),
						textColumn({
							field: "city",
							headerName: i18n.t("city"),
						}),
						textColumn({
							field: "phone",
							headerName: i18n.t("phone"),
						}),
						textColumn({
							field: "email",
							headerName: i18n.t("email"),
						}),
						textColumn({
							field: "paymentTermLabel",
							headerName: i18n.t("payment_term"),
						}),
						textColumn({
							field: "deliveryMethodLabel",
							headerName: i18n.t("delivery_method"),
						}),
						textColumn({
							field: "deliveryTermsLabel",
							headerName: i18n.t("delivery_terms"),
						}),
						textColumn({
							field: "note",
							headerName: i18n.t("note"),
						}),
						booleanColumn({
							field: "isActive",
							headerName: i18n.t("active"),
						}),
					]}
					disableMultipleRowSelection
					actionBarMenuComponents={[
						<MenuCheckbox
							key={"showDeactivated"}
							label={i18n.t("show_deactivated")}
							checked={currentParams.showDeactivated}
							onChange={(showDeactivated) => refreshData({ showDeactivated })}
						/>,
						<AsyncMenuButton
							key={"exportAllSupplierParts"}
							label={i18n.t("export_all_supplier_parts")}
							icon={faFileCsv}
							onClick={async () => {
								const fileHandle = await SupplierActionApi.getAllSupplierPartsCsv();
								downloadFile(fileHandle);
							}}
						/>,
					]}
					actionBarComponents={
						<>
							<DocumentsOfObjectButton
								objectRef={nullableAavoObjectRef("SUPPLIER", onlySelectedRow?.supplierId)}
							/>
							<AavoTextField
								label={i18n.t("search")}
								onSubmit={(searchQuery) => refreshData({ searchQuery })}
							/>
						</>
					}
					rowContextMenuComponents={({ row }) => [
						<AsyncMenuButton
							key={"purchaseContracts"}
							label={i18n.t("purchase_contracts")}
							icon={faFileSignature}
							onClick={() => {
								openLegacyView("41F3623BB2AF71C9", {
									supplierId: row.supplierId,
								});
							}}
						/>,
						row.isActive && (
							<AsyncMenuButton
								key={"deactivate"}
								label={i18n.t("deactivate")}
								icon={faBan}
								onClick={async () => {
									const confirmed = await showConfirmDialog();
									if (!confirmed) return;
									await SupplierActionApi.deactivateSupplier({
										supplierId: row.supplierId,
									});
									await refreshData();
								}}
							/>
						),
						!row.isActive && (
							<AsyncMenuButton
								key={"activate"}
								label={i18n.t("activate")}
								icon={faUndo}
								onClick={async () => {
									const confirmed = await showConfirmDialog();
									if (!confirmed) return;
									await SupplierActionApi.activateSupplier({
										supplierId: row.supplierId,
									});
									await refreshData();
								}}
							/>
						),
					]}
					remove={({ row }) => SupplierEditApi.delete_({ supplierId: row.supplierId })}
					form={{
						addRowEnabled: true,
						editEnabled: true,
						dialogSize: "lg",
						dialogTitle: i18n.t("supplier"),
						component: ({ row, onCompleted, onFormEdited }) => (
							<SupplierForm
								supplierId={row?.supplierId}
								onCompleted={onCompleted}
								onFormEdited={onFormEdited}
							/>
						),
					}}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
