import { ConfigurationPropertyValueFilter } from "src/api/generated/erp/configurator/propertyValueFiltering/configurationPropertyValueFilter.ts";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender";
import { ConfiguratorFieldComponentAsFilterApi } from "src/api/generated/erp/configurator/propertyValueFiltering/configuratorFieldComponentAsFilterApi.ts";
import { ConfiguratorFieldComponent } from "src/components/views/erp/configurator/configuratorForm/components/field/ConfiguratorFieldComponent.tsx";
import { TransformedConfigurationComponent_Field } from "src/api/generated/erp/configurator/componentTransformation/model/transformedConfigurationComponent.ts";
import { useState } from "react";
import { Skeleton } from "@mui/material";
import { CONFIGURATOR_INPUT_SCALAR_NULL_VALUE } from "src/components/views/erp/configurator/configuratorUtils.ts";
import {
	ConfiguratorInput_InputValue_Scalar
} from "src/api/generated/erp/configurator/model/configuratorInput.ts";

export interface ConfiguratorFieldComponentsAsFilterProps {
	components: ConfiguratorFilterComponentDefinition[];
	filterValues: ConfigurationPropertyValueFilter[];
	onSubmit: (values: ConfigurationPropertyValueFilter[]) => void;
}

export interface ConfiguratorFilterComponentDefinition {
	componentUuid: string;
}

export const ConfiguratorFieldComponentsAsFilter = ({
	components: componentDefinitions,
	filterValues,
	onSubmit,
}: ConfiguratorFieldComponentsAsFilterProps) => {
	if (componentDefinitions.length === 0) return null;

	return (
		<AsyncFetchRender
			noContainer
			fetch={async () => {
				const components = await ConfiguratorFieldComponentAsFilterApi.getFilterComponents({
					componentUuids: componentDefinitions.map((c) => c.componentUuid),
				});
				return components.map((c) => ({
					...c,
					required: false,
					visible: true,
				}));
			}}
			skeleton={
				<>
					{componentDefinitions.map((component) => (
						<Skeleton
							key={component.componentUuid}
							variant="rounded"
							sx={{
								alignSelf: "stretch",
								width: 100,
								height: "initial",
							}}
						/>
					))}
				</>
			}
			content={(components: TransformedConfigurationComponent_Field[]) => (
				<>
					{components.map((component) => (
						<RenderComponent
							key={component.configurationComponentId}
							component={component}
							onSubmit={(value) => onSubmitComponent(component, value)}
						/>
					))}
				</>
			)}
		/>
	);

	async function onSubmitComponent(
		component: TransformedConfigurationComponent_Field,
		value: ConfiguratorInput_InputValue_Scalar,
	) {
		const withoutOldValue = filterValues.filter((f) => f.propertyName !== component.componentName);

		const withNewValue =
			value.propertyValue.type === "null" ?
				withoutOldValue
			:	withoutOldValue.concat({
					propertyName: component.componentName,
					propertyValue: value.propertyValue,
				});

		onSubmit(withNewValue);
	}
};

interface RenderComponentProps {
	component: TransformedConfigurationComponent_Field;
	onSubmit: (value: ConfiguratorInput_InputValue_Scalar) => Promise<unknown>;
}

const RenderComponent = ({ component, onSubmit }: RenderComponentProps) => {
	const [value, setValue] = useState<ConfiguratorInput_InputValue_Scalar>(CONFIGURATOR_INPUT_SCALAR_NULL_VALUE);

	const componentWithValue = {
		...component,
		currentValue: value,
	};

	return (
		<ConfiguratorFieldComponent
			field={componentWithValue}
			onChange={setValue}
			onSubmit={async (value) => {
				setValue(value);
				await onSubmit(value);
			}}
		/>
	);
};
