// This file is automatically generated. Do not edit manually.

import { OperationView } from "src/api/generated/erp/db/types/tables/operationView";
import { Operation } from "src/api/generated/erp/db/types/tables/operation";
import { ControlChartView } from "src/api/generated/postgres/db/types/public_/tables/controlChartView";
import { CostEventPriceList } from "src/api/generated/erp/db/types/tables/costEventPriceList";
import { SurveyForm } from "src/api/generated/erp/db/types/tables/surveyForm";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace OperationApi {
	export async function getOperations(args: {
		searchQuery?: string;
		siteId: number | null | undefined;
		includeInactive?: boolean;
	}): Promise<Array<OperationView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/operations/getOperations",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				siteId: args.siteId,
				includeInactive: args.includeInactive,
			},
		});
		return response as Array<OperationView>;
	}

	export async function getFormInitData(args: {
		siteId: number;
		operationId: number | null | undefined;
	}): Promise<OperationApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/operations/getFormInitData",
			method: "POST",
			data: {
				siteId: args.siteId,
				operationId: args.operationId,
			},
		});
		return response as OperationApi_FormInitData;
	}

	export async function insert(args: { operation: Operation }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/operations/insert",
			method: "POST",
			data: {
				operation: args.operation,
			},
		});
		return response as number;
	}

	export async function update(args: { operation: Operation }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/operations/update",
			method: "POST",
			data: {
				operation: args.operation,
			},
		});
		return response as void;
	}

	export async function delete_(args: { operationId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/operations/delete",
			method: "POST",
			data: {
				operationId: args.operationId,
			},
		});
		return response as void;
	}

	export async function setIsActive(args: { operationId: number; isActive: boolean }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/operations/setIsActive",
			method: "POST",
			data: {
				operationId: args.operationId,
				isActive: args.isActive,
			},
		});
		return response as void;
	}
}

export interface OperationApi_FormInitData {
	controlChartOptions: Array<ControlChartView>;
	conwipReleasingOperationOptions: Array<Operation>;
	costEventPriceListOptions: Array<CostEventPriceList>;
	operation: Operation | null | undefined;
	surveyFormOptions: Array<SurveyForm>;
}
