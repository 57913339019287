import { AavoCheckbox } from "src/components/common/inputFields/AavoCheckbox.tsx";
import i18n from "i18next";
import { ConfiguratorFieldComponentProps } from "src/components/views/erp/configurator/configuratorForm/components/field/ConfiguratorFieldComponent.tsx";
import { getConfiguratorFieldComponentLabelWithRequiredMark } from "src/components/views/erp/configurator/configuratorForm/components/field/configuratorFieldComponentUtils.ts";

export const ConfiguratorCheckboxField = ({ field, onSubmit, disabled }: ConfiguratorFieldComponentProps) => {
	return (
		<AavoCheckbox
			label={getConfiguratorFieldComponentLabelWithRequiredMark(field)}
			disabled={!field.enabled || disabled}
			checked={field.currentValue.propertyValue.type === "bool" ? field.currentValue.propertyValue.value : false}
			onChange={async (newValue) => {
				await onSubmit({
					type: "SCALAR",
					propertyValue: {
						type: "bool",
						value: newValue,
						label: newValue ? i18n.t("yes") : i18n.t("no"),
						fileUuid: field.imageUuid,
					},
				});
			}}
		/>
	);
};
