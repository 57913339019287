import { useState } from "react";
import { SupplierView } from "src/api/generated/erp/db/types/tables/supplierView";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic";
import { SuppliersDataGrid } from "src/components/views/erp/purchase/suppliers/SuppliersDataGrid.tsx";
import i18n from "i18next";
import { CenteredTypography } from "src/components/common/CenteredTypography";
import { SupplierPartsDataGrid } from "src/components/views/erp/purchase/suppliers/SupplierPartsDataGrid.tsx";
import { ContactsDataGrid } from "src/components/views/erp/contacts/ContactsDataGrid.tsx";

export const SuppliersView = () => {
	const [selectedSupplier, setSelectedSupplier] = useState<SupplierView | undefined>(undefined);

	return (
		<AavoMosaic
			layout={{
				type: "column",
				first: {
					type: "panel",
					title: i18n.t("suppliers"),
					content: <SuppliersDataGrid onSelectionChanged={setSelectedSupplier} />,
				},
				second: {
					type: "tabs",
					items: [
						{
							type: "panel",
							title: i18n.t("suppliers_parts"),
							content:
								selectedSupplier === undefined ?
									<CenteredTypography>{i18n.t("select_a_supplier")}</CenteredTypography>
								:	<SupplierPartsDataGrid
										key={selectedSupplier.supplierId}
										supplierId={selectedSupplier.supplierId}
									/>,
						},
						{
							type: "panel",
							title: i18n.t("contacts"),
							content:
								selectedSupplier == undefined ?
									<CenteredTypography>{i18n.t("select_a_supplier")}</CenteredTypography>
								:	<ContactsDataGrid
										key={selectedSupplier.supplierId}
										connectedItemType={"SUPPLIER"}
										connectedItemId={selectedSupplier.supplierId}
									/>,
						},
					],
				},
			}}
		/>
	);
};
