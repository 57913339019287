import {
    ReplenishPartWarehouseLocationResult
} from "src/api/generated/erp/warehouse/warehouseLocation/service/replenishPartWarehouseLocationResult.ts";
import {useStoredState} from "src/utils/useStoredState.ts";

export interface UseReplenishmentRequestLogReturn {
	log: Array<ReplenishPartWarehouseLocationResult>;
	addLogEntry: (logItem: ReplenishPartWarehouseLocationResult) => void;
	dropLogEntryById: (warehouseReplenishmentRequestId: number) => void;
}

export const useReplenishmentRequestLog = (): UseReplenishmentRequestLogReturn => {
	const [log, setLog] = useStoredState<Array<ReplenishPartWarehouseLocationResult>>({
		key: "E16BEF276CA98D4",
		defaultValue: [],
	});

	const logLength = 30;

	return {
		log: log,
		addLogEntry: (logItem: ReplenishPartWarehouseLocationResult) => {
			const shortenedLog = log.length < logLength ? log : log.slice(0, logLength);
			setLog([logItem].concat(shortenedLog));
		},
		dropLogEntryById: (warehouseReplenishmentRequestId) =>
			setLog(log.filter((entry) => entry.replenishmentRequestId != warehouseReplenishmentRequestId)),
	};
};
