// This file is automatically generated. Do not edit manually.

import { ProcessImprovement } from "src/api/generated/postgres/db/types/public_/tables/processImprovement";
import { ProcessImprovementCategory } from "src/api/generated/postgres/db/types/public_/tables/processImprovementCategory";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProcessImprovementEditApi {
	export async function getFormInitData(args: {
		processImprovementId: number | null | undefined;
	}): Promise<ProcessImprovementEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/processImprovementEdit/getFormInitData",
			method: "POST",
			data: {
				processImprovementId: args.processImprovementId,
			},
		});
		return response as ProcessImprovementEditApi_FormInitData;
	}

	export async function insert(args: { processImprovement: ProcessImprovement }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/processImprovementEdit/insert",
			method: "POST",
			data: {
				processImprovement: args.processImprovement,
			},
		});
		return response as number;
	}

	export async function update(args: { processImprovement: ProcessImprovement }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/processImprovementEdit/update",
			method: "POST",
			data: {
				processImprovement: args.processImprovement,
			},
		});
		return response as void;
	}

	export async function release(args: { processImprovementId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/processImprovementEdit/release",
			method: "POST",
			data: {
				processImprovementId: args.processImprovementId,
			},
		});
		return response as void;
	}

	export async function cancel(args: { processImprovementId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/processImprovementEdit/cancel",
			method: "POST",
			data: {
				processImprovementId: args.processImprovementId,
			},
		});
		return response as void;
	}

	export async function joinProcessImprovementToRawRecord(args: {
		processImprovementId: number;
		rawRecordId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/processImprovementEdit/joinProcessImprovementToRawRecord",
			method: "POST",
			data: {
				processImprovementId: args.processImprovementId,
				rawRecordId: args.rawRecordId,
			},
		});
		return response as void;
	}
}

export interface ProcessImprovementEditApi_FormInitData {
	categoryOptions: Array<ProcessImprovementCategory>;
	processImprovement: ProcessImprovement | null | undefined;
}
