import {AavoMosaic} from "src/components/common/mosaic/AavoMosaic.tsx";
import {ProcessImprovementsDataGrid} from "src/components/views/spc/processImprovement/ProcessImprovementsDataGrid.tsx";
import {CenteredTypography} from "src/components/common/CenteredTypography.tsx";
import {useState} from "react";
import {SingleSourceTasksView} from "src/components/views/tasks/pages/SingleSourceTasksView.tsx";
import i18n from "i18next";
import {
	ConnectedSpecialCausesDataGrid
} from "src/components/views/spc/processImprovement/ConnectedSpecialCausesDataGrid.tsx";

export interface ProcessImprovementsViewProps {
	onlyProcessImprovementId?: number | null;
	selectFirstImprovementOnLoad?: boolean;
	isActive?: boolean;
}

export const ProcessImprovementsView = ({
	onlyProcessImprovementId = null,
	selectFirstImprovementOnLoad = false,
}: ProcessImprovementsViewProps) => {
	const [selectedProcessImprovementId, setSelectedProcessImprovementId] = useState<number | undefined>();
	return (
		<AavoMosaic
			layout={{
				type: "column",
				first: {
					type: "panel",
					title: i18n.t("explanations"),
					content: (
						<ProcessImprovementsDataGrid
							setSelectedProcessImprovementId={setSelectedProcessImprovementId}
							onlyProcessImprovementId={onlyProcessImprovementId}
							selectFirstRowOnLoad={selectFirstImprovementOnLoad}
						/>
					),
				},
				second: {
					type: "tabs",
					items: [
						{
							type: "panel",
							title: i18n.t("tasks"),
							content:
								selectedProcessImprovementId == null ?
									<CenteredTypography>{i18n.t("select_row")}</CenteredTypography>
								:	<SingleSourceTasksView
										key={selectedProcessImprovementId}
										taskSourceRef={{
											sourceType: "PROCESS_IMPROVEMENT",
											sourceId: selectedProcessImprovementId,
										}}
									/>,
						},
						{
							type: "panel",
							title: i18n.t("connected_special_causes"),
							content:
								selectedProcessImprovementId == null ?
									<CenteredTypography>{i18n.t("select_row")}</CenteredTypography>
								:	<ConnectedSpecialCausesDataGrid
										key={selectedProcessImprovementId}
										processImprovementId={selectedProcessImprovementId}
									/>,
						},
					],
				},
			}}
		/>
	);
};
