import {
    CustomerOrderOfferLinesDataGridApi,
    CustomerOrderOfferLinesDataGridApi_Data,
} from "src/api/generated/erp/sales/customerOrderOffer/api/customerOrderOfferLinesDataGridApi";
import {
    ClientSideDataGridModel,
    ClientSideDataGridModelRenderProps,
} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel";
import {CustomerOrderOfferLineView} from "src/api/generated/erp/db/types/tables/customerOrderOfferLineView";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid";
import {
    booleanColumn,
    dateColumn,
    enumColumn,
    floatColumn,
    integerColumn,
    textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {getSalesPartTypeLabels} from "src/api/generated/erp/db/types/enums/salesPartType.ts";
import {DocumentsOfObjectButton} from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import {nullableAavoObjectRef} from "src/utils/aavoObjectRefUtils.ts";
import {AavoTextField} from "src/components/common/inputFields/AavoTextField.tsx";
import {
    CustomerOrderOrOfferSummaryText
} from "src/components/views/erp/sales/customerOrderLine/CustomerOrderOrOfferSummaryText.tsx";
import {AavoButton} from "src/components/common/buttons/AavoButton.tsx";
import {faFileImport} from "@fortawesome/pro-regular-svg-icons";
import {openLegacyCustomerOrderPreLineImportView} from "src/components/views/legacy/legacyViewAdapters.ts";
import {useOpenLegacyView} from "src/components/views/legacy/useOpenLegacyView.ts";
import {CustomerOrderOfferLinesDataGridRowContextMenu} from "./CustomerOrderOfferLinesDataGridRowContextMenu";
import {
    CustomerOrderOfferLineForm
} from "src/components/views/erp/sales/customerOrderOffer/CustomerOrderOfferLineForm.tsx";
import {
    CustomerOrderOfferLineEditApi
} from "src/api/generated/erp/sales/customerOrderOffer/api/customerOrderOfferLineEditApi.ts";
import {useUserPermissions} from "src/components/views/erp/common/userPermissions.ts";
import {RefreshableElementRef} from "src/utils/useRefreshRef.ts";

export interface CustomerOrderOfferLinesDataGridProps {
    customerOrderOfferId: number;
    parentLineId: number | undefined;
    refreshRef?: RefreshableElementRef
}

export const CustomerOrderOfferLinesDataGrid = (props: CustomerOrderOfferLinesDataGridProps) => {
    const {customerOrderOfferId, parentLineId, refreshRef} = props;
    return (
        <ClientSideDataGridModel
            fetchData={({searchQuery}) =>
                CustomerOrderOfferLinesDataGridApi.getData({
                    customerOrderOfferId: customerOrderOfferId,
                    parentLineId: parentLineId,
                    searchQuery: searchQuery,
                })
            }
            initialParams={{
                searchQuery: "",
            }}
            refreshRef={refreshRef}
            getRows={(data) => data.customerOrderOfferLines}
            getRowId={(row) => row.customerOrderOfferLineId}
            gridId={"D74A0E43F5A300F8"}
            render={(params) => <DataGrid {...props} {...params} />}
        />
    );
};

interface DataGridParams {
    searchQuery: string;
}

interface DataGridProps
    extends CustomerOrderOfferLinesDataGridProps,
        ClientSideDataGridModelRenderProps<
            CustomerOrderOfferLinesDataGridApi_Data,
            CustomerOrderOfferLineView,
            DataGridParams
        > {
}

const DataGrid = ({
                      customerOrderOfferId,
                      parentLineId,
                      dataGridProps,
                      onlySelectedRow,
                      refreshData,
                      data: {customerOrderOffer, customerOrderOfferLines, offerSummary},
                  }: DataGridProps) => {
    const openLegacyView = useOpenLegacyView();

    const hasAnyConfigurableLines = customerOrderOfferLines.some((line) => line.partIsConfigurable === true);
    const editEnabled = ["INITIAL", "OFFERED"].includes(customerOrderOffer.customerOrderOfferState);
    const userPermissions = useUserPermissions();
    const onlySalespersonData = userPermissions.sales.onlySalespersonOwnOfferData;

    return (
        <CrudDataGrid<CustomerOrderOfferLineView>
            columns={[
                integerColumn({
                    field: "lineNumber",
                    headerName: i18n.t("number_shortened"),
                    width: 50,
                }),
                textColumn({
                    field: "configurationOrPartDescription",
                    headerName: i18n.t("part"),
                    width: 250,
                }),
                floatColumn({
                    field: "salesQuantityAsSalesUnits",
                    headerName: i18n.t("ordered_quantity"),
                }),
                textColumn({
                    field: "salesUnit",
                    headerName: i18n.t("sales_unit"),
                }),
                textColumn({
                    field: "mark",
                    headerName: i18n.t("mark"),
                    width: 200,
                }),
                parentLineId == null && [
                    dateColumn({
                        field: "plannedDeliveryDate",
                        headerName: i18n.t("planned_delivery_date"),
                        width: 160,
                    }),
                    floatColumn({
                        field: "unitPrice",
                        headerName: i18n.t("unit_price"),
                        width: 90,
                    }),
                    floatColumn({
                        field: "unitPriceWithVat",
                        headerName: i18n.t("unit_price_with_vat"),
                        width: 150,
                    }),
                    floatColumn({
                        field: "discountPercentage",
                        headerName: i18n.t("discount_percentage"),
                        width: 80,
                    }),
                    floatColumn({
                        field: "totalPrice",
                        headerName: i18n.t("total_price"),
                    }),
                    floatColumn({
                        field: "totalPriceWithVat",
                        headerName: i18n.t("total_price_with_vat"),
                        width: 155,
                    }),
                    textColumn({
                        field: "salesPriceUnit",
                        headerName: i18n.t("sales_price_unit"),
                    }),
                ],
                hasAnyConfigurableLines &&
                booleanColumn({
                    field: "isAwaitingConfiguration",
                    headerName: i18n.t("awaiting_configuration"),
                    width: 155,
                }),
                !onlySalespersonData &&
                enumColumn({
                    field: "salesPartType",
                    headerName: i18n.t("type"),
                    enumLabels: getSalesPartTypeLabels(),
                    width: 75,
                }),
                !onlySalespersonData &&
                textColumn({
                    field: "shopOrderBatchCode",
                    headerName: i18n.t("shop_order_batch"),
                }),
                textColumn({
                    field: "capacityQuantity",
                    headerName: i18n.t("capacity_quantity"),
                    width: 125,
                }),
                !onlySalespersonData &&
                floatColumn({
                    field: "unitCost",
                    headerName: i18n.t("unit_cost"),
                    width: 125,
                }),
                !onlySalespersonData &&
                floatColumn({
                    field: "totalCost",
                    headerName: i18n.t("total_cost"),
                    width: 135,
                }),
                parentLineId == null &&
                textColumn({
                    field: "vatCodeName",
                    headerName: i18n.t("vat_code"),
                }),
                textColumn({
                    field: "partUnit",
                    headerName: i18n.t("warehouse_unit"),
                    width: 105,
                }),
                integerColumn({
                    field: "customerOrderOfferLineId",
                    headerName: i18n.t("id"),
                    width: 75,
                }),
            ]}
            actionBarComponents={
                <>
                    <DocumentsOfObjectButton
                        objectRef={nullableAavoObjectRef(
                            "CUSTOMER_ORDER_OFFER_LINE",
                            onlySelectedRow?.customerOrderOfferLineId,
                        )}
                    />
                    <AavoTextField
                        label={i18n.t("search")}
                        onSubmit={async (value) => {
                            await refreshData({searchQuery: value});
                        }}
                    />
                    {parentLineId == null && (
                        <CustomerOrderOrOfferSummaryText objectType={"CUSTOMER_OFFER"} summary={offerSummary}/>
                    )}
                    {parentLineId != null && (
                        <AavoButton
                            label={i18n.t("import_lines")}
                            icon={faFileImport}
                            variant={"outlined"}
                            onClick={() => {
                                openLegacyCustomerOrderPreLineImportView({
                                    openLegacyView: openLegacyView,
                                    targetType: "CUSTOMER_ORDER_OFFER",
                                    targetId: customerOrderOfferId,
                                    targetPackageLineId: parentLineId,
                                });
                            }}
                        />
                    )}
                </>
            }
            rowContextMenuComponents={(params) => (
                <CustomerOrderOfferLinesDataGridRowContextMenu refreshData={refreshData} {...params} />
            )}
            form={{
                addRowEnabled: editEnabled,
                editEnabled: true,
                dialogSize: "lg",
                dialogTitle: i18n.t("offer_line"),
                component: ({row, ...other}) => (
                    <CustomerOrderOfferLineForm
                        customerOrderOfferId={customerOrderOfferId}
                        customerOrderOfferLineId={row?.customerOrderOfferLineId}
                        parentLineId={parentLineId}
                        disabled={!editEnabled}
                        {...other}
                    />
                ),
            }}
            remove={
                editEnabled ?
                    {
                        type: "enabled",
                        action: ({items}) =>
                            CustomerOrderOfferLineEditApi.delete_({
                                customerOrderOfferLineIds: items.map((item) => item.customerOrderOfferLineId),
                            }),
                    }
                    : {
                        type: "disabled",
                    }
            }
            {...dataGridProps}
        />
    );
};
