import {WorkcenterWarehouseArea} from "src/api/generated/erp/db/types/tables/workcenterWarehouseArea.ts";
import {
    WorkCenterWarehouseAreaApi
} from "src/api/generated/erp/production/basedata/workcenters/workCenterWarehouseAreaApi.ts";
import {FormTextField} from "src/components/common/forms/fields/FormTextField.tsx";
import {FormCommonProps} from "src/components/common/forms/types";
import {AavoForm, AavoFormContentParams} from "src/components/common/forms/AavoForm.tsx";
import {DeepPartial} from "react-hook-form";
import i18n from "i18next";

export interface WorkCenterWarehouseAreaFormProps extends FormCommonProps<number> {
	workCenterId: number;
	workCenterWarehouseArea: WorkcenterWarehouseArea | undefined;
}

export const WorkCenterWarehouseAreaForm = (props: WorkCenterWarehouseAreaFormProps) => {
	const { onCompleted, onFormEdited, workCenterId, workCenterWarehouseArea } = props;
	return (
		<AavoForm
			defaultValues={getDefaultValues(workCenterWarehouseArea)}
			submit={submit}
			columns={2}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues(
		workCenterWarehouseArea: WorkcenterWarehouseArea | undefined,
	): DeepPartial<WorkcenterWarehouseArea> {
		return workCenterWarehouseArea == undefined ? { workcenterId: workCenterId } : { ...workCenterWarehouseArea };
	}

	async function submit(values: WorkcenterWarehouseArea) {
		if (workCenterWarehouseArea == undefined)
			return await WorkCenterWarehouseAreaApi.insert({
				workCenterWarehouseArea: values,
			});
		else {
			await WorkCenterWarehouseAreaApi.update({ workCenterWarehouseArea: values });
			return workCenterWarehouseArea.workcenterWarehouseAreaId;
		}
	}
};

interface FormContentProps extends WorkCenterWarehouseAreaFormProps, AavoFormContentParams<WorkcenterWarehouseArea> {}

const FormContent = ({ control }: FormContentProps) => {
	return (
		<>
			<FormTextField control={control} name={"warehouseArea.warehouse"} label={i18n.t("warehouse")} />
			<FormTextField control={control} name={"warehouseArea.aisle"} label={i18n.t("aisle")} />
			<FormTextField control={control} name={"warehouseArea.shelfSpace"} label={i18n.t("shelf_space")} />
			<FormTextField control={control} name={"warehouseArea.tier"} label={i18n.t("tier")} />
			<FormTextField control={control} name={"warehouseArea.locker"} label={i18n.t("locker")} />
		</>
	);
};
