import Box from "@mui/material/Box";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import i18n from "i18next";
import { faBookReader, faQrcode, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { ReplenishmentRequestLogView } from "src/components/views/erp/warehouse/replenishment/ReplenishmentRequestLogView.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext";
import { QrcodeInputField } from "src/components/common/inputFields/QrcodeInputField.tsx";
import { Typography } from "@mui/material";
import { ReplenishPartWarehouseLocationResult } from "src/api/generated/erp/warehouse/warehouseLocation/service/replenishPartWarehouseLocationResult.ts";

export interface ReplenishmentRequestCameraViewProps {
	createReplenishmentRequest: (code: string) => Promise<void>;
	cancelReplenishmentRequest: (code: string) => Promise<void>;
	log: Array<ReplenishPartWarehouseLocationResult>;
}

export const ReplenishmentRequestCameraView = ({
	createReplenishmentRequest,
	cancelReplenishmentRequest,
	log,
}: ReplenishmentRequestCameraViewProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				flex: 1,
				alignItems: "stretch",
				margin: "0.5rem",
				gap: "0.5rem",
			}}
		>
			<AsyncButton
				sx={{
					flex: 1,
				}}
				icon={faQrcode}
				variant={"outlined"}
				onClick={async () => {
					openDialog(({ closeDialog }) => ({
						title: i18n.t("read_code"),
						size: "sm",
						content: (
							<QrcodeInputField
								sx={{ margin: 1 }}
								onQrCodeRead={async (qrCodeContent) => {
									await closeDialog();
									await createReplenishmentRequest(qrCodeContent);
								}}
								showTextField
								autoFocus
								openImmediately
							/>
						),
					}));
				}}
			>
				<Typography>{i18n.t("read_code")}</Typography>
			</AsyncButton>
			<AsyncButton
				sx={{
					flex: 1,
				}}
				icon={faUndo}
				variant={"outlined"}
				onClick={async () => {
					openDialog(({ closeDialog }) => ({
						title: i18n.t("read_code"),
						size: "sm",
						content: (
							<QrcodeInputField
								sx={{ margin: 1 }}
								onQrCodeRead={async (qrCodeContent) => {
									await closeDialog();
									await cancelReplenishmentRequest(qrCodeContent);
								}}
								showTextField
								autoFocus
								openImmediately
							/>
						),
					}));
				}}
			>
				<Typography>{i18n.t("cancel_request")}</Typography>
			</AsyncButton>
			<AsyncButton
				sx={{
					flex: 1,
				}}
				label={i18n.t("log")}
				icon={faBookReader}
				variant={"outlined"}
				onClick={async () => {
					openDialog({
						title: i18n.t("log"),
						content: <ReplenishmentRequestLogView log={log} />,
					});
				}}
			>
				<Typography>{i18n.t("log")}</Typography>
			</AsyncButton>
		</Box>
	);
};
