// This file is automatically generated. Do not edit manually.

import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { CustomerOfferSignedContractDownloadResult } from "src/api/generated/erp/sales/customerOrderOffer/contracts/signing/model/customerOfferSignedContractDownloadResult";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOfferContractApi {
	export async function getCustomerOfferContractPrintout(args: {
		customerOfferId: number;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderOffer/contract/getCustomerOfferContractPrintout",
			method: "POST",
			data: {
				customerOfferId: args.customerOfferId,
			},
		});
		return response as FrontendDownloadFileHandle;
	}

	export async function beginContractSigningProcess(args: {
		customerOfferId: number;
		documentIdsToAttach?: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderOffer/contract/beginContractSigningProcess",
			method: "POST",
			data: {
				customerOfferId: args.customerOfferId,
				documentIdsToAttach: args.documentIdsToAttach,
			},
		});
		return response as void;
	}

	export async function tryDownloadAndSaveSignedContractDocument(args: {
		customerOfferId: number;
	}): Promise<CustomerOfferSignedContractDownloadResult> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrderOffer/contract/tryDownloadAndSaveSignedContractDocument",
			method: "POST",
			data: {
				customerOfferId: args.customerOfferId,
			},
		});
		return response as CustomerOfferSignedContractDownloadResult;
	}
}
