import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import i18n from "i18next";
import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType.ts";
import { PartConfiguratorTreeResult } from "src/components/views/erp/configurator/configuratorForm/configuratorTree/PartConfiguratorTreeStatefulView.tsx";
import { faSquareCheck } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { CatalogPartRevisionConfiguratorSelectionsAsyncView } from "src/components/views/erp/configurator/managing/catalogPart/configuratorSelections/CatalogPartRevisionConfiguratorSelectionsAsyncView.tsx";
import { CatalogPartTestConfiguratorResultsView } from "src/components/views/erp/configurator/configuratorTesting/catalogPart/CatalogPartTestConfiguratorResultsView.tsx";
import { PartConfiguratorTopLevelView } from "src/components/views/erp/configurator/configuratorForm/PartConfiguratorTopLevelView.tsx";

export interface CatalogPartTestConfiguratorViewProps {
	productFamilyVersionId: number;
	catalogPartRevisionId: number;
	closeDialog?: () => void;
}

export const CatalogPartTestConfiguratorView = ({
	catalogPartRevisionId,
	productFamilyVersionId,
	closeDialog,
}: CatalogPartTestConfiguratorViewProps) => {
	const { openDialog } = useGenericDialog();

	const configuratorType: PartConfiguratorType = {
		type: "catalogPartTest",
		catalogPartRevisionId: catalogPartRevisionId,
		productFamilyVersionId: productFamilyVersionId,
		isTestConfigurator: true,
		isSubConfigurator: false,
	};

	return (
		<PartConfiguratorTopLevelView
			configuratorType={configuratorType}
			onCompleted={async (result) => {
				if (result.type === "success") {
					onSubmit(result.value);
				} else {
					closeDialog?.();
				}
			}}
			footerExtraComponents={
				<AsyncButton
					icon={faSquareCheck}
					label={i18n.t("select_components")}
					color={"secondary"}
					onClick={onSelectComponentsClick}
				/>
			}
			transformComponentsErrorCustomContent={
				<AsyncButton
					icon={faSquareCheck}
					label={i18n.t("select_components")}
					variant={"outlined"}
					onClick={onSelectComponentsClick}
				/>
			}
		/>
	);

	function onSubmit({ propertyValues, configurationSessionId }: PartConfiguratorTreeResult) {
		openDialog(() => ({
			title: i18n.t("test_configuration"),
			content: (
				<CatalogPartTestConfiguratorResultsView
					configurationSessionId={configurationSessionId}
					configuratorType={configuratorType}
					propertyValues={propertyValues}
				/>
			),
		}));
	}

	function onSelectComponentsClick() {
		openDialog(({ closeDialog, onDataDirtyStateChanged }) => ({
			title: i18n.t("select_components_for_test_configurator"),
			size: "xl",
			content: (
				<CatalogPartRevisionConfiguratorSelectionsAsyncView
					catalogPartRevisionId={catalogPartRevisionId}
					productFamilyVersionId={productFamilyVersionId}
					onSubmitted={async () => {
						await closeDialog();
					}}
					onCancelled={async () => {
						await closeDialog({ confirmIfEdited: true });
					}}
					onDataDirtyStateChanged={onDataDirtyStateChanged}
				/>
			),
		}));
	}
};
