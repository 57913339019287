import { DocumentPreviewView } from "src/components/views/documents/preview/DocumentPreviewView.tsx";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender.tsx";
import { PartConfiguratorFormApi } from "src/api/generated/erp/configurator/api/partConfiguratorFormApi.ts";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import i18n from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faQuestion } from "@fortawesome/pro-regular-svg-icons";
import { useTheme } from "@mui/material";
import { VerticalBox } from "src/components/common/box/VerticalBox";
import { AavoActionBar } from "src/components/common/actionBar/AavoActionBar.tsx";
import { downloadFile } from "src/utils/fileDownloading";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { DocumentFileApi } from "src/api/generated/documents/api/documentFileApi.ts";

export interface ConfigurationComponentDocumentViewProps {
	componentId: number | null;
}

export const ConfigurationComponentDocumentView = ({ componentId }: ConfigurationComponentDocumentViewProps) => {
	const { palette } = useTheme();

	if (componentId == null) {
		return (
			<CenteredTypography>
				{i18n.t("configuration_component_document_alt_text_1")}
				<FontAwesomeIcon icon={faQuestion} color={palette.primary.main} />
				{i18n.t("configuration_component_document_alt_text_2")}
			</CenteredTypography>
		);
	}

	return (
		<AsyncFetchRender
			key={componentId}
			fetch={() =>
				PartConfiguratorFormApi.getComponentDocumentId({
					configuratorComponentId: componentId,
				})
			}
			content={(documentId) => {
				if (documentId == null) return null;

				return (
					<VerticalBox flex={1}>
						<DocumentPreviewView documentId={documentId} />
						<AavoActionBar
							sx={{
								alignSelf: "stretch",
								justifyContent: "flex-end",
								borderTop: "1px solid",
							}}
						>
							<AsyncButton
								label={i18n.t("download")}
								icon={faDownload}
								onClick={async () => {
									const fileHandle = await DocumentFileApi.downloadDocumentsAsSingleFile({
										documentIds: [documentId],
									});
									downloadFile(fileHandle);
								}}
							/>
						</AavoActionBar>
					</VerticalBox>
				);
			}}
		/>
	);
};
