import { ConfigurationPropertyValue } from "src/api/generated/io/aavo/applications/db/erp/types/configurationPropertyValue.ts";
import { concatWithPipe, getStringFirstLine } from "src/utils/strings.tsx";
import { getConfigurationProductFamilyVersionStateLabel } from "src/api/generated/erp/db/types/enums/configurationProductFamilyVersionState.ts";
import { ConfigurationProductFamilyVersion } from "src/api/generated/erp/db/types/tables/configurationProductFamilyVersion.ts";
import { ConfigurationComponent } from "src/api/generated/erp/db/types/tables/configurationComponent.ts";
import i18n from "i18next";
import { getConfigurationComponentFieldTypeLabel } from "src/api/generated/erp/db/types/enums/configurationComponentFieldType.ts";
import { getConfigurationComponentTypeLabel } from "src/api/generated/erp/db/types/enums/configurationComponentType.ts";
import { ConfiguratorInput_InputValue_Scalar } from "src/api/generated/erp/configurator/model/configuratorInput.ts";

export const CONFIGURATION_PROPERTY_NULL_VALUE: ConfigurationPropertyValue = {
	type: "null",
	label: "-",
	infoText: "",
};

export const CONFIGURATOR_INPUT_SCALAR_NULL_VALUE: ConfiguratorInput_InputValue_Scalar = {
	type: "SCALAR",
	propertyValue: CONFIGURATION_PROPERTY_NULL_VALUE,
};

export function isEmptyConfigurationPropertyValue(value: ConfigurationPropertyValue | null | undefined): boolean {
	if (value == null) return true;

	switch (value.type) {
		case "null":
			return true;
		case "string":
			return value.value === "";
		case "int":
			return false;
		case "decimal":
			return false;
		case "bool":
			return !value.value;
		case "sub_configuration":
			return Object.keys(value.value).length === 0;
		case "sub_configuration_list":
			return value.value.length === 0;
		default:
			return false;
	}
}

export function configuratorScalarInputValueToString(
	inputValue: ConfiguratorInput_InputValue_Scalar | null | undefined,
): string {
	if (inputValue == null) {
		return "";
	}
	return configurationPropertyValueToString(inputValue.propertyValue);
}

export function configurationPropertyValueToString(
	propValue: ConfigurationPropertyValue | null | undefined,
): string {
	if (propValue == null) {
		return "";
	}
	switch (propValue.type) {
		case "null":
			return "";
		default:
			return propValue.value.toString();
	}
}

export function configuratorComponentIndentToMargin(indent: number): string {
	return `${indent}rem`;
}

export function getConfiguratorProductFamilyVersionLabel(
	productFamilyVersion: ConfigurationProductFamilyVersion,
): string {
	return concatWithPipe(
		productFamilyVersion.versionNumber,
		getStringFirstLine(productFamilyVersion.description).substring(0, 30),
		getConfigurationProductFamilyVersionStateLabel(productFamilyVersion.state),
	);
}

export function formatConfigurationComponentType(component: ConfigurationComponent) {
	if (component.componentType === "FIELD") {
		return i18n.t("input_field") + " - " + getConfigurationComponentFieldTypeLabel(component.fieldType);
	} else {
		return getConfigurationComponentTypeLabel(component.componentType);
	}
}
