import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {
    WorkCenterOperationsApi
} from "src/api/generated/erp/production/basedata/workcenters/workCenterOperationsApi.ts";
import {useGlobalInitData} from "src/contexts/GlobalInitDataContext.ts";
import {AavoDataGrid} from "src/components/common/dataGrid/AavoDataGrid.tsx";
import {booleanColumn, integerColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {useTenantCustomizations} from "src/tenantCustomizations/TenantCustomizationsContext.ts";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {faLink} from "@fortawesome/pro-regular-svg-icons";
import {OperationView} from "src/api/generated/erp/db/types/tables/operationView.ts";

export interface WorkCenterOperationSelectionViewProps {
	workCenterId: number;
	onSubmit: (selectedOperationIds: number[]) => Promise<void>;
}

export const WorkCenterOperationSelectionView = ({ workCenterId, onSubmit }: WorkCenterOperationSelectionViewProps) => {
	const { defaultSiteId } = useGlobalInitData();
	const { tenantConfig } = useTenantCustomizations();
	return (
		<ClientSideDataGridModel
			gridId={"CBC949F5428BB775"}
			fetchData={WorkCenterOperationsApi.getWorkCenterAvailableOperationOptions}
			getRows={(data) => data}
			initialParams={{
				siteId: defaultSiteId,
				searchQuery: "",
				workCenterId: workCenterId,
			}}
			getRowId={(row) => row.operationId}
			render={({ dataGridProps, selectedRows }) => {
				return (
					<AavoDataGrid<OperationView>
						checkboxSelection
						columns={[
							textColumn({
								field: "siteName",
								headerName: i18n.t("site"),
							}),
							integerColumn({
								field: "operationNo",
								headerName: i18n.t("operation_no"),
								width: 120,
							}),
							textColumn({
								field: "operationDescription",
								headerName: i18n.t("description"),
								width: 160,
							}),
							tenantConfig.erp.surveysEnabled && [
								textColumn({
									field: "surveyFormName",
									headerName: i18n.t("survey"),
									width: 180,
								}),
								booleanColumn({
									field: "surveyRequired",
									headerName: i18n.t("survey_required"),
								}),
							],
						]}
						actionBarComponents={
							<>
								<AsyncButton
									icon={faLink}
									variant={"outlined"}
									label={i18n.t("connect")}
									onClick={async () => {
										await onSubmit(selectedRows.map((row) => row.operationId));
									}}
								/>
							</>
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
