// This file is automatically generated. Do not edit manually.

import { ProcessImprovementState } from "src/api/generated/postgres/db/types/public_/enums/processImprovementState";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ProcessImprovementCategory } from "src/api/generated/postgres/db/types/public_/tables/processImprovementCategory";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { ProcessImprovementView } from "src/api/generated/postgres/db/types/public_/tables/processImprovementView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProcessImprovementsApi {
	export async function getProcessImprovementsGridData(args: {
		processImprovementId?: number | null | undefined;
		processImprovementCategoryId?: number | null | undefined;
		responsiblePersonId?: number | null | undefined;
		processImprovementState?: ProcessImprovementState | null | undefined;
		showCancelled?: boolean;
		searchQuery?: string;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ProcessImprovementsApi_GridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/processImprovements/getProcessImprovementsGridData",
			method: "POST",
			data: {
				processImprovementId: args.processImprovementId,
				processImprovementCategoryId: args.processImprovementCategoryId,
				responsiblePersonId: args.responsiblePersonId,
				processImprovementState: args.processImprovementState,
				showCancelled: args.showCancelled,
				searchQuery: args.searchQuery,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ProcessImprovementsApi_GridData;
	}
}

export interface ProcessImprovementsApi_GridData {
	categoryOptions: Array<ProcessImprovementCategory>;
	processImprovements: ServerSideDataModelResult<ProcessImprovementView>;
}
