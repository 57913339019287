import i18n from "i18next";
import {faFileInvoiceDollar} from "@fortawesome/pro-regular-svg-icons";
import {useTenantCustomizations} from "src/tenantCustomizations/TenantCustomizationsContext.ts";
import {AsyncButton, AsyncButtonProps} from "src/components/common/buttons/AsyncButton.tsx";
import {useGenericDialog} from "src/components/common/dialogs/GenericDialogContext.ts";
import {CustomerOrdersPage} from "src/components/views/erp/sales/customerOrder/CustomerOrdersPage.tsx";
import {resolveValueOrProvider, ValueOrProvider} from "src/utils/valueOrProvider.ts";
import {useUserPermissions} from "src/components/views/erp/common/userPermissions.ts";

export interface OpenCustomerOrderButtonProps extends Omit<AsyncButtonProps, "onClick"> {
	customerOrderId: ValueOrProvider<number | Promise<number>> | null | undefined;
}

export const OpenCustomerOrderButton = ({
	customerOrderId: customerOrderIdProp,
	variant = "menu",
}: OpenCustomerOrderButtonProps) => {
	const { openDialog } = useGenericDialog();
	const { tenantConfig } = useTenantCustomizations();
	const userPermissions = useUserPermissions();
	if (!tenantConfig.erp.salesEnabled) return null;

	return (
		userPermissions.sales.readCustomerOrders && (
			<AsyncButton
				label={i18n.t("open_customer_order")}
				icon={faFileInvoiceDollar}
				variant={variant}
				disabled={customerOrderIdProp == null}
				onClick={async () => {
					if (customerOrderIdProp == null) return;
					const customerOrderId = await resolveValueOrProvider(customerOrderIdProp);
					openDialog({
						title: i18n.t("customer_order"),
						content: <CustomerOrdersPage onlyCustomerOrderToShowId={customerOrderId} />,
					});
				}}
			/>
		)
	);
};
