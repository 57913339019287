// This file is automatically generated. Do not edit manually.

import { WorkCenterOperationView } from "src/api/generated/erp/db/types/tables/workCenterOperationView";
import { OperationView } from "src/api/generated/erp/db/types/tables/operationView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WorkCenterOperationsApi {
	export async function getWorkCenterOperations(args: {
		workCenterId: number;
	}): Promise<Array<WorkCenterOperationView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterOperations/getWorkCenterOperations",
			method: "POST",
			data: {
				workCenterId: args.workCenterId,
			},
		});
		return response as Array<WorkCenterOperationView>;
	}

	export async function delete_(args: { workCenterOperationId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterOperations/delete",
			method: "POST",
			data: {
				workCenterOperationId: args.workCenterOperationId,
			},
		});
		return response as void;
	}

	export async function addOperationToWorkCenter(args: {
		workCenterId: number;
		operationIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterOperations/addOperationToWorkCenter",
			method: "POST",
			data: {
				workCenterId: args.workCenterId,
				operationIds: args.operationIds,
			},
		});
		return response as void;
	}

	export async function getWorkCenterAvailableOperationOptions(args: {
		siteId: number;
		workCenterId: number;
		searchQuery: string;
	}): Promise<Array<OperationView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterOperations/getWorkCenterAvailableOperationOptions",
			method: "POST",
			data: {
				siteId: args.siteId,
				workCenterId: args.workCenterId,
				searchQuery: args.searchQuery,
			},
		});
		return response as Array<OperationView>;
	}
}
