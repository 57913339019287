import { enumColumn, floatColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { getAcquisitionMethodLabels } from "src/api/generated/erp/db/types/enums/acquisitionMethod.ts";
import { TestConfigurationResultViewCommonProps } from "src/components/views/erp/configurator/configuratorTesting/TestConfigurationResultViewCommonProps.ts";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import React from "react";
import { AavoDataGrid } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { ResolvedPartConfigurationBomLine } from "src/api/generated/erp/configurator/configurationResolving/bom/resolvedPartConfigurationBomLine";
import { concatWithPipe } from "src/utils/strings.tsx";

export interface TestConfigurationBomDataGridBaseProps extends TestConfigurationResultViewCommonProps {
	fetchRows: () => Promise<ResolvedPartConfigurationBomLine[]>;
	subConfigurationResultsView: React.FC<TestConfigurationResultViewCommonProps>;
	showPrices?: boolean;
}

export const TestConfigurationBomDataGridBase = ({
	fetchRows,
	configurationSessionId,
	configuratorType,
	propertyValues,
	subConfigurationResultsView,
	showPrices = false,
}: TestConfigurationBomDataGridBaseProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<ClientSideDataGridModel
			fetchData={async () => {
				const rows = await fetchRows();
				return rows.map((row, index) => ({ ...row, index }));
			}}
			getRows={(data) => data}
			getRowId={(row) => row.index}
			initialParams={{}}
			gridId={"AE593DFB73807651"}
			render={({ dataGridProps, onlySelectedRow }) => (
				<AavoDataGrid<ResolvedPartConfigurationBomLineWithIndex>
					columns={[
						integerColumn({
							field: "referenceNo",
							headerName: i18n.t("reference_no"),
							width: 100,
						}),
						textColumn({
							field: "description" as any,
							headerName: i18n.t("part"),
							width: 200,
							valueGetter: (_, row) => getRowDescription(row),
						}),
						floatColumn({
							field: "quantity",
							headerName: i18n.t("quantity"),
							width: 100,
						}),
						showPrices && floatColumn({
							field: "unitPrice",
							headerName: i18n.t("unit_price"),
						}),
						showPrices && floatColumn({
							field: "totalPrice",
							headerName: i18n.t("total_price"),
						}),
						enumColumn({
							field: "acquisitionMethod",
							headerName: i18n.t("acquisition_method"),
							width: 120,
							enumLabels: getAcquisitionMethodLabels(),
						}),
						textColumn({
							field: "note",
							headerName: i18n.t("note"),
							width: 200,
						}),
					]}
					actionBarComponents={
						<>
							<AsyncButton
								key={"openSubConfiguration"}
								label={i18n.t("sub_configuration")}
								icon={faExternalLink}
								disabled={onlySelectedRow?.bomLineConfiguration == null}
								variant={"outlined"}
								onClick={() => {
									openSubConfigurationResults(onlySelectedRow!);
								}}
							/>
						</>
					}
					onRowDoubleClick={({ row }) => openSubConfigurationResults(row)}
					{...dataGridProps}
				/>
			)}
		/>
	);

	function openSubConfigurationResults(row: ResolvedPartConfigurationBomLine) {
		const subConfig = row.bomLineConfiguration;
		if (subConfig == null) return;
		openDialog(() => ({
			title: getRowDescription(row),
			content: subConfigurationResultsView({
				configurationSessionId: configurationSessionId,
				propertyValues: subConfig.propertyValues,
				configuratorType: {
					type: "subConfigurator",
					parentConfiguratorType: configuratorType,
					parentProperties: propertyValues,
					subConfiguratorCatalogPartId: row.bomLineCatalogPartId,
					isTestConfigurator: configuratorType.isTestConfigurator,
					isSubConfigurator: true,
					configuratorVersion: null
				},
			}),
		}));
	}
};

function getRowDescription(row: ResolvedPartConfigurationBomLine) {
	return (
		row.bomLineConfiguration?.topLevelScriptResult?.description ??
		concatWithPipe(row.bomLinePartNo, row.bomLinePartDescription1)
	);
}

interface ResolvedPartConfigurationBomLineWithIndex extends ResolvedPartConfigurationBomLine {
	index: number;
}
