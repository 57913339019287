import { ConfiguratorTreeItemState } from "src/components/views/erp/configurator/configuratorForm/configuratorTree/treeItemState/ConfiguratorTreeItemState.ts";
import {
	ConfiguratorInput_InputValue,
	ConfiguratorInput_InputValue_SubConfigurator,
} from "src/api/generated/erp/configurator/model/configuratorInput.ts";
import { ConfiguratorVersion } from "src/api/generated/erp/configurator/model/configuratorVersion.ts";
import { ConfigurationPropertyValues } from "src/api/generated/erp/configurator/model/configurationPropertyValues.ts";
import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType.ts";

/**
 * Immutable update function for a single item in the configurator tree.
 * Returns a new root item state with the specified item updated.
 */
export function updateConfiguratorTreeItemState(
	rootItemState: ConfiguratorTreeItemState,
	keyToUpdate: string,
	updateFn: (state: ConfiguratorTreeItemState) => ConfiguratorTreeItemState,
): ConfiguratorTreeItemState {
	if (keyToUpdate === rootItemState.key) {
		return updateFn(rootItemState);
	}

	const updatedChildren = rootItemState.children.map((child) =>
		updateConfiguratorTreeItemState(child, keyToUpdate, updateFn),
	);
	return {
		...rootItemState,
		children: updatedChildren,
	};
}

/**
 * Recursively copies the input component values from the children to the ancestors.
 */
export function propagateConfiguratorInputChangeToAncestors(
	rootItemState: ConfiguratorTreeItemState,
): ConfiguratorTreeItemState {
	const propagatedChildren = rootItemState.children.map((child) =>
		propagateConfiguratorInputChangeToAncestors(child),
	);

	const inputComponentValuesAsRecord = rootItemState.configuratorInput.inputComponentValues.reduce(
		(record, { componentName, value }) => {
			record[componentName] = value;
			return record;
		},
		{} as Record<string, ConfiguratorInput_InputValue>,
	);

	const singleSubConfiguratorComponents = rootItemState.subConfiguratorComponents.filter(
		(component) => component.componentType === "SINGLE_CONFIGURATOR",
	);
	for (const singleSubConfiguratorComponent of singleSubConfiguratorComponents) {
		const childForComponent = propagatedChildren.find(
			(child) => child.selfConfigurationComponentName === singleSubConfiguratorComponent.componentName,
		);

		inputComponentValuesAsRecord[singleSubConfiguratorComponent.componentName] = {
			type: "SUB_CONFIGURATION",
			configuratorVersion: getTreeItemConfiguratorVersion(childForComponent),
			subConfiguratorInput: childForComponent?.configuratorInput ?? {
				inputComponentValues: [],
			},
		};
	}

	const subConfiguratorListComponents = rootItemState.subConfiguratorComponents.filter(
		(component) => component.componentType === "SUB_CONFIGURATOR_LIST",
	);
	for (const subConfiguratorListComponent of subConfiguratorListComponents) {
		const childrenForComponent = propagatedChildren.filter(
			(child) => child.selfConfigurationComponentName === subConfiguratorListComponent.componentName,
		);
		const subConfigurationInputs: ConfiguratorInput_InputValue_SubConfigurator[] = childrenForComponent.map(
			(child) => ({
				type: "SUB_CONFIGURATION",
				configuratorVersion: getTreeItemConfiguratorVersion(child),
				subConfiguratorInput: child.configuratorInput,
			}),
		);

		inputComponentValuesAsRecord[subConfiguratorListComponent.componentName] = {
			type: "SUB_CONFIGURATION_LIST",
			subConfiguratorInputs: subConfigurationInputs,
		};
	}

	const newConfiguratorInput = {
		inputComponentValues: Object.entries(inputComponentValuesAsRecord).map(([componentName, value]) => ({
			componentName,
			value,
		})),
	};

	return {
		...rootItemState,
		configuratorInput: newConfiguratorInput,
		children: propagatedChildren,
	};
}

export function propagateParentPropertyValuesToDescendants(
	rootItemState: ConfiguratorTreeItemState,
): ConfiguratorTreeItemState {
	const updatedChildren = rootItemState.children.map((child) =>
		propagateParentPropertyValuesToDescendantsHelper(
			child,
			rootItemState.propertyValues,
			rootItemState.configuratorType,
		),
	);

	return {
		...rootItemState,
		children: updatedChildren,
	};
}

function propagateParentPropertyValuesToDescendantsHelper(
	treeItem: ConfiguratorTreeItemState,
	parentProperties: ConfigurationPropertyValues,
	parentConfiguratorType: PartConfiguratorType,
): ConfiguratorTreeItemState {
	const updatedChildren = treeItem.children.map((child) =>
		propagateParentPropertyValuesToDescendantsHelper(child, treeItem.propertyValues, treeItem.configuratorType),
	);

	const configuratorType =
		treeItem.configuratorType.type !== "subConfigurator" ?
			treeItem.configuratorType
		:	{
				...treeItem.configuratorType,
				parentProperties: parentProperties,
				parentConfiguratorType: parentConfiguratorType,
			};

	return {
		...treeItem,
		children: updatedChildren,
		configuratorType: configuratorType,
	};
}

function getTreeItemConfiguratorVersion(
	treeItem: ConfiguratorTreeItemState | null | undefined,
): ConfiguratorVersion | undefined {
	if (treeItem?.configuratorType?.type !== "subConfigurator") {
		return undefined;
	}
	return treeItem.configuratorType.configuratorVersion ?? undefined;
}
