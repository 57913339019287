// This file is automatically generated. Do not edit manually.

import { BillingPlanModelLine } from "src/api/generated/erp/db/types/tables/billingPlanModelLine";
import { ObjectAttributeValueWithFieldId } from "src/api/generated/erp/common/objectAttributes/model/objectAttributeValueWithFieldId";
import { UpOrDown } from "src/api/generated/common/upOrDown";
import { ObjectAccountingDimensionValue } from "src/api/generated/erp/sales/accounting/objectAccountingDimensionValue";
import { ObjectAttributeField } from "src/api/generated/erp/common/objectAttributes/model/objectAttributeField";
import { ObjectAttribute } from "src/api/generated/erp/db/types/tables/objectAttribute";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace BillingPlanModelLineEditApi {
	export async function getFormInitData(args: {
		billingPlanModelLineId: number | null | undefined;
	}): Promise<BillingPlanModelLineEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/billingPlanModelLine/getFormInitData",
			method: "POST",
			data: {
				billingPlanModelLineId: args.billingPlanModelLineId,
			},
		});
		return response as BillingPlanModelLineEditApi_FormInitData;
	}

	export async function insert(args: {
		billingPlanModelLine: BillingPlanModelLine;
		accountingCodeIds: Array<number>;
		attributes?: Array<ObjectAttributeValueWithFieldId>;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/billingPlanModelLine/insert",
			method: "POST",
			data: {
				billingPlanModelLine: args.billingPlanModelLine,
				accountingCodeIds: args.accountingCodeIds,
				attributes: args.attributes,
			},
		});
		return response as number;
	}

	export async function update(args: {
		billingPlanModelLine: BillingPlanModelLine;
		accountingCodeIds: Array<number>;
		attributes?: Array<ObjectAttributeValueWithFieldId>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/billingPlanModelLine/update",
			method: "POST",
			data: {
				billingPlanModelLine: args.billingPlanModelLine,
				accountingCodeIds: args.accountingCodeIds,
				attributes: args.attributes,
			},
		});
		return response as void;
	}

	export async function delete_(args: { billingPlanModelLineIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/billingPlanModelLine/delete",
			method: "POST",
			data: {
				billingPlanModelLineIds: args.billingPlanModelLineIds,
			},
		});
		return response as void;
	}

	export async function move(args: { billingPlanModelLineId: number; direction: UpOrDown }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/billingPlanModelLine/move",
			method: "POST",
			data: {
				billingPlanModelLineId: args.billingPlanModelLineId,
				direction: args.direction,
			},
		});
		return response as void;
	}
}

export interface BillingPlanModelLineEditApi_FormInitData {
	accountingDimensions: Array<ObjectAccountingDimensionValue>;
	attributeFields: Array<ObjectAttributeField>;
	attributeValues: Array<ObjectAttribute>;
	billingPlanModelLine: BillingPlanModelLine | null | undefined;
}
