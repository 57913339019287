import {FrontendView, ViewGroup} from "src/api/mainApi";
import {View, ViewAssociation} from "../../legacy/types";
import {MainMenuItem} from "src/components/views/drawer/mainMenuTree/mainMenuItem";
import {resolveHandlebarsTemplate} from "src/utils/handlebars";
import {keyValuePairsToRecord} from "src/utils/keyValuePair.ts";
import {ResolvedFrontendViewConfig} from "src/components/views/frontendViews/frontendViewConfig.ts";
import addToFavoritesHelperImagePath from "./assets/addToFavoritesHelperImage.png";
import _ from "underscore";
import i18n from "i18next";
import {Typography} from "@mui/material";
import {VerticalBox} from "src/components/common/box/VerticalBox.tsx";

export const getMainMenuItems = (
	viewGroups: ViewGroup[],
	views: ViewAssociation[],
	frontendViews: FrontendView[],
	frontendViewConfigs: ResolvedFrontendViewConfig[],
	favoriteKeys: string[],
): MainMenuItem[] => {
	const frontendViewConfigsByStaticKeys = _(frontendViewConfigs).groupBy((config) => config.staticKey);

	const frontendViewExists = (view: FrontendView): boolean => {
		const configs = frontendViewConfigsByStaticKeys[view.key];
		return configs !== undefined && configs.length > 0;
	};

	const frontendViewsWithoutReplacement = frontendViews.filter((view) => {
		return view.replacesView === null && frontendViewExists(view);
	});
	const frontendViewsWithReplacement = frontendViews.filter((view) => {
		return view.replacesView !== null && frontendViewExists(view);
	});
	const menuItemsFromBackendViews: MainMenuItemWithOriginalData[] = views
		.filter((v) => v.showDirectly)
		.flatMap((v) => {
			const replacingFrontendView = frontendViewsWithReplacement.find((feView) => {
				const replacesViewRegex = new RegExp(`${feView.replacesView}(_\\d+)?`);
				return replacesViewRegex.test(v.view.id);
			});
			if (replacingFrontendView !== undefined) {
				return frontendViewToMainMenuItems(replacingFrontendView, frontendViewConfigsByStaticKeys);
			} else {
				return [backendViewToMainMenuItem(v.view)];
			}
		});

	const viewGroupItemsWithoutChildren: MainMenuItemWithOriginalData[] = viewGroups
		.filter((viewGroup) => !viewGroup.transparent)
		.map(viewGroupToMainMenuItemWithoutChildren);

	const allMenuItems: MainMenuItemWithOriginalData[] = [
		...frontendViewsWithoutReplacement.flatMap((v) =>
			frontendViewToMainMenuItems(v, frontendViewConfigsByStaticKeys),
		),
		...menuItemsFromBackendViews,
		...viewGroupItemsWithoutChildren,
	];

	const uniqueItems = dropDuplicateMenuItems(allMenuItems);
	const allItemsWithChildren = addChildrenToMenuItems(uniqueItems);
	const onlyRootLevel = dropNonRootMenuItems(allItemsWithChildren);
	const sorted = sortMenuItemsRecursively(onlyRootLevel);
	const withFavorites = [...createFavoritesGroupMenuItem(uniqueItems, favoriteKeys), ...sorted];
	// Dropping unnecessary properties makes testing easier.
	return dropUnnecessaryProperties(withFavorites);
};

type MainMenuItemWithOriginalData = MainMenuItem & {
	parentKey: string | undefined;
	pos: number[];
	children: MainMenuItemWithOriginalData[];
};

const frontendViewToMainMenuItems = (
	view: FrontendView,
	frontendViewConfigsByStaticKeys: Record<string, ResolvedFrontendViewConfig[]>,
): MainMenuItemWithOriginalData[] => {
	const viewImpls = frontendViewConfigsByStaticKeys[view.key] ?? [];
	return viewImpls.map((viewImpl) => ({
		key: viewImpl.key,
		label: viewImpl.title,
		icon: viewImpl.icon.iconName,
		type: "view",
		children: [],
		parentKey: view.groupId?.toString(),
		pos: [view.pos],
	}));
};

const backendViewToMainMenuItem = (view: View): MainMenuItemWithOriginalData => {
	const label = resolveHandlebarsTemplate(view.displayName, keyValuePairsToRecord(view.staticParams));
	return {
		key: view.id,
		label: label,
		icon: view.icon,
		type: "view",
		children: [],
		parentKey: view.groupId?.toString(),
		pos: view.pos,
	};
};

const viewGroupToMainMenuItemWithoutChildren = (viewGroup: ViewGroup): MainMenuItemWithOriginalData => {
	return {
		key: viewGroup.id.toString(),
		label: i18n.t(viewGroup.displayName as any),
		icon: viewGroup.icon,
		type: "viewGroup",
		children: [],
		parentKey: viewGroup.parentId?.toString(),
		pos: viewGroup.pos,
	};
};

const dropDuplicateMenuItems = (menuItems: MainMenuItemWithOriginalData[]): MainMenuItemWithOriginalData[] => {
	const uniqueItems: MainMenuItemWithOriginalData[] = [];
	menuItems.forEach((menuItem) => {
		const existingItem = uniqueItems.find((item) => item.key === menuItem.key && item.type === menuItem.type);
		if (existingItem === undefined) {
			uniqueItems.push(menuItem);
		} else {
			existingItem.pos.push(...menuItem.pos);
		}
	});
	return uniqueItems;
};

const addChildrenToMenuItems = (
	menuItemsToMap: MainMenuItemWithOriginalData[],
	allMenuItems: MainMenuItemWithOriginalData[] = menuItemsToMap,
): MainMenuItemWithOriginalData[] => {
	return menuItemsToMap.map((menuItem) => {
		const children =
			menuItem.type === "view" ?
				[]
			:	allMenuItems.filter((child) => {
					return child.parentKey === menuItem.key;
				});
		return {
			...menuItem,
			children: addChildrenToMenuItems(children, allMenuItems),
		};
	});
};

export const MAIN_MENU_FAVORITES_GROUP_KEY = "__favorites__";

const createFavoritesGroupMenuItem = (
	allMenuItems: MainMenuItemWithOriginalData[],
	favoriteKeys: string[],
): MainMenuItemWithOriginalData[] => {
	const favoriteItems = allMenuItems
		.filter((item) => item.type === "view" && favoriteKeys.includes(item.key))
		.sort((item) => favoriteKeys.indexOf(item.key));

	return [
		{
			key: MAIN_MENU_FAVORITES_GROUP_KEY,
			label: i18n.t("favorites"),
			icon: "star",
			type: "viewGroup",
			parentKey: undefined,
			pos: [-1],
			isFavoritesGroupShortcutItem: true,
			helpText:
				favoriteItems.length > 0 ?
					undefined
				:	<VerticalBox>
						<Typography>{i18n.t("main_menu_favorites_help_text")}</Typography>
						<img src={addToFavoritesHelperImagePath} alt="add-to-favorites-img" />
					</VerticalBox>,
			children: favoriteItems.map((item) => {
				return {
					...item,
					isFavoritesGroupShortcutItem: true,
					parentKey: MAIN_MENU_FAVORITES_GROUP_KEY,
				};
			}),
		},
	];
};

const dropNonRootMenuItems = (menuItems: MainMenuItemWithOriginalData[]): MainMenuItemWithOriginalData[] => {
	return menuItems.filter((menuItem) => menuItem.parentKey === undefined);
};

const sortMenuItemsRecursively = (menuItems: MainMenuItemWithOriginalData[]): MainMenuItemWithOriginalData[] => {
	const thisLevelSorted = menuItems.sort(compareMenuItemsWithSameParent);
	return thisLevelSorted.map((menuItem) => {
		return {
			...menuItem,
			children: sortMenuItemsRecursively(menuItem.children),
		};
	});
};

const compareMenuItemsWithSameParent = (
	itemA: MainMenuItemWithOriginalData,
	itemB: MainMenuItemWithOriginalData,
): number => {
	const largerPosLength = Math.max(itemA.pos.length, itemB.pos.length);
	for (let i = 0; i < largerPosLength; i++) {
		const posA = itemA.pos[i] ?? 0;
		const posB = itemB.pos[i] ?? 0;
		if (posA !== posB) return posA - posB;
	}
	return 0;
};

const dropUnnecessaryProperties = (menuItems: MainMenuItemWithOriginalData[]): MainMenuItem[] => {
	return menuItems.map((menuItem) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { pos, ...rest } = menuItem;
		return {
			...rest,
			children: dropUnnecessaryProperties(menuItem.children),
		};
	});
};
