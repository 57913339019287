import {CenteredTypography} from "src/components/common/CenteredTypography.tsx";
import i18n from "i18next";
import {AavoMosaic} from "src/components/common/mosaic/AavoMosaic.tsx";
import {
    DocumentsOfSingleObjectView
} from "src/components/views/documents/objectDocuments/DocumentsOfSingleObjectView.tsx";
import {ShopOrderBomDataGrid} from "src/components/views/erp/production/shopOrderBom/ShopOrderBomDataGrid.tsx";
import {
    PartConfigurationPropertiesDataGrid
} from "src/components/views/erp/configurator/inspecting/PartConfigurationPropertiesDataGrid.tsx";
import {
    PartConfigurationAttributesDataGrid
} from "src/components/views/erp/configurator/inspecting/PartConfigurationAttributesDataGrid.tsx";
import {
    JobQueueShopOrderOperation
} from "src/components/views/erp/production/jobQueue/base/JobQueueShopOrderOperation.ts";
import {
    SelectedOperationDocumentsView
} from "src/components/views/erp/production/jobQueue/base/inProgressView/SelectedOperationDocumentsView.tsx";
import { useTenantCustomizations } from "src/tenantCustomizations/TenantCustomizationsContext.ts";
import {
	SelectedOperationWorkInstructionView
} from "src/components/views/erp/production/jobQueue/base/inProgressView/SelectedOperationWorkInstructionView.tsx";

export interface SelectedOperationTabsViewProps {
	selectedOperation: JobQueueShopOrderOperation | undefined;
}

export const SelectedOperationTabsView = ({ selectedOperation }: SelectedOperationTabsViewProps) => {
	const selectJobTypography = <CenteredTypography>{i18n.t("select_a_job")}</CenteredTypography>;
	const { tenantConfig } = useTenantCustomizations();

	return (
		<AavoMosaic
			sx={{
				flex: 3,
			}}
			layout={{
				type: "tabs",
				items: [
					tenantConfig.documentsEnabled && {
						type: "panel",
						title: i18n.t("documents"),
						content:
							selectedOperation == null ? selectJobTypography : (
								<SelectedOperationDocumentsView
									key={selectedOperation.shopOrderOperationId}
									shopOrderOperation={selectedOperation}
								/>
							),
					},
					{
						type: "panel",
						title: i18n.t("bom"),
						content:
							selectedOperation == null ? selectJobTypography : (
								<ShopOrderBomDataGrid
									key={selectedOperation.shopOrderOperationId}
									shopOrderId={selectedOperation.shopOrderId}
									filterByShopOrderOperationId={selectedOperation.shopOrderOperationId}
									editingEnabled={false}
								/>
							),
					},
					selectedOperation?.partConfigurationId != null && {
						type: "panel",
						title: i18n.t("configuration"),
						content: (
							<PartConfigurationPropertiesDataGrid
								key={selectedOperation.shopOrderOperationId}
								partConfigurationId={selectedOperation.partConfigurationId!}
							/>
						),
					},
					selectedOperation?.partConfigurationId != null && {
						type: "panel",
						title: i18n.t("work_instruction"),
						content: (
							<SelectedOperationWorkInstructionView
								key={selectedOperation.shopOrderOperationId}
								partConfigurationId={selectedOperation.partConfigurationId!}
							/>
						),
					},
					selectedOperation?.partConfigurationId != null && {
						type: "panel",
						title: i18n.t("attributes"),
						content: (
							<PartConfigurationAttributesDataGrid
								key={selectedOperation.shopOrderOperationId}
								partConfigurationId={selectedOperation.partConfigurationId!}
								editable={false}
							/>
						),
					},
					selectedOperation?.customerOrderId != null && tenantConfig.documentsEnabled && {
						type: "panel",
						title: i18n.t("order_documents"),
						content: (
							<DocumentsOfSingleObjectView
								key={selectedOperation.shopOrderOperationId}
								objectRef={{
									objectType: "CUSTOMER_ORDER",
									objectId: selectedOperation.customerOrderId,
								}}
								hidePreview
								editable={false}
							/>
						),
					},
				],
			}}
		/>
	);
};
