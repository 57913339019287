import { faCamera } from "@fortawesome/pro-regular-svg-icons";
import { QrcodeReader } from "src/components/common/QrcodeReader.tsx";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import i18n from "i18next";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { Box, Modal, useTheme } from "@mui/material";
import { RefObject, useEffect, useState } from "react";
import { SxProps, Theme } from "@mui/material/styles";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/ErrorDialogContext.tsx";
import { mergeSx } from "src/utils/styles.ts";
import { useForwardedRef } from "src/utils/useForwardedRef.ts";

export interface AavoQrcodeFieldProps {
	onQrCodeRead: (qrCodeContent: string) => Promise<unknown> | void;
	showTextField?: boolean;
	sx?: SxProps<Theme>;
	autoFocus?: boolean;
	textFieldInputRef?: RefObject<HTMLInputElement>;
	onTextFieldChange?: (value: string) => void;
	textFieldValue?: string;
	openImmediately?: boolean;
}

export const QrcodeInputField = ({
	onQrCodeRead,
	showTextField,
	sx,
	autoFocus,
	textFieldInputRef: textFieldInputRefProp,
	onTextFieldChange,
	textFieldValue,
	openImmediately = false,
}: AavoQrcodeFieldProps) => {
	const theme = useTheme();
	const [open, setOpen] = useState(openImmediately);
	const { logErrorAndShowOnDialog } = useErrorDialog();
	const [isLoading, setIsLoading] = useState(false);

	const textFieldInputRef = useForwardedRef(textFieldInputRefProp);

	useEffect(() => {
		if (autoFocus) {
			textFieldInputRef.current?.focus();
			setTimeout(() => {
				textFieldInputRef.current?.focus();
			}, 0);
		}
		// eslint-disable-next-line
	}, []);

	return (
		<HorizontalBox
			sx={mergeSx(
				{
					alignItems: "center",
				},
				sx,
			)}
		>
			{showTextField && (
				<AavoTextField
					label={i18n.t("code")}
					onChange={(value) => (onTextFieldChange ? onTextFieldChange(value) : {})}
					onSubmit={async (value, source) => {
						if(source === "enterKey") {
							await onQrCodeRead(value);
						}
					}}
					size={"small"}
					autoFocus={autoFocus}
					inputRef={textFieldInputRef}
					value={textFieldValue}
				/>
			)}
			<AavoButton
				icon={faCamera}
				size={"large"}
				sx={{ color: theme.palette.primary.main }}
				loading={isLoading}
				onClick={async () => {
					setOpen(true);
				}}
			/>
			<Modal open={open} onClose={() => setOpen(false)} onClick={() => setOpen(false)}>
				<Box
					sx={{
						display: "flex",
					}}
				>
					<QrcodeReader
						onQrCodeRead={(qrCodeContent) => {
							setOpen(false);
							setIsLoading(true);
							try {
								onQrCodeRead(qrCodeContent);
							} catch (e) {
								logErrorAndShowOnDialog(e);
							} finally {
								setIsLoading(false);
							}
						}}
					/>
				</Box>
			</Modal>
		</HorizontalBox>
	);
};
