import { ConfiguratorFieldComponent } from "src/components/views/erp/configurator/configuratorForm/components/field/ConfiguratorFieldComponent.tsx";
import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType.ts";
import React, { useEffect, useState } from "react";
import { ConfigurationPropertyValues } from "src/api/generated/erp/configurator/model/configurationPropertyValues.ts";
import { setComponentCurrentValue } from "src/components/views/erp/configurator/configuratorForm/utils/configuratorFormUtils.tsx";
import { Box } from "@mui/material";
import { NonLayoutTransformedConfigurationComponent } from "src/components/views/erp/configurator/configuratorForm/components/types/NonLayoutTransformedConfigurationComponent.tsx";
import { mergeSx } from "src/utils/styles.ts";
import { ConfiguratorTextComponent } from "./text/ConfiguratorTextComponent";
import { ConfiguratorInput_InputValue } from "src/api/generated/erp/configurator/model/configuratorInput.ts";

export interface ConfiguratorComponentProps {
	apiRef: React.RefCallback<ConfiguratorComponentApi>;
	component: NonLayoutTransformedConfigurationComponent;
	onSubmit: (value: ConfiguratorInput_InputValue) => Promise<unknown>;
	configuratorType: PartConfiguratorType;
	propertyValues: ConfigurationPropertyValues;
	disabled: boolean;
}

export interface ConfiguratorComponentApi {
	setValue: (value: ConfiguratorInput_InputValue) => void;
}

export const ConfiguratorComponent = (props: ConfiguratorComponentProps) => {
	const { component } = props;
	if (!component.visible) return null;

	return (
		<LayoutWrapper {...props}>
			<ConfiguratorComponentInner {...props} />
		</LayoutWrapper>
	);
};

const LayoutWrapper = ({ children, component }: ConfiguratorComponentProps & React.PropsWithChildren) => {
	const layout = component.layout;
	return (
		<Box
			sx={mergeSx(
				layout.spanAllColumns ?
					{ gridColumn: "1 / -1" }
				:	{
						gridColumnStart: layout.placeOnNewRow ? `1` : "auto",
						gridColumnEnd: `span ${layout.columnSpan ?? 1}`,
					},
			)}
		>
			{children}
		</Box>
	);
};

const ConfiguratorComponentInner = (props: ConfiguratorComponentProps) => {
	const { apiRef, component, onSubmit: onSubmitProp, disabled } = props;

	const [componentValue, setComponentValue] = useState<ConfiguratorInput_InputValue>(component.currentValue);

	useEffect(() => {
		apiRef({
			setValue: setComponentValue,
		});
	}, [setComponentValue, apiRef]);

	const onSubmit = async (newValue: ConfiguratorInput_InputValue) => {
		setComponentValue(newValue);
		await onSubmitProp(newValue);
	};

	const componentWithLiveValue = setComponentCurrentValue(component, componentValue);

	switch (componentWithLiveValue.type) {
		case "field":
			return (
				<ConfiguratorFieldComponent
					field={componentWithLiveValue}
					onChange={setComponentValue}
					onSubmit={onSubmit}
					disabled={disabled}
				/>
			);
		case "text":
			return <ConfiguratorTextComponent component={componentWithLiveValue} />;
		case "subConfigurator":
			return <></>;
		case "subConfiguratorList":
			return <></>;
	}
};
