import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType.ts";
import {
	ConfiguratorInput,
	ConfiguratorInput_InputValue,
} from "src/api/generated/erp/configurator/model/configuratorInput.ts";
import React, {  useRef } from "react";
import { useAsyncState } from "src/utils/async/asyncState.ts";
import { AsyncRender, AsyncRenderErrorContentProps } from "src/components/common/async/AsyncRender.tsx";
import {
	constructConfiguratorInputFromComponents,
	setConfiguratorInputSingleComponentValue,
} from "src/components/views/erp/configurator/configuratorForm/utils/configuratorFormUtils.tsx";
import { PartConfiguratorFormApi } from "src/api/generated/erp/configurator/api/partConfiguratorFormApi.ts";
import { getConfiguratorFormErrors } from "src/components/views/erp/configurator/configuratorForm/validation.ts";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { ConfiguratorTabComponent } from "src/components/views/erp/configurator/configuratorForm/components/tab/ConfiguratorTabComponent.tsx";
import { groupConfiguratorComponentsToSections } from "src/components/views/erp/configurator/configuratorForm/components/section/sectionGrouping.ts";
import {
	ConfiguratorSectionComponent,
	ConfiguratorSectionComponentProps,
} from "src/components/views/erp/configurator/configuratorForm/components/section/ConfiguratorSectionComponent.tsx";
import { ConfiguratorSectionComponents } from "src/components/views/erp/configurator/configuratorForm/components/types/ConfiguratorSectionComponents.ts";
import { NonTabTransformedConfigurationComponent } from "src/components/views/erp/configurator/configuratorForm/components/types/NonTabTransformedConfigurationComponent.tsx";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/ErrorDialogContext.tsx";
import { ConfiguratorEvent } from "src/api/generated/erp/configurator/model/configuratorEvent.ts";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import {
	TransformedConfigurationComponent,
	TransformedConfigurationComponent_Tab,
} from "src/api/generated/erp/configurator/componentTransformation/model/transformedConfigurationComponent.ts";
import { PartConfiguratorDocumentContextProvider } from "src/components/views/erp/configurator/configuratorForm/componentDocument/PartConfiguratorDocumentContextProvider.tsx";
import {
	ConfiguratorComponent,
	ConfiguratorComponentApi,
} from "src/components/views/erp/configurator/configuratorForm/components/ConfiguratorComponent.tsx";
import { ConfigurationPropertyValues } from "src/api/generated/erp/configurator/model/configurationPropertyValues.ts";
import { TransformComponentsResult } from "src/api/generated/erp/configurator/componentTransformation/transformationPipe/transformComponentsResult.ts";
import { useAsyncEffectOnMount } from "src/utils/useAsyncEffectOnMount.ts";

export interface PartConfiguratorFormProps {
	configurationSessionId: string;
	configuratorType: PartConfiguratorType;
	configuratorInput: ConfiguratorInput;
	onConfiguratorInputChanged: (params: OnConfiguratorInputChangedParams) => void;
	disabled?: boolean;
	componentIdWithActiveDocument: number | null;
	setComponentIdWithActiveDocument: (componentId: number) => void;
	transformComponentsErrorContent: (props: AsyncRenderErrorContentProps) => React.ReactNode;
}

export interface OnConfiguratorInputChangedParams {
	configuratorInput: ConfiguratorInput;
	errors: string[];
	reason: ConfiguratorInputChangedReason;
	resolvedValues?: {
		propertyValues: ConfigurationPropertyValues;
		configuratorLabel: string;
	};
}

export type ConfiguratorInputChangedReason = "initialize" | "inputChanged" | "reload";

export const PartConfiguratorForm = (props: PartConfiguratorFormProps) => {
	const {
		configuratorType,
		configurationSessionId,
		configuratorInput,
		onConfiguratorInputChanged,
		disabled = false,
		setComponentIdWithActiveDocument,
		componentIdWithActiveDocument,
		transformComponentsErrorContent,
	} = props;

	const { logErrorAndShowOnDialog } = useErrorDialog();

	const [transformComponentsResultAsync, setTransformComponentsResultAsync] =
		useAsyncState<TransformComponentsResult>({});

	const componentApiRefByComponentNames = useRef<Record<string, ConfiguratorComponentApi>>({});

	useAsyncEffectOnMount(async () => {
		await transformComponents(configuratorInput, "initialize");
	});

	return (
		<AsyncRender
			asyncData={transformComponentsResultAsync}
			reloadOnError={() => transformComponents(configuratorInput, "reload")}
			errorContent={transformComponentsErrorContent}
			content={({ components, propertyValues, componentsWithDocument }) => (
				<PartConfiguratorDocumentContextProvider
					componentsWithDocument={componentsWithDocument}
					componentIdWithActiveDocument={componentIdWithActiveDocument}
					setComponentIdWithActiveDocument={setComponentIdWithActiveDocument}
				>
					<ConfiguratorTabsLayout
						allComponents={components}
						renderChildComponent={(component) => (
							<ConfiguratorComponent
								key={component.configurationComponentId}
								component={component}
								configuratorType={configuratorType}
								propertyValues={propertyValues}
								onSubmit={async (newValue) => {
									await onSubmitComponent(component, newValue, components);
								}}
								apiRef={(api) => {
									if (api != null) setComponentApiRef(component, api);
								}}
								disabled={disabled}
							/>
						)}
					/>
				</PartConfiguratorDocumentContextProvider>
			)}
		/>
	);

	async function onSubmitComponent(
		component: TransformedConfigurationComponent,
		value: ConfiguratorInput_InputValue,
		allComponents: TransformedConfigurationComponent[],
	) {
		try {
			const newConfiguratorInput = setConfiguratorInputSingleComponentValue({
				configuratorInput: configuratorInput,
				componentName: component.name,
				newComponentValue: value,
			});
			if (component.type === "field" && component.refreshOnChange) {
				await transformComponents(newConfiguratorInput, "inputChanged", {
					type: "componentValueChanged",
					componentId: component.configurationComponentId,
				});
			} else {
				const errors = getConfiguratorFormErrors(allComponents, newConfiguratorInput);
				onConfiguratorInputChanged({
					configuratorInput: newConfiguratorInput,
					errors: errors,
					reason: "inputChanged",
				});
			}
		} catch (e) {
			logErrorAndShowOnDialog(e);
		}
	}

	async function transformComponents(
		configuratorInput: ConfiguratorInput,
		reason: ConfiguratorInputChangedReason,
		event: ConfiguratorEvent | undefined = undefined,
	): Promise<TransformComponentsResult> {
		const transformResult = await setTransformComponentsResultAsync(() =>
			PartConfiguratorFormApi.transformComponents({
				configurationSessionId: configurationSessionId,
				configuratorType: configuratorType,
				configuratorInput: configuratorInput,
				event: event,
			}),
		);
		const newConfiguratorInput = constructConfiguratorInputFromComponents(transformResult.components);
		const errors = getConfiguratorFormErrors(transformResult.components, newConfiguratorInput);

		newConfiguratorInput.inputComponentValues.forEach(({ componentName, value }) => {
			const componentApi = componentApiRefByComponentNames.current[componentName];
			componentApi?.setValue(value);
		});

		onConfiguratorInputChanged({
			configuratorInput: newConfiguratorInput,
			errors,
			reason: reason,
			resolvedValues: {
				propertyValues: transformResult.propertyValues,
				configuratorLabel: transformResult.configuratorLabel,
			},
		});

		return transformResult;
	}

	function setComponentApiRef(component: TransformedConfigurationComponent, api: ConfiguratorComponentApi) {
		componentApiRefByComponentNames.current[component.configurationComponentId] = api;
	}
};

interface ConfiguratorTabsLayoutProps extends Pick<ConfiguratorSectionComponentProps, "renderChildComponent"> {
	allComponents: TransformedConfigurationComponent[];
}

const ConfiguratorTabsLayout = ({ allComponents, renderChildComponent }: ConfiguratorTabsLayoutProps) => {
	interface TabWithChildren {
		tab: TransformedConfigurationComponent_Tab;
		sections: ConfiguratorSectionComponents[];
	}

	const tabsWithChildren = allComponents.reduce<TabWithChildren[]>((acc, component) => {
		if (component.type !== "tab") return acc;

		const childComponents = allComponents.reduce((acc, c) => {
			if (c.type === "tab") return acc;
			if (c.layout.parentTabComponentId !== component.configurationComponentId) return acc;
			return [...acc, c];
		}, Array<NonTabTransformedConfigurationComponent>());

		const sections = groupConfiguratorComponentsToSections(childComponents);
		return [
			...acc,
			{
				tab: component,
				sections: sections,
			},
		];
	}, []);

	return (
		<VerticalBox flex={1}>
			<AavoMosaic
				layout={{
					type: "tabs",
					items: tabsWithChildren.map(({ tab, sections }) => ({
						type: "panel",
						title: tab.label,
						content: (
							<ConfiguratorTabComponent tabComponent={tab}>
								{sections.map((section) => (
									<ConfiguratorSectionComponent
										key={section.sectionBreakComponent?.configurationComponentId ?? -1}
										components={section}
										renderChildComponent={renderChildComponent}
									/>
								))}
							</ConfiguratorTabComponent>
						),
					})),
				}}
			/>
		</VerticalBox>
	);
};
