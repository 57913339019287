import Typography from "@mui/material/Typography/Typography";
import { ConfigurationComponent } from "src/api/generated/erp/db/types/tables/configurationComponent.ts";
import { VerticalBox } from "src/components/common/box/VerticalBox";
import i18n from "i18next";

export interface TextComponentPreviewProps {
	component: ConfigurationComponent;
}

export const TextComponentPreview = ({ component }: TextComponentPreviewProps) => {
	return (
		<VerticalBox
			sx={{
				border: "1px dashed",
				borderRadius: 1,
				padding: 1,
				flex: 1,
				minHeight: "2rem",
			}}
		>
			<Typography fontStyle={"italic"}>{`${i18n.t("text")}: ${component.label}`}</Typography>
		</VerticalBox>
	);
};
