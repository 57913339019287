import {
	LuukkuPurchaseOrdersApi,
	LuukkuPurchaseOrdersApi_LuukkuPurchaseOrder,
	LuukkuPurchaseOrdersApi_LuukkuPurchaseOrderLine,
} from "src/api/generated/tenants/mellano/luukku/purchaseOrders/api/luukkuPurchaseOrdersApi.ts";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic";
import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel";
import { AavoDataGrid } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { dateColumn, floatColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import { getOnlyMember } from "src/utils/arrayUtils.ts";
import { useStaticDataGridModel } from "src/components/common/dataGrid/gridModel/useStaticDataGridModel.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { useState } from "react";
import { AsyncButton } from "src/components/common/buttons/AsyncButton";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { faShoppingCart } from "@fortawesome/pro-regular-svg-icons";
import { useAavoToast } from "src/components/common/toast/AavoToastContext.tsx";

export const LuukkuPurchaseOrderImportView = () => {
	const [selectedOrders, setSelectedOrders] = useState<LuukkuPurchaseOrdersApi_LuukkuPurchaseOrder[]>([]);
	const onlySelectedOrder = getOnlyMember(selectedOrders);

	return (
		<AavoMosaic
			viewId={"F7E6197D9FE16DC"}
			layout={{
				type: "column",
				first: {
					type: "panel",
					title: "Tilaukset",
					content: <PurchaseOrdersDataGrid onSelectionChanged={setSelectedOrders} />,
				},
				second: {
					type: "panel",
					title: "Tilausrivit",
					content:
						onlySelectedOrder == null ?
							<CenteredTypography>Valitse yksi tilaus</CenteredTypography>
						:	<PurchaseOrderLinesDataGrid
								key={onlySelectedOrder.header.orderNumber}
								order={onlySelectedOrder}
							/>,
				},
			}}
		/>
	);
};

interface PurchaseOrdersDataGridProps {
	onSelectionChanged: (selectedOrders: LuukkuPurchaseOrdersApi_LuukkuPurchaseOrder[]) => void;
}

const PurchaseOrdersDataGrid = ({ onSelectionChanged }: PurchaseOrdersDataGridProps) => {
	const showConfirmDialog = useConfirmDialog();
	const { showToast } = useAavoToast();

	return (
		<ClientSideDataGridModel
			fetchData={() => LuukkuPurchaseOrdersApi.getLuukkuPurchaseOrdersForImport()}
			initialParams={{}}
			gridId={"F6A679F837ED082C"}
			getRows={(data) => data}
			getRowId={(row) => row.header.orderNumber}
			onSelectionChanged={onSelectionChanged}
			render={({ dataGridProps, selectedRows, refreshData }) => (
				<AavoDataGrid<LuukkuPurchaseOrdersApi_LuukkuPurchaseOrder>
					columns={[
						integerColumn({
							field: "orderNumber" as any,
							headerName: "Tilausnro",
							valueGetter: (_, row) => row.header.orderNumber,
						}),
						textColumn({
							field: "customer" as any,
							headerName: "Asiakas",
							valueGetter: (_, row) => row.header.customer,
							width: 300,
						}),
						textColumn({
							field: "reference" as any,
							headerName: "Viite",
							valueGetter: (_, row) => row.header.reference,
							width: 300,
						}),
						dateColumn({
							field: "deliveryDate" as any,
							headerName: "Toimituspvm",
							valueGetter: (_, row) => row.header.deliveryDate,
						}),
						dateColumn({
							field: "reservationDate" as any,
							headerName: "Jemmapvm",
							valueGetter: (_, row) => row.header.reservationDate,
						}),
					]}
					actionBarComponents={
						<AsyncButton
							label={"Luo ostotilaukset"}
							icon={faShoppingCart}
							variant={"contained"}
							onClick={async () => {
								const confirmed = await showConfirmDialog({
									message: "Haluatko varmasti luoda ostotilauksen valituista tilauksista?",
								});
								if (!confirmed) return;

								await LuukkuPurchaseOrdersApi.importPurchaseOrders({
									luukkuPurchaseOrders: selectedRows,
								});
								await refreshData();
								showToast({
									message: "Ostotilaukset luotu",
								});
							}}
						/>
					}
					{...dataGridProps}
				/>
			)}
		/>
	);
};

interface PurchaseOrderLinesDataGridProps {
	order: LuukkuPurchaseOrdersApi_LuukkuPurchaseOrder;
}

const PurchaseOrderLinesDataGrid = ({ order }: PurchaseOrderLinesDataGridProps) => {
	const { dataGridProps } = useStaticDataGridModel({
		rows: order.lines,
		gridId: "C2CA79272F91EBC1",
		getRowId: (row) => row.lineNumber,
	});

	return (
		<AavoDataGrid<LuukkuPurchaseOrdersApi_LuukkuPurchaseOrderLine>
			columns={[
				integerColumn({
					field: "lineNumber",
					headerName: "Rivinro",
				}),
				textColumn({
					field: "partNumber",
					headerName: "Tarkennekoodi",
				}),
				textColumn({
					field: "partDescription",
					headerName: "Selite",
					width: 400,
				}),
				floatColumn({
					field: "quantity",
					headerName: "Määrä",
				}),
			]}
			{...dataGridProps}
		/>
	);
};
