import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import i18n from "i18next";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {faLink} from "@fortawesome/pro-regular-svg-icons";
import {useGlobalInitData} from "src/contexts/GlobalInitDataContext.ts";
import {
    WorkcenterPartWarehouseLocationView
} from "src/api/generated/erp/db/types/tables/workcenterPartWarehouseLocationView.ts";
import {
    WorkCenterPartWarehouseLocationsApi
} from "src/api/generated/erp/production/basedata/workcenters/workCenterPartWarehouseLocationsApi.ts";
import {SitePartQueryApi} from "src/api/generated/erp/parts/sitePart/api/sitePartQueryApi.ts";
import {FormAsyncSelectField} from "src/components/common/forms/fields/FormAsyncSelectField.tsx";
import {
    PartWarehouseLocationQueryApi
} from "src/api/generated/erp/parts/partWarehouseLocations/api/partWarehouseLocationQueryApi.ts";
import {FormLazySelectField} from "src/components/common/forms/fields/FormLazySelectField.tsx";
import {requireRule} from "src/components/common/forms/validation.ts";
import {FetchAsyncOptionParams} from "src/components/common/inputFields/AsyncSelectField.tsx";
import {coloredBooleanColumn, enumColumn, integerColumn, textColumn,} from "src/components/common/dataGrid/columns.tsx";
import {
    getWarehouseReplenishmentMethodLabels
} from "src/api/generated/erp/db/types/enums/warehouseReplenishmentMethod.ts";
import {useFormInput} from "src/components/common/dialogs/formInput/useFormInput.tsx";

export interface WorkCenterPartWarehouseLocationsDataGridProps {
	workCenterId: number;
}

export const WorkCenterPartWarehouseLocationsDataGrid = ({
	workCenterId,
}: WorkCenterPartWarehouseLocationsDataGridProps) => {
	const showFormInput = useFormInput();
	const { defaultSiteId } = useGlobalInitData();

	return (
		<ClientSideDataGridModel
			gridId={"C92651CF19C85AC8"}
			fetchData={() =>
				WorkCenterPartWarehouseLocationsApi.getWorkCenterPartWarehouseLocations({ workCenterId: workCenterId })
			}
			initialParams={{}}
			getRows={(data) => data}
			getRowId={(row) => row.workcenterPartWarehouseLocationId}
			render={({ dataGridProps, refreshData }) => {
				return (
					<CrudDataGrid<WorkcenterPartWarehouseLocationView>
						columns={[
							textColumn({
								field: "locationCode",
								headerName: i18n.t("warehouse_location"),
							}),
							textColumn({
								field: "partLongName",
								headerName: i18n.t("part"),
								width: 200,
							}),
							integerColumn({
								field: "locationQuantity",
								headerName: i18n.t("quantity"),
							}),
							enumColumn({
								field: "replenishmentMethod",
								headerName: i18n.t("control_code"),
								enumLabels: getWarehouseReplenishmentMethodLabels(),
							}),
							integerColumn({
								field: "givingSiteId",
								headerName: i18n.t("giving_site"),
							}),
							integerColumn({
								field: "givingPartWarehouseLocationId",
								headerName: i18n.t("giving_warehouse_location"),
							}),
							integerColumn({
								field: "defaultReplenishmentQuantity",
								headerName: i18n.t("default_replenishment_quantity"),
							}),
							coloredBooleanColumn({
								field: "isDefault",
								headerName: i18n.t("default"),
								falseColor: "grey",
							}),
							coloredBooleanColumn({
								field: "askTransferInfo",
								headerName: i18n.t("ask_transfer_info"),
								falseColor: "grey",
							}),
							coloredBooleanColumn({
								field: "isActive",
								headerName: i18n.t("active"),
								falseColor: "red",
							}),
						]}
						actionBarComponents={
							<>
								<AsyncButton
									icon={faLink}
									label={i18n.t("add_part")}
									variant={"outlined"}
									onClick={async () => {
										const input = await showFormInput<{
											partId: number | null;
											partWarehouseLocationId: number | null;
										}>({
											title: i18n.t("add_part_warehouse_location"),
											defaultValues: {
												partId: null,
												partWarehouseLocationId: null,
											},
											content: ({ control, watch }) => {
												const currentPartId = watch("partId");
												return (
													<>
														<FormAsyncSelectField
															control={control}
															name={"partId"}
															label={i18n.t("select_part")}
															fetchOptions={({
																searchQuery,
																currentSelection,
															}: FetchAsyncOptionParams<number>) =>
																SitePartQueryApi.getPartSelectionOptions({
																	currentPartId: currentSelection,
																	searchQuery: searchQuery,
																	siteId: defaultSiteId,
																	withWarehouseLocations: true,
																})
															}
															getOptionKey={(o) => o.partId}
															getOptionLabel={(o) => o.partLongName}
															rules={requireRule()}
														/>
														<FormLazySelectField
															control={control}
															name={"partWarehouseLocationId"}
															label={i18n.t("warehouse_location")}
															disabled={currentPartId == null}
															rules={requireRule()}
															fetchOptions={async () => {
																if (currentPartId == null) return [];
																return await PartWarehouseLocationQueryApi.getPartWarehouseLocations(
																	{
																		sitePartId: currentPartId,
																	},
																);
															}}
															getOptionKey={(option) => option.partWarehouseLocationId}
															getOptionLabel={(option) => option.locationCode}
														/>
													</>
												);
											},
										});
										if (input?.partWarehouseLocationId == null) return;

										await WorkCenterPartWarehouseLocationsApi.addNewWorkCenterPartWarehouseLocation(
											{
												partWarehouseLocationId: input.partWarehouseLocationId,
												workCenterId: workCenterId,
											},
										);
										await refreshData();
									}}
								/>
							</>
						}
						remove={({ row }) =>
							WorkCenterPartWarehouseLocationsApi.delete_({
								workCenterPartWarehouseLocationId: row.workcenterPartWarehouseLocationId,
							})
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
