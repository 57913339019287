// This file is automatically generated. Do not edit manually.

import { WorkcenterProductionLine } from "src/api/generated/erp/db/types/tables/workcenterProductionLine";
import { ProductionLine } from "src/api/generated/erp/db/types/tables/productionLine";
import { WorkcenterProductionLineView } from "src/api/generated/erp/db/types/tables/workcenterProductionLineView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WorkCenterProductionLinesApi {
	export async function getWorkCenterProductionLinesGridData(args: {
		workCenterId: number;
		siteId: number;
	}): Promise<WorkCenterProductionLinesApi_GridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterProductionLines/getWorkCenterProductionLinesGridData",
			method: "POST",
			data: {
				workCenterId: args.workCenterId,
				siteId: args.siteId,
			},
		});
		return response as WorkCenterProductionLinesApi_GridData;
	}

	export async function delete_(args: { workCenterProductionLineId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterProductionLines/delete",
			method: "POST",
			data: {
				workCenterProductionLineId: args.workCenterProductionLineId,
			},
		});
		return response as void;
	}

	export async function addProductionLineToWorkCenter(args: {
		workCenterId: number;
		productionLineId: number;
	}): Promise<WorkcenterProductionLine> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterProductionLines/addProductionLineToWorkCenter",
			method: "POST",
			data: {
				workCenterId: args.workCenterId,
				productionLineId: args.productionLineId,
			},
		});
		return response as WorkcenterProductionLine;
	}
}

export interface WorkCenterProductionLinesApi_GridData {
	productionLineOptions: Array<ProductionLine>;
	productionLines: Array<WorkcenterProductionLineView>;
}
