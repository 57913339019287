// This file is automatically generated. Do not edit manually.

import { WarehouseSupplyRequestInputService_SupplyRequestInput } from "src/api/generated/erp/warehouse/warehouseTransfer/service/warehouseSupplyRequestInputService";
import { ReplenishPartWarehouseLocationResult } from "src/api/generated/erp/warehouse/warehouseLocation/service/replenishPartWarehouseLocationResult";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WarehouseReplenishmentRequestApi {
	export async function createReplenishmentRequest(args: {
		partWarehouseLocationId: number;
		replenishmentRequestInput: WarehouseSupplyRequestInputService_SupplyRequestInput;
	}): Promise<ReplenishPartWarehouseLocationResult> {
		const response = await makeAxiosRequest({
			url: "/api/ext/warehouse/replenishmentRequest/createReplenishmentRequest",
			method: "POST",
			data: {
				partWarehouseLocationId: args.partWarehouseLocationId,
				replenishmentRequestInput: args.replenishmentRequestInput,
			},
		});
		return response as ReplenishPartWarehouseLocationResult;
	}

	export async function cancelWarehouseReplenishmentRequest(args: {
		partNo: string;
		locationCode: string;
		siteId: number;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/warehouse/replenishmentRequest/cancelWarehouseReplenishmentRequest",
			method: "POST",
			data: {
				partNo: args.partNo,
				locationCode: args.locationCode,
				siteId: args.siteId,
			},
		});
		return response as number;
	}
}
