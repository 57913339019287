import React, { useState, Dispatch, SetStateAction } from "react";
import { useStoredState } from "src/utils/useStoredState.ts";

export const usePartiallyStoredState = <T extends object>({
	initialState,
	storedKeys,
	key,
}: {
	initialState: T;
	storedKeys: Array<keyof T>;
	key: string;
}): [state: T, setState: React.Dispatch<React.SetStateAction<T>>] => {
	const [storedState, setStoredState] = useStoredState({ defaultValue: initialState, key: key });
	const [localState, setLocalState] = useState(initialState);

	const combinedState = { ...localState };
	for (const storedKey of storedKeys) {
		combinedState[storedKey] = storedState[storedKey];
	}

	const setState: Dispatch<SetStateAction<T>> = (valueOrFunction) => {
		setLocalState(valueOrFunction);

		setStoredState((prev) => {
			const newVal = typeof valueOrFunction === "function" ? valueOrFunction(prev) : valueOrFunction;

			const newStoredState: Partial<T> = {};
			for (const storedKey of storedKeys) {
				newStoredState[storedKey] = newVal[storedKey];
			}

			return newStoredState as T;
		});
	};

	return [combinedState, setState];
};
