import i18n from "i18next";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";
import {
	JobQueueInitialsView,
	JobQueueInitialsViewProps,
} from "src/components/views/erp/production/jobQueue/base/JobQueueInitialsView.tsx";
import {
	JobQueueCompletedView,
	JobQueueCompletedViewProps,
} from "src/components/views/erp/production/jobQueue/base/JobQueueCompletedView.tsx";
import {
	JobQueueInProgressView,
	JobQueueInProgressViewProps,
} from "src/components/views/erp/production/jobQueue/base/inProgressView/JobQueueInProgressView.tsx";
import { JobQueueViewContext } from "src/components/views/erp/production/jobQueue/base/JobQueueViewContext.ts";

export interface JobQueueViewProps {
	viewId: string;
	isActive: boolean;
	moveShopOrdersOnProductionLine?: boolean;
	initialsProps: Omit<JobQueueInitialsViewProps, "gridId" | "moveShopOrdersOnProductionLine" | "isActive">;
	inProgressProps: Omit<JobQueueInProgressViewProps, "moveShopOrdersOnProductionLine">;
	completedProps: Omit<JobQueueCompletedViewProps, "gridId" | "moveShopOrdersOnProductionLine">;
}

export const JobQueueView = ({
	viewId,
	isActive,
	moveShopOrdersOnProductionLine = false,
	initialsProps,
	inProgressProps,
	completedProps,
}: JobQueueViewProps) => {
	return (
		<JobQueueViewContext.Provider
			value={{
				initialsViewRefreshRef: useRefreshRef(),
				inProgressViewRefreshRef: useRefreshRef(),
				completedViewRefreshRef: useRefreshRef(),
				inProgressViewDocumentsRefreshRef: useRefreshRef(),
			}}
		>
			<AavoMosaic
				viewId={"C8B563C5E4FACA0D"}
				layout={{
					type: "tabs",
					items: [
						{
							type: "panel",
							title: i18n.t("not_started"),
							content: (
								<JobQueueInitialsView
									gridId={`${viewId}_A09AD21D665D102E`}
									isActive={isActive}
									moveShopOrdersOnProductionLine={moveShopOrdersOnProductionLine}
									{...initialsProps}
								/>
							),
						},
						{
							type: "panel",
							title: i18n.t("in_progress"),
							content: (
								<JobQueueInProgressView
									moveShopOrdersOnProductionLine={moveShopOrdersOnProductionLine}
									{...inProgressProps}
								/>
							),
						},
						{
							type: "panel",
							title: i18n.t("completed"),
							content: (
								<JobQueueCompletedView
									gridId={`${viewId}_65A8FA96AF3955F8`}
									moveShopOrdersOnProductionLine={moveShopOrdersOnProductionLine}
									{...completedProps}
								/>
							),
						},
					],
				}}
			/>
		</JobQueueViewContext.Provider>
	);
};
