import {
	CustomerOrderOfferLineEditApi,
	CustomerOrderOfferLineEditApi_FormInitData,
} from "src/api/generated/erp/sales/customerOrderOffer/api/customerOrderOfferLineEditApi";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm";
import { FormCommonProps, FormSubmitResult } from "src/components/common/forms/types.ts";
import { CustomerOrderOfferLine } from "src/api/generated/erp/db/types/tables/customerOrderOfferLine.ts";
import { FormAsyncSelectField } from "src/components/common/forms/fields/FormAsyncSelectField.tsx";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { SalesPartView } from "src/api/generated/erp/db/types/tables/salesPartView.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { round } from "src/utils/numberUtils.ts";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/ErrorDialogContext.tsx";
import { getDefaultVatCode } from "src/components/views/erp/sales/salesUtils.ts";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { faCubes } from "@fortawesome/pro-solid-svg-icons";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { CustomerOrderOfferLinesDataGrid } from "./CustomerOrderOfferLinesDataGrid";
import { DeepPartial } from "react-hook-form";
import { IsoDateString } from "src/types/dateTime.ts";
import { dayJsToDateIsoStringNullable } from "src/utils/dayjsUtils.ts";
import { useUserPermissions } from "src/components/views/erp/common/userPermissions.ts";
import { showAsyncDialog } from "src/components/common/dialogs/asyncDialog.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { CustomerOfferLineBeforeCreateConfiguratorView } from "src/components/views/erp/configurator/configuratorForm/impl/CustomerOfferLineBeforeCreateConfiguratorView.tsx";
import { createUnsavedCustomerOfferLineConfiguratorType } from "src/components/views/erp/configurator/configuratorForm/utils/partConfiguratorTypeCreators.ts";
import { getConfiguratorFormDialogTitle } from "src/components/views/erp/configurator/configuratorForm/utils/configuratorFormUtils.tsx";

export interface CustomerOrderOfferLineFormProps extends FormCommonProps<number> {
	customerOrderOfferId: number;
	parentLineId: number | undefined;
	customerOrderOfferLineId: number | undefined;
	disabled: boolean;
}

interface FormValues extends Omit<CustomerOrderOfferLine, "salesPartId"> {
	salesPart: SalesPartView;
}

export const CustomerOrderOfferLineForm = (props: CustomerOrderOfferLineFormProps) => {
	const { customerOrderOfferId, parentLineId, customerOrderOfferLineId, onCompleted, onFormEdited, disabled } = props;
	const { openDialog } = useGenericDialog();

	return (
		<AsyncForm
			fetch={() =>
				CustomerOrderOfferLineEditApi.getFormInitData({
					customerOrderOfferId,
					parentLineId,
					customerOrderOfferLineId,
				})
			}
			getDefaultValues={getDefaultValues}
			getUseFormProps={() => ({ disabled })}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			submit={submitForm}
			columns={2}
			render={(params) => <FormContent {...props} {...params} />}
			footerExtraComponents={({ handleSubmit, contentProps: { watch } }) => (
				<>
					{customerOrderOfferLineId == null && watch("salesPart")?.partIsConfigurable && (
						<AsyncButton label={i18n.t("configure")} onClick={() => handleSubmit(configureAndInsert)} />
					)}
				</>
			)}
		/>
	);

	function getDefaultValues({
		offer,
		offerLine,
		salesPart,
		parentLine,
		defaultLineNumber,
		vatCodeOptions,
	}: CustomerOrderOfferLineEditApi_FormInitData): DeepPartial<FormValues> {
		if (offerLine != null)
			return {
				...offerLine,
				salesPart: salesPart ?? undefined,
				unitPrice: offer.inputPricesWithVat ? offerLine.unitPriceWithVat : offerLine.unitPrice,
			};
		else
			return {
				customerOrderOfferId: customerOrderOfferId,
				parentLineId: parentLineId,
				lineNumber: defaultLineNumber,
				plannedDeliveryDate: offer.plannedDeliveryDate,
				salesQuantityAsSalesUnits: 1,
				discountPercentage: 0,
				vatCodeId: parentLine?.vatCodeId ?? getDefaultVatCode(vatCodeOptions)?.vatCodeId,
				priceLocked: false,
				costLocked: false,
				isAdjustment: false,
			};
	}

	async function submitForm(values: FormValues) {
		const offerLine = mapFormValuesToCustomerOfferLine(values);
		if (customerOrderOfferLineId != null) {
			await CustomerOrderOfferLineEditApi.update({
				offerLine: offerLine,
			});
			return customerOrderOfferLineId;
		} else {
			return await CustomerOrderOfferLineEditApi.insert({
				offerLine: offerLine,
			});
		}
	}

	async function configureAndInsert(values: FormValues): Promise<FormSubmitResult<number>> {
		const customerOfferLine = mapFormValuesToCustomerOfferLine(values);

		const configuratorType = createUnsavedCustomerOfferLineConfiguratorType(customerOfferLine);
		const dialogTitle = await getConfiguratorFormDialogTitle({configuratorType: configuratorType});
		const partConfigurationId = await showAsyncDialog<number>(openDialog, ({ onCompleted }) => ({
			title: dialogTitle,
			content: (
				<CustomerOfferLineBeforeCreateConfiguratorView
					configuratorType={configuratorType}
					onCompleted={onCompleted}
				/>
			),
		}));

		if (partConfigurationId === undefined) return "interrupted";

		return await CustomerOrderOfferLineEditApi.insert({
			offerLine: {
				...customerOfferLine,
				partConfigurationId: partConfigurationId,
			},
		});
	}

	function mapFormValuesToCustomerOfferLine(values: FormValues): CustomerOrderOfferLine {
		return {
			...values,
			salesPartId: values.salesPart.salesPartId,
		};
	}
};

interface FormContentProps
	extends CustomerOrderOfferLineFormProps,
		AsyncFormContentParams<CustomerOrderOfferLineEditApi_FormInitData, FormValues> {}

const FormContent = ({
	parentLineId,
	control,
	watch,
	setValue,
	getValues,
	data: { offerLine, offer, vatCodeOptions },
}: FormContentProps) => {
	const { logErrorAndShowOnDialog } = useErrorDialog();
	const { openDialog } = useGenericDialog();

	const isPackageChildLine = parentLineId != null;
	const isExistingRecord = offerLine != null;

	const salesPart = watch("salesPart");
	const salesQuantityAsSalesUnits = watch("salesQuantityAsSalesUnits");
	const acquisitionMethod = salesPart?.acquisitionMethod;
	const userPermissions = useUserPermissions();
	const onlySalespersonOwnOfferData = userPermissions.sales.onlySalespersonOwnOfferData;

	return (
		<>
			<FormAsyncSelectField
				control={control}
				name={"salesPart"}
				formValueType="option"
				label={i18n.t("sales_part")}
				rules={requireRule()}
				fetchOptions={({ searchQuery, currentSelection }) =>
					CustomerOrderOfferLineEditApi.getSalesPartOptions({
						customerOfferId: offer.customerOrderOfferId,
						searchQuery: searchQuery,
						currentSelection: currentSelection,
					})
				}
				getOptionKey={(option: SalesPartView) => option.salesPartId}
				getOptionLabel={(option) => `${option.salesPartNo} | ${option.salesPartDescription}`}
				onChange={onSalesPartChanged}
				disabled={isExistingRecord}
				spanGridColumns
			/>
			<FormTextField control={control} name={"mark"} label={i18n.t("mark")} spanGridColumns />
			{getSalesQuantityField()}
			{!isPackageChildLine && (
				<>
					<FormDateField
						control={control}
						name={"plannedDeliveryDate"}
						label={i18n.t("planned_delivery_date")}
						onChange={async (newPlannedDeliveryDate) => {
							await resetUnitPrice({
								plannedDeliveryDate: dayJsToDateIsoStringNullable(newPlannedDeliveryDate),
							});
						}}
					/>
					{getUnitPriceField()}
					<FormCheckbox control={control} name={"priceLocked"} label={i18n.t("price_locked")} />
					<FormNumberField
						control={control}
						name={"discountPercentage"}
						label={i18n.t("discount_percentage")}
					/>
				</>
			)}
			{!isPackageChildLine && offer.vatHandling !== "REVERSE_CHARGE_VAT" && (
				<FormSelectField
					control={control}
					name={"vatCodeId"}
					label={i18n.t("vat_code")}
					options={vatCodeOptions}
					getOptionKey={(vatCode) => vatCode.vatCodeId}
					getOptionLabel={(vatCode) => vatCode.vatCodeName}
					rules={requireRule()}
					startNewGridRow
					onChange={async (vatCodeId) => {
						await resetUnitPrice({ vatCodeId });
					}}
				/>
			)}
			{salesPart?.salesPartType !== "PACKAGE_" && !onlySalespersonOwnOfferData && (
				<>
					<FormNumberField control={control} name={"unitCost"} label={i18n.t("cost")} startNewGridRow />
					<FormCheckbox control={control} name={"costLocked"} label={i18n.t("cost_locked")} />
				</>
			)}
			{acquisitionMethod === "MANUFACTURE" && (
				<>
					{!onlySalespersonOwnOfferData && (
						<FormTextField
							control={control}
							name={"shopOrderBatchCode"}
							label={i18n.t("shop_order_batch")}
							sx={{
								visibility: salesPart?.acquisitionMethod === "MANUFACTURE" ? "visible" : "hidden",
							}}
						/>
					)}
					<FormNumberField control={control} name={"capacityQuantity"} label={i18n.t("capacity_quantity")} />
				</>
			)}
			<FormNumberField
				control={control}
				name={"lineNumber"}
				label={i18n.t("line_number")}
				type={"integer"}
				rules={requireRule()}
			/>
			<FormCheckbox
				control={control}
				name={"isAdjustment"}
				label={i18n.t("is_adjustment_line")}
				helperText={i18n.t("is_adjustment_line_explanation")}
				startNewGridRow
				disabled={isExistingRecord}
			/>
			{offerLine != null && offerLine.salesPartType === "PACKAGE_" && (
				<AavoButton
					label={i18n.t("package_lines")}
					icon={faCubes}
					onClick={() => {
						openDialog(() => ({
							title: i18n.t("package_lines"),
							size: "xl",
							content: (
								<CustomerOrderOfferLinesDataGrid
									customerOrderOfferId={offerLine.customerOrderOfferId}
									parentLineId={offerLine.customerOrderOfferLineId}
								/>
							),
						}));
					}}
				/>
			)}
		</>
	);

	function getSalesQuantityField() {
		const labelSalesUnitPart = salesPart?.salesUnit != null ? ` (${salesPart.salesUnit})` : "";
		const label = `${i18n.t("quantity")}${labelSalesUnitPart}`;

		return (
			<FormNumberField
				control={control}
				name={"salesQuantityAsSalesUnits"}
				label={label}
				rules={requireRule()}
				onSubmit={(value) => {
					setValue("capacityQuantity", getDefaultCapacityQuantity(salesPart, value));
				}}
			/>
		);
	}

	function getUnitPriceField() {
		const labelVatPart = offer.inputPricesWithVat ? i18n.t("with_vat") : i18n.t("without_vat");
		const labelSalesUnitPart = salesPart?.salesUnit != null ? ` (${salesPart.salesUnit})` : "";
		const label = `${i18n.t("unit_price")}${labelSalesUnitPart}, ${labelVatPart}`;
		return (
			<FormNumberField control={control} name={"unitPrice"} label={label} rules={requireRule()} startNewGridRow />
		);
	}

	async function onSalesPartChanged(salesPart: SalesPartView | null) {
		try {
			const vatCodeId = getDefaultVatCodeId(salesPart);
			await resetUnitPrice({
				salesPart,
				vatCodeId,
			});
			setValue("vatCodeId", vatCodeId);
			setValue("isAdjustment", salesPart?.isAdjustment ?? false);
			setValue("capacityQuantity", getDefaultCapacityQuantity(salesPart, salesQuantityAsSalesUnits));
			setValue(
				"unitCost",
				salesPart == null || salesPart.standardCost == null ?
					0
				:	round(salesPart.standardCost * salesPart.salesFactor, 2),
			);
		} catch (e) {
			logErrorAndShowOnDialog(e);
		}
	}

	async function resetUnitPrice({
		salesPart = getValues("salesPart"),
		vatCodeId = getValues("vatCodeId"),
		plannedDeliveryDate = getValues("plannedDeliveryDate"),
	}: {
		salesPart?: SalesPartView | null;
		vatCodeId?: number | null;
		plannedDeliveryDate?: IsoDateString | null;
	}) {
		if (getValues("priceLocked") === true) return;

		let defaultUnitPrice = 0;
		if (salesPart != null && vatCodeId != null) {
			defaultUnitPrice = await CustomerOrderOfferLineEditApi.getSalesPartDefaultPrice({
				salesPartId: salesPart.salesPartId,
				customerOfferId: offer.customerOrderOfferId,
				withVat: offer.inputPricesWithVat,
				vatCodeId: vatCodeId,
				plannedDeliveryDate: plannedDeliveryDate ?? offer.plannedDeliveryDate,
			});
		}
		setValue("unitPrice", defaultUnitPrice);
	}

	function getDefaultVatCodeId(salesPart: SalesPartView | null): number {
		if (salesPart != null) return salesPart.vatCodeId;

		return getDefaultVatCode(vatCodeOptions).vatCodeId;
	}

	function getDefaultCapacityQuantity(salesPart: SalesPartView | null, salesQuantityAsSalesUnits: number | null) {
		if (salesPart == null || salesPart.capacityQuantity == null || salesQuantityAsSalesUnits == null) return 0;

		return salesPart.capacityQuantity * salesPart.salesFactor * salesQuantityAsSalesUnits;
	}
};
