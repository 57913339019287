import { ProcessImprovementsDataGrid } from "src/components/views/spc/processImprovement/ProcessImprovementsDataGrid.tsx";
import { ProcessImprovementView } from "src/api/generated/postgres/db/types/public_/tables/processImprovementView.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faLink } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { ProcessImprovementEditApi } from "src/api/generated/spc/processImprovement/processImprovementEditApi.ts";

export interface ConnectProcessImprovementToRawRecordViewProps {
	rawRecordId: number;
	onSubmit: () => Promise<void>;
}

export const ConnectProcessImprovementToRawRecordView = ({
	rawRecordId,
	onSubmit,
}: ConnectProcessImprovementToRawRecordViewProps) => {
	return (
		<ProcessImprovementsDataGrid
			actionBarExtraComponents={(onlySelectedRow: ProcessImprovementView | undefined) => {
				return (
					<>
						<AsyncButton
							icon={faLink}
							label={i18n.t("link")}
							disabled={onlySelectedRow == null}
							variant={"outlined"}
							onClick={async () => {
								if (onlySelectedRow == null) return;
								await ProcessImprovementEditApi.joinProcessImprovementToRawRecord({
									processImprovementId: onlySelectedRow?.id,
									rawRecordId: rawRecordId,
								});
								await onSubmit();
							}}
						/>
					</>
				);
			}}
		/>
	);
};
