import {
	ConfiguratorInput,
	ConfiguratorInput_InputValue,
} from "src/api/generated/erp/configurator/model/configuratorInput.ts";
import i18n from "i18next";
import { filterNulls } from "src/utils/arrayUtils.ts";
import { CONFIGURATOR_INPUT_SCALAR_NULL_VALUE } from "src/components/views/erp/configurator/configuratorUtils.ts";
import { TransformedConfigurationComponent } from "src/api/generated/erp/configurator/componentTransformation/model/transformedConfigurationComponent.ts";

export function getConfiguratorFormErrors(
	components: TransformedConfigurationComponent[],
	input: ConfiguratorInput,
): string[] {
	return filterNulls(
		components.map((component) => {
			const value =
				input.inputComponentValues.find(({ componentName }) => componentName === component.name)?.value ??
				CONFIGURATOR_INPUT_SCALAR_NULL_VALUE;

			return getConfiguratorComponentErrorMessage(component, value);
		}),
	);
}

export function getConfiguratorComponentErrorMessage(
	component: TransformedConfigurationComponent,
	value: ConfiguratorInput_InputValue,
): string | undefined {
	if (component.type !== "field") return undefined;

	if (component.error != null && component.error.trim() !== "") {
		return component.error;
	}

	if (component.required && component.visible) {
		if (value.type !== "SCALAR" || value.propertyValue.type == "null" || value.propertyValue.value === "") {
			return i18n.t("configurator_component_error_required", { label: component.label });
		}
	}

	return undefined;
}

