import { ConfigurationComponent } from "src/api/generated/erp/db/types/tables/configurationComponent.ts";
import { AavoTextField, AavoTextFieldProps } from "src/components/common/inputFields/AavoTextField.tsx";
import { AavoCheckbox } from "src/components/common/inputFields/AavoCheckbox.tsx";
import { SelectField } from "src/components/common/inputFields/SelectField.tsx";
import {
	configuratorComponentIndentToMargin, configurationPropertyValueToString
} from "src/components/views/erp/configurator/configuratorUtils.ts";
import { AavoFileInput } from "src/components/common/inputFields/fileInput/AavoFileInput.tsx";

export interface ConfiguratorFieldComponentPreviewProps {
	component: ConfigurationComponent;
}

export const ConfiguratorFieldComponentPreview = (props: ConfiguratorFieldComponentPreviewProps) => {
	const { component } = props;

	switch (component.fieldType) {
		case "TEXT":
		case "TEXTAREA":
			return <RenderTextField {...props} multiline={component.fieldType === "TEXTAREA"} />;
		case "INTEGER":
		case "DECIMAL":
			return <RenderTextField {...props} type={"number"} />;
		case "CHECKBOX":
			return <RenderCheckbox {...props} />;
		case "SELECTION":
			return <RenderSelectField {...props} />;
		case "DOCUMENT":
			return <RenderDocumentField {...props} />;
	}
};

interface RenderTextFieldProps
	extends ConfiguratorFieldComponentPreviewProps,
		Pick<AavoTextFieldProps, "multiline" | "type"> {}

const RenderTextField = ({ component, multiline, type }: RenderTextFieldProps) => {
	return (
		<AavoTextField
			label={getLabelWithRequiredMark(component)}
			value={configurationPropertyValueToString(component.fieldDefaultValue)}
			multiline={multiline}
			type={type}
			sx={{
				flex: 1,
				marginLeft: configuratorComponentIndentToMargin(component.indent),
			}}
		/>
	);
};

const RenderCheckbox = ({ component }: ConfiguratorFieldComponentPreviewProps) => {
	return (
		<AavoCheckbox
			label={getLabelWithRequiredMark(component)}
			checked={component.fieldDefaultValue?.type === "bool" ? component.fieldDefaultValue.value : false}
			sx={{
				paddingRight: 1,
			}}
		/>
	);
};

const RenderSelectField = ({ component }: ConfiguratorFieldComponentPreviewProps) => {
	return (
		<SelectField
			label={getLabelWithRequiredMark(component)}
			value={configurationPropertyValueToString(component.fieldDefaultValue)}
			options={component.fieldSelectionOptions}
			getOptionLabel={(option) => option.label}
			getOptionKey={(option) => option.key}
			sx={{
				flex: 1,
			}}
		/>
	);
};

const RenderDocumentField = ({ component }: ConfiguratorFieldComponentPreviewProps) => {
	return (
		<AavoFileInput
			label={getLabelWithRequiredMark(component)}
			value={null}
			disabled={true}
			sx={{
				flex: 1,
			}}
		/>
	);
};

function getLabelWithRequiredMark(component: ConfigurationComponent): string {
	return component.label + (component.fieldRequired ? " *" : "");
}
