// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const DefectPerformanceMetricValues = ["YIELD", "DPU"] as const;

export type DefectPerformanceMetric = (typeof DefectPerformanceMetricValues)[number];

export const getDefectPerformanceMetricLabels = () => ({
	YIELD: t("yield"),
	DPU: t("dpu"),
});

export const getDefectPerformanceMetricLabel = (value: DefectPerformanceMetric): string => {
	return getDefectPerformanceMetricLabels()[value];
};
