// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const CostEventObjectTypeValues = ["RECLAMATION", "PROJECT_ACTIVITY", "SHOP_ORDER_OPERATION"] as const;

export type CostEventObjectType = (typeof CostEventObjectTypeValues)[number];

export const getCostEventObjectTypeLabels = () => ({
	RECLAMATION: t("reclamation"),
	PROJECT_ACTIVITY: t("project_activity"),
	SHOP_ORDER_OPERATION: t("shop_order_operation"),
});

export const getCostEventObjectTypeLabel = (value: CostEventObjectType): string => {
	return getCostEventObjectTypeLabels()[value];
};
