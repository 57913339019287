import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {
    DefectPerformanceChartLineApi
} from "src/api/generated/spc/defectPerformanceChart/defectPerformanceChartLineApi.ts";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {textColumn} from "src/components/common/dataGrid/columns.tsx";
import {ChartGroupView} from "src/api/generated/postgres/db/types/public_/tables/chartGroupView.ts";
import i18n from "i18next";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {faLink} from "@fortawesome/pro-regular-svg-icons";

export interface DefectPerformanceChartConnectableDefectGroupsDataGridProps {
	defectPerformanceChartId: number;
	closeDialog: () => Promise<void>;
}

export const DefectPerformanceChartConnectableDefectGroupsDataGrid = ({
	defectPerformanceChartId,
	closeDialog,
}: DefectPerformanceChartConnectableDefectGroupsDataGridProps) => {
	return (
		<ClientSideDataGridModel
			gridId={"5F99A87B473D0181"}
			fetchData={() =>
				DefectPerformanceChartLineApi.getConnectableDefectGroups({
					defectPerformanceChartId: defectPerformanceChartId,
				})
			}
			getRows={(data) => data}
			getRowId={(row) => row.chartGroupId}
			initialParams={{}}
			render={({ dataGridProps, selectedRows }) => {
				return (
					<CrudDataGrid<ChartGroupView>
						checkboxSelection
						columns={[
							textColumn({
								field: "parentGroupName",
								headerName: i18n.t("parent_group"),
							}),
							textColumn({
								field: "name",
								headerName: i18n.t("defect_group"),
							}),
						]}
						actionBarComponents={
							<>
								<AsyncButton
									icon={faLink}
									variant={"outlined"}
									label={i18n.t("connect")}
									onClick={async () => {
										await DefectPerformanceChartLineApi.connectDefectGroupsToDefectPerformanceChart(
											{
												defectPerformanceChartId: defectPerformanceChartId,
												defectGroupIds: selectedRows.map((row) => row.chartGroupId),
											},
										);
										await closeDialog();
									}}
								/>
							</>
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
