import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm";
import { FormCommonProps } from "src/components/common/forms/types";
import {
	SupplierPartEditApi,
	SupplierPartEditApi_FormInitData,
} from "src/api/generated/erp/purchase/suppliers/api/supplierPartEditApi.ts";
import { DeepPartial } from "react-hook-form";
import { SupplierPart } from "src/api/generated/erp/db/types/tables/supplierPart.ts";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import i18n from "i18next";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField";
import { requireRule } from "src/components/common/forms/validation.ts";

export interface SupplierPartFormProps extends FormCommonProps<number> {
	supplierPartId: number;
}

interface FormValues extends SupplierPart {}

export const SupplierPartForm = (props: SupplierPartFormProps) => {
	const { supplierPartId, onCompleted, onFormEdited } = props;

	return (
		<AsyncForm
			fetch={() => SupplierPartEditApi.getFormInitData({ supplierPartId })}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues({ supplierPart }: SupplierPartEditApi_FormInitData): DeepPartial<FormValues> {
		return supplierPart;
	}

	async function submit(values: FormValues) {
		await SupplierPartEditApi.update({ supplierPart: values });
		return supplierPartId;
	}
};

interface FormContentProps
	extends SupplierPartFormProps,
		AsyncFormContentParams<SupplierPartEditApi_FormInitData, FormValues> {}

const FormContent = ({ control, data: { erpUnitOptions } }: FormContentProps) => {
	return (
		<>
			<FormTextField control={control} name={"supplierPartNo"} label={i18n.t("supplier_part_no")} />
			<FormTextField
				control={control}
				name={"supplierPartDescription"}
				label={i18n.t("supplier_part_description")}
			/>
			<FormSelectField
				control={control}
				name={"purchaseUnitId"}
				getOptionKey={(option) => option.unitId}
				getOptionLabel={(option) => option.unitName}
				label={i18n.t("purchase_unit")}
				options={erpUnitOptions}
			/>
			<FormNumberField
				control={control}
				name={"purchaseFactor"}
				label={i18n.t("purchase_factor")}
				rules={requireRule()}
			/>
			<FormSelectField
				control={control}
				name={"purchasePriceUnitId"}
				getOptionKey={(option) => option.unitId}
				getOptionLabel={(option) => option.unitName}
				label={i18n.t("price_unit")}
				options={erpUnitOptions}
			/>
			<FormNumberField
				control={control}
				name={"purchasePriceFactor"}
				label={i18n.t("price_factor")}
				rules={requireRule()}
			/>
			<FormNumberField
				control={control}
				name={"purchasePrice"}
				label={i18n.t("purchase_price")}
				rules={requireRule()}
			/>
			<FormNumberField control={control} name={"packSize"} label={i18n.t("pack_size")} rules={requireRule()} />
			<FormNumberField control={control} name={"deliveryDuration"} label={i18n.t("delivery_duration_days")} />
		</>
	);
};
