import { DeliveriesDataGrid } from "../../delivery/delivery/DeliveriesDataGrid.tsx";

export const AllCustomerOrderDeliveriesDataGrid = () => {
	return (
		<DeliveriesDataGrid
			gridId={"F87A8E4E5856D928"}
			deliverySourceType={"CUSTOMER_ORDER"}
			showSearchField
			showOnlyUndeliveredByDefault
		/>
	);
};
