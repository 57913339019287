import i18n from "i18next";
import { useUserPermissions } from "src/components/views/erp/common/userPermissions.ts";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { Fragment } from "react";
import { Typography } from "@mui/material";
import { CustomerOrderSummary } from "src/api/generated/erp/sales/customerOrder/service/customerOrderSummary.ts";
import { formatMoney, formatNumber } from "src/utils/numberUtils.ts";
import { keepOnlyObjects } from "src/utils/arrayUtils.ts";
import { useTenantCustomizations } from "src/tenantCustomizations/TenantCustomizationsContext.ts";
import { CustomerOfferSummary } from "src/api/generated/erp/sales/customerOrderOffer/service/customerOfferSummary.ts";

export interface CustomerOrderOrOfferSummaryTextProps {
	objectType: "CUSTOMER_ORDER" | "CUSTOMER_OFFER";
	summary: CustomerOrderSummary | CustomerOfferSummary;
}

export interface CustomerOrderOrOfferSummaryField {
	label: string;
	value: string;
}

export const CustomerOrderOrOfferSummaryText = ({ objectType, summary }: CustomerOrderOrOfferSummaryTextProps) => {
	const userPermissions = useUserPermissions();
	const tenantCustomizations = useTenantCustomizations();

	const onlySalespersonOwnOfferData = userPermissions.sales.onlySalespersonOwnOfferData;

	const tenantFields =
		objectType === "CUSTOMER_ORDER" ?
			tenantCustomizations.erp?.sales?.customerOrderSummaryExtraFields?.(summary)
		:	tenantCustomizations.erp?.sales?.customerOfferSummaryExtraFields?.(summary);

	const fields = [
		{ label: i18n.t("sum"), value: formatMoney(summary.totalPrice) },
		{
			label: i18n.t("sum_with_vat"),
			value: formatMoney(summary.totalPriceWithVat),
		},
		!onlySalespersonOwnOfferData && {
			label: i18n.t("cost"),
			value: formatMoney(summary.totalCost),
		},
		!onlySalespersonOwnOfferData && {
			label: i18n.t("gross_margin"),
			value: `${formatNumber(summary.grossMargin * 100, { minDigits: 2, maxDigits: 2 })} %`,
		},
		{
			label: i18n.t("capacity_quantity"),
			value: `${formatNumber(summary.totalCapacityQuantity)}`,
		},
		...(tenantFields ?? []),
	];

	return (
		<HorizontalBox
			sx={{
				gap: "0.25rem",
				alignSelf: "end",
				flexWrap: "wrap",
			}}
		>
			{keepOnlyObjects(fields).map((field, index) => (
				<Fragment key={index}>
					<Typography
						sx={{
							fontStyle: "italic",
							marginLeft: "0.75rem",
						}}
					>{`${field.label}: `}</Typography>
					<Typography>{field.value}</Typography>
				</Fragment>
			))}
		</HorizontalBox>
	);
};
