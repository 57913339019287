import {
	CustomerOrderDataGridApi,
	CustomerOrderDataGridApi_CustomerOrderDto,
} from "src/api/generated/erp/sales/customerOrder/api/customerOrderDataGridApi.ts";
import {useServerSideDataGridModel} from "../../../../common/dataGrid/gridModel/useServerSideDataGridModel";
import {ControlledAsyncCrudDataGrid} from "../../../../common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import {
	booleanColumn,
	dateColumn,
	dateTimeColumn,
	enumColumn,
	integerColumn,
	textColumn,
} from "../../../../common/dataGrid/columns.tsx";
import i18n from "i18next";
import {
	CustomerOrderState,
	getCustomerOrderStateLabels,
} from "src/api/generated/erp/db/types/enums/customerOrderState.ts";
import {useTenantCustomizations} from "src/tenantCustomizations/TenantCustomizationsContext.ts";
import {associate} from "src/utils/arrayUtils.ts";
import {genericNullableValue} from "src/utils/genericNullableValue.ts";
import {CustomerOrdersDataGridActionBarComponents} from "./CustomerOrdersDataGridActionBarComponents.tsx";
import {CustomerOrdersDataGridContextMenuComponents} from "./CustomerOrdersDataGridContextMenuComponents.tsx";
import {dataGridRowClassNames} from "../../../../common/dataGrid/styles/dataGridClassNames.ts";
import {CustomerOrderForm} from "./CustomerOrderForm.tsx";
import {CustomerOrdersDataGridActionBarMenuComponents} from "./CustomerOrdersDataGridActionBarMenuComponents.tsx";
import {useContext} from "react";
import {CustomerOrdersPageContext} from "./CustomerOrdersPageContext.tsx";
import {getVatHandlingLabels} from "src/api/generated/erp/db/types/enums/vatHandling.ts";
import {CustomerOrderActionApi} from "src/api/generated/erp/sales/customerOrder/api/customerOrderActionApi.ts";

export interface CustomerOrdersDataGridProps {
	onlyCustomerOrderToShowId?: number;
	selectedCustomerOrder: CustomerOrderDataGridApi_CustomerOrderDto | undefined;
	setSelectedCustomerOrder: (customerOrder: CustomerOrderDataGridApi_CustomerOrderDto | undefined) => void;
}

export interface CustomerOrdersDataGridFilterParams {
	stateFilter?: Array<CustomerOrderState>;
	searchQuery?: string;
	onlyDefaultSiteOrders?: boolean;
	showInvoicedOrders?: boolean;
	showCancelledOrders?: boolean;
	tenantStateFilter?: string | null | undefined;
	responsiblePersonId?: number | null | undefined;
	showOnlyUnconfirmedOrders?: boolean;
	showOnlyFrozenOrders?: boolean;
	customerOrderTypeId?: number | null | undefined;
}

export const CustomerOrdersDataGrid = ({
	onlyCustomerOrderToShowId,
	selectedCustomerOrder,
	setSelectedCustomerOrder,
}: CustomerOrdersDataGridProps) => {
	const customerOrdersPageContext = useContext(CustomerOrdersPageContext);
	const refreshRef = customerOrdersPageContext?.customerOrdersRefreshRef;
	const customerOrderLinesRefreshRef = customerOrdersPageContext?.customerOrderLinesRefreshRef;
	const tasksViewRefreshRef = customerOrdersPageContext?.tasksViewRefreshRef;
	const billingPlanViewRefreshRef = customerOrdersPageContext?.billingPlanViewRefreshRef;
	const salesCommissionViewRefreshRef = customerOrdersPageContext?.salesCommissionViewRefreshRef;

	const tenantCustomizations = useTenantCustomizations();
	const tenantConfig = tenantCustomizations.tenantConfig;
	const salesCustomizations = tenantCustomizations.erp?.sales;

	const { dataGridProps, refreshData, currentParams } = useServerSideDataGridModel<
		CustomerOrderDataGridApi_CustomerOrderDto,
		CustomerOrdersDataGridFilterParams
	>({
		gridId: "283BE47D04FA5DD6",
		fetchData: (params) =>
			CustomerOrderDataGridApi.searchCustomerOrders({
				customerOrderIdFilter: onlyCustomerOrderToShowId,
				...params,
			}),
		refreshRef: refreshRef,
		getRowId: (row) => row.customerOrderId,
		initialParams: {
			searchQuery: "",
			stateFilter: Array<CustomerOrderState>(),
			tenantStateFilter: genericNullableValue<string>(),
			responsiblePersonId: genericNullableValue<number>(),
			customerOrderTypeId: genericNullableValue<number>(),
			onlyDefaultSiteOrders: true,
			showOnlyUnconfirmedOrders: false,
			showOnlyFrozenOrders: false,
			showInvoicedOrders: false,
			showCancelledOrders: false,
		},
		storedParams: ["showInvoicedOrders"],
		onSelectionChanged: (rows) => setSelectedCustomerOrder(rows[0]),
		selectFirstRowOnLoad: onlyCustomerOrderToShowId != null,
	});
	const tenantStateLabelsByKeys = associate(
		tenantConfig.erp.customerOrderTenantStates,
		(state) => state.key,
		(state) => state.label,
	);

	return (
		<ControlledAsyncCrudDataGrid<CustomerOrderDataGridApi_CustomerOrderDto>
			disableMultipleRowSelection
			remove={
				selectedCustomerOrder != null && selectedCustomerOrder.customerOrderState == "INITIAL" ?
					{
						type: "enabled",
						action: async () =>
							await CustomerOrderActionApi.deleteCustomerOrder({
								customerOrderId: selectedCustomerOrder.customerOrderId,
							}),
					}
				:	{ type: "hidden" }
			}
			getRowClassNames={({ row }) => {
				if (!row.billingPlanIsComplete) {
					return dataGridRowClassNames.invalid;
				}
				if (row.frozenAt != null) {
					return dataGridRowClassNames.yellow;
				}
			}}
			columns={[
				integerColumn({
					field: "customerOrderId",
					headerName: i18n.t("number_shortened"),
				}),
				textColumn({
					field: "siteName",
					headerName: i18n.t("site_short"),
				}),
				textColumn({
					field: "customerName",
					headerName: i18n.t("customer"),
					width: 150,
				}),
				textColumn({
					field: "orderReference",
					headerName: i18n.t("reference"),
					width: 150,
				}),
				textColumn({
					field: "customerPoNo",
					headerName: i18n.t("customer_po_no"),
				}),
				enumColumn({
					field: "customerOrderState",
					headerName: i18n.t("state"),
					enumLabels: getCustomerOrderStateLabels(),
				}),
				tenantConfig.erp.useCustomerOrderTenantStates &&
					textColumn({
						headerName: tenantConfig.erp.customerOrderTenantStateColumnLabel,
						field: "tenantState",
						valueGetter: (_, row) => tenantStateLabelsByKeys[row.tenantState ?? ""] ?? "-",
					}),
				dateColumn({
					field: "plannedDeliveryDate",
					headerName: i18n.t("planned_delivery_date_short"),
				}),
				textColumn({
					field: "deliveryCity",
					headerName: i18n.t("delivery_city"),
				}),
				textColumn({
					field: "deliveryAddress_1",
					headerName: i18n.t("delivery_address"),
				}),
				textColumn({
					field: "responsiblePersonName",
					headerName: i18n.t("responsible_person"),
				}),
				textColumn({
					field: "salespersonName",
					headerName: i18n.t("salesperson"),
				}),
				tenantConfig.erp.customerOrderCheckingEnabled &&
					dateColumn({
						field: "checkedAt",
						headerName: i18n.t("checked_at"),
					}),
				dateColumn({
					field: "confirmedAt",
					headerName: i18n.t("confirmed_at"),
				}),
				tenantConfig.erp.customerOrderFreezingEnabled &&
					dateColumn({
						field: "frozenAt",
						headerName: i18n.t("frozen_at"),
					}),
				textColumn({
					field: "contactName",
					headerName: i18n.t("contact_person"),
				}),
				textColumn({
					field: "externalOrderId",
					headerName: i18n.t("external_order_id"),
				}),
				textColumn({
					field: "deliveryTermsCode",
					headerName: i18n.t("delivery_terms"),
				}),
				textColumn({
					field: "deliveryMethodCode",
					headerName: i18n.t("delivery_method"),
				}),
				textColumn({
					field: "deliveryTermsDestination",
					headerName: i18n.t("delivery_terms_destination"),
				}),
				booleanColumn({
					field: "capacityReservation",
					headerName: i18n.t("capacity_reservation"),
				}),
				integerColumn({
					field: "transportDuration",
					headerName: i18n.t("transport_duration"),
				}),
				textColumn({
					field: "transportRouteName",
					headerName: i18n.t("transport_route"),
				}),
				textColumn({
					field: "paymentTermDesc",
					headerName: i18n.t("payment_term"),
				}),
				enumColumn({
					field: "vatHandling",
					headerName: i18n.t("vat_handling"),
					enumLabels: getVatHandlingLabels(),
				}),
				booleanColumn({
					field: "billingPlanEnabled",
					headerName: i18n.t("billing_plan"),
				}),
				textColumn({
					field: "createdByUserName",
					headerName: i18n.t("created_by"),
				}),
				dateTimeColumn({
					field: "createdDate",
					headerName: i18n.t("created_at"),
				}),
				textColumn({
					field: "customerOrderTypeName",
					headerName: i18n.t("customer_order_type"),
				}),
				...(salesCustomizations?.customerOrderDataGridCustomColumns?.() ?? []),
			]}
			actionBarComponents={
				<CustomerOrdersDataGridActionBarComponents
					selectedCustomerOrder={selectedCustomerOrder}
					refreshData={refreshData}
				/>
			}
			actionBarMenuComponents={
				<CustomerOrdersDataGridActionBarMenuComponents
					key={"actionBarMenuComponentsWrapper"}
					refreshData={refreshData}
					currentParams={currentParams}
				/>
			}
			rowContextMenuComponents={(params) => (
				<CustomerOrdersDataGridContextMenuComponents
					key={"rowContextMenuComponentsWrapper"}
					refreshData={refreshData}
					{...params}
				/>
			)}
			form={{
				dialogSize: "lg",
				dialogTitle: i18n.t("customer_order"),
				addRowEnabled: true,
				editEnabled: true,
				component: ({ row, onCompleted, onFormEdited }) => {
					return (
						<CustomerOrderForm
							customerOrderId={row?.customerOrderId}
							onFormEdited={onFormEdited}
							onCompleted={(result) => {
								onCompleted(result);
								if (result.type === "success") {
									customerOrderLinesRefreshRef?.refresh();
									tasksViewRefreshRef?.refresh();
									billingPlanViewRefreshRef?.refresh();
									salesCommissionViewRefreshRef?.refresh();
								}
							}}
						/>
					);
				},
			}}
			{...dataGridProps}
		/>
	);
};
