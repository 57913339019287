import { CustomerOrderLinePartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/impl/customerOrderLinePartConfiguratorType";
import { UnsavedCustomerOfferLinePartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/impl/unsavedCustomerOfferLinePartConfiguratorType";
import { CustomerOrderOfferLine } from "src/api/generated/erp/db/types/tables/customerOrderOfferLine";
import { CustomerOrderLine } from "src/api/generated/erp/db/types/tables/customerOrderLine.ts";
import { UnsavedCustomerOrderLinePartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/impl/unsavedCustomerOrderLinePartConfiguratorType.ts";
import { ShopOrderLinePartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/impl/shopOrderLinePartConfiguratorType.ts";
import {
	PurchaseOrderLinePartConfiguratorType
} from "src/api/generated/erp/configurator/configuratorType/impl/purchaseOrderLinePartConfiguratorType.ts";
import {
	CustomerOrderOfferLinePartConfiguratorType
} from "src/api/generated/erp/configurator/configuratorType/impl/customerOrderOfferLinePartConfiguratorType.ts";
import {
	ProjectActivityMaterialPartConfiguratorType
} from "src/api/generated/erp/configurator/configuratorType/impl/projectActivityMaterialPartConfiguratorType.ts";

export function createCustomerOrderLineConfiguratorType(
	customerOrderLineId: number,
): CustomerOrderLinePartConfiguratorType {
	return {
		type: "customerOrderLine",
		customerOrderLineId: customerOrderLineId,
		isTestConfigurator: false,
		isSubConfigurator: false,
		explicitPartConfigurationId: null,
	};
}

export function createCustomerOfferLineConfiguratorType(
	customerOfferLineId: number,
): CustomerOrderOfferLinePartConfiguratorType {
	return {
		type: "customerOrderOfferLine",
		customerOrderOfferLineId: customerOfferLineId,
		isTestConfigurator: false,
		isSubConfigurator: false,
		explicitPartConfigurationId: null,
	};
}

export function createUnsavedCustomerOrderLineConfiguratorType(
	{ customerOrderLine, initialValuesFromConfigurationId }: {
		customerOrderLine: CustomerOrderLine,
		initialValuesFromConfigurationId: number | undefined
	},
): UnsavedCustomerOrderLinePartConfiguratorType {
	return {
		type: "unsavedCustomerOrderLine",
		customerOrderId: customerOrderLine.customerOrderId,
		plannedDeliveryDate: customerOrderLine.plannedDeliveryDate,
		salesPartId: customerOrderLine.salesPartId,
		salesQuantityAsSalesUnits: customerOrderLine.salesQuantityAsSalesUnits,
		shopOrderBatchCode: customerOrderLine.shopOrderBatchCode,
		initialValuesFromConfigurationId: initialValuesFromConfigurationId,
		isTestConfigurator: false,
		isSubConfigurator: false,
	};
}

export function createUnsavedCustomerOfferLineConfiguratorType(
	customerOfferLine: CustomerOrderOfferLine,
): UnsavedCustomerOfferLinePartConfiguratorType {
	return {
		type: "unsavedCustomerOfferLine",
		customerOfferId: customerOfferLine.customerOrderOfferId,
		plannedDeliveryDate: customerOfferLine.plannedDeliveryDate,
		salesPartId: customerOfferLine.salesPartId,
		salesQuantityAsSalesUnits: customerOfferLine.salesQuantityAsSalesUnits,
		shopOrderBatchCode: customerOfferLine.shopOrderBatchCode,
		isTestConfigurator: false,
		isSubConfigurator: false,
	};
}

export function createProjectActivityMaterialPartConfiguratorType(
	activityMaterialLineId: number,
): ProjectActivityMaterialPartConfiguratorType {
	return {
		type: "projectActivityMaterial",
		activityMaterialLineId: activityMaterialLineId,
		isTestConfigurator: false,
		isSubConfigurator: false,
		explicitPartConfigurationId: null,
	};
}

export function createPurchaseOrderLinePartConfiguratorType(
	purchaseOrderLineId: number,
): PurchaseOrderLinePartConfiguratorType {
	return {
		type: "purchaseOrderLine",
		purchaseOrderLineId: purchaseOrderLineId,
		isTestConfigurator: false,
		isSubConfigurator: false,
		explicitPartConfigurationId: null,
	};
}

export function createShopOrderLinePartConfiguratorType(shopOrderLineId: number): ShopOrderLinePartConfiguratorType {
	return {
		type: "shopOrderLine",
		shopOrderLineId: shopOrderLineId,
		isTestConfigurator: false,
		isSubConfigurator: false,
		explicitPartConfigurationId: null,
	};
}
