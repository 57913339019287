import { useParams } from "react-router-dom";
import { WopiFileView } from "./WopiFileView";
import { useAsyncState } from "src/utils/async/asyncState";
import { useAsync } from "react-async-hook";
import { makeAxiosRequest } from "src/api/utils/apiClient.ts";
import { AsyncRender } from "src/components/common/async/AsyncRender";
import { WopiClientConfig } from "src/api/generated/documents/wopi/model/wopiClientConfig.ts";
import { setFavicon } from "src/utils/favicon.ts";

export const WopiFilePage = () => {
	const { action, fileId } = useParams();

	const [clientConfigAsync, reloadClientConfig] = useAsyncState<WopiClientConfig>({});

	useAsync(async () => {
		if (action !== undefined && fileId !== undefined) {
			const config = await reloadClientConfig(() => getWopiClientConfig(action, fileId));
			setFavicon(config.favicon);
		}
	}, [action, fileId]);

	return (
		<AsyncRender
			asyncData={clientConfigAsync}
			content={(clientConfig) => {
				return WopiFileView({ clientConfig });
			}}
		/>
	);
};

const getWopiClientConfig = async (action: string, fileId: string) => {
	return await makeAxiosRequest<WopiClientConfig>({
		method: "GET",
		url: `/api/wopi/clientConfig/${action}/${fileId}`
	});
};

export default WopiFilePage;
