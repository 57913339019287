import * as Sentry from "@sentry/react";
import { getFrontendReleaseCode, getSentryConfig } from "src/config/appConfig.ts";

const setupSentry = () => {
	const sentryConfig = getSentryConfig();
	if (sentryConfig == null || sentryConfig.dsn == null) return;
	const { dsn, debug = false, environment, tracesSampleRate = 0.25 } = sentryConfig;

	Sentry.init({
		dsn: dsn,
		debug: debug,
		environment: environment,
		release: getFrontendReleaseCode(),
		integrations: [
			Sentry.captureConsoleIntegration({
				levels: ["error", "warn"],
			}),
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllInputs: false,
				maskAllText: false,
				blockAllMedia: false,
			}),
		],
		tracesSampleRate: tracesSampleRate,
		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: 1.0,
		ignoreErrors: [
			"MUI: useResizeContainer - The parent DOM element of the data grid has an empty height.",
			"Lit is in dev mode",
			'AG Grid: Cell data type is "object" but no value formatter has been provided',
			'AG Grid: Cell data type is "object" but no value parser has been provided',
		],
	});
};

setupSentry();
