import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import { ConfiguratorSessionLogView } from "src/components/views/erp/configurator/configuratorTesting/logViews/ConfiguratorSessionLogView.tsx";
import { TestConfiguratorContextDataForm } from "src/components/views/erp/configurator/configuratorTesting/TestConfiguratorContextDataForm.tsx";
import {
	PartConfiguratorForm,
	PartConfiguratorFormProps,
} from "src/components/views/erp/configurator/configuratorForm/PartConfiguratorForm.tsx";
import { ConfigurationComponentDocumentView } from "./componentDocument/ConfigurationComponentDocumentView";
import { useState } from "react";

export interface PartConfiguratorFormWithTestViewsProps
	extends Omit<PartConfiguratorFormProps, "componentIdWithActiveDocument" | "setComponentIdWithActiveDocument"> {}

export const PartConfiguratorFormWithTestViews = (props: PartConfiguratorFormWithTestViewsProps) => {
	const { configuratorType, configurationSessionId } = props;

	if (!configuratorType.isTestConfigurator) return <PartConfiguratorFormWithDocumentView {...props} />;
	else
		return (
			<AavoMosaic
				viewId={"66D753A537DEFB11"}
				layout={{
					type: "tabs",
					items: [
						{
							type: "panel",
							title: i18n.t("configurator"),
							content: <PartConfiguratorFormWithDocumentView {...props} />,
						},
						{
							type: "panel",
							title: i18n.t("log"),
							content: (
								<ConfiguratorSessionLogView
									sessionId={configurationSessionId}
									configuratorType={configuratorType}
								/>
							),
						},
						{
							type: "panel",
							title: i18n.t("context_data"),
							content: <TestConfiguratorContextDataForm configuratorType={configuratorType} />,
						},
					],
				}}
			/>
		);
};

const PartConfiguratorFormWithDocumentView = (props: PartConfiguratorFormWithTestViewsProps) => {
	const [componentIdWithActiveDocument, setComponentIdWithActiveDocument] = useState<number | null>(null);

	return (
		<AavoMosaic
			viewId={"63D753A534DEFB14"}
			layout={{
				type: "row",
				splitPercentage: 65,
				first: {
					type: "panel",
					content: (
						<PartConfiguratorForm
							{...props}
							componentIdWithActiveDocument={componentIdWithActiveDocument}
							setComponentIdWithActiveDocument={setComponentIdWithActiveDocument}
						/>
					),
				},
				second: {
					type: "panel",
					title: i18n.t("document"),
					content: <ConfigurationComponentDocumentView componentId={componentIdWithActiveDocument} />,
				},
			}}
		/>
	);
};
