import { PartConfiguratorTopLevelView } from "src/components/views/erp/configurator/configuratorForm/PartConfiguratorTopLevelView.tsx";
import { ConfigurationCreationApi } from "src/api/generated/erp/configurator/api/configurationCreationApi";
import { UnsavedCustomerOrderLinePartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/impl/unsavedCustomerOrderLinePartConfiguratorType.ts";
import { FormResult } from "src/components/common/forms/types.ts";

export interface CustomerOrderLineBeforeCreateConfiguratorViewProps {
	configuratorType: UnsavedCustomerOrderLinePartConfiguratorType;
	onCompleted: (result: FormResult<number>) => void;
}

export const CustomerOrderLineBeforeCreateConfiguratorView = ({
	configuratorType,
	onCompleted,
}: CustomerOrderLineBeforeCreateConfiguratorViewProps) => {
	return (
		<PartConfiguratorTopLevelView
			configuratorType={configuratorType}
			onCompleted={async (result) => {
				if (result.type === "success") {
					const { propertyValues, configurationSessionId } = result.value;
					const partConfigurationId =
						await ConfigurationCreationApi.createPartConfigurationForUnsavedCustomerOrderLine({
							configuratorType: configuratorType,
							propertyValues: propertyValues,
							configurationSessionId: configurationSessionId,
						});
					onCompleted({ type: "success", value: partConfigurationId });
				} else {
					onCompleted(result);
				}
			}}
		/>
	);
};
