import {
    DefectPerformanceChartEditApi,
    DefectPerformanceChartEditApi_InitData,
} from "src/api/generated/spc/defectPerformanceChart/defectPerformanceChartEditApi.ts";
import {AsyncForm, AsyncFormContentParams} from "src/components/common/forms/AsyncForm.tsx";
import {FormCommonProps} from "src/components/common/forms/types.ts";
import {FormTextField} from "src/components/common/forms/fields/FormTextField.tsx";
import i18n from "i18next";
import {FormSelectField} from "src/components/common/forms/fields/FormSelectField.tsx";
import {
    DefectPerformanceMetricValues,
    getDefectPerformanceMetricLabel,
} from "src/api/generated/io/aavo/applications/db/postgres/enums/defectPerformanceMetric.ts";
import {FormNumberField} from "src/components/common/forms/fields/FormNumberField.tsx";
import {concatWithPipe} from "src/utils/strings.tsx";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {faChartLine} from "@fortawesome/pro-regular-svg-icons";
import {useGenericDialog} from "src/components/common/dialogs/GenericDialogContext.ts";
import {ControlChartSelectionForm} from "src/components/views/spc/controlChart/dataInput/ControlChartSelectionForm.tsx";
import {requireRule} from "src/components/common/forms/validation.ts";
import {DefectPerformanceChart} from "src/api/generated/postgres/db/types/public_/tables/defectPerformanceChart.ts";

export interface DefectPerformanceChartFormProps extends FormCommonProps<number> {
	defectPerformanceChartId: number | undefined;
}

interface FormValues extends DefectPerformanceChart {
	outputChartLongName: string;
}

export const DefectPerformanceChartForm = (props: DefectPerformanceChartFormProps) => {
	const { onCompleted, onFormEdited, defectPerformanceChartId } = props;
	return (
		<AsyncForm
			fetch={async () =>
				await DefectPerformanceChartEditApi.getFormInitData({
					defectPerformanceChartId: defectPerformanceChartId,
				})
			}
			getDefaultValues={(data) =>
				data.defectPerformanceChart == null ?
					{}
				:	{
						...data.defectPerformanceChart,
						outputChartLongName: data.totalOutputChartFullName,
					}
			}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			columns={2}
			layoutSx={{
				gridTemplateColumns: "2fr 1fr",
			}}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	async function submit(values: FormValues) {
		if (values.id == null)
			return await DefectPerformanceChartEditApi.insert({
				defectPerformanceChart: values,
			});
		else {
			await DefectPerformanceChartEditApi.update({
				defectPerformanceChart: values,
			});
			return values.id;
		}
	}
};

interface FormContentProps
	extends DefectPerformanceChartFormProps,
		AsyncFormContentParams<DefectPerformanceChartEditApi_InitData | null, FormValues> {}

const FormContent = ({ control, setValue, watch }: FormContentProps) => {
	const { openDialog } = useGenericDialog();
	return (
		<>
			<FormTextField
				control={control}
				name={"name"}
				label={i18n.t("name")}
				spanGridColumns
				rules={requireRule()}
			/>
			<FormTextField control={control} name={"description"} label={i18n.t("description")} spanGridColumns />
			<FormSelectField
				control={control}
				name={"metric"}
				options={DefectPerformanceMetricValues}
				getOptionKey={(o) => o}
				getOptionLabel={(o) => getDefectPerformanceMetricLabel(o)}
				label={i18n.t("performance_metric_column_name")}
				spanGridColumns
				rules={requireRule()}
			/>
			<FormTextField
				control={control}
				name={"outputChartLongName"}
				label={i18n.t("total_output_chart")}
				disabled
				rules={requireRule()}
			/>
			<AsyncButton
				icon={faChartLine}
				label={i18n.t("change_control_chart")}
				variant={"outlined"}
				onClick={() => {
					openDialog(({ closeDialog }) => ({
						title: i18n.t("select_control_chart"),
						size: "sm",
						content: (
							<ControlChartSelectionForm
								currentControlChartId={watch("totalOutputChartId")}
								onSubmit={(controlChart) => {
									setValue(
										"outputChartLongName",
										concatWithPipe(
											controlChart?.chartSuperGroupName,
											controlChart?.chartGroupName,
											controlChart?.chartName,
										),
									);
									setValue("totalOutputChartId", controlChart?.chartId);
								}}
								closeDialog={closeDialog}
							/>
						),
					}));
				}}
			/>
			<FormNumberField
				control={control}
				name={"shownPointCount"}
				label={i18n.t("shown_point_count")}
				spanGridColumns
				rules={requireRule()}
			/>
		</>
	);
};
