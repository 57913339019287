// This file is automatically generated. Do not edit manually.

import { PurchaseOrderType } from "src/api/generated/erp/db/types/enums/purchaseOrderType";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { IsoDateString } from "src/types/dateTime";
import { IsoDateTimeString } from "src/types/dateTime";
import { Currency } from "src/api/generated/erp/db/types/enums/currency";
import { PurchaseOrderSourceType } from "src/api/generated/erp/db/types/enums/purchaseOrderSourceType";
import { PurchaseOrderState } from "src/api/generated/erp/db/types/enums/purchaseOrderState";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace PurchaseOrdersToReceiveDataGridApi {
	export async function getPurchaseOrdersToReceive(args: {
		searchQuery: string;
		purchaseOrderType: PurchaseOrderType;
		supplierId?: number | null | undefined;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<PurchaseOrdersToReceiveDataGridApi_Row>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/purchase/purchaseOrder/purchaseOrdersToReceiveDataGrid/getPurchaseOrdersToReceive",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				purchaseOrderType: args.purchaseOrderType,
				supplierId: args.supplierId,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<PurchaseOrdersToReceiveDataGridApi_Row>;
	}
}

export interface PurchaseOrdersToReceiveDataGridApi_Row {
	nextPlannedArrivalDate: IsoDateString;
	businessId: string;
	confirmedDate: IsoDateTimeString | null | undefined;
	createdByUserId: number;
	createdByUserName: string;
	createdDate: IsoDateTimeString;
	currency: Currency;
	deliveryAddress_1: string;
	deliveryAddress_2: string;
	deliveryCity: string | null | undefined;
	deliveryMethodCode: string;
	deliveryMethodDescription: string;
	deliveryMethodId: number;
	deliveryName: string;
	deliveryPostalCode: string | null | undefined;
	deliveryTermsCode: string;
	deliveryTermsDescription: string;
	deliveryTermsDestination: string | null | undefined;
	deliveryTermsId: number;
	internalOrderResponsiblePersonId: number | null | undefined;
	internalSupplierSiteId: number | null | undefined;
	isInternalSupplier: boolean | null | undefined;
	lastUpdated: IsoDateTimeString;
	note: string | null | undefined;
	orderReference: string | null | undefined;
	orderSource: PurchaseOrderSourceType;
	orderSourceId: number | null | undefined;
	ourContact: string | null | undefined;
	paymentDays: number;
	paymentTerm: string;
	paymentTermDesc: string;
	paymentTermId: number;
	plannedArrivalDate: IsoDateString;
	printedDate: IsoDateTimeString | null | undefined;
	purchaseOrderId: number;
	purchaseOrderLongName: string | null | undefined;
	purchaseOrderState: PurchaseOrderState;
	purchaseOrderType: PurchaseOrderType;
	releasedDate: IsoDateTimeString | null | undefined;
	responsiblePersonCode: string;
	responsiblePersonEmail: string;
	responsiblePersonId: number;
	responsiblePersonName: string;
	responsiblePersonPhone: string;
	siteBusinessId: string;
	siteId: number;
	siteName: string;
	supplierAddress_1: string;
	supplierAddress_2: string;
	supplierCity: string;
	supplierContact: string | null | undefined;
	supplierContactEmail: string | null | undefined;
	supplierContactPhone: string | null | undefined;
	supplierExternalId: string;
	supplierHasSalesOrderIntegration: number;
	supplierId: number;
	supplierName: string;
	supplierPostalCode: string;
	supplierSalesOrderId: string | null | undefined;
}
