import {
	PartConfiguratorTopLevelView
} from "src/components/views/erp/configurator/configuratorForm/PartConfiguratorTopLevelView.tsx";

export const TestPage = () => {
	return (
		<PartConfiguratorTopLevelView
			configuratorType={{
				type: "catalogPartTest",
				catalogPartRevisionId: 3701,
				productFamilyVersionId: 3682,
				isTestConfigurator: true,
				isSubConfigurator: false,
			}}
			onCompleted={async () => {}}
		/>
	);
};
