import { logError } from "src/errorHandling/errorLogging.ts";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { SelectField } from "src/components/common/inputFields/SelectField.tsx";
import {
	CONFIGURATOR_INPUT_SCALAR_NULL_VALUE,
	configuratorScalarInputValueToString,
} from "src/components/views/erp/configurator/configuratorUtils.ts";
import { ConfigurationFieldSelectionOptions_Option } from "src/api/generated/io/aavo/applications/db/erp/types/configurationFieldSelectionOptions.ts";
import {
	ConfigurationPropertyValue_StringValue,
} from "src/api/generated/io/aavo/applications/db/erp/types/configurationPropertyValue.ts";
import i18n from "i18next";
import { ConfiguratorFieldComponentProps } from "src/components/views/erp/configurator/configuratorForm/components/field/ConfiguratorFieldComponent.tsx";
import { getConfiguratorFieldComponentLabelWithRequiredMark } from "src/components/views/erp/configurator/configuratorForm/components/field/configuratorFieldComponentUtils.ts";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { getConfiguratorComponentErrorMessage } from "src/components/views/erp/configurator/configuratorForm/validation.ts";
import {
	ConfiguratorInput_InputValue,
} from "src/api/generated/erp/configurator/model/configuratorInput.ts";

export const ConfiguratorSelectField = (props: ConfiguratorFieldComponentProps) => {
	const { field, onSubmit, disabled } = props;

	const showInputDialog = useInputDialog();

	const currentFreeInputValue: ConfigurationPropertyValue_StringValue | null =
		field.currentValue.propertyValue.type === "string" && field.currentValue.propertyValue.isFreeInput ?
			field.currentValue.propertyValue
		:	null;

	return (
		<VerticalBox flex={1} gap={1}>
			<HorizontalBox flex={1} gap={1}>
				<SelectField
					label={getConfiguratorFieldComponentLabelWithRequiredMark(field)}
					disabled={!field.enabled || disabled}
					value={configuratorScalarInputValueToString(field.currentValue)}
					options={getOptionsWithFreeInput()}
					getOptionKey={(option) => option.key}
					getOptionLabel={(option) => option.label}
					disableClearable={field.required}
					sx={{
						flex: 1,
					}}
					error={getConfiguratorComponentErrorMessage(field, field.currentValue)}
					onChange={(_, option: ConfigurationFieldSelectionOptions_Option | null) => onChange(option)}
				/>
				{currentFreeInputValue != null && (
					<AsyncButton
						icon={faPen}
						disabled={!field.enabled || disabled}
						tooltip={i18n.t("edit")}
						onClick={() => askAndSubmitFreeInput()}
					/>
				)}
			</HorizontalBox>
		</VerticalBox>
	);

	function getOptionsWithFreeInput(): ConfigurationFieldSelectionOptions_Option[] {
		if (!currentFreeInputValue) return field.selectionFieldOptions;

		const freeInputOption = field.selectionFieldOptions.find((option) => option.freeInput);
		if (!freeInputOption) return field.selectionFieldOptions;

		return [
			...field.selectionFieldOptions.filter((option) => !option.freeInput),
			{
				...freeInputOption,
				key: currentFreeInputValue.value,
				label: currentFreeInputValue.label,
			},
		];
	}

	async function onChange(option: ConfigurationFieldSelectionOptions_Option | null) {
		let newConfigurationPropertyValue: ConfiguratorInput_InputValue;

		if (option?.freeInput) {
			await askAndSubmitFreeInput();
		} else {
			newConfigurationPropertyValue =
				option == null ?
					CONFIGURATOR_INPUT_SCALAR_NULL_VALUE
				:	{
						type: "SCALAR",
						propertyValue: {
							type: "string",
							value: option.key,
							label: option.label,
							// Keep the current info text for now. Backend will update it
							infoText: field.currentValue.propertyValue.infoText,
							fileUuid: option.file?.fileUuid ?? field.imageUuid,
							isFreeInput: false,
							hiddenOnPrintouts: option.hiddenOnPrintouts,
						},
					};
			await onSubmit(newConfigurationPropertyValue);
		}
	}

	async function askAndSubmitFreeInput() {
		const freeInputOption = field.selectionFieldOptions.find((option) => option.freeInput);
		if (freeInputOption == null) {
			logError("Attempt to submit free input value, but no free input option found.");
			return;
		}

		const newFreeInputValue = await showInputDialog({
			type: "string",
			title: field.label,
			fieldLabel: freeInputOption.label,
			required: true,
			defaultValue: currentFreeInputValue?.value ?? "",
		});
		if (newFreeInputValue === undefined) return;

		const newConfigurationPropertyValue: ConfigurationPropertyValue_StringValue = {
			type: "string",
			value: newFreeInputValue,
			label: freeInputOption.label + ": " + newFreeInputValue,
			fileUuid: null,
			isFreeInput: true,
			hiddenOnPrintouts: freeInputOption.hiddenOnPrintouts,
		};
		await onSubmit({ type: "SCALAR", propertyValue: newConfigurationPropertyValue });
	}
};
