import { Box, Typography } from "@mui/material";
import {
	CChartEventCollectionApi,
	CChartEventCollectionApi_CChartDto,
} from "src/api/generated/spc/cChartEvents/cChartEventCollectionApi";
import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender";
import { AsyncButton } from "src/components/common/buttons/AsyncButton";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { AddCChartEventForm } from "./AddCChartEventForm";
import i18n from "i18next";
import { isNullOrBlank } from "src/utils/strings.tsx";
import { AavoCheckbox } from "src/components/common/inputFields/AavoCheckbox";
import { useState } from "react";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";

export interface CChartEventCollectionViewProps {
	controlChartGroupId: number;
	isActive: boolean
}

export const CChartEventCollectionView = ({
	controlChartGroupId,
}: CChartEventCollectionViewProps) => {
	return (
		<AsyncFetchRender
			fetch={() =>
				CChartEventCollectionApi.getCCharts({
					chartGroupId: controlChartGroupId,
				})
			}
			content={(controlCharts, refresh) => (
				<AsyncContent controlCharts={controlCharts} refresh={refresh} />
			)}
		/>
	);
};

interface AsyncContentProps {
	controlCharts: CChartEventCollectionApi_CChartDto[];
	refresh: () => Promise<unknown>;
}

const AsyncContent = ({ controlCharts, refresh }: AsyncContentProps) => {
	const [removeLatestChecked, setRemoveLatestChecked] = useState(false);
	return (
		<VerticalBox
			sx={{
				flex: 1,
			}}
		>
			<AavoCheckbox
				label={i18n.t("remove_latest")}
				checked={removeLatestChecked}
				onChange={setRemoveLatestChecked}
			/>
			<Box
				sx={{
					flex: 1,
					alignSelf: "stretch",
					display: "grid",
					gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
					alignItems: "stretch",
					gap: 1,
					padding: 1,
				}}
			>
				{controlCharts.map((controlChart) => (
					<ControlChartButton
						key={controlChart.controlChartId}
						controlChart={controlChart}
						refresh={refresh}
						removeLatestChecked={removeLatestChecked}
						setRemoveLatestChecked={setRemoveLatestChecked}
					/>
				))}
			</Box>
		</VerticalBox>
	);
};

interface ControlChartButtonProps {
	controlChart: CChartEventCollectionApi_CChartDto;
	refresh: () => Promise<unknown>;
	removeLatestChecked: boolean;
	setRemoveLatestChecked: (value: boolean) => void;
}

const ControlChartButton = ({
	controlChart,
	refresh,
	removeLatestChecked,
	setRemoveLatestChecked,
}: ControlChartButtonProps) => {
	const { openDialog } = useGenericDialog();

	const onClick = async () => {
		if (removeLatestChecked) {
			await CChartEventCollectionApi.removeLatestEvent({
				controlChartId: controlChart.controlChartId,
			});
			setRemoveLatestChecked(false);
			await refresh();
			return;
		}

		if (anyExtraInputEnabled(controlChart))
			openDialog(({ closeDialog }) => ({
				title: i18n.t("add_event"),
				size: "sm",
				content: (
					<AddCChartEventForm
						controlChart={controlChart}
						onCompleted={async () => {
							await closeDialog();
							await refresh();
						}}
					/>
				),
			}));
		else {
			await CChartEventCollectionApi.addEvent({
				controlChartId: controlChart.controlChartId,
			});
			await refresh();
		}
	};

	return (
		<AsyncButton
			variant={"outlined"}
			onClick={onClick}
			reserveSpaceForLoadingSpinner
			sx={{
				borderWidth: 2,
			}}
		>
			<Typography
				variant={"button"}
				sx={{
					fontSize: "1rem",
					overflow: "hidden",
					textAlign: "center",
					display: "-webkit-box",
					WebkitLineClamp: "3",
					WebkitBoxOrient: "vertical",
				}}
			>
				{controlChart.name} ({controlChart.currentEventCount})
			</Typography>
		</AsyncButton>
	);
};

const anyExtraInputEnabled = (controlChart: CChartEventCollectionApi_CChartDto) => {
	return (
		controlChart.documentsEnabled ||
		controlChart.dimensions.some(({ label }) => !isNullOrBlank(label))
	);
};
