import { Types } from "./types";
import {
	DEFAULT_LANGUAGE,
	getFontSizeStorage,
	getLocalSettingsStorage,
	setLocalSettingsStorage,
} from "../storage/settings";
import { isSupportedLanguage, SupportedLanguage } from "../types/languages";
import i18n from "i18next";

export const setAndApplyLocalSettings = (settings: Types) => {
	setLocalSettings(settings, true, true);
};

export const setLocalSettings = (
	settings: Types,
	refetchBackendTranslations: boolean = false,
	applyFontSize: boolean = false,
) => {
	setLocalSettingsStorage({ fontSize: settings.fontSize });
	if (applyFontSize) {
		applyFontSizeSetting();
	}
	changeLanguage(settings.userLanguage, refetchBackendTranslations);
};

export const applyLocalSettings = (refetchBackendTranslations: boolean = false) => {
	applyFontSizeSetting();
	applyLanguageSetting(refetchBackendTranslations);
};

export const getLocalSettings = (): Types => {
	return {
		...getLocalSettingsStorage(),
		userLanguage: getLanguageSetting(),
	};
};
export const changeLanguage = (
	language: SupportedLanguage,
	refetchBackendTranslations: boolean = false,
): SupportedLanguage => {
	if (getLanguageSetting() !== language) {
		i18n.changeLanguage(language);
		if (refetchBackendTranslations) {
			window.location.reload();
		}
	}
	return language;
};

export const getFontSizeSetting = (): number => {
	return getFontSizeStorage();
};

const applyFontSizeSetting = () => {
	const htmlElement = document.documentElement;
	htmlElement.style.fontSize = `${getFontSizeSetting()}px`;
};

export const getLanguageSetting = (): SupportedLanguage => {
	const language = i18n.resolvedLanguage;
	return isSupportedLanguage(language) ? language : DEFAULT_LANGUAGE;
};

const applyLanguageSetting = (refetchBackendTranslations: boolean = false) => {
	if (refetchBackendTranslations) {
		window.location.reload();
	}
};
