// This file is automatically generated. Do not edit manually.

import { ProductionLineGroup } from "src/api/generated/erp/db/types/tables/productionLineGroup";
import { ProductionLineGroupStatus } from "src/api/generated/erp/production/productionLineStatus/model/productionLineGroupStatus";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProductionLineGroupStatusViewApi {
	export async function getProductionLineGroupsForStatusViewGeneration(): Promise<Array<ProductionLineGroup>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLineGroupStatus/getProductionLineGroupsForStatusViewGeneration",
			method: "POST",
			data: {},
		});
		return response as Array<ProductionLineGroup>;
	}

	export async function getProductionLineGroupStatus(args: {
		productionLineGroupId: number;
	}): Promise<ProductionLineGroupStatus> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLineGroupStatus/getProductionLineGroupStatus",
			method: "POST",
			data: {
				productionLineGroupId: args.productionLineGroupId,
			},
		});
		return response as ProductionLineGroupStatus;
	}
}
