import { QrcodeInputField } from "src/components/common/inputFields/QrcodeInputField.tsx";
import i18n from "i18next";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faBars, faCheck, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { ReplenishmentRequestLogView } from "src/components/views/erp/warehouse/replenishment/ReplenishmentRequestLogView.tsx";
import { useEffect, useRef, useState } from "react";
import { useMediaQueryBreakpointDown } from "src/utils/breakpointUtils.ts";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { Box } from "@mui/material";
import { ReplenishPartWarehouseLocationResult } from "src/api/generated/erp/warehouse/warehouseLocation/service/replenishPartWarehouseLocationResult.ts";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/ErrorDialogContext.tsx";
import { AavoMenuButton } from "src/components/common/menu/AavoMenuButton.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";

export interface ReplenishmentRequestBarcodeViewProps {
	createReplenishmentRequest: (code: string) => Promise<void>;
	cancelReplenishmentRequest: (code: string) => Promise<void>;
	log: Array<ReplenishPartWarehouseLocationResult>;
}

export const ReplenishmentRequestBarcodeView = ({
	createReplenishmentRequest,
	cancelReplenishmentRequest,
	log,
}: ReplenishmentRequestBarcodeViewProps) => {
	const [code, setCode] = useState<string>("");
	const isMobile = useMediaQueryBreakpointDown("sm");
	const { openDialog } = useGenericDialog();
	const { logErrorAndShowOnDialog } = useErrorDialog();

	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		inputRef.current?.focus();
	}, []);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: isMobile ? "column-reverse" : "column",
				flex: 1,
				margin: 1,
			}}
		>
			<HorizontalBox
				sx={{
					alignItems: "center",
					gap: 1,
				}}
			>
				<AavoMenuButton
					icon={faBars}
					menuContent={(closeMenu) => [
						<AsyncMenuButton
							key={"cancel"}
							label={i18n.t("cancel_request")}
							icon={faUndo}
							onClick={() => {
								closeMenu();
								openDialog(({ closeDialog }) => ({
									title: i18n.t("read_code"),
									size: "sm",
									content: (
										<QrcodeInputField
											sx={{ margin: 1 }}
											onQrCodeRead={async (qrCodeContent) => {
												await cancelReplenishmentRequest(qrCodeContent);
												await closeDialog();
												inputRef.current?.focus();
											}}
											showTextField
											autoFocus
										/>
									),
								}));
							}}
						/>,
					]}
				/>
				<QrcodeInputField
					onQrCodeRead={async (qrCodeContent) => {
						try {
							await createReplenishmentRequest(qrCodeContent);
							setCode("");
						} catch (e) {
							logErrorAndShowOnDialog(e);
						} finally {
							setCode("");
							inputRef.current?.focus();
						}
					}}
					showTextField={true}
					autoFocus={true}
					textFieldInputRef={inputRef}
					onTextFieldChange={(value) => setCode(value)}
					textFieldValue={code}
				/>
				<AsyncButton
					label={i18n.t("ok")}
					icon={faCheck}
					variant={"outlined"}
					disabled={code.length === 0}
					onClick={async () => {
						await createReplenishmentRequest(code);
						setCode("");
						inputRef.current?.focus();
					}}
				/>
			</HorizontalBox>
			<ReplenishmentRequestLogView log={log} />
		</Box>
	);
};
