import { AavoViewsContext, AavoViewsDispatchContext } from "src/components/views/legacy/AavoViewsContext.tsx";
import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { useFrontendViewsContext } from "src/components/views/frontendViews/useFrontendViewsContext.ts";
import { ResolvedFrontendViewConfig } from "src/components/views/frontendViews/frontendViewConfig.ts";

export const FrontendViewsWrapper = () => {
	const { state } = useContextOrThrow(AavoViewsContext);
	const dispatch = useContextOrThrow(AavoViewsDispatchContext);
	const location = useLocation();
	const viewsOpened = state.frontendViewsOpened;
	const { getFrontendViewByUrl, getFrontendViewByKey } = useFrontendViewsContext();

	useEffect(() => {
		const frontendView = getFrontendViewByUrl(location.pathname);
		if (frontendView !== undefined) {
			dispatch({
				type: "addActiveFrontendView",
				viewKey: frontendView.key,
			});
		}
	}, [location.pathname, dispatch, getFrontendViewByUrl]);

	return (
		<>
			{viewsOpened.map(({ viewKey }) => {
				const frontendView = getFrontendViewByKey(viewKey)!;
				const viewUrl = frontendView.url;
				const isActive = location.pathname === viewUrl;
				return (
					<FrontendViewWrapper
						key={`frontend-view-${viewKey}`}
						isActive={isActive}
						frontendView={frontendView}
					/>
				);
			})}
		</>
	);
};

interface FrontendViewWrapperProps {
	isActive: boolean;
	frontendView: ResolvedFrontendViewConfig;
}

const FrontendViewWrapper = React.memo(({ isActive, frontendView }: FrontendViewWrapperProps) => {
	const viewProps = frontendView.props as any;
	return (
		<Box
			sx={{
				flex: 1,
				display: "flex",
				flexDirection: "column",
				opacity: isActive ? 1 : 0,
				pointerEvents: isActive ? undefined : "none",
				position: "absolute",
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
			}}
		>
			<frontendView.component {...viewProps} isActive={isActive} />
		</Box>
	);
});
