import { ProcessImprovement } from "src/api/generated/postgres/db/types/public_/tables/processImprovement.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import { DeepPartial } from "react-hook-form";
import {
	ProcessImprovementEditApi,
	ProcessImprovementEditApi_FormInitData,
} from "src/api/generated/spc/processImprovement/processImprovementEditApi.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import i18n from "i18next";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { FormAsyncUserSelectField } from "src/components/views/users/FormAsyncUserSelectField.tsx";
import { FormSection } from "src/components/common/forms/FormSection.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import {
	getProcessImprovementStateLabel,
	ProcessImprovementStateValues,
} from "src/api/generated/postgres/db/types/public_/enums/processImprovementState.ts";
import { useGlobalInitData } from "src/contexts/GlobalInitDataContext.ts";

export interface ProcessImprovementFormProps extends FormCommonProps<number> {
	processImprovementId: number | undefined;
}

export const ProcessImprovementForm = (props: ProcessImprovementFormProps) => {
	const { onCompleted, onFormEdited, processImprovementId } = props;
	const { appUserId } = useGlobalInitData();
	return (
		<AsyncForm
			fetch={() => ProcessImprovementEditApi.getFormInitData({ processImprovementId: processImprovementId })}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			columns={2}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues({
		processImprovement,
	}: ProcessImprovementEditApi_FormInitData): DeepPartial<ProcessImprovement> {
		return processImprovement != null ? processImprovement : (
				{
					responsiblePersonUserId: appUserId,
					processImprovementState: "INITIAL",
				}
			);
	}

	async function submit(values: ProcessImprovement) {
		if (values.id == null) {
			return await ProcessImprovementEditApi.insert({
				processImprovement: values,
			});
		} else {
			await ProcessImprovementEditApi.update({
				processImprovement: values,
			});
			return values.id;
		}
	}
};

interface FormContentProps
	extends ProcessImprovementFormProps,
		AsyncFormContentParams<ProcessImprovementEditApi_FormInitData, ProcessImprovement> {}

const FormContent = ({ control, data: { categoryOptions } }: FormContentProps) => {
	return (
		<>
			<FormTextField control={control} name={"title"} label={i18n.t("name")} rules={requireRule()} />
			<FormSelectField
				control={control}
				name={"categoryId"}
				getOptionKey={(o) => o.id}
				getOptionLabel={(o) => o.categoryName}
				label={i18n.t("category")}
				options={categoryOptions}
				rules={requireRule()}
			/>
			<FormAsyncUserSelectField
				control={control}
				name={"responsiblePersonUserId"}
				label={i18n.t("responsible_person")}
			/>
			<FormTextField control={control} name={"team"} label={i18n.t("team")} rules={requireRule()} />
			<FormSelectField
				control={control}
				name={"processImprovementState"}
				label={i18n.t("state")}
				options={ProcessImprovementStateValues}
				getOptionKey={(o) => o}
				getOptionLabel={(o) => getProcessImprovementStateLabel(o)}
				rules={requireRule()}
			/>
			<FormTextField
				control={control}
				name={"description"}
				label={i18n.t("description")}
				rules={requireRule()}
				spanGridColumns
			/>
			<FormSection label={i18n.t("analysis")} />
			<FormTextField control={control} name={"analysis"} label={i18n.t("analysis")} spanGridColumns multiline />
			<FormSection label={i18n.t("act")} />
			<FormTextField control={control} name={"act"} label={i18n.t("act")} spanGridColumns multiline />
			<FormTextField control={control} name={"actEffect"} label={i18n.t("act_effect")} spanGridColumns multiline />
		</>
	);
};
