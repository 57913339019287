import React from "react";
import { ConfiguratorTreeItemState } from "src/components/views/erp/configurator/configuratorForm/configuratorTree/treeItemState/ConfiguratorTreeItemState.ts";
import { VerticalBox } from "src/components/common/box/VerticalBox";
import {
	OnConfiguratorInputChangedParams,
} from "src/components/views/erp/configurator/configuratorForm/PartConfiguratorForm.tsx";
import { RecursiveConfiguratorTreeItem } from "src/components/views/erp/configurator/configuratorForm/configuratorTree/RecursiveConfiguratorTreeItem.tsx";
import { PartConfiguratorFormWithTestViews } from "src/components/views/erp/configurator/configuratorForm/PartConfiguratorFormWithTestViews.tsx";
import { ConfiguratorTreeFooter } from "src/components/views/erp/configurator/configuratorForm/components/ConfiguratorTreeFooter.tsx";
import { TransformComponentsErrorContent } from "../TransformComponentsErrorContent";
import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType.ts";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic";

export interface PartConfiguratorTreeStatelessViewProps {
	onSubmit: () => Promise<unknown>;
	onCancel: () => Promise<unknown>;
	rootItem: ConfiguratorTreeItemState;
	selectedItem: ConfiguratorTreeItemState | undefined;
	selectTreeItem: (key: string) => void;
	toggleTreeItemExpansion: (key: string) => void;
	onConfiguratorInputChanged: (key: string, params: OnConfiguratorInputChangedParams) => void;
	refreshTreeItemComputedValues: (key: string) => void;
	rootConfiguratorType: PartConfiguratorType;
	rootConfigurationSessionId: string;
	configuratorInputVersion: number;
	hasUnsavedChanges: boolean;
	resetToExistingConfiguration: () => Promise<unknown>;
	resetToDefaultValues: () => Promise<unknown>;
	onAddSubConfiguratorClicked: (parentKey: string) => Promise<unknown>;
	deleteSubConfiguratorListItem: (key: string) => void;
	hideFooter?: boolean;
	footerExtraComponents?: React.ReactNode;
	transformComponentsErrorCustomContent?: React.ReactNode;
	readonly?: boolean;
}

export const PartConfiguratorTreeStatelessView = ({
	onSubmit,
	onCancel,
	rootItem,
	selectedItem,
	selectTreeItem,
	toggleTreeItemExpansion,
	onConfiguratorInputChanged,
	refreshTreeItemComputedValues,
	rootConfiguratorType,
	rootConfigurationSessionId,
	configuratorInputVersion,
	hasUnsavedChanges,
	resetToExistingConfiguration,
	resetToDefaultValues,
	onAddSubConfiguratorClicked,
	deleteSubConfiguratorListItem,
	hideFooter,
	footerExtraComponents,
	transformComponentsErrorCustomContent,
	readonly,
}: PartConfiguratorTreeStatelessViewProps) => {
	const rootHasAnySubConfigurators = rootItem.subConfiguratorComponents.length > 0;

	return (
		<VerticalBox flex={1}>
			<AavoMosaic
				viewId={"D632746D610D8F9D"}
				layout={{
					type: "row",
					first: {
						type: "panel",
						hidden: !rootHasAnySubConfigurators,
						content: (
							<VerticalBox
								component={"ul"}
								sx={{
									overflow: "auto",
									paddingX: 0,
									flex: 1,
								}}
							>
								<RecursiveConfiguratorTreeItem
									key={rootItem.key}
									state={rootItem}
									depth={0}
									selectedItemKey={selectedItem?.key}
									selectTreeItem={selectTreeItem}
									toggleTreeItemExpansion={toggleTreeItemExpansion}
									onAddSubConfiguratorClicked={onAddSubConfiguratorClicked}
									deleteSubConfiguratorListItem={deleteSubConfiguratorListItem}
									refreshTreeItemComputedValues={refreshTreeItemComputedValues}
									readonly={readonly}
								/>
							</VerticalBox>
						),
					},
					second: {
						type: "panel",
						content:
							selectedItem == null ?
								<></>
							:	<PartConfiguratorFormWithTestViews
									key={selectedItem.key}
									configuratorType={selectedItem.configuratorType}
									configurationSessionId={selectedItem.configurationSessionId}
									configuratorInput={selectedItem.configuratorInput}
									onConfiguratorInputChanged={(params) =>
										onConfiguratorInputChanged(selectedItem.key, params)
									}
									disabled={readonly}
									transformComponentsErrorContent={(params) => (
										<TransformComponentsErrorContent
											{...params}
											customContent={transformComponentsErrorCustomContent}
											resetToExistingConfiguration={resetToExistingConfiguration}
											resetToDefaultValues={resetToDefaultValues}
										/>
									)}
								/>,
					},
				}}
			/>
			{!hideFooter && (
				<ConfiguratorTreeFooter
					rootConfigurationSessionId={rootConfigurationSessionId}
					rootConfiguratorType={rootConfiguratorType}
					rootConfiguratorInput={{ ...rootItem.configuratorInput, version: configuratorInputVersion }}
					rootConfiguratorInputErrors={rootItem.errors}
					onCancel={onCancel}
					submitForm={onSubmit}
					resetToExistingConfiguration={resetToExistingConfiguration}
					resetToDefaultValues={resetToDefaultValues}
					hasUnsavedChanges={hasUnsavedChanges}
					extraComponents={footerExtraComponents}
				/>
			)}
		</VerticalBox>
	);
};
