import { useEffect, useRef } from "react";

export interface UseIntervalParams {
	callback: IntervalCallback;
	intervalMs: number;
	isActive: boolean;
	immediate?: boolean;
}

export type IntervalCallback = (params: IntervalCallbackParams) => unknown;

export interface IntervalCallbackParams {
	isCancelled: () => boolean;
}

export const useInterval = ({ callback, intervalMs, isActive, immediate = false }: UseIntervalParams) => {
	const ref = useRef<IntervalCallback>();

	// keep reference to callback without restarting the interval
	useEffect(() => {
		ref.current = callback;
	}, [callback]);

	useEffect(() => {
		// when this flag is set, closure is stale
		let cancelled = false;

		// wrap callback to pass isCancelled getter as an argument
		const fn = () => {
			if (isActive) {
				ref.current?.({
					isCancelled: () => cancelled,
				});
			}
		};

		const intervalId = setInterval(fn, intervalMs);
		if (immediate) fn();

		return () => {
			cancelled = true;
			clearInterval(intervalId);
		};
	}, [intervalMs, immediate, isActive]);
};
