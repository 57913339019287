import {AavoMosaic} from "src/components/common/mosaic/AavoMosaic.tsx";
import {
    DefectPerformanceChartsListView
} from "src/components/views/spc/defectPerformanceChart/DefectPerformanceChartsListView.tsx";
import {
    DefectPerformanceChartGraphView
} from "src/components/views/spc/defectPerformanceChart/DefectPerformanceChartGraphView.tsx";
import {useState} from "react";
import {CenteredTypography} from "src/components/common/CenteredTypography.tsx";
import i18n from "i18next";

export const DefectPerformanceChartsView = () => {
	const [selectedDefectPerformanceChartId, setSelectedDefectPerformanceChartId] = useState<number | null>(null);
	return (
		<AavoMosaic
			viewId={"234B6E26E28D6A4D"}
			layout={{
				type: "row",
				splitPercentage: 15,
				first: {
					type: "panel",
					content: (
						<DefectPerformanceChartsListView
							selectedDefectPerformanceChartId={selectedDefectPerformanceChartId}
							setSelectedDefectPerformanceChartId={setSelectedDefectPerformanceChartId}
						/>
					),
				},
				second: {
					type: "panel",
					content:
						selectedDefectPerformanceChartId == null ?
							<CenteredTypography>{i18n.t("select_yield_chart")}</CenteredTypography>
						:	<DefectPerformanceChartGraphView
								key={selectedDefectPerformanceChartId}
								defectPerformanceChartId={selectedDefectPerformanceChartId}
							/>,
				},
			}}
		/>
	);
};
