import {ControlChartSelectionFormFields} from "src/components/views/spc/common/ControlChartSelectionFormFields.tsx";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {faCheck} from "@fortawesome/pro-regular-svg-icons";
import {CloseDialogFunc} from "src/components/common/dialogs/GenericDialogContext.ts";
import {AavoFormLayout} from "src/components/common/forms/AavoFormLayout.tsx";
import {AavoFormFooter} from "src/components/common/forms/AavoFormFooter.tsx";
import {useState} from "react";
import {VerticalBox} from "src/components/common/box/VerticalBox.tsx";
import i18n from "i18next";
import {ControlChartView} from "src/api/generated/postgres/db/types/public_/tables/controlChartView.ts";

export interface ControlChartSelectionFormProps {
	currentControlChartId: number | undefined;
	onSubmit: (controlChart: ControlChartView | undefined) => Promise<unknown> | void;
	closeDialog: CloseDialogFunc;
}

export const ControlChartSelectionForm = ({
	currentControlChartId,
	onSubmit,
	closeDialog,
}: ControlChartSelectionFormProps) => {
	const [selectedChart, setSelectedChart] = useState<ControlChartView | undefined>(undefined);
	return (
		<VerticalBox
			sx={{
				flex: 1,
			}}
		>
			<AavoFormLayout>
				<ControlChartSelectionFormFields
					currentControlChartId={currentControlChartId}
					onChange={(controlChart) => setSelectedChart(controlChart)}
					onlyTimeBasedControlCharts={false}
				/>
			</AavoFormLayout>
			<AavoFormFooter>
				<AsyncButton
					icon={faCheck}
					label={i18n.t("save")}
					onClick={async () => {
						await onSubmit(selectedChart);
						await closeDialog();
					}}
				/>
			</AavoFormFooter>
		</VerticalBox>
	);
};
