import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {DefectPerformanceChartApi} from "src/api/generated/spc/defectPerformanceChart/defectPerformanceChartApi.ts";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {enumColumn, integerColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {
    DefectPerformanceChartView
} from "src/api/generated/postgres/db/types/public_/tables/defectPerformanceChartView.ts";
import {
    getDefectPerformanceMetricLabels
} from "src/api/generated/io/aavo/applications/db/postgres/enums/defectPerformanceMetric.ts";
import {concatWithPipe} from "src/utils/strings.tsx";
import {
    DefectPerformanceChartForm
} from "src/components/views/spc/basedata/performanceDefectCharts/DefectPerformanceChartForm.tsx";
import {
    DefectPerformanceChartEditApi
} from "src/api/generated/spc/defectPerformanceChart/defectPerformanceChartEditApi.ts";

export interface DefectPerformanceChartsDataGridProps {
	setSelectedDefectPerformanceChartId: (defectPerformanceChartId: number | undefined) => void;
}

export const DefectPerformanceChartsDataGrid = ({
	setSelectedDefectPerformanceChartId,
}: DefectPerformanceChartsDataGridProps) => {
	return (
		<ClientSideDataGridModel
			gridId={"261B68142F2B3B78"}
			fetchData={DefectPerformanceChartApi.getPerformanceCharts}
			initialParams={{}}
			getRows={(data) => data}
			getRowId={(row) => row.defectPerformanceChartId}
			onSelectionChanged={(rows) => setSelectedDefectPerformanceChartId(rows[0]?.defectPerformanceChartId)}
			render={({ dataGridProps }) => {
				return (
					<CrudDataGrid<DefectPerformanceChartView>
						disableMultipleRowSelection
						columns={[
							textColumn({
								field: "defectPerformanceChartName",
								headerName: i18n.t("name"),
								width: 150,
							}),
							textColumn({
								field: "defectPerformanceChartDescription",
								headerName: i18n.t("description"),
							}),
							enumColumn({
								field: "performanceMetric",
								headerName: i18n.t("performance_metric_column_name"),
								enumLabels: getDefectPerformanceMetricLabels(),
							}),
							textColumn({
								field: "totalOutputChartName",
								headerName: i18n.t("total_output_chart"),
								width: 150,
								valueGetter: (_, row) =>
									concatWithPipe(
										row.totalOutputChartSuperGroupName,
										row.totalOutputChartGroupName,
										row.totalOutputChartName,
									),
							}),
							integerColumn({
								field: "shownPointCount",
								headerName: i18n.t("shown_point_count"),
							}),
							integerColumn({
								field: "defectPerformanceChartId",
								headerName: i18n.t("id"),
							}),
						]}
						form={{
							editEnabled: true,
							addRowEnabled: true,
							dialogSize: "sm",
							dialogTitle: i18n.t("yield_chart"),
							component: ({ row, onFormEdited, onCompleted }) => (
								<DefectPerformanceChartForm
									defectPerformanceChartId={row?.defectPerformanceChartId}
									onCompleted={onCompleted}
									onFormEdited={onFormEdited}
								/>
							),
						}}
						remove={({ row }) =>
							DefectPerformanceChartEditApi.delete_({
								defectPerformanceChartId: row.defectPerformanceChartId,
							})
						}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
