import { TextFieldVariants } from "@mui/material/TextField/TextField";
import { ChangeEvent, ForwardedRef, forwardRef, useEffect, useState } from "react";
import { AavoTextField, AavoTextFieldProps } from "src/components/common/inputFields/AavoTextField.tsx";
import { FLOAT_INPUT_REGEX, INT_INPUT_REGEX } from "src/utils/regexPatterns.ts";
import { toFloatOrNull } from "src/utils/strings.tsx";

export type AavoNumberFieldProps<Variant extends TextFieldVariants = TextFieldVariants> = Omit<
	AavoTextFieldProps<Variant>,
	"onSubmit" | "onChange" | "value" | "multiline"
> & {
	onChange?: (value: number | null, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	onSubmit?: (value: number | null, source: "enterKey" | "blur") => void;
	value?: number | null;
	type?: "integer" | "decimal";
};

export const AavoNumberField = forwardRef(
	(
		{ onChange, onSubmit, value, type = "decimal", ...other }: AavoNumberFieldProps,
		ref: ForwardedRef<HTMLDivElement> | undefined,
	) => {
		// Internal value as string has to be used to keep exact typed input on memory.
		// e.g. to separate inputs '1,0' and '1.0'.
		const [internalValue, setInternalValue] = useState<string>(value?.toString() ?? "");

		useEffect(() => {
			const newValAsString = value?.toString() ?? "";
			if (equalsIgnoringDecimalSeparator(internalValue, newValAsString)) return;

			setInternalValue(newValAsString);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [value]);

		return (
			<AavoTextField
				value={internalValue}
				onChange={(newInput: string, e) => {
					// Do not allow invalid input.
					const regex = getRegexToUse();
					if (!newInput.match(regex) && newInput !== "") {
						return;
					}
					setInternalValue(newInput);
					// Regex is supposed to work flawless.
					// Leading dot or comma is removed on parser.
					// undefined value should return only from empty input
					const asNumberOrNull = parseInput(newInput);
					onChange?.(asNumberOrNull, e);
				}}
				onSubmit={(newValue: string, source) => {
					const asNumberOrNull = parseInput(newValue);
					onSubmit?.(asNumberOrNull, source);
				}}
				type={"number"}
				ref={ref}
				{...other}
			/>
		);

		function getRegexToUse() {
			switch (type) {
				case "integer":
					return INT_INPUT_REGEX;
				case "decimal":
					return FLOAT_INPUT_REGEX;
			}
		}
	},
);

function parseInput(value: string): number | null {
	const withoutComma = value.replace(",", ".");
	const withoutTrailingDot = withoutComma.replace(/\.$/, "");
	return toFloatOrNull(withoutTrailingDot) ?? null;
}

function equalsIgnoringDecimalSeparator(a: string, b: string): boolean {
	return a.replace(",", ".") === b.replace(",", ".");
}
