import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { WorkspacesTreeView } from "src/components/views/workspaces/treeView/WorkspacesTreeView.tsx";
import { WorkspacePermissionsDataGrid } from "src/components/views/workspaces/permission/WorkspacePermissionsDataGrid.tsx";
import { useState } from "react";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import i18n from "i18next";
import { WorkspaceInfoView } from "src/components/views/workspaces/infoView/WorkspaceInfoView.tsx";
import { DocumentsOfSingleObjectView } from "src/components/views/documents/objectDocuments/DocumentsOfSingleObjectView.tsx";
import { SingleSourceTasksView } from "src/components/views/tasks/pages/SingleSourceTasksView.tsx";
import { WorkspaceConnectionsDataGrid } from "src/components/views/workspaces/workspaceConnections/WorkspaceConnectionsDataGrid.tsx";
import { useMediaQueryBreakpointDown } from "src/utils/breakpointUtils.ts";
import { AavoMosaicNode, AavoMosaicTabItem } from "src/components/common/mosaic/types.ts";
import { WorkspacesTreeViewApi_WorkspaceDto } from "src/api/generated/workspaces/api/workspacesTreeViewApi.ts";
import { WorkspaceConnectedItemRef } from "src/api/generated/workspaces/connections/workspaceConnectedItemRef.ts";

export interface WorkspacesViewProps {
	onlyWorkspaceIdToShow?: number;
	onlyWorkspacesConnectedToItem?: WorkspaceConnectedItemRef;
	isActive?: boolean
}

export const WorkspacesView = ({ onlyWorkspaceIdToShow, onlyWorkspacesConnectedToItem }: WorkspacesViewProps) => {
	const [selectedWorkspace, setSelectedWorkspace] = useState<WorkspacesTreeViewApi_WorkspaceDto | null>(null);
	const isMobile = useMediaQueryBreakpointDown("sm");

	const treeView = (
		<WorkspacesTreeView
			onlyWorkspaceIdToShow={onlyWorkspaceIdToShow}
			onlyWorkspacesConnectedToItem={onlyWorkspacesConnectedToItem}
			onSelectedWorkspaceChanged={setSelectedWorkspace}
		/>
	);

	if (isMobile) return treeView;

	const upperRightCornerViews: AavoMosaicNode = {
		type: "tabs",
		items: [
			{
				type: "panel",
				title: i18n.t("info"),
				content:
					selectedWorkspace == null ?
						<CenteredTypography>{i18n.t("select_workspace")}</CenteredTypography>
					:	<WorkspaceInfoView
							key={selectedWorkspace.workspaceId}
							workspaceId={selectedWorkspace.workspaceId}
						/>,
			},
			{
				type: "panel",
				title: i18n.t("permissions"),
				hidden: !selectedWorkspace?.adminPermissions,
				content:
					selectedWorkspace == null ?
						<CenteredTypography>{i18n.t("select_workspace")}</CenteredTypography>
					:	<WorkspacePermissionsDataGrid
							key={selectedWorkspace.workspaceId}
							workspaceId={selectedWorkspace.workspaceId}
						/>,
			},
		],
	} as const;

	const connectionTabItems: AavoMosaicTabItem[] =
		selectedWorkspace == null ?
			[]
		:	[
				{
					key: "documents",
					type: "panel",
					title: i18n.t("documents"),
					hidden: selectedWorkspace?.documentsEnabled != true,
					content: (
						<DocumentsOfSingleObjectView
							key={selectedWorkspace.workspaceId}
							viewId={"7106B7C5C6EFF3D7"}
							objectRef={{
								objectType: "WORKSPACE",
								objectId: selectedWorkspace.workspaceId,
							}}
							hidePreview={true}
						/>
					),
				},
				{
					key: "tasks",
					type: "panel",
					title: i18n.t("tasks"),
					hidden: selectedWorkspace?.tasksEnabled != true,
					content: (
						<SingleSourceTasksView
							key={selectedWorkspace.workspaceId}
							taskSourceRef={{
								sourceType: "WORKSPACE",
								sourceId: selectedWorkspace.workspaceId,
							}}
						/>
					),
				},
				{
					key: "connections",
					type: "panel",
					title: i18n.t("connections"),
					hidden: selectedWorkspace?.connectionsEnabled != true,
					content: (
						<WorkspaceConnectionsDataGrid
							key={selectedWorkspace.workspaceId}
							workspaceId={selectedWorkspace.workspaceId}
						/>
					),
				},
			];

	const anyConnectionTypeIsEnabled =
		selectedWorkspace?.documentsEnabled || selectedWorkspace?.tasksEnabled || selectedWorkspace?.connectionsEnabled;

	return (
		<AavoMosaic
			viewId={"ADD0BF2619422FBF1D"}
			layout={{
				type: "row",
				splitPercentage: 30,
				first: {
					type: "panel",
					content: treeView,
				},
				second:
					anyConnectionTypeIsEnabled ?
						{
							type: "column",
							first: upperRightCornerViews,
							second: {
								type: "tabs",
								items: connectionTabItems,
							},
						}
					:	upperRightCornerViews,
			}}
		/>
	);
};
