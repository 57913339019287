import i18n from "i18next";
import { ComponentProductionStatusView } from "./views/ComponentProductionStatusView.tsx";
import { FrontendViewConfig } from "src/components/views/frontendViews/frontendViewConfig.ts";
import { faFileXls, faMoneyBillAlt, faObjectsColumn, faPenRuler, faTable } from "@fortawesome/pro-regular-svg-icons";
import { FuturaPlanHolzmaFileModifierView } from "./views/FuturaPlanHolzmaFileModifierView.tsx";
import { defineStaticView } from "src/components/views/frontendViews/frontendViews.ts";
import { faChartSimple } from "@fortawesome/pro-solid-svg-icons";
import { TransactionStatusPage } from "./views/TransactionStatusPage.tsx";
import { ComponentCountPage } from "src/tenantCustomizations/tenants/mellano/views/ComponentCountPage.tsx";
import { MellanoWinnerDesignsView } from "src/tenantCustomizations/tenants/mellano/views/winner/MellanoWinnerDesignsView.tsx";
import {
	LuukkuPurchaseOrderImportView
} from "src/tenantCustomizations/tenants/mellano/views/luukku/purchaseOrders/LuukkuPurchaseOrderImportView.tsx";

export const MELLANO_VIEWS_BY_KEYS: Record<string, FrontendViewConfig> = {
	mellanoComponentProductionStatusView: defineStaticView({
		component: ComponentProductionStatusView,
		title: i18n.t("component_production_status"),
		icon: faObjectsColumn,
		url: "/mellano/componentProductionStatus",
	}),
	mellanoFuturaPlanHolzmaFileModifierView: defineStaticView({
		component: FuturaPlanHolzmaFileModifierView,
		title: "Holzma-tiedoston muokkaus",
		icon: faFileXls,
		url: "/mellano/holzmaFileModify",
	}),
	mellanoTransactionStatusPage: defineStaticView({
		component: TransactionStatusPage,
		title: "Tilaustilanne",
		icon: faChartSimple,
		url: "/mellano/transactionStatus",
	}),
	mellanoComponentCountPage: defineStaticView({
		component: ComponentCountPage,
		title: "Keskeneräinen tuotanto",
		icon: faTable,
		url: "/mellano/componentCount",
	}),
	mellanoWinnerDesignsView: defineStaticView({
		component: MellanoWinnerDesignsView,
		title: "Winner",
		icon: faPenRuler,
		url: "/mellano/winner/designs",
	}),
	mellanoLuukkuPurchaseOrderImportPage: defineStaticView({
		component: LuukkuPurchaseOrderImportView,
		title: "Luukun ostotilaukset",
		icon: faMoneyBillAlt,
		url: "/mellano/luukku/purchaseOrderImport",
	}),
};
