import {Box, Tooltip, Typography, TypographyProps} from "@mui/material";
import {HorizontalBox} from "src/components/common/box/HorizontalBox.tsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/pro-regular-svg-icons";
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import React from "react";

export interface TypographyWithInfoCircleProps extends TypographyProps {
    infoContent: React.ReactNode;
    icon?: IconDefinition;
}

export const TypographyWithInfoCircle = ({
                                             infoContent,
                                             icon = faInfoCircle,
                                             children,
                                             ...other
                                         }: TypographyWithInfoCircleProps) => {
    return (
        <HorizontalBox alignItems={"center"}>
            <Typography {...other}>{children}</Typography>
            {infoContent &&
                <Tooltip title={infoContent ?? "-"}>
                    <Box marginTop={-0.75} padding={1}>
                        <FontAwesomeIcon icon={icon} size={"xs"}/>
                    </Box>
                </Tooltip>
            }
        </HorizontalBox>
    );
};
