// This file is automatically generated. Do not edit manually.

import { DefectPerformanceChartLineView } from "src/api/generated/postgres/db/types/public_/tables/defectPerformanceChartLineView";
import { ChartGroupView } from "src/api/generated/postgres/db/types/public_/tables/chartGroupView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace DefectPerformanceChartLineApi {
	export async function getDefectPerformanceChartLines(args: {
		defectPerformanceChartId: number;
	}): Promise<Array<DefectPerformanceChartLineView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/defectPerformanceChartLine/getDefectPerformanceChartLines",
			method: "POST",
			data: {
				defectPerformanceChartId: args.defectPerformanceChartId,
			},
		});
		return response as Array<DefectPerformanceChartLineView>;
	}

	export async function connectDefectGroupsToDefectPerformanceChart(args: {
		defectPerformanceChartId: number;
		defectGroupIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/defectPerformanceChartLine/connectDefectGroupsToDefectPerformanceChart",
			method: "POST",
			data: {
				defectPerformanceChartId: args.defectPerformanceChartId,
				defectGroupIds: args.defectGroupIds,
			},
		});
		return response as void;
	}

	export async function getConnectableDefectGroups(args: {
		defectPerformanceChartId: number;
	}): Promise<Array<ChartGroupView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/defectPerformanceChartLine/getConnectableDefectGroups",
			method: "POST",
			data: {
				defectPerformanceChartId: args.defectPerformanceChartId,
			},
		});
		return response as Array<ChartGroupView>;
	}

	export async function delete_(args: { defectPerformanceChartLineId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/spc/defectPerformanceChartLine/delete",
			method: "POST",
			data: {
				defectPerformanceChartLineId: args.defectPerformanceChartLineId,
			},
		});
		return response as void;
	}
}
