import i18n from "i18next";
import {AavoMosaic} from "src/components/common/mosaic/AavoMosaic.tsx";
import {
    ChartGroupsDataGrid
} from "src/components/views/spc/controlChart/parentGroupInspectingView/ChartGroupsDataGrid.tsx";
import {useState} from "react";
import {ControlChartGraphView} from "src/components/views/spc/controlChart/controlChartGraph/ControlChartGraphView.tsx";
import {CenteredTypography} from "src/components/common/CenteredTypography.tsx";
import {
    ControlChartsDataGrid
} from "src/components/views/spc/controlChart/parentGroupInspectingView/ControlChartsDataGrid.tsx";
import {ChartGroupView} from "src/api/generated/postgres/db/types/public_/tables/chartGroupView.ts";
import {ControlChartView} from "src/api/generated/postgres/db/types/public_/tables/controlChartView.ts";

export interface ControlChartParentGroupInspectingViewProps {
	parentGroupId: number;
	isActive?: boolean;
}

export const ControlChartParentGroupInspectingView = ({
	parentGroupId,
}: ControlChartParentGroupInspectingViewProps) => {
	const [selectedChartGroup, setSelectedChartGroup] = useState<ChartGroupView | undefined>(undefined);
	const [selectedControlCharts, setSelectedControlCharts] = useState<ControlChartView[]>([]);
	const selectedControlChartIds = selectedControlCharts.map((c) => c.chartId);

	return (
		<AavoMosaic
			viewId={"1A363DC48A584B14"}
			layout={{
				type: "row",
				splitPercentage: 25,
				first: {
					type: "column",
					first: {
						type: "panel",
						title: i18n.t("chart_groups"),
						content: (
							<ChartGroupsDataGrid
								parentGroupId={parentGroupId}
								onSelectionChanged={(row) => {
									setSelectedChartGroup(row);
									setSelectedControlCharts([]);
								}}
							/>
						),
					},
					second: {
						type: "panel",
						title: i18n.t("control_charts"),
						content:
							selectedChartGroup == null ?
								<CenteredTypography children={i18n.t("select_a_chart_group")} />
							:	<ControlChartsDataGrid
									key={selectedChartGroup.chartGroupId}
									chartGroupId={selectedChartGroup.chartGroupId}
									onSelectionChanged={setSelectedControlCharts}
								/>,
					},
				},
				second: {
					type: "panel",
					title: i18n.t("control_chart"),
					content:
						selectedControlCharts.length === 0 ?
							<CenteredTypography children={i18n.t("select_a_control_chart")} />
						:	<ControlChartGraphView
								key={selectedControlChartIds.join(",")}
								controlChartIds={selectedControlChartIds}
								showOpenInspectingButton
							/>,
				},
			}}
		/>
	);
};
