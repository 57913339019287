import {
	dateColumn,
	dateTimeColumn,
	enumColumn,
	floatColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { ProjectActivityMaterialView } from "src/api/generated/erp/db/types/tables/projectActivityMaterialView.ts";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { useUserPermissions } from "src/components/views/erp/common/userPermissions.ts";
import { faBan, faShare, faTools } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/ConfirmDialogContext.ts";
import { OpenPurchaseOrderButton } from "src/components/views/erp/utilComponents/OpenPurchaseOrderButton.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { ProjectActivityMaterialConfiguratorView } from "src/components/views/erp/configurator/configuratorForm/impl/ProjectActivityMaterialConfiguratorView.tsx";
import { OpenPartConfigurationButton } from "src/components/views/erp/configurator/inspecting/OpenPartConfigurationButton.tsx";
import { ServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { useGenericDialog } from "src/components/common/dialogs/GenericDialogContext.ts";
import { ProjectActivityMaterialForm } from "src/components/views/erp/project/projectActivity/material/ProjectActivityMaterialForm.tsx";
import { logError } from "src/errorHandling/errorLogging.ts";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest.ts";
import { ProjectActivityMaterialsDataGridApi_DataGridData } from "src/api/generated/erp/project/projectActivityMaterial/api/projectActivityMaterialsDataGridApi.ts";
import { getProjectActivityMaterialStateLabels } from "src/api/generated/erp/db/types/enums/projectActivityMaterialState.ts";
import { getAcquisitionMethodLabels } from "src/api/generated/erp/db/types/enums/acquisitionMethod.ts";
import { ProjectActivityMaterialActionApi } from "src/api/generated/erp/project/projectActivityMaterial/api/projectActivityMaterialActionApi.ts";
import { OpenShopOrderButton } from "src/components/views/erp/utilComponents/OpenShopOrderButton.tsx";
import { OpenObjectChangeLogButton } from "src/components/views/changeLogging/OpenObjectChangeLogButton.tsx";
import { OpenInventoryProfileButton } from "../../../utilComponents/OpenInventoryProfileButton.tsx";
import {
	createProjectActivityMaterialPartConfiguratorType,
} from "src/components/views/erp/configurator/configuratorForm/utils/partConfiguratorTypeCreators.ts";
import { getConfiguratorFormDialogTitle } from "src/components/views/erp/configurator/configuratorForm/utils/configuratorFormUtils.tsx";

export interface ProjectActivityMaterialsDataGridBaseProps {
	gridId: string;
	fetchData: (args: {
		showCancelledLines: boolean;
		dataModelRequest: ServerSideDataModelRequest;
	}) => Promise<ProjectActivityMaterialsDataGridApi_DataGridData>;
	showActivityColumn?: boolean;
	showLineNumberColumn?: boolean;
}

export const ProjectActivityMaterialsDataGridBase = ({
	gridId,
	fetchData,
	showActivityColumn = false,
	showLineNumberColumn = false,
}: ProjectActivityMaterialsDataGridBaseProps) => {
	const isExternalProjectUser = useUserPermissions().project.isExternalProjectUser;

	const showConfirmDialog = useConfirmDialog();
	const { openDialog } = useGenericDialog();

	return (
		<ServerSideDataGridModel
			fetchData={fetchData}
			initialParams={{
				showCancelledLines: false,
			}}
			getDataModelResult={(data) => data.data}
			getRowId={(row) => row.projectActivityMaterialId}
			gridId={gridId + "_v2"}
			render={({ dataGridProps, refreshData, currentParams, onlySelectedRow, data: { activity } }) => (
				<CrudDataGrid<ProjectActivityMaterialView>
					columns={[
						showActivityColumn && [
							textColumn({
								field: "subProjectDescription",
								headerName: i18n.t("sub_project"),
								width: 150,
							}),
							textColumn({
								field: "activityName",
								headerName: i18n.t("activity"),
								width: 150,
							}),
						],
						showLineNumberColumn &&
							integerColumn({
								field: "lineNumber",
								headerName: i18n.t("line_no"),
							}),
						textColumn({
							field: "configurationOrPartDescription",
							headerName: i18n.t("part"),
							width: 200,
						}),
						floatColumn({
							field: "quantityInBaseUnit",
							headerName: i18n.t("quantity"),
						}),
						floatColumn({
							field: "reservedQuantityInBaseUnit",
							headerName: i18n.t("reserved_quantity"),
						}),
						floatColumn({
							field: "pickedQuantityInBaseUnit",
							headerName: i18n.t("picked_quantity"),
						}),
						floatColumn({
							field: "deliveredQuantityInBaseUnit",
							headerName: i18n.t("delivered_quantity"),
						}),
						textColumn({
							field: "partUnit",
							headerName: i18n.t("base_unit"),
						}),
						floatColumn({
							field: "unitCost",
							headerName: i18n.t("unit_cost"),
						}),
						enumColumn({
							field: "acquisitionMethod",
							headerName: i18n.t("acquisition_method"),
							enumLabels: getAcquisitionMethodLabels(),
							width: 150,
						}),
						textColumn({
							field: "mark",
							headerName: i18n.t("mark"),
						}),
						dateColumn({
							field: "demandDate",
							headerName: i18n.t("demand_date"),
						}),
						dateTimeColumn({
							field: "releasedAt",
							headerName: i18n.t("ordered_at"),
							width: 150,
						}),
						enumColumn({
							field: "state",
							headerName: i18n.t("state"),
							enumLabels: getProjectActivityMaterialStateLabels(),
						}),
						dateTimeColumn({
							field: "cancelledAt",
							headerName: i18n.t("cancelled_at"),
						}),
						textColumn({
							field: "supplierName",
							headerName: i18n.t("supplier"),
						}),
					]}
					actionBarMenuComponents={[
						<MenuCheckbox
							key={"showCancelled"}
							label={i18n.t("show_cancelled")}
							checked={currentParams.showCancelledLines}
							onChange={(checked) => refreshData({ showCancelledLines: checked })}
						/>,
					]}
					actionBarComponents={
						<>
							<DocumentsOfObjectButton
								objectRef={nullableAavoObjectRef(
									"PROJECT_ACTIVITY_MATERIAL",
									onlySelectedRow?.projectActivityMaterialId,
								)}
							/>
						</>
					}
					rowContextMenuComponents={({ row, allSelectedRows, onlySingleRowSelected }) => [
						!isExternalProjectUser && allSelectedRows.some((r) => r.state === "INITIAL") && (
							<AsyncMenuButton
								key={"release"}
								label={i18n.t("release")}
								icon={faShare}
								onClick={async () => {
									const confirmed = await showConfirmDialog({
										message: i18n.t("confirm_release_project_activity_materials"),
									});
									if (!confirmed) return;

									await ProjectActivityMaterialActionApi.releaseMaterials({
										projectActivityMaterialIds: allSelectedRows.map(
											(r) => r.projectActivityMaterialId,
										),
									});
									await refreshData();
								}}
							/>
						),
						onlySingleRowSelected && (
							<AsyncButton
								key={"cancel"}
								label={i18n.t("cancel")}
								icon={faBan}
								variant={"menu"}
								onClick={async () => {
									const confirmed = await showConfirmDialog({
										message: i18n.t("confirm_cancel_project_activity_material_line"),
									});
									if (!confirmed) return;

									await ProjectActivityMaterialActionApi.cancelMaterial({
										projectActivityMaterialId: row.projectActivityMaterialId,
									});
									await refreshData();
								}}
							/>
						),
						onlySingleRowSelected && row.partIsConfigurable && (
							<AsyncMenuButton
								key={"configure"}
								label={i18n.t("configure")}
								icon={faTools}
								disabled={row.state !== "INITIAL"}
								onClick={async () => {
									const configuratorType = createProjectActivityMaterialPartConfiguratorType(
										row.projectActivityMaterialId,
									);
									const dialogTitle = await getConfiguratorFormDialogTitle({configuratorType: configuratorType});
									openDialog(({ closeDialog }) => ({
										title: dialogTitle,
										content: (
											<ProjectActivityMaterialConfiguratorView
												configuratorType={configuratorType}
												closeDialog={closeDialog}
												refreshSourceView={refreshData}
											/>
										),
									}));
								}}
							/>
						),
						onlySingleRowSelected && (
							<OpenPartConfigurationButton
								key={"openConfiguration"}
								partConfigurationId={row.partConfigurationId}
							/>
						),
						onlySingleRowSelected && !isExternalProjectUser && (
							<OpenPurchaseOrderButton key="openPurchaseOrder" purchaseOrderId={row.purchaseOrderId} />
						),
						onlySingleRowSelected && !isExternalProjectUser && (
							<OpenShopOrderButton key="openShopOrder" shopOrderId={row.shopOrderId} />
						),
						onlySingleRowSelected && !isExternalProjectUser && (
							<OpenInventoryProfileButton key={"openInventoryLevelProfile"} partId={row.partId} />
						),
						onlySingleRowSelected && (
							<OpenObjectChangeLogButton
								key={"changeLog"}
								objectRef={{
									objectType: "PROJECT_ACTIVITY_MATERIAL",
									objectId: row.projectActivityMaterialId,
								}}
							/>
						),
					]}
					form={{
						editEnabled: true,
						addRowEnabled:
							activity != null && ["INITIAL", "RELEASED", "STARTED"].includes(activity.activityState),
						dialogSize: "md",
						dialogTitle: i18n.t("material_line"),
						component: ({ row, ...other }) => {
							const activityIdToUse = activity?.activityId ?? row?.activityId;
							if (activityIdToUse == null) {
								logError("Form opened but activityId is null");
								return;
							}
							return (
								<ProjectActivityMaterialForm
									activityId={activityIdToUse}
									materialLineId={row?.projectActivityMaterialId}
									{...other}
								/>
							);
						},
					}}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
