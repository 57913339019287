import {AsyncFetchRefreshFunc, AsyncFetchRender} from "src/components/common/async/AsyncFetchRender";
import {HorizontalBox} from "src/components/common/box/HorizontalBox";
import {VerticalBox} from "src/components/common/box/VerticalBox";
import {AsyncButton} from "src/components/common/buttons/AsyncButton";
import {faSync} from "@fortawesome/pro-regular-svg-icons";
import {useInterval} from "src/utils/useInterval.ts";
import {
    ProductionLineGroupStatusViewApi
} from "src/api/generated/erp/production/productionLineStatus/api/productionLineGroupStatusViewApi.ts";
import {ProductionLineStatusView} from "./ProductionLineStatusView";
import {
    ProductionLineGroupStatus
} from "src/api/generated/erp/production/productionLineStatus/model/productionLineGroupStatus";
import {Typography} from "@mui/material";

export interface ProductionLineGroupStatusViewProps {
    productionLineGroupId: number;
    isActive: boolean;
}

export const ProductionLineGroupStatusView = (props: ProductionLineGroupStatusViewProps) => {
    const {productionLineGroupId} = props;
    return (
        <AsyncFetchRender
            fetch={() => ProductionLineGroupStatusViewApi.getProductionLineGroupStatus({productionLineGroupId})}
            content={(status, refresh) => <AsyncContent status={status} refresh={refresh} {...props} />}
        />
    );
};

interface AsyncContentProps extends ProductionLineGroupStatusViewProps {
    status: ProductionLineGroupStatus;
    refresh: AsyncFetchRefreshFunc<unknown>;
}

const AsyncContent = ({status, refresh, isActive}: AsyncContentProps) => {
    useInterval({
        callback: () => refresh({silent: true}),
        intervalMs: 30 * 1000,
        isActive: isActive,
    });

    return (
        <VerticalBox
            sx={{
                flex: 1,
                margin: 1,
            }}
        >
            <HorizontalBox
                sx={{
                    position: "absolute",
                    top: "0.5rem",
                    left: "0.5rem"
                }}
            >
                <AsyncButton icon={faSync} onClick={() => refresh()}/>
            </HorizontalBox>
            <HorizontalBox
                sx={{
                    flex: 2,
                    gap: 1,
                }}
            >
                {status.productionLines.map((productionLine) => (
                    <VerticalBox flex={1}>
                        <Typography variant={"h4"} textAlign={"center"}>
                            {productionLine.productionLineName}
                        </Typography>
                        <ProductionLineStatusView
                            key={productionLine.productionLineId}
                            productionLineId={productionLine.productionLineId}
                            status={productionLine.status}
                            intervalRefresh={false}
                            refresh={refresh}
                            hideRefreshButton
                            sx={{
                                margin: 0
                            }}
                        />
                    </VerticalBox>
                ))}
            </HorizontalBox>
        </VerticalBox>
    );
};
